import { HttpClient } from '@angular/common/http';
import { Component, ContentChild, OnInit, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ChatService } from '../../services/chat.service';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import * as moment from 'moment';
import { Utils } from '../../utils';

@Component({
  selector: 'app-public-event',
  templateUrl: './public-event.component.html',
  styleUrls: ['./public-event.component.less']
})
export class PublicEventComponent implements OnInit{

  constructor(protected route: ActivatedRoute, protected router: Router, public http: HttpClient, private title: Title, protected commonData: CommonDataService, private chatSrv?: ChatService, private _ngZone?: NgZone) {
    // компоненты
    this.eventComponents = PublicEventComponent.eventComponents.map(e => JSON.parse(JSON.stringify(e)))
      .filter(e => commonData.NOTIFICATIONS_ENABLED || e.key != "notifications");
    this.eventComponentsNoReg = PublicEventComponent.eventComponents.map(e => JSON.parse(JSON.stringify(e)))
      .filter(e => (commonData.NOTIFICATIONS_ENABLED || e.key != "notifications") && e.key != "registration" && e.key != 'polling' && e.key != 'chat');
    this.canSeeModules = false;
  }

  loginId: number;
  chatSub: Subscription;
  destroy$: Subject<boolean>;
  eventComponents: EventComponent[];
  eventComponentsNoReg: EventComponent[];
  canSeeModules: boolean;

  // Только выбранные компоненты
  get eventComponentsChecked(): EventComponent[] {
    // голосование доступно, если есть активные голосования или для редактора
    // ---- Всегда доступно, чтобы можно было сделать делегирование DEV-30070
    //this.eventComponents.forEach(e => e.isDisabled = (e.key == 'polling' && !this.publicEvent.isAnyActivePolling && !this.publicEvent.isEditor));
    if (!this.publicEvent.isVoting && !this.publicEvent.isEditor && !this.publicEvent.isAccessRightsEditor && !this.publicEvent.isParticipant) {
      this.canSeeModules = true;
      let compList = this.eventComponentsNoReg.filter(e => e.isChecked && e.key != 'notifications');
      if (this.publicEvent.components && this.publicEvent.components.indexOf('registration') >= 0) {
        compList.push(PublicEventComponent.regComponent);
      }
      return compList;
    }
    return this.eventComponents.filter(e => e.isChecked
      // голосование доступно только для голосующего или редактора, уведомления доступны для редактора
      && (e.key != 'polling' || this.publicEvent.isVoting || this.publicEvent.isEditor)
      && (e.key != 'chat' || this.publicEvent.isParticipant || this.publicEvent.isEditor)
      && (e.key != 'notifications' || this.publicEvent.isEditor));
  }

  initComplete() { } // для наследников - событие, что мы прочитали данные
  setDescription(id) { }

  ngOnInit(): void {
    if (!history.state.data) { // пришли не из списка, прочитать

      //!!!CHAT
      // Приемник сообщений на время запроса данных
      let tmpMessages: PublicEventChatItem[] = [];
      if (this.chatSrv) {
        this.destroy$ = new Subject<boolean>();
        // подписка на события от Chat Hub
        this.chatSub = this.chatSrv.messageReceivedObservable.pipe(
          takeUntil(this.destroy$),
          distinctUntilChanged((prev, curr) => prev.lastMessageId === curr.lastMessageId)
        ).subscribe((it: PublicEventChatItem) => {
          if (it) {
            if (it.messages?.length > 0 && this.chatSrv.clientId !== it.clientId) {
              this._ngZone.run(() => {
                // Обновление badge
                console.debug(`Unread count badge update. EventId=${it.publicEventId}, ClientId=${it.clientId}`);

                if (this.publicEvent) {
                  this.incUnreadCount(this.publicEvent, it);
                }
                else {
                  // Если еще не зашли на страницу, складываем сообщения в приемник
                  tmpMessages.push(it);
                }
              });
            }
          }
        });
      }
      this.route.params.subscribe(params => {
          const id = +params["id"];
          this.http.get<PublicEvents>(AjaxMethods.getPublicEvents + "/" + id)
            .subscribe(ev => {
              this.publicEvents = <any>ev;
              this.publicEvent = ev && ev.events && ev.events.length ? ev.events[0] : <any>this.initNewPublicEvent() /*новое мероприятие*/;
              this.loginId = this.publicEvents.loginId;
              this.setIsChecked();
              this.title.setTitle(this.publicEvent?.title);

              if (!this.publicEvent.isVoting && !this.publicEvent.isEditor && !this.publicEvent.isAccessRightsEditor && !this.publicEvent.isParticipant) {
                this.canSeeModules = true;
              }
              // Проверка и восстановление соединения чата
              if (this.chatSrv) {
                this._ngZone.runOutsideAngular(() => {
                  this.chatSrv.connect([id], this.loginId);
                });
              }
              if (tmpMessages && tmpMessages.length > 0) {
                tmpMessages.forEach((it) => {
                  this.incUnreadCount(this.publicEvent, it, true);
                });
              }

              this.setDescription(this.publicEvent.id);
              this.initComplete();
            });
         
      });
    }
    else {
      this.publicEvents = history.state.data.publicEvents;
      this.publicEvent = history.state.data.publicEvent;
      if (!this.publicEvent) {
        this.publicEvent = <any>this.initNewPublicEvent();
      }
      this.title.setTitle( this.publicEvent?.title );
      this.setIsChecked();
      this.initComplete();
    }
  }



  incUnreadCount(ev: PublicEvent, it: PublicEventChatItem, isUnreadInc: boolean = false)
  {
    if (ev) {
      if (ev.id === it.publicEventId) {
        if (it.chatId === 0 && (it.isPrivate && it.loginId === this.loginId)) {
          ev.unreadCount++;
        }
        else if (it.chatId > 0 && ev.userChats.some(y => y.chatId === it.chatId)) {
          if (isUnreadInc) {
            const readMessageOrder = ev.userChats.find(x => x.chatId === it.chatId)?.readMessageOrder;
            if (readMessageOrder > 0 && it.lastMessageOrder > readMessageOrder) {
              ev.unreadCount++;
            }
          }
          else {
            ev.unreadCount++;
          }
        }
      }
    }
  }


  initNewPublicEvent() {
    return {
      id: 0, beginDate: new Date(), endDate: moment(new Date()).add(1,"h"), publishDateBegin: null, publishDateEnd: null,
      eventTypeId: this.publicEvents?.ddEventType.length == 1 ? this.publicEvents.ddEventType[0].key : null
    };
  }

  setIsChecked() {
    if (this.publicEvent) { // выставить isChecked из eventComponents
      const sComponents = " " + this.publicEvent.components + " ";
      const sComponentsNoReg = " " + this.publicEvent.componentsNoReg + " ";
      this.eventComponents.forEach(cmp => cmp.isChecked = (sComponents.indexOf(' ' + cmp.key + ' ') >= 0));
      this.eventComponentsNoReg.forEach(cmp => cmp.isChecked = (sComponentsNoReg.indexOf(' ' + cmp.key + ' ') >= 0));
      //this.eventComponentsNoReg.forEach(cmp => cmp.isDisabled = (sComponents.indexOf(' ' + cmp.key + ' ') < 0));
      this.eventComponentsNoReg = this.eventComponentsNoReg.filter(e => (sComponents.indexOf(' ' + e.key + ' ') >= 0));//показываем модуль для выбора, если он помечен в основном списке
    }
  }

  ngOnDestroy(): void {

    //!!!CHAT
    if (this.destroy$) {
      this.destroy$.next(true);
      this.destroy$.complete();

      this.destroy$.unsubscribe();
      console.log("PublicEvent Component Chat Unsubscribe");

    }
    // !!! не закрываем
    // if (this.chatSrv)
    // this.chatSrv.close();
  }

  publicEvent?: PublicEvent = <any>{};
  publicEvents: PublicEvents;
  get ddEventType(): { key: any, value: string }[] { return this.publicEvents?.ddEventType; }
  get isEditor() {
    return this.publicEvents?.isEditor && (this.publicEvents?.isCCSEditor || !this.publicEvent?.isEventTypeCCS);
  }

  //  "редактировать"
  onEditClick() {
    this.route.url.subscribe(urls => {
      var editUrl = '/' + urls.join('/') + '/edit'
      this.router.navigate([editUrl]);
    });
  }
  // Infobar - зеленый/серый текст
  static getInfoBar(p: PublicEvent): { isHighlighted: boolean, text: string } {
    let now = moment(new Date());
    if ((p.publishDateBegin && p.publishDateEnd) && !now.isBetween(moment(p.publishDateBegin), moment(p.publishDateEnd))) {
      var isExpired = moment(p.publishDateEnd) < moment(new Date());
      var ret = {
        isHighlighted: !isExpired,
        text: isExpired ? `Скрыто ${Utils.formatDateLong(p.publishDateEnd)}`
          : `Опубликовать: ${Utils.formatDateLong(p.publishDateBegin)}`
      };
      return ret;
    }
    return null;
  }
  // Информационное сообщение в зеленой полоске
  get infoBar() {
    if (this.publicEvents?.isEditor && this.publicEvent?.publishDateBegin && this.publicEvent?.publishDateEnd) {
      return PublicEventComponent.getInfoBar(this.publicEvent);
    }
    return null;
  }

  static regComponent: EventComponent = { key: 'registration', icon: "registration", routePart: "registration", text: 'Регистрация' };

  static eventComponents: EventComponent[] = [
    { key: 'info', icon: "info", routePart: "info", text: 'О мероприятии' },
    { key: 'program', icon: "clipboard", routePart: "programme", text: 'Программа' },
    /*{ key: 'news', icon: "clipboard", routePart: "news", text: 'Новости' },*/
    { key: 'files', icon: "file-download", routePart: "files", text: 'Материалы' },
    { key: 'polling', icon: "polling", routePart: "polling", text: 'Голосования и опросы' },
    { key: 'participants', icon: "participants", routePart: "participants", text: 'Участники' },
    PublicEventComponent.regComponent,
    { key: 'contacts', icon: "contacts", routePart: "contacts", text: 'Связаться с организаторами' },
    { key: 'notifications', icon: "bell-black", routePart: "notifications", text: 'Уведомления' },
    { key: 'gallery', icon: "gallery", routePart: "gallery", text: 'Фотогалерея' },
    { key: 'location', icon: "location", routePart: "location", text: 'Место проведения' },
    { key: 'chat', icon: "chat", routePart: "chat", text: 'Чат' },
  ];


  ngAfterContentInit() {
  }

  // ссылка "назад". На список мероприятий
  get backUrl(): string {
    if (this.publicEvent?.isEventTypeCCS) {
      return "/public-events-ccs";
    }
    return "/public-events";
  }

}
