import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { PublicEventComponent } from '../public-event/public-event.component';

@Component({
  selector: 'app-public-event-info',
  templateUrl: './public-event-info.component.html',
  styleUrls: ['./public-event-info.component.less']
})
export class PublicEventInfoComponent extends PublicEventComponent implements OnInit {

  constructor(route: ActivatedRoute, router: Router, http: HttpClient, title: Title, commonData: CommonDataService) {
    super(route, router, http, title, commonData);
  }
  isEditMode = false;

  self = this;
  setDescription(id) {
    setTimeout(() => { 
      this.http.get<PublicEvent>(AjaxMethods.getPublicEventDescription.replace("{id}", id)).subscribe(data => {
        if (data && data.id === id) {
          this.publicEvent.description = data.description;
          this.publicEvent.isNewDescription = data.isNewDescription;
        }
      });
    });
  }


  // Сохранить
  onSaveClick() {
    this.http.post<any>(AjaxMethods.postPublicEventInfo, { publicEventId: this.publicEvent?.id, content: this.publicEvent?.description, isNewDescription: this.publicEvent?.isNewDescription })
      .subscribe(() => {
        this.isEditMode = false;
      });
  }

  onCancelEditClick() {
    this.isEditMode = false;
    if (this.backUrl) { // указано, куда переходить. Туда и переходим
      this.router.navigate([this.backUrl]);
    }
  }

  // Ссылка назад
  get backUrl() {
    return '/' + this.route.snapshot.url.slice(0, 2).join('/') + '/';
  }
}
