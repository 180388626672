


<div [@routeAnimations]="prepareRoute(outlet)" class="top-wrapper"> 
  <router-outlet #outlet="outlet"></router-outlet>
</div>

<div class="spinner-style" *ngIf="isSpinnerVisible">
  <mat-progress-spinner color="#000"
                        mode="indeterminate"
                        diameter="60">
  </mat-progress-spinner>
    <div class="backdrop"></div>
</div>

<app-confirmation-dialog #confirmationDialog></app-confirmation-dialog>
