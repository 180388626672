<app-screen-general header='Мероприятия'
                    backUrl="/"
                    [showFilter]="true"
                    [isFilterApplied]="isFilterApplied"
                    [filterTmpl]="filterTmpl"
                    (onFilterApply)="onFilterApply()"
                    (onFilterReset)="onFilterReset()"
                    [fixedTmpl]="fixedTmpl">
  <ng-template #fixedTmpl>
    <app-new-delete-buttons *ngIf="isEditor"
                            (onNewClick)="onNewClick()">
    </app-new-delete-buttons>
  </ng-template>
 
  <div>
    <ng-container *ngTemplateOutlet="eventsTmpl; context: {items: actualEvents}"></ng-container>
  </div>
  <!--Прошедшие-->
  <div>
    <h2>Прошедшие</h2>
    <div class="hist-years-wrapper">
      <div *ngFor="let it of historyYears" class="hist-year" [ngClass]="histYear == it ? 'active' : ''"
           (click)="histYear = (histYear == it ? -1 : it)">
        <div>{{it}}</div>
      </div>
    </div>
    <div>
      <ng-container *ngTemplateOutlet="eventsTmpl; context: {items: getHistoryEvents(histYear||-1), isHistory:true }"></ng-container>
    </div>
  </div>


</app-screen-general>

<!-- Шаблон. Итератор для показа списка событий -->
<ng-template #eventsTmpl let-items="items" let-isHistory="isHistory">
  <app-nav-list-item *ngFor="let it of items; let ix = index" [textTmpl]="tmpl" [navLink]="'/public-events/' + it.id "
                     [param]="null" [isTopBorder]="ix == 0">
    <!--{publicEvent:it, publicEvents: publicEvents}"-->
    <ng-template #tmpl>
      <div class="event-item" [matBadge]="it.unreadCount > 9 ? '9+' : ''+it.unreadCount" [matBadgeHidden]="!it.unreadCount" matBadgeSize="medium" matBadgeOverlap="true" matBadgePosition="after">
        <div class="text-block" [ngClass]="isHistory ? 'history-item' : ''">
          <div class="first-line" [innerHTML]="it.title + '<br/>' + it.eventTypeName"></div>
          <div class="second-line" [innerHTML]="it.dateStr"></div>
          <div class="publish-status" *ngIf="isEditor" [ngClass]="this.getInfoBar(it)?.isHighlighted ? '' : 'grey'">{{this.getInfoBar(it)?.text}}</div>
        </div>
      </div>
    </ng-template>
  </app-nav-list-item>
</ng-template>


<!--Фильтр-->
<ng-template #filterTmpl>
  <div class="filter-form">
    <mat-form-field>
      <mat-label>Что ищем?</mat-label>
      <input matInput [(ngModel)]="filterSearchText" />
    </mat-form-field>
  </div>
  <h2>Время проведения</h2>
  <app-date-and-time label="Начало" [editValue]="filterDates.filterDateBegin" (editValueChange)="onFilterDateBeginChange($event)" [showTime]="false">
  </app-date-and-time>
  <app-date-and-time label="Окончание"  [editValue]="filterDates.filterDateEnd" (editValueChange)="onFilterDateEndChange($event)" [showTime]="false">
  </app-date-and-time>
</ng-template>
