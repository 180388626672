import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApplicationRef, Component, ElementRef, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '../services/chat.service';
import { CommonDataService } from '../common-data.service';
import { BiometricService } from '../services/biometric.service';
import { AjaxMethods } from '../models/AjaxMethods';
import { SwPush } from '@angular/service-worker';
import { Utils } from '../utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { range, of, Subject, interval, timer, merge, Observable } from 'rxjs';
import { mergeMap, delay, map, delayWhen,takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.less']
})
export class UserProfileComponent implements OnInit {

  get platform() { return this.commonSrv.platform; }

  constructor(private http: HttpClient, private chatSrv: ChatService, private commonSrv: CommonDataService, private bioSrv: BiometricService, private router: Router, private app: ApplicationRef, private swPush: SwPush, private snackbar: MatSnackBar, private _ngZone: NgZone, private sanitizer: DomSanitizer, private appRef: ApplicationRef) {
    //commonSrv.TCHLinkEditBackUrl = "user-profile";
    this.NOTIFICATIONS_ENABLED = commonSrv.NOTIFICATIONS_ENABLED;
  }
  NOTIFICATIONS_ENABLED;
  getUserNameWithCRLF() {
    return this.name?.replace(/ /g, ' <br/>');
  }
  
  webSubs: { endpoint, p256dh, auth } = <any>{};
  webTokenName: string = "WebTokenName";
  // mobileToken: any;
  mobileTokenSubscription: any;
  // Контекст, в которм выполняется подписка
  ctxSubscribe: string;
  isPushSubscribed: boolean;
  useBioCredential: boolean = false;

  getMobileToken: any = new Subject();


  // Получить токен подписки. Вызов нужного метода в зависимости от платформы
  getSubscription(reqSubscribe: boolean): Promise<boolean>{
    return new Promise<boolean>(resolve => {
      this.commonSrv.platformPromise.then(platform => {
        (platform == "web" ? this.getWebSubscription(reqSubscribe) : this.getMobileSubscription(reqSubscribe))
          .then(d=> resolve(d));
      });
    });
  }

  // Функция задержки
  delay(ms: number): Promise<any> {
    return of().pipe(delay(ms)).toPromise();
  }


  // Получить токен подписки, если есть
  getMobileSubscription(reqSubscribe: boolean): Promise<boolean> {
    console.log("getMobileSubscription invoked");
    return new Promise<boolean>(resolve => {
        // запросить статус разрешения
        PushNotifications.checkPermissions().then(permStatus => {
            console.log("getMobileSubscription. checkPermissions. Permission status:" + permStatus.receive);
            if (permStatus.receive == 'prompt') {
              if (reqSubscribe) {
                // запросить разрешение
                PushNotifications.requestPermissions().then(permStatus => {
                  console.log("getMobileSubscription. permission status:" + permStatus.receive);
                  if (permStatus.receive == 'granted') {
                    console.log("cap. Regsitering");
                    PushNotifications.register()
                      .then(() => {
                        // Подождать, чтобы регистрация закончилась и пришло событие registered с токеном
                        const start = new Date().getTime();
                        interval(100).pipe(takeUntil(merge(this.getMobileToken, timer(5000)))).subscribe(
                          x => {  },
                          e => { console.log('PushNotifications. Registry error: ', e); },
                          () => {
                            const end = new Date().getTime();
                            if (this.token) {
                              console.log(`Registered. Token:  ${this.token}. Lead time: ${end - start}ms`);
                              resolve(true);
                            }
                            else {
                              console.log("Not registered. Token was not received.");
                            }
                          });
                          //setTimeout(() => {
                          //  console.log("Registered. Token: " + this.token);
                          //  resolve(true);
                          //}, 1000);
                      })
                      .catch(reason => {
                        console.error("cap. Register rejected, reason", reason);
                        resolve(false);
                      });
                  }
                  else {
                    console.error("Denied permissions!  Permission status:", permStatus.receive);
                    resolve(false);
                  }
                }).catch(error => { console.error(`getMobileSubscription. checkPermissions error:${error}`); });
              }
              else {
                // console.error("Denied permissions!  Permission status:", permStatus.receive);
                resolve(false);
              }
            }
            else if (permStatus.receive == 'granted') {
              console.log("Regsitering");
              PushNotifications.register()
                .then(() => {
                  // Подождать, чтобы регистрация закончилась и пришло событие registered с токеном
                  const start = new Date().getTime();
                  interval(100).pipe(takeUntil(merge(this.getMobileToken, timer(5000)))).subscribe(
                    x => { },
                    e => { console.log('PushNotifications. Registry error: ', e); },
                    () => {
                      const end = new Date().getTime();
                      if (this.token) {
                        console.log(`Registered. Token:  ${this.token}. Lead time: ${end - start}ms`);
                        resolve(true);
                      }
                      else {
                        console.log("Not registered. Token was not received.");
                      }
                    });

                  //setTimeout(() => {
                  // Подождать, чтобы регистрация закончилась и пришло событие registered с токеном
                  //  console.log("Registered. Token: " + this.token);
                  //  resolve(true);
                  //}, 1000);

                })
                .catch(reason => {
                  console.error("Register rejected, reason", reason);
                  resolve(false);
                });
            }
            else {
              console.error("Denied permissions!  Permission status:", permStatus.receive);
              resolve(false);
            }
        }).catch(error => { console.error(`getMobileSubscription. checkPermissions error:${error}`); });
      
    });
  }


  // Подписаться Web
  getWebSubscription(reqSubscribe?: boolean/*если указано, то не проверяем permission*/): Promise<boolean> {
    console.log("getWebSubscription invoked");
    return new Promise<boolean>((resolve, reject) => {

      console.log("in promise before first check");

      if (!window.PushManager) {
        if (reqSubscribe) {
          console.error('Failed to subscribe');
          this.snackbar.open("Не удалось включить подписку, поскольку версия Вашего браузера не поддерживает push-уведомления", "Закрыть", Utils.snacBarConfig).onAction().subscribe(() => {
            // this.appRef.tick();
          });
        }
        else {
          console.error('Не удалось включить подписку, поскольку версия Вашего браузера не поддерживает push-уведомления');
        }
        resolve(false);
      }
      else if (!window.Notification || window.Notification?.permission !== "granted") {
        if (reqSubscribe) {
          console.error('Failed to subscribe');
          this.snackbar.open("Не удалось включить подписку из-за отсутствия разрешения в Вашем браузере", "Закрыть", Utils.snacBarConfig);
        }
        else {
          console.error('Не удалось включить подписку из-за отсутствия разрешения в Вашем браузере');
        }
        resolve(false);
      }
      else {
          this.swPush.subscription.subscribe((subscription) => {
            if (subscription) {

              var base64String = btoa(String.fromCharCode.apply(null, <any>new Uint8Array(<any>subscription.options.applicationServerKey)));
              console.log("serverKey:" + base64String);
              this.webSubs.endpoint = subscription.endpoint;
              this.webSubs.p256dh = Utils.arrayBufferToBase64(subscription.getKey("p256dh"));
              this.webSubs.auth = Utils.arrayBufferToBase64(subscription.getKey("auth"));
              //localStorage[this.webTokenName] = this.webSubs.auth;
              resolve(true);
            }
            else {
              this.swPush.requestSubscription({ serverPublicKey: this.commonSrv.VAPIDPublicKey })
                .then((subs) => {
                  // console.log("subscription:", subs);
                  var base64String = btoa(String.fromCharCode.apply(null, <any>new Uint8Array(<any>subs.options.applicationServerKey)));
                  console.log("serverKey:" + base64String);
                  this.webSubs.endpoint = subs.endpoint;
                  this.webSubs.p256dh = Utils.arrayBufferToBase64(subs.getKey("p256dh"));
                  this.webSubs.auth = Utils.arrayBufferToBase64(subs.getKey("auth"));
                  //localStorage[this.webTokenName] = this.webSubs.auth;
                  resolve(true);
                })
                .catch((err) => {
                  // Возможно сменился ключ, переподписываемся
                  this.swPush.unsubscribe().then(successful => {
                    this.swPush.requestSubscription({ serverPublicKey: this.commonSrv.VAPIDPublicKey })
                      .then((subs) => {
                        // console.log("subscription:", subs);
                        var base64String = btoa(String.fromCharCode.apply(null, <any>new Uint8Array(<any>subs.options.applicationServerKey)));
                        console.log("serverKey:" + base64String);
                        this.webSubs.endpoint = subs.endpoint;
                        this.webSubs.p256dh = Utils.arrayBufferToBase64(subs.getKey("p256dh"));
                        this.webSubs.auth = Utils.arrayBufferToBase64(subs.getKey("auth"));
                        //localStorage[this.webTokenName] = this.webSubs.auth;
                        resolve(true);
                      })
                      .catch((err) => {
                        if (reqSubscribe) {
                          console.error('Failed to subscribe');
                          this.snackbar.open("Не удалось включить подписку, поскольку версия Вашего браузера устарела", "Закрыть", Utils.snacBarConfig);
                          //localStorage[this.webTokenName] = null;
                        }
                        else {
                          console.error('Не удалось включить подписку, поскольку версия Вашего браузера устарела');
                        }
                        resolve(false);
                      });
                  }).catch(e => {
                    if (reqSubscribe) {
                      console.error('Failed to subscribe');
                      this.snackbar.open("Не удалось включить подписку, поскольку версия Вашего браузера устарела", "Закрыть", Utils.snacBarConfig);
                      //localStorage[this.webTokenName] = null;
                    }
                    else {
                      console.error('Не удалось включить подписку, поскольку версия Вашего браузера устарела');
                    }
                    resolve(false);
                  });
                }).catch(e => {
                  if (reqSubscribe) {
                    console.error('Failed to subscribe');
                    this.snackbar.open("Не удалось включить подписку, поскольку версия Вашего браузера устарела", "Закрыть", Utils.snacBarConfig);
                    //localStorage[this.webTokenName] = null;
                  }
                  else {
                    console.error('Не удалось включить подписку, поскольку версия Вашего браузера устарела');
                  }
                  resolve(false);
                });
            }
          });
      }
    });
  }

  // Токен в зависимости от платформы
  get token(): string {
    return this.platform == "web" ? this.webSubs?.auth : this.commonSrv.mobileToken;
  }

  get isBioAvailable(): boolean {
    //!!! BIO
    //  && this.commonSrv.baseUrl === "https://appdev.tch.ru"
    if (this.platform !== "web") {   
      return this.bioSrv ? this.bioSrv.isAvailable : false;
    }
    return false;
  }

  get isBioCredentialAvailable(): boolean {
    return this.isBioAvailable ? this.bioSrv.isCredentialAvailable : false;
  }
      
  get bioTypeName(): string {
    return this.isBioAvailable ? this.bioSrv.biometryTypeName : "";
  }

  bioTypeNameCases(casenumber, iscapitalise = false): string {

    return this.isBioAvailable ? this.bioSrv.biometryTypeNameCase(casenumber, iscapitalise) : "";

  }

  onUseBioCredential() {

    if (this.useBioCredential) {
      console.log('useBioCredential set false ');
      this.useBioCredential = false;
      localStorage.setItem("useBioCredential", "false");
      if (this.isBioCredentialAvailable) {
        console.log('Delete bio credential. ');
        this.bioSrv.deleteCredential(this.commonSrv.baseUrl);
      }
    }
    else {
      console.log('useBioCredential set true ');
      this.useBioCredential = true;
      localStorage.setItem("useBioCredential", "true");
    }


  }


  // Инициализация при открытии окна. получить токен подписки, чтобы понять, есть ли подписка
  ngOnInit(): void {

    this.ctxSubscribe = "ngOnInit";

    const self = this;
  
    if (this.commonSrv.notificationRegistration) {

      this.mobileTokenSubscription = this.commonSrv.notificationRegistration.subscribe(ltoken => {

        if (ltoken && ltoken === this.token) {
          console.log(`Notification registered. Token:${this.token}`);
          this.getMobileToken.next();
        }
      });
    }

    
    if (this.platform !== "web") {
      const strUseBioCredential = localStorage["useBioCredential"];
      console.log(`strUseBioCredential: ${strUseBioCredential}`);
      if (strUseBioCredential) {
        this.useBioCredential = JSON.parse(strUseBioCredential);

        console.log(`useBioCredential init: ${this.useBioCredential}`);
      }

      // Проверка существования BIO Credential
      // this.bioSrv.checkCredenti+al(null, () => { this.useBioCredential = false; });
      this.bioSrv.checkCredential();
    }
    


    this.http.post<UserProfileAttrs>(AjaxMethods.userProfile, this.token, { headers: new HttpHeaders({ 'Content-Type': "text/plain" }) }).subscribe((data) => {

      for (let attrName in data) {
        if (attrName !== 'showEventMessages' && attrName !== 'isPushSubscribed') {
          (<any>this)[attrName] = (<any>data)[attrName];
        }
      }

      (this.getSubscription(false))
        .then((isOK) => {
          if (this.token) {  //  && this.platform == "web"
            setTimeout(function () {
              self.http.post<UserProfileAttrs>(AjaxMethods.userPushSubscriptions, self.token, { headers: new HttpHeaders({ 'Content-Type': "text/plain" }) }).subscribe((data) => {
                if (data) {
                  console.log(`Get User profile - IsPushSubscribed:${data.isPushSubscribed}. ShowEventMessages:${data.showEventMessages}.`);
                  self.isPushSubscribed = data.isPushSubscribed;
                  self.showEventMessages = data.isPushSubscribed ? data.showEventMessages : false;
                  self.isTchMailingSubscribed = data.isPushSubscribed ? data.isTchMailingSubscribed : false;
                }
              });
            }, 100);
          }
        });
    });


 

  }

  birthDate: string;
  email: string;
  name: string;
  login: string;
  company: string;
  phone: string;
  position: string;
  photo: string;
  showContactData: boolean;
  showEventMessages: boolean;
  hasTCHLinkAccess: boolean;
  // hasAccess: boolean;
  hasParticipantRole: boolean;
  hasTchMailingRole: boolean;
  isTchMailingSubscribed: boolean;
  
  // toggleSubscribed: boolean;
 

  get safePhoto() {
    return this.photo ? this.sanitizer.bypassSecurityTrustResourceUrl(this.photo) : null;
  }
  // Фото есть
  get photoExists() : boolean { return Boolean(this.photo); }

  // Кнопка "Выйти"
  onExitClick() {
    // localStorage[this.commonSrv.localStorageTokenKey] = "";
    //!!!CHAT 
    if (this.chatSrv) {
      this.chatSrv.close();
    }

    this.commonSrv.JWTToken = "";
    this.http.get<any>(AjaxMethods.logoff).subscribe();
    this.router.navigate(["/login/?"]);
  }

  
  
  // Включить подписку
  doSubscribe() {
    let self = this;
    let subsDone = new Promise<boolean>(resolve => resolve(true));

    console.debug("doSubscribe run");

    if (!this.token || !this.isPushSubscribed) {
      console.debug("Subscription Auth/mobileToken not defined");
      subsDone = this.getSubscription(true);
    }
    subsDone.then((flag) => {
      if (self.token) {
        var obj = { ...{}, ...self.webSubs, ...{ platform: self.platform, mobileToken: self.commonSrv.mobileToken, baseUrl: window.location.origin } };
        console.log(`Setting subscription. Token:${self.token} Platform:${self.platform}, request:${JSON.stringify(obj)}`);

        self.http.post(AjaxMethods.SetPushSubscription
          , { ...{}, ...self.webSubs, ...{ platform: self.platform, mobileToken: self.commonSrv.mobileToken, baseUrl: window.location.origin } })
          .subscribe(() => {
            self._ngZone.run(() => { self.isPushSubscribed = true; });
          });
      }
      else {
        setTimeout(() => {
          self._ngZone.run(() => { self.isPushSubscribed = false; });
        });
      }
    });
  }

  // отключить подписку
  doUnsubscribe() {

    console.debug("doUnsubscribe run");

    let self = this;
    if (this.showEventMessages) {
      this.onCbxChange('showEventMessages');
    }
    if (this.isTchMailingSubscribed) {
      this.onCbxChange('isTchMailingSubscribed');
    }
    this.http.post(AjaxMethods.RemovePushSubscription, this.token
      , { headers: new HttpHeaders({ 'Content-type': 'text/plain' }) }).subscribe(() => {
        self._ngZone.run(() => { self.isPushSubscribed = false; });

      });
  }

  // кнопка "подписаться/отписаться"
  onSubscriptionManage() {

    this.ctxSubscribe = "onSubscriptionManage";
    if (this.isPushSubscribed) {
      this.doUnsubscribe();
    }
    else {
      this.doSubscribe();
    }

    // this.isPushSubscribed = !this.isPushSubscribed;
    
  }

  @ViewChild("fileInput")
  fileInputElement: ElementRef<HTMLInputElement>;

  // UserProfileAttrs для post
  getPostModel() {
    var ret: UserProfileAttrs = {
      photo: this.photo,
      showContactData: this.showContactData,
      showEventMessages: this.showEventMessages,
      isTchMailingSubscribed: this.isTchMailingSubscribed
    };
    return ret;
  }

  // Загрузили файл
  maxFileSize = 10 << 20;
  onFileInputChange($event: Event) {
    let inputElement = (<HTMLInputElement>$event.target);
    let files: FileList = inputElement.files;
    if (files.length) {
      let uploadedFile = files[0];

      let isImage = uploadedFile.type.toLowerCase().startsWith("image") && (uploadedFile.name.toLowerCase().endsWith(".jpg") || uploadedFile.name.toLowerCase().endsWith(".jpeg")
        || uploadedFile.name.toLowerCase().endsWith(".png") || uploadedFile.name.toLowerCase().endsWith(".gif") || uploadedFile.name.toLowerCase().endsWith(".bmp"));
      if (!isImage) {
        this.commonSrv.confirmationDialog.msgBox({
          message: `Файл не является изображением`
        });
        inputElement.value = '';
        return;
      }
      if (uploadedFile.size > this.maxFileSize) {
        this.commonSrv.confirmationDialog.msgBox({
          message: `Размер файла ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize >> 20}Mb`
        });
        inputElement.value = '';
        return;
      }

      uploadedFile.arrayBuffer().then(arr => {
        let bl = new Blob([arr]);
        let rdr = new FileReader();
        rdr.onloadend = () => {
          this.photo = <string>rdr.result;
          // Отправить в профайл пользователя
          this.http.post<any>(AjaxMethods.postProfileAttrs, this.getPostModel())
            .subscribe(null,
              (err) => {
                this.photo = null;
                console.error(`onFileInputChange. savePhoto error:${err}`);
              });
        }
        rdr.readAsDataURL(bl);
      });
      inputElement.value = '';
    }
  }
  // Один из признаков профайла изменился. Сохранить
  onCbxChange(attrName: string) {
    this[attrName] = !this[attrName];
    this.http.post<any>(AjaxMethods.postProfileAttrs, this.getPostModel())
      .subscribe(null, () => this[attrName] = !this[attrName]);
  }

  //-------- Загрузить фото
  onUploadPhotoClick() {
    if (this.photoExists) {

      this.commonSrv.confirmationDialog.confirm({ message: "Удалить фотографию?", title: 'Удаление' })
        .then((confirmed) => {
          if (!confirmed) return;
          this.photo = null;
          this.http.post<any>(AjaxMethods.postProfileAttrs, this.getPostModel())
            .subscribe(null,
              (err) => {
                console.error(`onUploadPhotoClick. savePhoto error:${err}`);
              });
        });
    }
    else {
      this.fileInputElement.nativeElement.click();
    }


  }


  ngOnDestroy() {
    if (this.mobileTokenSubscription)
      this.mobileTokenSubscription.unsubscribe();
  }
}
