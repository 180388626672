
<div #wrapper>
  <app-screen-with-panel [title]="mainTitle"
                         [bodyTmpl]="bodyTmpl"
                         [isEditable]="isEditable"
                         (onEditClick)="onEditClick()"
                         (onBackClicked)="onBackClicked()"
                         [backUrl]="backUrl"
                         [isInnerScroll]="true"
                         >

    <ng-template #bodyTmpl>

      <app-public-event-files-int [publicEventId]="publicEventId" [moveOutElement]="wrapper" [programItemId]="0" (onEditBeginClick)="onEditBeginClick()" (onEditEndClick)="onEditEndClick()">
      </app-public-event-files-int>


    </ng-template>
  </app-screen-with-panel>
</div>
