import { Directive, ElementRef, HostListener, Input, EventEmitter } from '@angular/core';

  type UpDown = 'up' | 'down';

@Directive({
  selector: '[appDragPanel]'
})


export class DragPanelDirective {

  constructor(private elementRef: ElementRef) { }

  @Input("appDragPanel")
  prm: {
    onDrop: EventEmitter<UpDown>,
      allow: UpDown
  };

  isDragging = false;
  dragStart: Point;
  lastMove: any;
  originalHeight: number;

  getScreenPoint(ev: TouchEvent) {
    return { screenX: ev.touches[0].screenX, screenY: ev.touches[0].screenY };
  }

  @HostListener("window:touchmove", ['$event'])
  onTouchMove(ev: TouchEvent) {
    this.lastMove = this.getScreenPoint(ev);
    this.onMouseMove({ ...{ target: ev.target }, ...<any>this.lastMove });
    return true;
  }
  @HostListener("window:touchstart", ['$event'])
  onTouchStart(ev: TouchEvent) {
    this.lastMove = this.getScreenPoint(ev);
    this.onMouseDown({ ...<any>this.lastMove, ...{ target: ev.target } });
    return true;

  }
  @HostListener("window:touchend", ['$event'])
  onTouchEnd(ev: TouchEvent) {
    if (this.lastMove) {
      this.onMouseUp(this.lastMove);
    }
    this.lastMove = null;
    return true;

  }

  @HostListener("window:mousedown", ['$event'])
  onMouseDown(ev: MouseEvent) {
    //console.debug('target', ev.target);
    this.isDragging = true;
    this.wasMovement = false;
    this.dragStart = new Point(ev.screenX, ev.screenY);
    this.originalHeight = this.elementRef.nativeElement.clientHeight;
    return true;

  }
  wasMovement = false;
  @HostListener("window:mousemove", ['$event'])
  onMouseMove(ev: MouseEvent) {
    if (this.isDragging) {
      this.wasMovement = true;
      let movement = new Point(ev.screenX - this.dragStart.x, ev.screenY - this.dragStart.y);// перемещение относительно стартовой точки
      if ((movement.y < 0 && this.prm.allow == 'up') // только вверх
        || (movement.y > 0 && this.prm.allow == 'down')) {
        //console.log(`mouseMove:(${ev.screenX},${ev.screenY}) original:(${this.dragStart.x}, ${this.dragStart.y}) move:(${movement.x},${movement.y})`);
        //this.callDropEvent();
        this.setTransform(movement);
      } else {
      }
    }
    return true;
  }
  @HostListener("window:mouseup", ['$event'])
  onMouseUp(ev: MouseEvent) {
    this.isDragging = false;
    if (!this.wasMovement) return true; // не двигали
    this.prm.onDrop.emit(ev.screenY < this.dragStart.y ? 'up' : 'down');
    // вернуть на место
    let el = <HTMLElement>this.elementRef.nativeElement;
    el.style.transform = "";
    el.style.height = "";
    return true;
  }
  // Модификация CSS, чтобы двигать его
  setTransform(movement: Point) {
    let el = <HTMLElement>this.elementRef.nativeElement;
    el.style.transform = `translateY(${movement.y}px)`;
    el.style.height = `${this.originalHeight - movement.y}px`;
  }
}

class Point {
  constructor(public x: number, public y: number) { }
}
