import {  ApplicationRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicEventComponent } from '../public-event/public-event.component';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { NgModel } from '@angular/forms';
import { Utils } from '../../utils';
import { ScreenWithPanelComponent } from '../../screen-with-panel/screen-with-panel.component';
import { PublicEventFilesIntComponent } from '../public-event-files-int/public-event-files-int.component';

@Component({
  selector: 'app-public-event-files',
  templateUrl: './public-event-files.component.html',
  styleUrls: ['./public-event-files.component.less']
})
//----------- Материалы, aka Files мероприятия "public-events/:id/files"
export class PublicEventFilesComponent extends PublicEventComponent implements OnInit {

  constructor(route: ActivatedRoute, router: Router, http: HttpClient, title: Title, commonData: CommonDataService, private applicationRef: ApplicationRef, private common: CommonDataService, private sanitizer: DomSanitizer) {
    super(route, router, http, title, commonData);

  }

  ngOnInit(): void {
    // прочитать список файлов
    this.route.params.subscribe(params => {
      let id = params["id"];
      this.publicEventId = Number(id);
    });
  }
  publicEventId: number;
  isEditable = false;
  mainTitle = "Материалы";

  // Назад. К событию. Наш url "public-events/:id/files". Оставить только 2 сегмента
  get backUrl() {
    if (!this.isEditable) {
      return "/" + this.route.snapshot.url.slice(0, 2).join("/") + "/";
    }
    return null;
  }

  onBackClicked() {
    if (this.isEditable) {
      this.filesIntComponent.onBackClicked();
    }
  }

  onEditBeginClick() {
    this.isEditable = true;
    this.mainTitle = "Материал";
  }

  onEditEndClick() {
    this.isEditable = false;
    this.mainTitle = "Материалы";
  }

  @ViewChild(PublicEventFilesIntComponent)
  filesIntComponent: PublicEventFilesIntComponent;

  onEditClick() {
    this.filesIntComponent.editIconClicked();
  }
}
