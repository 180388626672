<app-screen-general header="Уведомлять о размере управляемой суммы" backUrl="tch-link/edit">

  <div class="hr"></div>

  <!--Дни недели-->
  <div class="weekday-wrapper" *ngFor="let w of weekdays">
    <mat-checkbox [(ngModel)]="w.isChecked"></mat-checkbox>
    <span>{{w.name}}</span>
  </div>

  <div class="hr"></div>
  <!--{{weekdaysMask.toString(16)}}-->

  <div class="subheader">Время уведомления</div>
  <div class="hr"></div>

  <!-- Время - список-->
  <div *ngFor="let t of times, let ix = index" class="time-row">
    <!--
  <app-time-picker [(editVal)]="t.dt"></app-time-picker>
    -->
    <app-date-and-time label="" [(editValue)]="t.dt" [showDate]="false" [showSep]="false"></app-date-and-time>
    <div class="remove-icon" (click)="onRemoveTimeClick(ix)">
      <svg-icon src="assets/cross.svg" [applyClass]="true" svgClass="remove-icon-svg"></svg-icon>
    </div>
  </div>

  <div class="add-time" (click)="onAddTimeClick()">
    <div class="icon-wrapper"><svg-icon src="assets/plus.svg" svgClass="add-time-svg" [applyClass]="true"></svg-icon></div>
    <div class="txt">Добавить время уведомления</div>
  </div>

  <!-- кнопки -->
  <div class="btns"><app-cancel-save-delete-btns (onSave)="onSaveBtn()" (onCancel)="onCancelBtn()"></app-cancel-save-delete-btns></div>


</app-screen-general>
