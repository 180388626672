import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from "moment";
import "moment/locale/ru.js";
import { AjaxMethods } from '../../../models/AjaxMethods';


@Component({
  selector: 'app-tchlink-setup-shdl',
  templateUrl: './tchlink-setup-shdl.component.html',
  styleUrls: ['./tchlink-setup-shdl.component.less']
})
export class TCHLinkSetupShdlComponent implements OnInit {

  static weekDaysStatic = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

  constructor(private http: HttpClient, private router: Router) {
    this.weekdays = TCHLinkSetupShdlComponent.weekDaysStatic
      .map((nm, ix) => { return { name: nm, factor: 2 << ix, isChecked: false } });
  }

  weekdays: { name: string, factor: number, isChecked: boolean }[];

  // Битовая маска. Понедельник - младший разряд
  get weekdaysMask(): number {
    return this.weekdays.reduce((prev, curr, ix) => (curr.isChecked ? prev + (1 << ix) : prev), 0);
  }

  times: { dt: Date }[] = [];

  onAddTimeClick() {
    this.times.push({ dt: new Date() });
  }
  // кнопка "удалить время"
  onRemoveTimeClick(ix: number) {
    if (ix >= 0 && ix < this.times.length) {
      this.times.splice(ix, 1);
    }
  }
  timeFormat = "HH:mm";
  // Разбор строки с временем в массив this.times и weekdays в weekdays.isChecked
  parseModel(model) {
    let sz = model.times;
    if (sz) {
      let tms = sz.split(";");
      if (tms && tms.length) {
        tms.forEach(t => this.times.push({ dt: moment(t, this.timeFormat).toDate() }));
      }
    }
    let wd = model.weekdays;
    let i = 0;
    while (wd && i < this.weekdays.length) {
      if (wd & 1) {
        this.weekdays[i].isChecked = true;
      }
      wd = wd >> 1;
      i++;
    }
  }


  ngOnInit(): void {
    // прочитать модель
    this.http.get<{ times: string, weekdays:number }>(AjaxMethods.GetNotificationSchedule)
      .subscribe(data=> this.parseModel(data));
  }
  // вытянуть в строку все времена через semicolon
  getSaveModel(): string {
    return this.times.map(t => moment(t.dt).format(this.timeFormat)).join(";");
  }
  // Сохранить
  onSaveBtn() {
    this.http.post<any>(AjaxMethods.PostNotificationSchedule, { times: this.getSaveModel(), weekdays: this.weekdaysMask })
      .subscribe();
  }
  // Cancel
  onCancelBtn() {
    this.router.navigate(["tch-link/edit"]);
  }
}
