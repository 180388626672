import { Injectable, EventEmitter, OnDestroy, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../common-data.service';
import { Subject, Observable } from 'rxjs';
import { AjaxMethods } from '../models/AjaxMethods';
import { AvailableResult, BiometryType, NativeBiometric, Credentials } from 'capacitor-native-biometric';

// https://github.com/epicshaggy/capacitor-native-biometric
// https://javascript.plainenglish.io/ionic-angular-capacitor-with-biometric-id-2d5666ce1410
@Injectable({
  providedIn: 'root'
})
export class BiometricService {


  constructor(private http: HttpClient, private commonSrv: CommonDataService, private router: Router, private route: ActivatedRoute) { }


  public isAvailable = false;
  public isCredentialAvailable = false;
  public biometryType: BiometryType = BiometryType.NONE;

  public isBiometryTypeMultiple(bioType): boolean {
    // Тип MULTIPLE для iOS доступен в 4 версии для Capacitor 4
    // iOS
    return false;

    // Android
    // return (bioType === BiometryType.MULTIPLE);
  }


  public get isBiometryTypeFace(): boolean {
    return (this.biometryType === BiometryType.FACE_ID || this.biometryType === BiometryType.FACE_AUTHENTICATION || this.isBiometryTypeMultiple(this.biometryType));
  }

  public get isBiometryTypeTouch(): boolean {
    return (this.biometryType === BiometryType.TOUCH_ID || this.biometryType === BiometryType.FINGERPRINT || this.isBiometryTypeMultiple(this.biometryType));
  }


  public get biometryTypeName(): string {
    if (this.isAvailable) {
      if (this.isBiometryTypeFace && this.isBiometryTypeTouch) {
        return "Face ID";
      }
      else if (this.isBiometryTypeFace) {
        return "Face ID";
      }
      else if (this.isBiometryTypeTouch) {
        return "Touch ID";
      }
    }
    return "";
  }


  public biometryTypeNameCase(casenumber, iscapitalise = false): string {
    if (this.isAvailable) {
      if (this.isBiometryTypeFace && this.isBiometryTypeTouch) {
        switch (casenumber) {
          case 0: // И.П.
            return iscapitalise ? this.titleCaseWord("биометрия") : "биометрия";
          case 1: // Р.П.
          case 2: // Д.П.
          case 5: // П.П.
            return iscapitalise ? this.titleCaseWord("биометрии") : "биометрии";
          case 3: // В.П.
          case 4: // Т.П.
            return iscapitalise ? this.titleCaseWord("биометрию") : "биометрию";
        }
        return iscapitalise ? this.titleCaseWord("биометрия") : "биометрия";
      }
      else if (this.isBiometryTypeFace) {
        return "Face ID";
      }
      else if (this.isBiometryTypeTouch) {
        return "Touch ID";
      }
    }
    return "";
  }


  private titleCaseWord(word: string) {
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1).toLowerCase();
}


  public setCredential(url: string, username: string, password: string, resolve?) {

    NativeBiometric.isAvailable().then((result: AvailableResult) => {

      console.log('Bio is available result ' + JSON.stringify(result));
      if (result.isAvailable) {
        // Save user's credentials
        console.log('Bio Url: ' + url);
        NativeBiometric.setCredentials({
          username: username,
          password: password,
          server: url, // 'www.example.com',
        }).then(() => {
          console.log('Bio Set Credential successful. ');
          this.isCredentialAvailable = true;
          if (resolve && typeof resolve !== 'undefined') resolve();
        })
          .catch((err) => {
            console.error('Bio Set Credential failed. Error: ' + err);
          });

      }
      else {
        // Св-во errorCode доступно в 4 версии для Capacitor 4
        // console.warn('Bio authentication is not available. Reason: ' + result.errorCode);
        console.warn('Bio authentication is not available.');
      }
    }).catch((err) => {
      console.warn('Bio authentication is not available. Reason: ' + err);
    });
 
  }


  public deleteCredential(url: string, resolve?) {
    // Delete user's credentials
    NativeBiometric.deleteCredentials({
      server: url, //'www.example.com',
    }).then(() => {
      console.log('Bio Delete Credential successful. ');
      this.isCredentialAvailable = false;
      if (resolve && typeof resolve !== 'undefined') resolve();
    })
      .catch((err) => {
        console.error('Bio Delete Credential failed. Error: ' + err);
      });
  }
   

  public checkCredential(resolve?, reject?) {

    NativeBiometric.isAvailable().then((result: AvailableResult) => {
      this.isAvailable = result.isAvailable;
      this.isCredentialAvailable = false;
      this.biometryType = BiometryType.NONE;
      console.log('Bio is available result ' + JSON.stringify(result));
      if (result.isAvailable) {

        this.biometryType = result.biometryType;
        if (result.biometryType === BiometryType.FACE_ID) {
          console.log('Bio authentication iOS Face ID ');
        }
        else if (result.biometryType === BiometryType.TOUCH_ID) {
          console.log('Bio authentication iOS Touch ID ');
        }
        else if (result.biometryType === BiometryType.FACE_AUTHENTICATION) {
          console.log('Bio authentication Android Face ');
        }
        else if (result.biometryType === BiometryType.FINGERPRINT) {
          console.log('Bio authentication Android Fingerprint ');
        }
        else if (result.biometryType === BiometryType.IRIS_AUTHENTICATION) {
          console.log('Bio authentication Android Iris ');
        }
        else if (this.isBiometryTypeMultiple(result.biometryType)) {
          console.log('Bio authentication Android Multiple ');
        }

        if (result.biometryType !== BiometryType.NONE) {

          const baseUrl = localStorage['userProfile'];
          if (baseUrl && baseUrl !== "null") {
            this.commonSrv.baseUrl = baseUrl;
          }
          console.log('Bio Base Url: ' + this.commonSrv.baseUrl);

          // Get user's credentials
          NativeBiometric.getCredentials({
            server: this.commonSrv.baseUrl, // 'www.example.com',
          }).then((credentials) => {
            console.log('Bio authenticate using biometrics before logging the user in. Credential: ' + JSON.stringify(credentials));
            if (credentials && credentials.username) {
              this.isCredentialAvailable = true;
              if (resolve && typeof resolve !== 'undefined') resolve(credentials);
            }
            else {
              console.log('Bio credentials is empty');
              if (reject && typeof reject !== 'undefined') reject();
            }
          }).catch((err) => {
            console.warn('Bio failed get credentials ' + err);
            if (reject && typeof reject !== 'undefined') reject();
          });
        }
        else {
          console.warn('Bio authentication type is none.');
          if (reject && typeof reject !== 'undefined') reject();
        }
      }
      else {
        // Св-во errorCode доступно в 4 версии для Capacitor 4
        // console.warn('Bio authentication is not available. Reason: ' + result.errorCode);
        console.warn('Bio authentication is not available.');
        if (reject && typeof reject !== 'undefined') reject();
      }
    }).catch((err) => {
      this.isAvailable = false;
      this.isCredentialAvailable = false;
      this.biometryType = BiometryType.NONE;
      console.warn('Bio authentication is not available. Reason: ' + err);
      if (reject && typeof reject !== 'undefined') reject();
    });
  }

  public loginByCredential(resolve?, reject?) {

    this.checkCredential((credentials: Credentials) => {

      if (credentials && credentials.username) {
        NativeBiometric.verifyIdentity({
          reason: 'Авторизация',
          title: 'Авторизация',
          //subtitle: 'Subtitle',
          //description: 'Description',
          negativeButtonText: 'Выход',
          // Св-во доступно в 4 версии для Capacitor 4
          // maxAttempts: 3
        })
          .then(() => {
            console.log('Bio authentication successful ');
            this.http.post<{ loginId: number; token: string }>(AjaxMethods.login, { ...{}, ...{ userName: credentials.username, userPassword: credentials.password }, ...{ timeZoneOffset: - (new Date().getTimezoneOffset() / 60) } })
              .subscribe(data => {
                if (resolve && typeof resolve !== 'undefined') {
                  resolve(data.loginId, data.token);
                }
                else {
                  this.commonSrv.loginId = data.loginId;
                  this.commonSrv.JWTToken = data.token;
                  this.route.params.subscribe(params => {
                    const fromUrl = params["returnurl"];
                    if (fromUrl) {
                      this.router.navigate([fromUrl]);
                    }
                    else {
                      this.router.navigate(["/home"]);
                    }
                  });
                }
              });
          })
          .catch((err) => {
            console.warn('Bio failed to authenticate ' + err);
            if (reject && typeof reject !== 'undefined') reject();
          });
      }

    }, reject);


  }








}
