import { ApplicationRef, Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DownloadService, Download } from '../../services/download.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Subject, Observable } from 'rxjs';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-public-event-files-int-detail',
  templateUrl: './public-event-files-int-detail.component.html',
  styleUrls: ['./public-event-files-int-detail.component.less']
})
export class PublicEventFilesIntDetailComponent implements OnInit {

  constructor(private location: Location, private route: ActivatedRoute, private router: Router, private http: HttpClient, private commonData: CommonDataService, private downloadSrv: DownloadService, private sanitizer: DomSanitizer, private applicationRef: ApplicationRef) { }

  ngOnInit(): void {
    // прочитать список файлов
    this.route.params.subscribe(params => {
      let id = params["id"];
      let fId = params["fileId"];
      this.publicEventId = Number(id);
      this.fileId = Number(fId);
      if (this.publicEventId && this.fileId) {
        this.http.get<PublicEventFiles>(AjaxMethods.getPublicEventFileDetail.replace("{id}", this.publicEventId + '').replace("{fileId}", this.fileId + '').replace('{programItemId}', 0 + ''))
          .subscribe(data => {
            if (data.files && data.files.length > 0 && data.isEditor) {
              this.fileDetail = data.files[0];
              this.isEditable = true;
              this.displayFilename = this.fileDetail.fileName;
              this.displayTitle = this.fileDetail.title;
              this.displayFileSize = this.fileDetail.fileSize;
            }
          });
      }
    });
  }
  publicEventId: number = null;
  fileId: number = null;
  fileDetail: PublicEventFile = null;
  displayTitle: string = null;
  displayFilename: string = null;
  displayFileSize: number = 0;
  isEditPanelVisible: boolean = false;
  isEditable: boolean = false;

  fileDataUrl;
  fileDataUrlSrc;
  downloadFileName: string;

  @ViewChild("downloadButton")
  downloadButtonElement: ElementRef<HTMLElement>;

  onBackClicked() {
    if (this.fileDetail) {
      if (this.fileDetail.programItemId > 0) {
        this.router.navigate([`/public-events/${this.publicEventId}/programme`]);
      }
      else {
        this.router.navigate([`/public-events/${this.publicEventId}/files`]);
      }
    }
    this.location.back();
  }

  download$: Observable<Download>
  onFileDownload(dataUrl: string, filename?: string, mimetype?: string) {
    if (this.commonData.platform !== "web") {
      this.download$ = this.downloadSrv.download(dataUrl, filename, mimetype, true);
    }
    else {
      // DEV-35477
      if (this.commonData.platform == 'web' && this.commonData.operatingSystem == 'ios') {
        window.open(this.fileDataUrl, "_self");
      }
      else {
        this.downloadButtonElement.nativeElement.click();
      }
    }
    return false;
  }

  // Открыть файл. 
  onFileOpenClick() {

    this.http.get<any>(`/PublicEvent/file/${this.fileDetail.guid}`, { responseType: 'blob' as "json", observe: "response" })
      .subscribe((resp) => {
        let reader = new FileReader();
        reader.addEventListener("loadend", () => {
          this.fileDetail.extension = this.fileDetail.extension.toLowerCase();
          this.fileDataUrlSrc = this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
          // DEV-35477
          if (this.commonData.platform === 'web' && this.commonData.operatingSystem === 'ios') {
            this.fileDataUrl = window.URL.createObjectURL(resp.body);
          }
          else {
            this.fileDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
          }

          let disposition = decodeURI(resp.headers.get('content-disposition'));
          let p = disposition.lastIndexOf("'");
          if (p > 0) {
            this.downloadFileName = disposition.substring(p + 1);
          }
          else {
            this.downloadFileName = this.fileDetail.fileName;
          }
          this.applicationRef.tick();

          this.onFileDownload(<string>reader.result, this.fileDetail.fileName, resp.body.type);
        });
        reader.readAsDataURL(resp.body);
      });
  }

  // редактировать файл
  onFileEditClick() {
    this.isEditPanelVisible = true;
  }

  @ViewChild("fileInput")
  fileInputElement: ElementRef<HTMLInputElement>;

  @ViewChild("fileForm")
  formElement: ElementRef<HTMLFormElement>;
  @ViewChild("titleField")
  titleField: NgModel;
  @ViewChild("fileNameField")
  fileNameField: NgModel;

  /*file: PublicEventFile = <any>{};*/
  // Сохранить
  onSaveClick() {
    //let formData = new FormData(this.formElement.nativeElement);
    if (!this.fileDetail?.title) {
      this.titleField?.control.markAsTouched();
      return;
    }
    if (!this.fileDetail.fileName) {
      this.fileNameField?.control.markAllAsTouched();
      return;
    }
    /*this.file.publicEvent_Id = this.publicEventId;
    this.file.programItemId = this.fileDetail.programItemId;*/
    this.http.post<{ newId: number, guid: string }>(AjaxMethods.PostPublicEventFileInfo.replace('{id}', String(this.publicEventId))
      , this.fileDetail)
      .subscribe(data => {

        this.fileDetail.id = data.newId;
        this.fileDetail.guid = data.guid;

        this.displayFilename = this.fileDetail.fileName;
        this.displayTitle = this.fileDetail.title;
        this.displayFileSize = this.fileDetail.fileSize;

        this.isEditPanelVisible = false;

        if (this.fileInputElement) {
          this.fileInputElement.nativeElement.value = ''; // очистить загруженные файлы
        }
      });
  }

  maxFileSize = 30 << 20;
  // Загрузился файл
  onFileInputChange($event: Event) {
    let inputElement = (<HTMLInputElement>$event.target);
    let files: FileList = inputElement.files;

    console.log(`length:${files.length}.`);


    if (files.length) {
      let uploadedFile = files[0];
      console.log(`name:${uploadedFile.name}, size: ${uploadedFile.size}, type: ${uploadedFile.type}.`);
      let isImage = uploadedFile.type.toLowerCase().startsWith("image") && (uploadedFile.name.toLowerCase().endsWith(".jpg") || uploadedFile.name.toLowerCase().endsWith(".jpeg")
        || uploadedFile.name.toLowerCase().endsWith(".png") || uploadedFile.name.toLowerCase().endsWith(".gif") || uploadedFile.name.toLowerCase().endsWith(".bmp"));
      let isPdf = uploadedFile.name.toLowerCase().endsWith(".pdf");
      if (!isImage && !isPdf) {
        this.commonData.confirmationDialog.msgBox({
          message: `Вы можете загрузить файлы только в форматах PDF, BMP, GIF, JPEG, JPG, PNG`
        });
        inputElement.value = '';
        return;
      }
      if (uploadedFile.size > this.maxFileSize) {
        this.commonData.confirmationDialog.msgBox({
          message: `Размер файла ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize >> 20}Mb`
        });
        inputElement.value = '';
        return;
      }
      this.fileDetail.fileName = uploadedFile.name;
      let p = uploadedFile.name.lastIndexOf('.');
      this.fileDetail.extension = p >= 0 ? uploadedFile.name.substring(p) : '.';
      this.fileDetail.fileSize = uploadedFile.size;
      this.fileDetail.lastModified = new Date(uploadedFile.lastModified);
      uploadedFile.arrayBuffer().then(arr => {
        let bl = new Blob([arr]);
        let rdr = new FileReader();
        rdr.onloadend = () => this.fileDetail.content = <string>rdr.result;
        rdr.readAsDataURL(bl);
      });
      inputElement.value = '';
    }
  }

  // Удалить файл
  onDeleteFileClick() {
    let self = this;
    var ids = [this.fileDetail.id + ''];
    this.commonData.confirmationDialog.confirm({ message: "Удалить материал?", title: 'Удаление' })
      .then((confirmed) => {
        if (!confirmed) return;
        this.http.delete(AjaxMethods.DeletePublicEventFile.replace("{id}", String(self.publicEventId))
          , { params: { publicEventId: self.publicEventId + '', ids: ids } }).subscribe(() => {
            self.isEditPanelVisible = false;
            this.onBackClicked();
          });
      });
  }

  // Отмена редактирования
  onEditCancelClick() {
    this.isEditPanelVisible = false;
    this.onBackClicked();
  }

  // Очистить загруженный файл
  onLoadedFileClearClick() {
    this.fileDetail.fileName = '';
    this.fileDetail.content = null;
  }

  getFileSizeSz(fileSize: number) {
    return fileSize >= (2 << 20) ? (fileSize >> 20) + 'Mb'
      : (fileSize >> 10) + 'Kb';
  }
}
