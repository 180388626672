import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';


const transitions = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    })
  ]),
  query(':enter', [
    style({ left: '-100%' })
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate('300ms ease-out', style({ left: '100%' }))
    ]),
    query(':enter', [
      animate('300ms ease-out', style({ left: '0%' }))
    ])
  ]),
  query(':enter', animateChild()),
];

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('PublicEvents <=> PublicEvent', transitions),
    transition('PublicEvent <=> PublicEventInfo', transitions),
    transition('PublicEvent <=> PublicEventProgram', transitions),
    transition('PublicEvent <=> PublicEventPolling', transitions),
    transition('PublicEventPolling <=> PublicEventVote', transitions)
  ]);
