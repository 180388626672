  <div class="wrapper">

    <ng-container *ngTemplateOutlet="tmpl || userList"></ng-container>

    <ng-template #userList>
      <mat-form-field *ngIf="!showStaticListOnly" [ngStyle]="{'width': '100%'}">
        <mat-label>Кого ищем?</mat-label>
        <input matInput type="text" [(ngModel)]="searchText" (ngModelChange)="onSearchTextChange()" />
        <mat-icon matSuffix *ngIf="searchText" (click)="searchTextClear()">close</mat-icon>
      </mat-form-field>

      <!--для управления доступом-->
      <div class="list-wrapper" *ngIf="isCbx">
        <div class="list-item" *ngFor="let it of users">

          <div class="photo-wrapper" *ngIf="showPhoto">
            <div class="photo">
              <div class="img-wrap">
                <svg-icon *ngIf="!getPhotoIsImage(it.photo)" [src]="getPhotoPath(it.photo)" [applyClass]="true" svgClass="photo-svg"></svg-icon>
                <img [src]="getPhotoSafe(it.photo)" *ngIf="getPhotoIsImage(it.photo)" />
              </div>
            </div>
          </div>

          <div class="text-part">
            <div class="user-name">{{it.name}}</div>
            <div class="user-role" *ngIf="showUserRole && it.isEditor">Организатор</div>
            <div class="user-position">{{getJointAttrs(it)}}</div>
          </div>
          <div class="cbx">
            <mat-slide-toggle [checked]="isUserChecked(it)" [disabled]="isUserDisabled(it)" (change)="onCbxChange(it)" [color]="'primary'"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <!-- список участников с иконокой - переходом к пользователю -->
      <div class="list-wrapper" *ngIf="!isCbx">
        <app-nav-list-item *ngFor="let it of users" [textTmpl]="userItemTmpl" (onIconClick)="onUserClick(it)"
                           [showIcon]="showIcon" [showPhoto]="true" [photo]="it.photo">
          <ng-template #userItemTmpl>
            <div class="user-item">
              <div class="first-line">{{it.name}}</div>
              <div class="user-role" *ngIf="showUserRole && it.isEditor">Организатор</div>
              <div class="second-line">{{getJointAttrs(it)}}</div>
            </div>
          </ng-template>
        </app-nav-list-item>
      </div>
    </ng-template>
  </div>

  <!------------------------->
  <!--     Пользователь    -->

  <ng-template #userDetails>
    <app-user-profile-display id="public-event-user-list" [userDisplay]="userDisplay" [showBtnAction]="false" [isViewAlt]="false" [isProfileActive]="true" ></app-user-profile-display>
  </ng-template>
