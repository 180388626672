import { EventEmitter, Output, TemplateRef } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.less']
})
export class TabsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  params: { label: string, labelIcon?: string, contentTmpl: TemplateRef<any> }[] = [];

  @Input()
  ixActive = 0;
  @Output()
  ixActiveChange = new EventEmitter<number>();

  isTabActive(ix: number) { return ix == this.ixActive; }

  onLabelClick(ix: number) {
    this.ixActive = ix;
    this.ixActiveChange.emit(ix);
  }

}
