import { HttpClient } from '@angular/common/http';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { ApplicationRef, Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';

// Режимы отображения
enum DisplayMode {
  list,
  edit,
  view,
  nameEdit,
}

@Component({
  selector: 'app-public-event-contacts',
  templateUrl: './public-event-contacts.component.html',
  styleUrls: ['./public-event-contacts.component.less']
})
export class PublicEventContactsComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private title: Title, private commonData: CommonDataService, private dialog: MatDialog, private sanitizer: DomSanitizer, private appRef: ApplicationRef) {
    title.setTitle(this.mainTitle);
  }
  mainTitle = "Связаться с организаторами";
  ngOnInit() {
    this.route.params.subscribe(prm => {
      this.publicEventId = Number(prm["id"]);
      // прочитать программу
      this.http.get<PublicEventContacts>(AjaxMethods.PublicEventContacts.replace("{id}", this.publicEventId + ''))
        .subscribe(data => {
          if (data) {
            this.isEditor = data.isEditor;
            this.items = data.items;
          }
        });
    });
  }
  ngAfterViewInit() {
    this.tmplMode = [
      { mode: DisplayMode.list, tmpl: this.tmplList, isSaveCancel: false, isEditable: false, title: "Связаться с организаторами" },
      { mode: DisplayMode.edit, tmpl: this.tmplEditItem, isSaveCancel: true, isEditable: false, title: "Связаться с организаторами" },
      { mode: DisplayMode.view, tmpl: this.tmplViewItem, isSaveCancel: false, isEditable: true, title: "Связаться с организаторами" },
      { mode: DisplayMode.nameEdit, tmpl: this.tmplEditName, isSaveCancel: false, isEditable: false, title: "Связаться с организаторами" },
    ]
  }

  publicEventId: number;
  isEditor: boolean;
  items: PublicEventContactItem[] = [];


  isSaveCancel = false;
  isEditable = false;
  viewModeNum = DisplayMode.view;
  editModeNum = DisplayMode.edit;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  /*isUsersPanelVisible = false;// панель выбора пользователя

  onUsersPanelVisibleChange(on: boolean) {
    if (!on) {
      this.isUsersPanelVisible = false;
    }
  }*/

  onEditNameClick() {
    this.switchMode(DisplayMode.nameEdit);
  }

  onUserClick(usr: UserAttrs) {
    if (usr) {
      this.editItem.userAccountId = usr.loginId;
      this.editItem.fullName = usr.name;
      this.editItem.email = usr.email;
      this.editItem.phone = usr.phone;
      this.editItem.photo = usr.photo;
    }
    this.switchMode(DisplayMode.edit);
  }

  // Режимы отображения для переключения
  tmplMode: { mode: DisplayMode, tmpl: TemplateRef<any>, isSaveCancel: boolean, isEditable: boolean, title: string }[] = [];

  mode: DisplayMode = DisplayMode.list;

  // переключение режима
  switchMode(mode: DisplayMode) {
    var tm = this.tmplMode.find(t => t.mode == mode);
    if (!tm) throw new Error(`Invalid mode:${mode}`);
    this.tmpl = tm.tmpl;
    this.isEditable = tm.isEditable;
    this.isSaveCancel = tm.isSaveCancel;
    if (tm.title) this.mainTitle = tm.title;
    this.mode = mode;
  }

  viewMode = DisplayMode.view;
  // Кнопка "назад"
  onBackClick() {
    switch (this.mode) {
      case DisplayMode.edit:// к просмотру
        // DEV-35468
        //this.switchMode(DisplayMode.view);
        //break;
      case DisplayMode.view:// к списку
        this.switchMode(DisplayMode.list);
        break;
      case DisplayMode.list:// на уровень вверх
        this.router.navigate([`/public-events/${this.publicEventId}`]);
        break;
      case DisplayMode.nameEdit:// к списку
        this.switchMode(DisplayMode.edit);
        break;
    }
  }
  // объект для редактирования
  editItem: PublicEventContactItem;
  // Добавить пункт программы
  onNewClick() {
    this.editItem = <any>{ phone: '+7' };
    this.switchMode(DisplayMode.edit);
  }
  // редактирование пункта программы
  onEditItemClick(it: PublicEventContactItem) {
    this.editItem = it;
    /*if (this.isEditor) {
      this.switchMode(DisplayMode.edit);
    }
    else {*/
      this.switchMode(DisplayMode.view);
    /*}*/
  }
  // Сохранить пункт программы
  onSave(): Promise<void> {
    return new Promise<void>((resolve) => {
      //if (this.getErrorMessages().length) return; // ошибки
      if (this.isTelegramUserNameError() || this.isWhatsappPhoneError()) return; // ошибки
      this.http.post<any>(AjaxMethods.PublicEventContacts.replace('{id}', this.publicEventId + ''),
        this.editItem).subscribe(d => {
          var isNew = !this.editItem.id;
          if (d.newId) {
            this.editItem.id = d.newId;
            // взять даты и время начала/окончания
            Object.assign(this.editItem, d);
          }
          if (isNew) {
            this.items.push(this.editItem);
            this.items = this.items.sort((a, b) =>
              (a.id == b.id) ? 0
                : (a.id < b.id) ? -1
                  : 1);
          }
          
          this.switchMode(DisplayMode.view); // к просмотру
          resolve();
        });
    });
  }
  onWhatsappFocus() {
    var phone = this.editItem?.whatsappPhone;
    if (phone === null || phone === undefined || phone.length <= 0) {
      this.editItem.whatsappPhone = this.editItem?.phone;
    }
  }
  onCancelEdit() {
    /*if (this.editItem?.id > 0) {
      this.switchMode(DisplayMode.view);
    }
    else {*/
      this.switchMode(DisplayMode.list);
    /*}*/
  }
  get isDeleteEnabled(): boolean {
    return Boolean(this.editItem?.id);
  }
  // Удалить пункт
  onDelete() {
    this.http.delete<any>(AjaxMethods.PublicEventContacts.replace('{id}', this.publicEventId + ''), { params: { itemId: this.editItem.id } })
      .subscribe(d => {
        var ix2del = this.items.findIndex(i => i.id == this.editItem.id);
        if (ix2del >= 0) {
          this.items.splice(ix2del, 1);
          this.switchMode(DisplayMode.list);
        }
      });
  }

  tmpl: TemplateRef<any>; // текущий шаблон
  @ViewChild("tmplList")
  tmplList: TemplateRef<any>;
  @ViewChild("tmplEditItem")
  tmplEditItem: TemplateRef<any>; // редактирование
  @ViewChild("tmplViewItem")
  tmplViewItem: TemplateRef<any>; // просмотр
  @ViewChild("tmplEditName")
  tmplEditName: TemplateRef<any>; // редактирование ФИО

  getItemSubjectFontWeight(it: PublicEventContactItem) {
    return "400";
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validatePhone(phone) {
    const re = /^[+][0-9]{1,15}$/;
    return re.test(String(phone));
  }

  validateTelegram(userName) {
    const re = /^[0-9a-zA-Z_]{5,32}$/;
    return re.test(String(userName));
  }

  // Сообщения об ошибках на диапазон дат
  //getErrorMessages(): string[] {
    //let msgs = [];
    /*if (this.editItem.email && !this.validateEmail(this.editItem.email)) {
      msgs.push("Задайте корректное значение почты");
    }
    if (this.editItem.phone && !this.validatePhone(this.editItem.phone)) {
      msgs.push("Задайте корректное значение телефона (до 15 цифр со знаком \"+\" в начале)");
    }*/
  //  if (this.editItem.telegramUserName && !this.validateTelegram(this.editItem.telegramUserName)) {
  //    msgs.push("Задайте корректное значение для Telegram (количество символов не меньше 5 и не больше 32, допускается использование символов a-z, 0-9 и подчеркивание)");
  //  }
  //  if (this.editItem.whatsappPhone && !this.validatePhone(this.editItem.whatsappPhone)) {
  //    msgs.push("Задайте корректное значение телефона для Whatsapp (до 15 цифр со знаком \"+\" в начале)");
  //  }

  //  return msgs;
  //}

  isTelegramUserNameError(): boolean {
    if (this.editItem.telegramUserName && !this.validateTelegram(this.editItem.telegramUserName)) {
      return true;
    }
    return false;
  }

  isWhatsappPhoneError(): boolean {
    if (this.editItem.whatsappPhone && !this.validatePhone(this.editItem.whatsappPhone)) {
      return true;
    }
    return false;
  }

  get safePhoto() {
    return this.editItem.photo ? this.sanitizer.bypassSecurityTrustResourceUrl(this.editItem.photo) : null;
  }
  // Фото есть
  get photoExists(): boolean { return Boolean(this.editItem.photo); }
}
