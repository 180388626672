<app-screen-general header="Уведомления" backUrl="/" (onScroll)="onScroll($event)">
  <div *ngFor="let dt of datesKeys">
    <!--Заголовок даты-->
    <div class="date-header">{{dt}}</div>
    <!--сообщения-->
    <div class="messages-container">
      <div class="message-item" *ngFor="let msg of dates[dt]" #messageItem>
        <div class="message-text" [innerHTML]="msg.message"></div>
        <div class="message-url"><a [href]="msg.url" (click)="onGotoUrlClick($event, msg.url)">{{msg.urlText}}</a></div>
        <div style="display:none">{{getMessageVisibility(messageItem, msg)}}</div>
        <div class="message-time">{{msg.time}}</div>
      </div>
    </div>
  </div>

</app-screen-general>
