import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';

@Component({
  selector: 'app-public-event-polling',
  templateUrl: './public-event-polling.component.html',
  styleUrls: ['./public-event-polling.component.less']
})
export class PublicEventPollingComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient) { }
  
  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.publicEventId = data["publicEventId"];
      this.http.get<Pollings>(AjaxMethods.getPublicEventPolling.replace("{id}", this.publicEventId)).subscribe(data => {
        this.pollings = data;
        if (!this.pollings.isEditor && this.pollings.items.length == 1) {// если одно голосование, то сразу на него перейти
          let route = `public-events/${this.publicEventId}/polling/${this.pollings.items[0].id}`;
          this.router.navigateByUrl(route);
        }
      });
    });
  }
  publicEventId: any;
  pollings?: Pollings;
  
  // Сгенерировать URL для кнопки back
  get backUrl(): string {
    let ixLastSeg = 2; // индекс последнего сегмента из URL, куда переходим. 2 - это включая "polling" https://appdev.tch.ru/public-events/111/polling/133
    if (this.pollings?.isSinglePolling) {// переход к мероприятию, голосование единственное
      ixLastSeg = 1;
    }
    return '/' +  this.route.snapshot.url.slice(0,ixLastSeg).join('/') + '/';
  }
  // Новый опрос
  onNewClick() {
    // -> public-events/:id/polling/:pollingId/edit
    this.router.navigate(["0/edit"], {relativeTo: this.route});
  }
}
