<app-screen-with-panel
                       innerTitle="Голосование или опрос"
                       [bodyTmpl]="bodyTmpl"
                       [backUrl]="backUrl"
                       [isSaveCancel]="true"
                       (onSaveClick)="onSaveClick()"
                       (onCancelEditClick)="onCancelEditClick()"
                       (onDeleteClick)="onDeleteClick()"
                       [doConfirmDelete]="false"
                       deleteBtnSubject="голосование/опрос"
                       [isDeleteEnabled]="!!polling?.id">
  <ng-template #bodyTmpl>
    <div class="form-wrapper">
      <mat-form-field>
        <mat-label>Заголовок голосования</mat-label>
        <input matInput type="text" [(ngModel)]="polling.topic" required maxlength="1024" />
        <button mat-button *ngIf="polling.topic" matSuffix mat-icon-button aria-label="Clear" (click)="polling.topic=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Описание</mat-label>
        <textarea matInput [(ngModel)]="polling.fullText"></textarea>
      </mat-form-field>
      <mat-checkbox type="text" [(ngModel)]="polling.isMultipleVotingAllowed" labelPosition="after">
        Разрешать отвечать несколько раз
      </mat-checkbox>
      <mat-checkbox type="text" [(ngModel)]="isAllQuestionsRequired" labelPosition="after">
        Все вопросы обязательны к ответу
        <div class="label-hint">Голосования с ответами не на все вопросы не учитываются в результатах</div>
      </mat-checkbox>
      <mat-checkbox type="text" [(ngModel)]="polling.isShowResultsToParticipant" labelPosition="after">
        Показывать результаты участникам
      </mat-checkbox>
      <mat-checkbox type="text" [(ngModel)]="polling.isShowVoteNames" labelPosition="after">
        Показывать имена пользователей в результатах
      </mat-checkbox>
      <h3>Время проведения</h3>
      <div class="date-range">
        <app-date-and-time [(editValue)]="polling.beginDate" [min]="pollings?.publicEvent?.publishDateBegin" [max]="pollings?.publicEvent?.publishDateEnd" label="Начало" [allowClear]="true"
                           [onFocusDefaultValue]="pollings?.publicEvent?.beginDate"></app-date-and-time>
        <app-date-and-time [(editValue)]="polling.endDate" [min]="pollings?.publicEvent?.publishDateBegin"
                           [max]="pollings?.publicEvent?.publishDateEnd"
                           label="Окончание" [allowClear]="true"
                           [onFocusDefaultValue]="pollings?.publicEvent?.endDate"></app-date-and-time>
        <div class="mat-error" *ngIf="isDateRangeInvalid()">Дата окончания не может быть ранее даты начала</div>
        <div class="mat-error" *ngIf="isDateNotSpecified()">Даты начала и окончания должны быть указаны или не указаны одновременно</div>
        <div class="mat-error" *ngIf="isOutOfEventPeriod()">Голосование не может быть вне периода опубликования мероприятия</div>
      </div>
    </div>

    <!--Уведомление о начале и об окончании-->
    <ng-container *ngIf="NOTIFICATIONS_ENABLED">
      <app-nav-list-item text="Уведомление о начале" (onIconClick)="onCfgNotificationClick('beginDate')"></app-nav-list-item>
      <app-nav-list-item text="Уведомление об окончании" (onIconClick)="onCfgNotificationClick('endDate')"></app-nav-list-item>
    </ng-container>
    <br />

    <!-- Конфигурация оповещений -->
    <app-child-panel [isVisible]="isNotificationsPanelVisible" (isVisibleChange)="onNotificationVisibleChange($event)" header="Оповещения">
      <app-configure-notification [(selected)]="cfgNotificationSelection" [(selectedDate)]="cfgNotificationDate"></app-configure-notification>
    </app-child-panel>
  </ng-template>

  
</app-screen-with-panel>
