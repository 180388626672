import { Injectable, EventEmitter, OnDestroy, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonDataService } from '../common-data.service';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel, HttpTransportType } from '@microsoft/signalr'
import { Subject, Observable } from 'rxjs';
import { AjaxMethods } from '../models/AjaxMethods';

declare type SendHandler = (data: any) => void;

export enum ChatMessageType {
  Text = 0,
  Join = 1,
  Leave = 2,
  Link = 3,
  TextAndLink = 4,
  Refresh = 5
}

@Injectable({
  providedIn: 'root'
})

export class ChatService  {

  private connection: HubConnection;

  readonly connectionUrl = "/publiceventchat"
  readonly controllerUrl = AjaxMethods.PublicEventChatMessage;

  private isUserJoin = false;
  private isCloseForce = false;
  private token: string;
  private loginId: number;
  private publicEvents: number[];
  private joinChatItem: PublicEventChatItem;
  private messageReceived = new Subject<PublicEventChatItem>();


  constructor(private http: HttpClient, private commonSrv: CommonDataService) { }

  public get isconnected(): boolean {
    return this.connection && this.connection?.connectionId && this.connection?.state === HubConnectionState.Connected;
  }


  public get clientId(): string {
    return this.connection?.connectionId;
  }


  public get messageReceivedObservable(): Observable<PublicEventChatItem> {
    return this.messageReceived?.asObservable();
  }

  public connect = (publicEvents: number[], loginId: number, isUserJoin = false) => {

    if (loginId) {
      this.isUserJoin = isUserJoin;
      this.startConnection(publicEvents, loginId);
    }
    else {
      console.error(`Hub connect error - user login is empty.`);
    }
  }

  public close = () => {

    this.stopConnection(true, false);
  }
    

  // Calls the controller method
  public send = (it: PublicEventChatItem) => {

    // this.http.post(this.controllerUrl.replace('{id}', this.publicEventIds), it).subscribe(data => handler(data));
    if (this.connection) {
      if (it && it.messages?.length > 0 && it.messages[0].messageType === 0 + ChatMessageType.Join) {
        this.joinChatItem = Object.assign({},  it);
      }

      this.connection.invoke("SendMessage", it)
        .catch(err => {
          console.error(`SendMessage - ${err}`);
        });
    }
  }

 
  // Start the connection
  private startConnection(publicEvents: number[], loginId: number, isrepeate = false) {

    if (publicEvents && publicEvents.length > 0) {
      if (this.connection?.connectionId && this.connection?.state === HubConnectionState.Connected) {
        // Предыдущее соединение не было закрыто
        if (this.loginId !== loginId || !this.publicEvents || this.publicEvents?.length === 0 || !publicEvents.every(x => this.publicEvents.find(y => y === x))) {
          this.stopConnection(true, false, true, () => {
            setTimeout(() => this.startConnection(publicEvents, loginId, true), 1000);
          });
        }
        return;
      }
    }
    else {
      if (this.connection?.connectionId && this.connection?.state === HubConnectionState.Connected) {
        this.stopConnection(true, false);
      }
      return;
    }


    if (!this.connection) {

      console.log("Create hub connection.");
         

      this.connection = new HubConnectionBuilder()
          .withUrl(this.commonSrv.baseUrl + `${this.connectionUrl}?publicEventIds=${publicEvents.join(',')}&loginId=${loginId}`, {
          // Error: Failed to start the connection: Error: Negotiation can only be skipped when using the WebSocket transport directly.
          // skipNegotiation: true,
          accessTokenFactory: () => this.token,
          transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling

        })
        .configureLogging(LogLevel.Trace)
        .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000])
        .build();

      if (this.connection) {
          this.publicEvents = Object.assign(publicEvents);
          this.loginId = loginId;

          this.registerOnServerEvents();
          this.connection.serverTimeoutInMilliseconds = 1000 * 60 * 120;
          // подписка на reconnect
          this.connection.onreconnected(connectionId => {
            if (this.connection.state === HubConnectionState.Connected) {
              if (this.isUserJoin && this.joinChatItem) {
                this.tryjoin();
              }
              console.info(`Connection reestablished. Connected with connectionId ${connectionId}.`);
            }
          });

          // подписка на onclose
          this.connection.onclose((err) => {
            if (err) {
              console.error(`Connection closed due to error: ${err}.`);
            }
            else {
              console.info(`Connection closed.`);
            }
            if (!this.isCloseForce && this.connection.state === HubConnectionState.Disconnected) {
              console.debug(`Connection start retrying...`);
              setTimeout(() => this.startConnection(this.publicEvents, this.loginId, true), 15000);
            }
          });
        }

    }


   
    if (this.connection) {

      const isChangePublicEvents = (!this.publicEvents || this.publicEvents?.length === 0 || !publicEvents.every(x => this.publicEvents.find(y => y === x)));
      if (this.loginId !== loginId || isChangePublicEvents) {
        if (isChangePublicEvents) this.publicEvents = Object.assign(publicEvents);
        this.connection.baseUrl = this.commonSrv.baseUrl + `${this.connectionUrl}?publicEventIds=${publicEvents.join(',')}&loginId=${loginId}`;
      }

      this.loginId = loginId;
      this.isCloseForce = false;
      this.connection.start()
        .then(() => {
          if (this.connection.state === HubConnectionState.Connected) {
            console.log("Start connection.");
            if (isrepeate && this.isUserJoin && this.joinChatItem) {
              this.tryjoin();
            }
          }
        })
        .catch((err) => {
          if (err) {
            console.error(`Connection start error while establishing connection: ${err}`);
          }
          else {
            console.warn(`Connection start error while establishing connection.`);
          }

          if (this.connection.state === HubConnectionState.Disconnected) {
            console.debug(`Connection start retrying ...`);
            setTimeout(() => this.startConnection(this.publicEvents, this.loginId, true), 15000);
          }
        });



    }
  }


  // Stop the connection
  private stopConnection(isForce = false, isDispose = false, isStart = true, callback: () => void = null) {

    if (this.connection && this.isconnected) {
      // determines behavior onclose
      this.isCloseForce = isForce;

      this.connection.stop()
        .then(() => {
          if (this.connection.state === HubConnectionState.Disconnected) {
            console.log("Stop connection.");
            this.publicEvents = [];
            this.loginId = 0;
            if (isDispose) {
              console.log("Dispose connection.");
              this.connection.off("MessageReceived");
              this.connection = null;
            }

            if (callback && typeof callback === 'function') {
              callback();
            }
          }
        })
        .catch((err) => {
          if (err) {
            console.error(`Connection stop error while establishing disconnection: ${err}`);
          }
          else {
            console.error(`Connection stop error while establishing disconnection.`);
          }
          if (isStart) {
            console.log(`Connection stop retrying ...`);
            setTimeout(() => this.stopConnection(isForce, isDispose, false), 5000);
          }
        });
    }
  }
  

  private registerOnServerEvents(): void {
    try {
      if (this.connection) {
        console.log(`Connection register server events.`);
        this.connection.on("MessageReceived", (chat: any) => {
          console.log("Get message: " + chat.messages[0].message);

          this.messageReceived.next(chat);
        });
      }
    }
    catch (err) {
      console.error(`Error register events: ${err}`);
    }
  }
  
 

  private tryjoin() {

    const sendItem = Object.assign({}, this.joinChatItem);
    sendItem.clientId = this.clientId;
    sendItem.messages[0].sendDate = new Date();
    sendItem.messages[0].messageStatus = 0;

    console.info(`User ${this.loginId} try join to chat.`);
    this.send(sendItem);
  }

}
