<!-- Детальные результаты голосования по вопросу -->
<app-screen-with-panel [title]="'Вопрос №' + (question?.no || '')" [bodyTmpl]="bodyTmpl" [backUrl]="backUrl">
</app-screen-with-panel>

<ng-template #bodyTmpl>
  
  <!--Вопрос-->
  <div class="question result-ql-editor" [innerHTML]="question?.fullText | HtmlRaw"></div>

  <div class="hr"></div>

  <div class="hist">
    <div *ngFor="let res of question?.summaryResults">
      <div class="hist-row" [ngClass]="res.isExpanded ? 'is-expanded' : ''">
        <div class="left-col" [ngClass]="res.voteEn">
          <div class="vote-text">{{res.voteText}}</div>
          <div class="hist-line" [style.width]="res.pct*100 + '%'"></div>
        </div>
        
        <div class="n-votes dark" (click)="onToggleNamesClick(res)">
          {{res.count}} {{getVoicesDecl(res.count)}}
          <svg-icon *ngIf="isShowVoteNames" [src]="res.isExpanded ? 'assets/chevron-up.svg' : 'assets/chevron-down.svg'" [applyClass]="true" svgClass="chevron"></svg-icon>
        </div>
      </div>

      <!--Фамилии-->
      <div *ngIf="isShowVoteNames" class="names-wrap" [ngClass]="res.isExpanded ? 'expanded':''">
        <!-- если множественное голосование разрешено, каждая фамилия - ссылка на историю голосований-->
        <ng-container *ngIf="questions?.polling.isMultipleVotingAllowed">
          <app-nav-list-item *ngFor="let u of res.names?.slice(0,res.showNamesCt)"
                             [navLink]="u.navLink" [textTmpl]="nameTmpl" [isDisabled]="!u.isHistoryAvl">
            <ng-template #nameTmpl>
              <div [ngClass]="res.voteEn">
                {{u.name}}
                <div class="grey-company">{{u.userCompanyName}}</div>
                <div class="proxy-vote" *ngIf="u.isProxyVote">Голос был делегирован</div>
              </div>
            </ng-template>
          </app-nav-list-item>
        </ng-container>
        <!-- множественное голосование не разрешено - просто фамилии -->
        <ng-container *ngIf="!questions?.polling.isMultipleVotingAllowed">
          <div class="names" [ngClass]="res.voteEn"
               *ngFor="let u of res.names.slice(0,res.showNamesCt)">
            {{u.name}}
            <div class="grey-company">{{u.userCompanyName}}</div>
            <div class="proxy-vote" *ngIf="u.isProxyVote">Голос был делегирован</div>
          </div>
        </ng-container>
        <!--показать оставшиеся голоса-->
        <div class="show-all-names" *ngIf="res.names?.length > res.showNamesCt" (click)="res.showNamesCt = res.names?.length">
          Показать все голоса {{res.voteText}}
          <!--Показать все {{res.names.length}} {{getVoicesDecl(res.names.length)}} {{res.voteText}}-->
        </div>
      </div>

    </div>
  </div>

</ng-template>
