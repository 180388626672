<!--голосование по вопросу-->
<app-screen-with-panel [title]="voteHistory?.userName"
                       [bodyTmpl]="bodyTmpl"
                       [backUrl]="backUrl">
 </app-screen-with-panel>

<ng-template #bodyTmpl>
  
  <div class="content-wrap">
    <!--Баннер, если был поверенный-->
    <div class="warn-block ok violet" *ngIf="voteHistory?.isProxy">
      <strong>Голос был делегирован</strong>
      <br />
      Голосовал {{voteHistory?.proxyName}}
    </div>
    <div class="vote-history-wrap">
      <!--Вопрос-->
      <div class="question-text result-ql-editor" [innerHTML]=" voteHistory?.questionText | HtmlRaw"></div>
      <h2>История голосования</h2>
      <div *ngFor="let it of voteHistory?.history" [ngClass]="it.voteEn" class="hist-item">
        <div>{{it.voteText}}</div>
        <div>{{it.voteDate}}</div>
      </div>
    </div>

    <!--Блок "следующий/предыдущий вопрос"-->
    <div class="prev-next">
      <div [ngClass]="{disabled: questionIx == 0}" (click)="onPrevQuestionClick()">
        <div class="btn-icon"><svg-icon src="assets/chevron-left.svg" [applyClass]="true" svgClass="icon" [viewBox]="'auto'"></svg-icon></div>
        <div class="btn-text">Предыдущий вопрос</div>
      </div>
      <div [ngClass]="{disabled: questionIx >= voteHistory?.questionIds.length - 1}" (click)="onNextQuestionCLick()">
        <div class="btn-text">Следующий вопрос</div>
        <div class="btn-icon"><svg-icon src="assets/chevron-right.svg" [applyClass]="true" svgClass="icon" [viewBox]="'auto'"></svg-icon></div>
      </div>
    </div>
  </div>
</ng-template>
