<app-screen-with-panel [title]="'Регистрация'"
                       [bodyTmpl]="bodyTmpl"
                       [isEditable]="publicEvents?.isEditor"
                       (onEditClick)="this.publicEvent?.isEditor && (isEditMode = true)"
                       (onCancelEditClick)="onCancelEditClick()"
                       [doNavigateBackOnCancel]="false"
                       [isSaveCancel]="isEditMode"
                       (onSaveClick)="onSaveClick()"
                       [backUrl]="backUrl"
                       [isDeleteEnabled]="false">


  <ng-template #bodyTmpl>
    <div *ngIf="!isEditMode && !isParticipant" class="main-text result-ql-editor">
      <div class="normal-message">
        После регистрации вам станут доступны все разделы мероприятия. Вам так же будут приходить уведомления, относящиеся к нему, чтобы вы всегда были в курсе последних новостей. Подключить уведомления можно на странице <a href="/user-profile">"Профиль"</a>
      </div>
      <br />
      <div class="hr"></div>
      <div class="panel-body-inner">
        <div class="btn-row">
          <button class="dialog-button register-violet" (click)="onRegisterBtnClick()">Зарегистрироваться</button>
        </div>
      </div>
    </div>
    <div *ngIf="!isEditMode && isParticipant" class="main-text result-ql-editor">
      <div class="green-message">
        Вы зарегистрированы на мероприятие "{{publicEvent.title}}", которое начнётся {{publicEvent.dateBegin4RegStr}}
      </div>
      <br />
      <div class="hr"></div>
      <div class="panel-body-inner">
        <div class="btn-row">
          <button class="dialog-button cancel-register-red" (click)="onCancelRegisterBtnClick()">Отменить регистрацию</button>
        </div>
      </div>
    </div>
    <!--модули-->
    <div *ngIf="isEditMode">
      <h3>Модули, доступные без регистрации</h3>
      <!--<div class="hr"></div>-->
      <div class="event-components">
        <div class="event-component" style="border: 0px" *ngFor="let it of eventComponentsNoReg">
          <div class="event-component-icon">
            <svg-icon [src]="'assets/' + it.icon + '.svg'" [applyClass]="true" svgClass="text-icon" [viewBox]="'auto'"></svg-icon>
          </div>
          <div class="event-component-text">{{it.text}}</div>
          <div class="slide-toggle">
            <mat-slide-toggle [checked]="it.isChecked" (change)="it.isChecked = !it.isChecked" [color]="'primary'"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="hr"></div>
      <div class="event-component" style="border: 0px">
        <div class="event-component-icon">
          <svg-icon [src]="'assets/chain.svg'" [applyClass]="true" svgClass="text-icon" [viewBox]="'auto'"></svg-icon>
        </div>
        <div class="event-component-text">Доступ по ссылке для регистрации</div>
        <div class="slide-toggle">
          <mat-slide-toggle [checked]="publicEvent.isLinkAccess" (change)="publicEvent.isLinkAccess = !publicEvent.isLinkAccess" [color]="'primary'"></mat-slide-toggle>
        </div>
      </div>
      <div class="grey-notice" *ngIf="publicEvent.isLinkAccess">
        Ссылка на мероприятие
      </div>
      <div class="event-component" style="border: 0px; padding-top:0px" *ngIf="publicEvent.isLinkAccess">
        <div class="event-component-text">{{eventLink}}</div>
        <button class="event-component-icon dialog-button" (click)="onCopyBtnClick()">
          <svg-icon [src]="'assets/copy.svg'" [applyClass]="true" svgClass="copy-icon" [viewBox]="'auto'"></svg-icon>
        </button>
      </div>
    </div>
  </ng-template>

</app-screen-with-panel>
