import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-menu-big-button',
  templateUrl: './menu-big-button.component.html',
  styleUrls: ['./menu-big-button.component.less']
})
export class MenuBigButtonComponent implements OnInit {

  constructor(private router:Router ) { }

  ngOnInit(): void {
  }
  @Input()
  icon?: string;
  @Input()
  text?: string;
  @Input()
  path?: string;
  @Output()
  click = new EventEmitter<void>();
 
  onClick() {
    this.click.emit();
    if (this.path) {
      this.router.navigateByUrl(this.path);
    }
  }
}
