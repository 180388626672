import { Injectable, EventEmitter, OnDestroy, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../common-data.service';
import { AjaxMethods } from '../models/AjaxMethods';
import { Browser } from '@capacitor/browser';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';


@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {

  storageUserKey = "storageuserkey";
  storageUserSelected = "";
  storageUserList: { name: string; passw: string; id: number }[] = []
  filteredUsers: Observable<string[]>;

  constructor(private http: HttpClient, private commonSrv: CommonDataService, private router: Router, private route: ActivatedRoute) { }

  getUserList(resolve, reject?) {

      const self = this;

      SecureStoragePlugin.get({
        key: this.storageUserKey,
      }).then(x => {
        if (x && x.value) {
          const storage = JSON.parse(x.value) as StorageUserLogin;
          if (storage && Array.isArray(storage.userLoginList)) {
            self.storageUserSelected = storage.userLoginSelected;
            storage.userLoginList.forEach((x) => { self.storageUserList.push(x); });
            if (self.storageUserSelected) {
              setTimeout(() => {
                const found = self.storageUserList.find((x) => x.name === self.storageUserSelected);
                if (found && found.passw) {
                  if (resolve) {
                    resolve(found.name, found.passw);
                  }
                  //this.formGroup.patchValue({
                  //  userName: found.name,
                  //  userPassword: found.passw
                  //});
                  console.log(`Storage get successful.`);
                }
              });

            }

          }
        }
      }).catch(error => {
          if (reject) reject();
          console.warn(`Storage get warn:${JSON.stringify(error)}`);
      });


       //SecureStoragePlugin.get({
        //  key: `${this.storageUserKey}_${userName}`,
        //}).then(x => {
        //  if (x && x.value) {
        //    this.formGroup.patchValue({
        //      userPassword: x.value
        //    });
        //    console.log(`Storage get successful.`);
        //  }
        //}).catch(error => console.warn(`Storage get warn:${JSON.stringify(error)}`));

  }

  findUserList(username: string): { name: string; passw: string; id: number } {

    return this.storageUserList.find((x) => x.name === username);
  }

  filterUserList(filter: string): string[] {

    if (filter) {
      filter = filter.trim().toLowerCase();
      if (filter.length <= 1) {
        return this.storageUserList.filter(option => option.name && option.name.toLowerCase().includes(filter)).map(option => option.name);
      }
    }
    return this.storageUserList.slice().map(option => option.name);
  }

  setUserList(loginId: number, username: string, userpasw: string, resolve?, reject?) {

    const self = this;

    const found = this.storageUserList.find((x) => x.name === username);
    if (found) {
      found.passw = userpasw;
    }
    else {
      this.storageUserList.push({ name: username, passw: userpasw, id: loginId });
    }
    SecureStoragePlugin.set({
      key: this.storageUserKey,
      value: JSON.stringify({ userLoginSelected: username, userLoginList: this.storageUserList })
    })
      .then(() => {
        self.storageUserSelected = username;

        if (resolve) resolve();
        console.log(`Storage set successful.`);
      })
      .catch(error => {
        if (reject) reject();
        console.error(`Storage set error:${JSON.stringify(error)}`);
      });
  }


  clearUserList(resolve?, reject?) {

    const self = this;

    SecureStoragePlugin.remove({ key: this.storageUserKey, })
      .then(() => {
        self.storageUserSelected = "";
        self.storageUserList = [];

        if (resolve) resolve();
        console.log(`Storage remove successful.`);
      })
      .catch(error => {
        if (reject) reject();
        console.error(`Storage remove error:${JSON.stringify(error)}`);
      });

  }


}
