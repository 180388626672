import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-configure-notification',
  templateUrl: './configure-notification.component.html',
  styleUrls: ['./configure-notification.component.less']
})
  //------------------
  // Конфигурация уведомлений
export class ConfigureNotificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  topHeaderText = ""; // например, "об окончании мероприятия"

  @Input()
  eventTimeText = ""; // после текста "в момент ", например, "окончания публикации"

  @Input()
  selected: number; // mask
  @Output("selectedChange")
  selectedChange = new EventEmitter<number>();
  @Input()
  selectedDate: Date;
  @Output()
  selectedDateChange = new EventEmitter<Date>();
  @Input()
  minDate: Date;
  @Input()
  maxDate: Date;

  isChecked(ix: number): boolean {
    return Boolean(this.selected & (1 << ix));
  }
  setChecked(v: boolean, ix: number) {
    if (v) {
      this.selected = this.selected | (1 << ix);
    }
    else {
      this.selected = this.selected & (0xffff - (1 << ix));
    }
    this.selectedChange.emit(this.selected);
    console.debug("selected change", this.selected.toString(16));
  }
  onSelectedDateChange(d: Date) {
    this.selectedDateChange.emit(d);
  }

  items: {id:number, text:string, checked?:boolean}[] = [
    { id: 1, text: "В момент " },
    { id: 2, text: "За 15 минут" },
    { id: 3, text: "За день" },
    { id: 4, text: "За два дня" },
    { id: 5, text: "За три дня" },
    { id: 6, text: "За неделю" },
    { id: 7, text: "За месяц" },
    { id: 0, text: "Другое" },

  ];
}
