import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.less']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  @ViewChild("confirmationTmpl")
  сonfirmationTmpl: TemplateRef<any>;

  confirmationText: string = 'Текст подтвердить?';
  title = 'Удаление';
  isMessageBox = false;
  noText = "Отменить";
  yesText = "Удалить";
  duration = 0;
  focusInitial = true;

  confirm(param: { message: string, title?: string, yesText?: string, noText?: string, duration?: number, focusInitial?: boolean }): Promise<boolean> {

    return new Promise<boolean>((resolve) => {
      if (this.dialog.openDialogs && this.dialog.openDialogs.length) return;

      this.confirmationText = param.message;
      this.title = param.title || 'Подтверждение';
      this.isMessageBox = false;
      this.yesText = param.yesText || 'Удалить';
      this.noText = param.noText || 'Отменить';
      this.duration = param.duration || 0;
      this.focusInitial = param.focusInitial != null ? param.focusInitial : true;

      // const self = this;
      const dlgRef = this.dialog.open(this.сonfirmationTmpl/*, { width: "317px", height: "220px" }*/);
      if (this.duration > 0) {
        dlgRef.afterOpened().subscribe(_ => { setTimeout(() => { dlgRef.close();  }, this.duration)
        });
      }

      dlgRef.afterClosed().subscribe((result) => {  resolve(Boolean(result)); });

    });

  }

  msgBox(param: { message: string, title?: string, duration?: number }): Promise<void> {

    return new Promise<void>((resolve) => {
      if (this.dialog.openDialogs && this.dialog.openDialogs.length) return;

      this.confirmationText = param.message;
      this.title = param.title || "Ошибка";
      this.isMessageBox = true;
      this.duration = param.duration || 0;

      let dlgRef = this.dialog.open(this.сonfirmationTmpl/*, { width: "317px", height: "220px" }*/);
      if (this.duration > 0) {
        dlgRef.afterOpened().subscribe(_ => {  setTimeout(() => {  dlgRef.close();  }, this.duration)  });
      }

      dlgRef.afterClosed().subscribe((result) => { resolve(); });

    });

  }
}
