<app-screen-with-panel [title]="'О мероприятии'"
                       [bodyTmpl]="bodyTmpl"
                       [isEditable]="publicEvents?.isEditor"
                       (onEditClick)="this.publicEvent?.isEditor && (isEditMode = true)"
                       (onCancelEditClick)="onCancelEditClick()"
                       [doNavigateBackOnCancel]="false"
                       [isSaveCancel]="isEditMode"
                       (onSaveClick)="onSaveClick()"
                       [isDeleteEnabled]="false"
                       [isDeleteVisible]="false"
                       [backUrl]="backUrl">

 
  <ng-template #bodyTmpl>
    <div class="header">
      {{publicEvent?.title}}
    </div>
    <div class="topheader-text" >
      <svg-icon src="/assets/clock-filled.svg" viewPort="auto" [applyClass]="false" svgClass="icon" [stretch]="true"></svg-icon>
      <div class="text" [innerHTML]="publicEvent?.dateStr"></div>
    </div>
    <div class="topheader-text">
      <svg-icon src="/assets/compass.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon>
      <div class="text" [innerHTML]="publicEvent?.locationText"></div>
    </div>

    <div class="hr"></div>

    <div class="main-text result-ql-editor">
      <div *ngIf="!isEditMode && publicEvent?.description" [innerHTML]="(publicEvent?.description || '' ) | HtmlRaw">
      </div>
      <div class="hint-message" *ngIf="!publicEvent?.description && !isEditMode">
        У мероприятия пока нет описания
      </div>
      <div class="quill-wrap">
        <quill-editor class="ql-snow" *ngIf="isEditMode" [(ngModel)]="publicEvent.description"
                      (onEditorCreated)="$event.focus()">
        </quill-editor>
      </div>
    </div>
  </ng-template>

</app-screen-with-panel>
