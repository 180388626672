import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PublicEventACLComponent } from './public-events/public-event-acl/public-event-acl.component';
import { PublicEventContactsComponent } from './public-events/public-event-contacts/public-event-contacts.component';
import { PublicEventNotificationComponent } from './public-events/public-event-notification/public-event-notification.component';
import { PublicEventEditComponent } from './public-events/public-event-edit/public-event-edit.component';
import { PublicEventFileComponent } from './public-events/public-event-file/public-event-file.component';
import { PublicEventFilesComponent } from './public-events/public-event-files/public-event-files.component';
import { PublicEventInfoComponent } from './public-events/public-event-info/public-event-info.component';
import { PublicEventParticipantsComponent } from './public-events/public-event-participants/public-event-participants.component';
import { PublicEventPollingAdminProxyComponent } from './public-events/public-event-polling-admin-proxy/public-event-polling-admin-proxy.component';
import { PublicEventPollingEditComponent } from './public-events/public-event-polling-edit/public-event-polling-edit.component';
import { PublicEventPollingSetProxyComponent } from './public-events/public-event-polling-set-proxy/public-event-polling-set-proxy.component';
import { PublicEventPollingComponent } from './public-events/public-event-polling/public-event-polling.component';
import { PublicEventProgramComponent } from './public-events/public-event-program/public-event-program.component';
import { PublicEventQuestionEditComponent } from './public-events/public-event-question-edit/public-event-question-edit.component';
import { PublicEventQuestionComponent } from './public-events/public-event-question/public-event-question.component';
import { PublicEventRegistrationComponent } from './public-events/public-event-registration/public-event-registration.component';
import { PublicEventVoteHistoryComponent } from './public-events/public-event-vote-history/public-event-vote-history.component';
import { PublicEventVoteResultComponent } from './public-events/public-event-vote-result/public-event-vote-result.component';
import { PublicEventVoteComponent } from './public-events/public-event-vote/public-event-vote.component';
import { PublicEventComponent } from './public-events/public-event/public-event.component';
import { PublicEventsComponent } from './public-events/public-events.component';
import { SendPushComponent } from './send-push/send-push.component';
import { TCHLinkEditComponent } from './tchlink/tchlink-edit/tchlink-edit.component';
import { TCHLinkSetupShdlComponent } from './tchlink/tchlink-edit/tchlink-setup-shdl/tchlink-setup-shdl.component';
import { TCHLinkComponent } from './tchlink/tchlink.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { WhatsNewComponent } from './whats-new/whats-new.component';
import { PublicEventLocationComponent } from './public-events/public-event-location/public-event-location.component';
import { PublicEventGalleryComponent } from './public-events/public-event-gallery/public-event-gallery.component';
import { PublicEventNewsComponent } from './public-events/public-event-news/public-event-news.component';
import { PublicEventChatComponent } from './public-events/public-event-chat/public-event-chat.component';
import { TchMailingComponent } from './tch-mailing/tch-mailing.component';
import { TchMailingDetailComponent } from './tch-mailing/tch-mailing-detail/tch-mailing-detail.component';
import { PublicEventFilesIntDetailComponent } from './public-events/public-event-files-int-detail/public-event-files-int-detail.component';

const routes: Routes = [
  { path: "", component: HomeComponent, data: {title: "ТКП" } },
  { path: "home", component: HomeComponent, data: { title: "ТКП" } },
  { path: "home/help", component: HelpComponent, data: {title: "Поддержка"} },
  { path: "login/:returnurl", component: LoginComponent, data: {title:"Подключение"}},
  { path: "user-profile", component: UserProfileComponent, data: {title: "Профиль"} },
  /*{ path: "whats-new", component: WhatsNewComponent, data: {title: "Новости"} },*/

  { path: "public-events", component: PublicEventsComponent, data: { title: "Мероприятия", animation: "PublicEvents" } },
  { path: "public-events/:id", component: PublicEventComponent, data: { animation: "PublicEvent" } },
  { path: "public-events-ccs", component: PublicEventsComponent, data: { title: "НСАВ", animation: "PublicEvents" } },
  { path: "public-events/:id/edit", component: PublicEventEditComponent, data: { animation: "PublicEventEdit" } },
  { path: "public-events/:id/info", component: PublicEventInfoComponent, data: { animation: "PublicEventInfo" } },
  { path: "public-events/:id/public-event-acl/:eventOrVoting", component: PublicEventACLComponent},
  { path: "public-events/:id/programme", component: PublicEventProgramComponent, data: { animation: "PublicEventProgram" } },
  { path: "public-events/:id/programme/:progId", component: PublicEventProgramComponent, data: { animation: "PublicEventProgram" } },
  //участники
  { path: "public-events/:id/participants", component: PublicEventParticipantsComponent },
  // Регистрация
  { path: "public-events/:id/registration", component: PublicEventRegistrationComponent },
  // Связаться с организаторами
  { path: "public-events/:id/contacts", component: PublicEventContactsComponent },
  // Место проведения
  { path: "public-events/:id/location", component: PublicEventLocationComponent },
  // Фотогалерея
  { path: "public-events/:id/gallery", component: PublicEventGalleryComponent },
  // Новости
  { path: "public-events/:id/news", component: PublicEventNewsComponent },
  // Новости
  { path: "public-events/:id/chat", component: PublicEventChatComponent },
  // Уведомления связанные с мероприятиями
  { path: "public-events/:id/notifications", component: PublicEventNotificationComponent },

  { path: "public-events/:id/vote/:pollingId", component: PublicEventVoteComponent, data: { animation: "PublicEventVote" } },

  { path: "public-events/:publicEventId/polling", component: PublicEventPollingComponent, data: { animation: "PublicEventPolling" } },
  { path: "public-events/:publicEventId/polling/:pollingId/edit", component: PublicEventPollingEditComponent, data: { animation: "PublicEventPollingEdit" } },
  // Опрос/голосование
  { path: "public-events/:publicEventId/polling/:pollingId", component: PublicEventQuestionComponent },
  { path: "public-events/:publicEventId/polling/:pollingId/:ixTab", component: PublicEventQuestionComponent },
  // Установить делегирование
  { path: "public-events/:publicEventId/setproxy/:pollingId", component: PublicEventPollingSetProxyComponent},
  // Установить делегирование - АДМИН
  { path: "public-events/:publicEventId/adminproxy/:pollingId", component: PublicEventPollingAdminProxyComponent},
  // Редактирование голосования
  { path: "public-events/:publicEventId/polling/:pollingId/question/:questionId", component: PublicEventQuestionEditComponent },
  // голосование пользователя
  { path: "public-events/:publicEventId/polling/:pollingId/vote/:questionId", component: PublicEventVoteComponent },
  // результаты голосования
  { path: "public-events/:publicEventId/polling/:pollingId/vote-result/:questionId", component: PublicEventVoteResultComponent },
  // история голосования 
  {
    path: "public-events/:publicEventId/polling/:pollingId/vote-history/:questionId/:userAccountId",
    component: PublicEventVoteHistoryComponent
  },
  // история голосования с возвратом на результаты голосования
  {
    path: "public-events/:publicEventId/polling/:pollingId/vote-history/:questionId/:userAccountId/:back2Results",
    component: PublicEventVoteHistoryComponent
  },
  // материалы (aka файлы)
  { path: "public-events/:id/files/:fileId", component: PublicEventFileComponent, data: { animation: "PublicEventFile" } },
  { path: "public-events/:id/files", component: PublicEventFilesComponent, data: { animation: "PublicEventFiles" } },
  { path: "public-events/:id/file-detail/:fileId", component: PublicEventFilesIntDetailComponent },
  // TCH Link
  { path: "tch-link", component: TCHLinkComponent },
  { path: "tch-link/edit", component: TCHLinkEditComponent, data: {backUrl: 'tch-link'} },
  { path: "tch-link/edit/shdl", component: TCHLinkSetupShdlComponent },
  // Официальная рассылка
  { path: "tch-mailing", component: TchMailingComponent, data: { title: "Официальные рассылки ТКП", animation: "TchMailing" } },
  { path: "tch-mailing/:id", component: TchMailingDetailComponent, data: { animation: "TchMailingDetail" } },
  // Оповещения
  { path: "notifications", component: NotificationsComponent},


  // тестироване push
  { path: "send-push", component: SendPushComponent },

  { path: "**", component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes
    , { onSameUrlNavigation: 'reload'  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
