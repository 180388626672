import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonDataService } from './common-data.service';
import { delay } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { slideInAnimation } from './animations';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component';
import { Plugins } from '@capacitor/core';
import { Platform } from '@angular/cdk/platform';
const { SplashScreen } = Plugins;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  title = 'TCHMob';
  
  //------- Constructor
  constructor(private http: HttpClient, public commonData: CommonDataService, private titleSrv: Title, private router: Router) {
    console.debug("App.component contructor");
    this.isSpinnerVisible = false;
    commonData.isSpinnerVisibleEvt
      .pipe(delay(0))
      .subscribe((fl: boolean) => this.isSpinnerVisible = fl);
    // Установка заголовка документа <title> из route.data.title
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        let r = this.router.routerState.root;
        while (r.firstChild) r = r.firstChild;
        r.data.subscribe(d => {
            if (d && d.title) {
              this.titleSrv.setTitle(d.title);// заголовок window
            }
          }
        );
      }
    });


  }// constructor


  
    ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.commonData.confirmationDialog = this.confirmationDialog;
  }

  @ViewChild("confirmationDialog")
  confirmationDialog: ConfirmationDialogComponent;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
  isSpinnerVisible = false;

  

  
}
