<app-screen-with-panel [title]="'Место проведения'"
                       [bodyTmpl]="bodyTmpl"
                       [isEditable]="isEditor"
                       (onEditClick)="isEditor && (isEditMode = true)"
                       (onCancelEditClick)="onCancelEditClick()"
                       [doNavigateBackOnCancel]="false"
                       [isSaveCancel]="isEditMode"
                       (onSaveClick)="onSave()"
                       (onDeleteClick)="onDelete()"
                       deleteBtnSubject="место проведения"
                       [isDeleteEnabled]="isDeleteEnabled"
                       [isDeleteVisible]="isDeleteEnabled"
                       [backUrl]="backUrl">


  <ng-template #bodyTmpl>
    <div class="main-text">
      <div class="hint-message" *ngIf="!location?.id && !isEditMode">
        У мероприятия пока нет места проведения
      </div>
      <div *ngIf="photoExists && !isEditMode" style="margin-bottom: 20px"><img style="max-width: 100%" [src]="safePhoto" /></div>
      <div *ngIf="!isEditMode && location?.id && location?.text" [innerHTML]="(location?.text || '' ) | HtmlRaw">
      </div>
      <div class="panel-body-inner" *ngIf="!isEditMode && location?.id && location?.text" style="margin-top: 32px">
        <div class="btn-row">
          <button class="dialog-button location-violet" (click)="onCopyBtnClick()">Скопировать адрес</button>
        </div>
      </div>

      <div class="title-hdr" *ngIf="isEditMode">
        Место проведения
      </div>
      <div class="file-load" (click)="onUploadPhotoClick()" *ngIf="isEditMode && !photoExists">
        <div class="icon">
          <svg-icon src="assets/plus.svg" [applyClass]="true"></svg-icon>
        </div>
        <div class="load-text">Загрузить схему</div>
      </div>

      

      <div *ngIf="photoExists && isEditMode" style="margin-top: 20px; margin-bottom: 20px; background-color: #F4F4F7">
        <div class="photo-wrapper">
          <div class="photo">
            <div><img style="max-width: 100%" [src]="safePhoto" /></div>
          </div>
          <div class="delete-photo-svg-wrapper"><svg-icon src="assets/crossrnd.svg" (click)="onDeletePhotoClick()"></svg-icon></div>
        </div>
      </div>

      <div class="form-wrapper" *ngIf="isEditMode" style="margin-top: 30px; margin-bottom: 10px;">
        <textarea matInput [(ngModel)]="location.text" rows="7" style="border: 1px solid #A1A1A1; border-radius: 4px; width: 100%"></textarea>
        <!--Сообщения об ошибках-->
        <div class="mat-error" *ngFor="let msg of getErrorMessages()">{{msg}}</div>
      </div>
    </div>
  </ng-template>

  <input #fileInput type="file" style="display:none;" name="location-file-input" (change)="onFileInputChange($event)" />

</app-screen-with-panel>
