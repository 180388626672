import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

// See the Moment.js docs for the meaning of these formats:

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-and-time',
  templateUrl: './date-and-time.component.html',
  styleUrls: ['./date-and-time.component.less'],
  
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
  
})
export class DateAndTimeComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {

  }
  @Input()
  label: string = '';

  @Input()
  noUnderline: boolean = false;

  @Input()
  isDisabled = false;


  _editValue: any;
  @Input()
  set editValue(v: any) {
    if (!v) {
      this._datePart = null;
      this._timePart = null;
    }
    else {
      if (!this.showTime && !this.showDate) this.showDate = true;
      this._editValue = this.showTime ? moment(v).startOf('minute') : moment(v).startOf('day');
      if (!this._editValue.isSame(moment(v))) {
        setTimeout(() => this.editValueChange.emit(this._editValue));
      }
      this._datePart = moment(this._editValue);
      this._datePart.startOf('day');
      this._timePart = this._editValue;
      console.debug("editValue set to:" + this._editValue.toDate());
    }
  }
  get editValue() { return this._editValue; }

  _datePart;
  set datePart(v) {
    this._datePart = moment(v);
    this._timePart = this._timePart || moment(this._timePart);
    this._editValue = this._datePart.startOf("day").hour(this._timePart.hour()).minute(this._timePart.minute());
    this.editValueChange.emit(this._editValue);
  }
  get datePart() {
    return this._datePart;
  }

  @Input()
  filter = function (d: Date | null) {
    return true;
  }
  @Input()
  min;
  @Input()
  max;
  // показать кнопку "очистить"
  @Input()
  allowClear = false;

  // редактировать дату
  @Input()
  showDate = true;
  // редактировать время
  @Input()
  showTime = true;
  // показывать разделитель
  @Input()
  showSep = true;
  // значение, которое выставить, если значение null, пользователь сделал click в поле
  @Input()
  onFocusDefaultValue = null;
  // Ввод даты получил фокус. Поставить onFocusDefaultValue, если текущее значение null 
  onDateFocus() {
    if (!this.editValue && this.onFocusDefaultValue) {
      this.editValue = this.onFocusDefaultValue;
      this.editValueChange.emit(this.onFocusDefaultValue);
    }
  }

  @Output()
  editValueChange = new EventEmitter<any>();

  // кнопка "очистить"
  onClearClick() {
    this._timePart = null;
    this._editValue = null;
    this.editValueChange.emit(null);
  }

  doNothing() { }

  _timePart: any;

  get timePart() {
    return this._editValue?.format('HH:mm') ?? '';

  }
  set timePart(v) {
    var tm = moment(v, "HH:mm");
    if (!this._editValue?.isValid()) {
      this._editValue = moment(this.onFocusDefaultValue || new Date());
    }
    if (tm.isValid()) {
      if (!moment(this._editValue).isValid()) {
        this._editValue = moment(this.onFocusDefaultValue);
      }
      this._editValue.hour(tm.hour()).minute(tm.minute());
      //console.debug('timepart set to:' + this._timePart.toDate());
      this.editValueChange.emit(this._editValue);
    }
  }

}
