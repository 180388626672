<div class="item-wrap" (click)="onClick(true)" [ngClass]="[isTopBorder ? 'item-top-border' : '', isDisabled? 'item-disabled' : '']">
  <div class="photo-wrapper" *ngIf="showPhoto">
    <div class="photo">
      <div class="img-wrap" [ngStyle]="{'width.px': photoSize, 'height.px':photoSize}">
        <svg-icon *ngIf="!photoIsImage" [src]="photoPath" [applyClass]="true" svgClass="photo-svg" [svgStyle]="{'width.px':photoSize, 'height.px':photoSize}"></svg-icon>
        <img [src]="photoSafe" *ngIf="photoIsImage" [ngStyle]="{'width.px': photoSize, 'height.px':photoSize}" />
      </div>
    </div>
  </div>
  <div class="text-wrap">
    <div class="first-line">
      <!--номер по порядку-->
      <div *ngIf="sequenceNo" class="seq-no">
        {{sequenceNo}}
      </div>
      <!--Иконка внутри текста-->
      <ng-container *ngIf="icon">
        <svg-icon [src]="iconPath" [applyClass]="true" svgClass="text-icon" [viewBox]="'auto'"></svg-icon>
      </ng-container>
      <!--сам текст-->
      <div class="text" appTextEllipsis *ngIf="text && text != ''">{{text}}</div>
      <!--шаблон-->
      <ng-content *ngTemplateOutlet="textTmpl; context:param?.it"></ng-content>
    </div>
    <div class="second-line" [innerHTML]="secondLine" [ngClass]="{indented: icon}"></div>
  </div>
  <div class="icon " (click)="onClick(false)" *ngIf="showIcon && navIcon">
    <svg-icon [src]="'assets/' + navIcon + '.svg'" [svgClass]="navIconClass" [applyClass]="true" [stretch]="true" [viewBox]="'auto'" [svgStyle]="{'width.px':'24', 'height.px':'24'}"></svg-icon>
  </div>
</div>
