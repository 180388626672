<div class="top-level-screen" appHeight100vh>
  <div class="screen-body">

    <div class="tch-icon">
      <svg-icon src="assets/tch-logo.svg" [svgStyle]="{ 'width.px':'113', 'height.px':'36' }"></svg-icon>
    </div>
    
    <h2 *ngIf="pageLogin == 0">
      Авторизация
    </h2>

    <app-dlg-panel>
      <form [formGroup]="formGroup">

        <mat-form-field *ngIf="pageLogin == 0">
          <mat-label>Логин</mat-label>
          <input matInput type="text" formControlName="userName" [required]="isLoginRequired" name="username" autocapitalize="off" autocomplete="username" />
        </mat-form-field>
        <br />
        <mat-form-field *ngIf="pageLogin == 0">
          <mat-label>Пароль</mat-label>
          <input matInput [type]="isShowPassword ? 'text' : 'password'" class="form-control" formControlName="userPassword" [required]="isLoginRequired" name="password" autocomplete="current-password" />
          <mat-icon matSuffix *ngIf="isShowPassword" (click)="toggleShowPassword()" [ngStyle]="{ 'width.px':'22', 'height.px':'22', 'cursor': 'pointer' }">visibility_on</mat-icon>
          <mat-icon matSuffix *ngIf="!isShowPassword" (click)="toggleShowPassword()" [ngStyle]="{ 'width.px':'22', 'height.px':'22', 'cursor': 'pointer' }">visibility_off</mat-icon>
        </mat-form-field>
        <mat-form-field *ngIf="userProfileVisible && pageLogin == 0">
          <mat-label>Контур</mat-label>
          <mat-select formControlName="userProfile" name="userProfile">
            <mat-option *ngFor="let opt of profileTypes" [value]="opt.id">
              <div [title]="opt.title"> {{opt.label}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!--
  <div *ngIf="userProfileVisible && pageLogin == 0">
            
    <mat-checkbox type="text" formControlName="useDebug" name="useDebug" labelPosition="after" (change)="onUseDebugCheck($event.checked)">Включить режим отладки</mat-checkbox>
  </div>
  -->
        <div class="biotype-image" *ngIf="pageLogin == 1 && isBioAvailable">
          <div class="photo-wrapper">
            <div class="photo" *ngIf="isBioTypeFace">
              <svg-icon src="assets/face-id.svg" [applyClass]="true" svgClass="photo-svg"></svg-icon>
            </div>
            <div class="photo" *ngIf="isBioTypeTouch">
              <svg-icon src="assets/touch-id-out.svg" [applyClass]="true" svgClass="photo-svg"></svg-icon>
            </div>
          </div>
          <div class="biotype-name" *ngIf="pageLogin == 1">{{bioTypeNameCases(0, true)}}</div>
          <div class="biotype-descr" *ngIf="pageLogin == 1">Использовать {{bioTypeNameCases(4)}} для входа в приложение?</div>
        </div>

        <div class="btn-row" *ngIf="pageLogin == 0">
          <button (click)="onSubmit()">Войти</button>
        </div>
        <div class="btn-row-next" *ngIf="pageLogin == 0 && isBioCredentialAvailable">
          <button (click)="onBioSubmit()">Войти через {{bioTypeNameCases(4)}}</button>
        </div>

        <div class="btn-row-next" *ngIf="pageLogin == 1 && isBioAvailable">
          <button (click)="onSubmit(2)">Использовать {{bioTypeNameCases(4)}}</button>
        </div>
        <div class="btn-row-next" *ngIf="pageLogin == 1 && isBioAvailable">
          <button (click)="onSubmit(1)">Входить с помощью пароля</button>
        </div>
        <div class="split-form-row" id="split-form-row"></div>
        <div class="biotype-help" *ngIf="pageLogin == 1 && isBioAvailable">Управлять входом по {{bioTypeNameCases(5)}} можно   в настройках профиля</div>
        <div *ngIf="pageLogin == 0">
          <div class="pass-link" (click)="forgotPassClick()">Забыли пароль?</div>&nbsp;&nbsp;&nbsp;<div class="pass-link" (click)="changePassClick()">Изменить пароль</div>
        </div>
        <div class="split-row" id="split-row"></div>
      </form>
    </app-dlg-panel>
    <div class="padding-space"></div>
  </div>
</div>
