<app-screen-with-panel [title]="info?.subject"
                       [headerPartTmpl]="headerTmpl"
                       [bodyTmpl]="bodyTmpl"
                       [backUrl]="backUrl"
                       [isEditable]="false">
  <ng-template #headerTmpl>
  </ng-template>

  <ng-template #bodyTmpl>
    <div class="message-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(info?.message)"></div>
    <hr class="hr-line" />
    <div class="field-block" *ngIf="info?.author">
      <label>Автор</label>
      <div>{{info?.author}}</div>
    </div>
    <div class="field-block" *ngIf="info?.createDateText">
      <label>Дата создания</label>
      <div>{{info?.createDateText}}</div>
    </div>
    <div class="field-block" *ngIf="info?.moveDateText">
      <label>Дата изменения</label>
      <div>{{info?.moveDateText}}</div>
    </div>
    <div class="field-block" *ngIf="info?.stateName">
      <label>Статус</label>
      <div>{{info?.stateName}}</div>
    </div>
    <div class="field-block" *ngIf="info?.recipientsText">
      <label>Кому</label>
      <div>{{info?.recipientsText}}</div>
    </div>
    <div class="field-block" *ngIf="info?.attachments && (info?.attachments.length > 0)">
      <label>Прикрепленные файлы</label>
      <div *ngFor="let it of info?.attachments; let ix = index" style="margin-bottom: 5px" class="download-text" (click)="onFileOpenClick(it)">{{it.fileName}}</div>
    </div>
    <div style="margin-top: 22px;">
      <div class="btn-row" *ngFor="let it of info?.nextStates; let ix = index" style="margin-top: 8px;">
        <button class="big dialog-button" [ngClass]="it.isRed ? 'action-red' : (it.isViolet ? 'action-violet' : 'action-green')" (click)="onActionClick(it)">{{it.actionName}}</button>
      </div>
    </div>
    <!--Открытие файла без дополнительного экрана-->
    <div style="display: none;">
      <a #downloadButton [href]="fileDataUrl" [download]="downloadFileName">Скачать файл</a>
    </div>
    <app-child-panel [header]="actionName" [(isVisible)]="isActionPanelVisible" [isPositionFixed]="true">
      <div>
        <div [innerHTML]="info?.subject" class="subject-text" appTextEllipsis></div>
        <mat-form-field style="width: 100%" class="mat-form-field-textarea">
          <mat-label>Комментарий</mat-label>
          <textarea matInput [(ngModel)]="changeRequest.comment" rows="10"></textarea>
        </mat-form-field>
        <div class="btn-row">
          <button (click)="onCancelClick()" class="dialog-button action-violet">Отменить</button>
          <button (click)="onDoClick()" class="dialog-button action-green">Выполнить</button>
        </div>
      </div>
    </app-child-panel>
  </ng-template>
</app-screen-with-panel>
