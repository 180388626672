import { Injectable, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CommonDataService } from './common-data.service';
import { ChatService } from './services/chat.service';
import { BiometricService } from './services/biometric.service';
import { SecureStorageService } from './services/secure-storage.service';
import { JWTInterceptorService, DEFAULT_TIMEOUT } from './jwtinterceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DlgPanelComponent } from './dlg-panel/dlg-panel.component';
import { MenuBigButtonComponent } from './menu-big-button/menu-big-button.component';
import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatRadioModule } from '@angular/material/radio'
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule} from '@angular/material/badge';


import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { WhatsNewComponent } from './whats-new/whats-new.component';
import { PublicEventsComponent } from './public-events/public-events.component';
import { PublicEventComponent } from './public-events/public-event/public-event.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ScreenGeneralComponent } from './screen-general/screen-general.component';
import { NavListItemComponent } from './nav-list-item/nav-list-item.component';
import { ScreenWithPanelComponent } from './screen-with-panel/screen-with-panel.component';
import { ScreenTopHeaderComponent } from './common/screen-top-header/screen-top-header.component';
import { PublicEventInfoComponent } from './public-events/public-event-info/public-event-info.component';
import { PublicEventProgramComponent } from './public-events/public-event-program/public-event-program.component';
import { PublicEventPollingComponent } from './public-events/public-event-polling/public-event-polling.component';
import { PublicEventFilesComponent } from './public-events/public-event-files/public-event-files.component';
import { TabsComponent } from './common/tabs/tabs.component';
import { PublicEventVoteComponent } from './public-events/public-event-vote/public-event-vote.component';
import { PublicEventVoteResultComponent } from './public-events/public-event-vote-result/public-event-vote-result.component';
import { Height100vhDirective } from './common/height100vh.directive';
import { DisableControlDirective } from './common/disabled.directive';
import { NewDeleteButtonsComponent } from './common/new-delete-buttons/new-delete-buttons.component';
import { PublicEventEditComponent } from './public-events/public-event-edit/public-event-edit.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { QuillModule } from 'ngx-quill';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { DateAndTimeComponent } from './common/date-and-time/date-and-time.component';
import { PublicEventFileComponent } from './public-events/public-event-file/public-event-file.component';
import { PublicEventPollingEditComponent } from './public-events/public-event-polling-edit/public-event-polling-edit.component';
import { PublicEventACLComponent } from './public-events/public-event-acl/public-event-acl.component';
import { IconButtonComponent } from './common/icon-button/icon-button.component';
import { PublicEventQuestionComponent } from './public-events/public-event-question/public-event-question.component';
import { PublicEventQuestionEditComponent } from './public-events/public-event-question-edit/public-event-question-edit.component';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component';
import { PublicEventVoteHistoryComponent } from './public-events/public-event-vote-history/public-event-vote-history.component';
import { ChildPanelComponent } from './common/child-panel/child-panel.component';
import { DragPanelDirective } from './common/drag-panel.directive';
import { TextEllipsisDirective } from './common/text-ellipsis.directive';
import { TimePickerComponent } from './common/time-picker/time-picker.component';
import { PreventTouchDirective } from './common/prevent-touch.directive';
import { HelpComponent } from './help/help.component';
import { HtmlRawPipe } from './HtmlRaw';
import { VoteSnackBarComponent } from './public-events/public-event-vote/VoteSnackBarComponent';
import { SendPushComponent } from './send-push/send-push.component';
import { TCHLinkComponent } from './tchlink/tchlink.component';
import { TCHLinkEditComponent } from './tchlink/tchlink-edit/tchlink-edit.component';
import { TCHLinkSetupShdlComponent } from './tchlink/tchlink-edit/tchlink-setup-shdl/tchlink-setup-shdl.component';
import { CancelSaveDeleteBtnsComponent } from './common/cancel-save-delete-btns/cancel-save-delete-btns.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PublicEventParticipantsComponent } from './public-events/public-event-participants/public-event-participants.component';
import { PublicEventRegistrationComponent } from './public-events/public-event-registration/public-event-registration.component';
import { PublicEventContactsComponent } from './public-events/public-event-contacts/public-event-contacts.component';
import { PublicEventNotificationComponent } from './public-events/public-event-notification/public-event-notification.component';
import { ConfigureNotificationComponent } from './configure-notification/configure-notification.component';
import { PublicEventPollingSetProxyComponent } from './public-events/public-event-polling-set-proxy/public-event-polling-set-proxy.component';
import { PublicEventUserListComponent } from './public-events/public-event-user-list/public-event-user-list.component';
import { PublicEventPollingAdminProxyComponent } from './public-events/public-event-polling-admin-proxy/public-event-polling-admin-proxy.component';
import { PublicEventFilesIntComponent } from './public-events/public-event-files-int/public-event-files-int.component';
import { SetPosLeftTopDirective } from './common/set-pos-left-top.directive';
import { MoveOutsideDirective } from './common/move-outside.directive';
import { UserProfileDisplayComponent } from './user-profile-display/user-profile-display.component';
import { PublicEventNewsComponent } from './public-events/public-event-news/public-event-news.component';
import { PublicEventLocationComponent } from './public-events/public-event-location/public-event-location.component';
import { PublicEventGalleryComponent } from './public-events/public-event-gallery/public-event-gallery.component';
import { PublicEventChatComponent } from './public-events/public-event-chat/public-event-chat.component';
import { TchMailingComponent } from './tch-mailing/tch-mailing.component';
import { TchMailingDetailComponent } from './tch-mailing/tch-mailing-detail/tch-mailing-detail.component';
import { PublicEventFilesIntDetailComponent } from './public-events/public-event-files-int-detail/public-event-files-int-detail.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DlgPanelComponent,
    MenuBigButtonComponent,
    WhatsNewComponent,
    PublicEventsComponent,
    PublicEventComponent,
    UserProfileComponent,
    ScreenGeneralComponent,
    NavListItemComponent,
    ScreenWithPanelComponent,
    ScreenTopHeaderComponent,
    PublicEventInfoComponent,
    PublicEventProgramComponent,
    PublicEventPollingComponent,
    PublicEventQuestionComponent,
    PublicEventQuestionEditComponent,
    PublicEventFilesComponent,
    TabsComponent,
    PublicEventVoteComponent,
    PublicEventVoteResultComponent,
    Height100vhDirective,
    DisableControlDirective,
    NewDeleteButtonsComponent,
    PublicEventEditComponent,
    DateAndTimeComponent,
    PublicEventFileComponent,
    PublicEventPollingEditComponent,
    PublicEventACLComponent,
    IconButtonComponent,
    ConfirmationDialogComponent,
    PublicEventVoteHistoryComponent,
    ChildPanelComponent,
    DragPanelDirective,
    TextEllipsisDirective,
    TimePickerComponent,
    PreventTouchDirective,
    HelpComponent,
    HtmlRawPipe,
    VoteSnackBarComponent,
    SendPushComponent,
    TCHLinkComponent,
    TCHLinkEditComponent,
    TCHLinkSetupShdlComponent,
    CancelSaveDeleteBtnsComponent,
    NotificationsComponent,
    PublicEventParticipantsComponent,
    PublicEventRegistrationComponent,
    PublicEventContactsComponent,
    PublicEventNotificationComponent,
    ConfigureNotificationComponent,
    PublicEventPollingSetProxyComponent,
    PublicEventUserListComponent,
    PublicEventPollingAdminProxyComponent,
    PublicEventFilesIntComponent,
    SetPosLeftTopDirective,
    MoveOutsideDirective,
    UserProfileDisplayComponent,
    PublicEventNewsComponent,
    PublicEventLocationComponent,
    PublicEventGalleryComponent,
    PublicEventChatComponent,
    TchMailingComponent,
    TchMailingDetailComponent,
    PublicEventFilesIntDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true/* || environment.production*/ }),   // включить SW
    // ServiceWorkerModule.register('my-ngsw.js', { enabled: true || environment.production }),   // включить SW
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatBadgeModule, 
    PdfJsViewerModule,
    NgxMatTimepickerModule.setLocale('ru-RU'),
    QuillModule.forRoot(),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    CommonDataService,
    ChatService,
    BiometricService,
    SecureStorageService,
    {
      useClass: JWTInterceptorService,
      provide: HTTP_INTERCEPTORS,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: MAT_DATE_LOCALE, useValue: 'ru' },
    { provide: DEFAULT_TIMEOUT, useValue: 10000 },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'DD/MM/YYYY',
          monthYearA11yLabel: 'MMMM YYYY',
        }
      },
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private iconReg: SvgIconRegistryService) {

  }
}
