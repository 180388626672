<app-screen-with-panel
                       [headerPartTmpl]="screenHeader"
                       [bodyTmpl]="bodyTmpl"
                       [backUrl]="backUrl"
                       [isEditable]="questions?.isEditor"
                       (onEditClick)="onEditClick()"
                       [isInnerScroll]="true"
                       >

  <!-- Заголовок -->
  <ng-template #screenHeader>
    <div class="topheader-text">{{polling?.topic}}</div>
    <div class="topheader-text">
      <svg-icon src="assets/clock.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon>
      <div [innerHTML]="polling?.dateStr" class="text"></div>
    </div>
  </ng-template>

  <!-- Основная часть -->
  <ng-template #bodyTmpl>

    <ng-container *ngIf="ixTabActive == 0">
      <!--кнопка "добавить" только на закладке с вопросами-->
      <app-new-delete-buttons *ngIf="questions?.isEditor"
                              (onNewClick)="onNewClick()">
      </app-new-delete-buttons>
    </ng-container>

    <app-nav-list-item text="Делегирование голоса" (onIconClick)="onSetProxyClick()" *ngIf="!doesProxyForExist;else warnNoSetProxy"></app-nav-list-item>

    <ng-template #warnNoSetProxy>
      <div class="warn-block warn" style="margin-bottom:20px;">
        Делегирование недоступно. Вы голосуете за другого участника.
      </div>
    </ng-template>

    <!--Предуреждение, что голос делегирован-->
    <div class="warn-block warn" *ngIf="questions?.hasProxy" style="margin-bottom:28px;">
      <strong>Голос делегирован</strong><br />Вы не можете участвовать в голосовании
    </div>

    <!--Описание голосования-->
    <div class="polling-full-text">{{polling?.fullText}}</div>

    <!--если нет доступа к результатам, то только голосование -->
    <ng-container *ngIf="!polling?.isShowResultsToParticipant && !questions?.isEditor">
      <ng-container *ngTemplateOutlet="pollingContent"></ng-container>
    </ng-container>


    <!--Голосование - content-->
    <ng-template #pollingContent>
      <div class="pollings-wrap">
        <!--предупреждение о необходимости проголосовать-->
        <div class="warn-block" [ngClass]="getWarn()?.type" *ngIf="getWarn()?.type" [innerHTML]="getWarn().text">
        </div>
        <!-- Предупреждение, что голосование не началось -->
        <div *ngIf="questions?.isPollingNotBegun" class="grey-notice">Вопросы будут доступны во время проведения голосования</div>
        <!--Список вопросов-->
        <app-nav-list-item *ngFor="let it of questions?.items; let ix=index" [param]="{question:it, questions:questions}" [navLink]="'/public-events/'+ questions?.polling.publicEventId +'/polling/' + questions?.polling.id + '/vote/' + it.id" [sequenceNo]="(ix+1) + '.'" [textTmpl]="textTmpl">
          <!--текст вопроса и иконка результата голосования-->
          <ng-template #textTmpl>
            <div class="topic-tmpl">
              <div class="text-block">
                <div class="full-text" appTextEllipsis>{{HTML2Text(it.fullText)}}</div>
                <div style="display:none" class="result-ql-editor" [innerHTML]="it.fullText | HtmlRaw" #dummyFull></div>
                <div class="vote" [ngClass]="it.voteEnText" *ngIf="it.voteText">
                  <svg-icon src="assets/vote-tick.svg" [applyClass]="true"></svg-icon>
                  {{it.voteText}}
                </div>
              </div>
            </div>
          </ng-template>
        </app-nav-list-item>
      </div>
    </ng-template>

    <!-- Закладки -->
    <app-tabs [params]="[{label:'Голосование', contentTmpl: pollingContent}, {label:'Результаты',  contentTmpl: resultContent}]" [(ixActive)]='ixTabActive' *ngIf="polling?.isShowResultsToParticipant || questions?.isEditor">


      <!--Результаты - content-->
      <ng-template #resultContent>

        <div class="no-votes" *ngIf="!isAnyVote()">Пока нет ни одного голоса</div>

        <ng-container *ngIf="isAnyVote()">
          <!--Показывать результаты одного участника/все результаты-->
          <div class="participant-choice" *ngIf="questions?.isEditor || polling?.isShowVoteNames">
            <!--только для организатора или участника, если показываем имена-->
            <mat-form-field>
              <mat-label>Показать</mat-label>
              <mat-select [(ngModel)]="showResults">
                <mat-option [value]="showOneParticipant">Одного участника</mat-option>
                <mat-option [value]="showAllVotes">Все учтенные голоса</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="showResults == showOneParticipant">
              <mat-label>Участник</mat-label>
              <input matInput type="text" [matAutocomplete]="autoParticipant" [(ngModel)]="showUser" #inputAutocomplete />
              <mat-autocomplete #autoParticipant="matAutocomplete" [displayWith]="showUserFn.bind(this)" (optionSelected)="onUserSelected($event)">
                <mat-option *ngFor="let p of filteredParticipants()" [value]="p">
                  {{p.userName}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <!--Не голосовал-->
            <div class="didnt-vote" *ngIf="didntVote()">
              Участник не голосовал
            </div>
          </div>

          <div class="pollings-wrap" *ngIf="!didntVote()">
            <app-nav-list-item *ngFor="let it of itemsWithResults; let ix=index"
                               [param]="{question:it, questions:questions}"
                               [navLink]="resultsNavLink(it)"
                               [sequenceNo]="(ix+1) + '.'"
                               [textTmpl]="resultTmpl"
                               [isDisabled]="!questions?.isEditor && showResults != showAllVotes && showUser?.userAccountId != questions?.currentLoginId">
              
              <!--topic вопроса и гистограмма результата голосования-->
              <ng-template #resultTmpl>
                <div class="result-tmpl">
                  <div appTextEllipsis>{{HTML2Text(it.fullText)}}</div>
                  <div class="hist" *ngIf="showResults == showAllVotes; else showOneVote">
                    <!--диаграмму - если показывать все-->
                    <div class="hist-row" *ngFor="let res of it.summaryResults">
                      <div class="left-col" [ngClass]="res.voteEn">
                        <div class="vote-text">{{res.voteText}}</div>
                        <div class="hist-line" [style.width]="res.pct*100 + '%'"></div>
                      </div>
                      <div class="n-votes">{{res.count}} {{getVoicesDecl(res.count)}}</div>
                    </div>
                  </div>
                  <ng-template #showOneVote>
                    <!--только результат для одного пользователя-->
                    <span [ngClass]="vote4ShownUser(it.id).class">{{vote4ShownUser(it.id).text}}</span>
                  </ng-template>
                </div>
              </ng-template>
            </app-nav-list-item>

            <!--Количество голосов и "Удалить голос"-->
            <ng-container *ngIf="!didntVote() && showResults == showOneParticipant && showUser">
              <div class="bottom-line">
                <h3>Результат</h3>
                <div class="row">
                  <div class="label">Вопросов отвечено</div>
                  <div class="count-text">{{getQuestionCountText()}}</div>
                </div>
                <div class="row">
                  <div class="label">Результат</div>
                  <div class="count-text" [ngClass]="getVoteAccepted().class">{{getVoteAccepted().text}}</div>
                </div>
                <div class="btn" *ngIf="questions?.isEditor">
                  <button (click)="onDeleteVoteClick()">Удалить голос из результатов</button>
                </div>
              </div>
            </ng-container>
          </div>

        </ng-container>
      </ng-template>



    </app-tabs>

  </ng-template>



</app-screen-with-panel>
