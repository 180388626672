<app-screen-with-panel innerTitle="{{title}}" [bodyTmpl]="userListTmpl" (onBackClicked)="onBackClicked()">


  <ng-template #userListTmpl>
    <app-public-event-user-list [publicEventId]="publicEventId"
                                [isVotingOnly]="false"
                                [isParticipants]="isParticipants"
                                [isCbx]="true"
                                [showIcon]="false" [showUserDetailsOnClick]="false" (cbxChange)="onCbxChange($event)">

    </app-public-event-user-list>
  </ng-template>


</app-screen-with-panel>
