import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
//import { Native } from '@angular/platform-browser';
import { AjaxMethods } from '../models/AjaxMethods';
import { CommonDataService } from '../common-data.service';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  constructor(private http: HttpClient, private commonSrv: CommonDataService) { }

  ngOnDestroy(): void {
    if (this.updateReadIntvl) {
      clearInterval(this.updateReadIntvl);
      this.updateReadIntvl = null;
    }
  }

  updateReadIntvl;

  ngOnInit(): void {
    this.http.get<NotificationsHistory>(AjaxMethods.GetNotificationsHistory)
      .subscribe(data => {
        this.notifications = data.notifications;
        // сгруппировать по дате
        data.notifications.forEach(it => {
          if (!(it.dtFmt in this.dates)) {
            this.dates[it.dtFmt] = [];
          }
          this.dates[it.dtFmt].push( it );
        });
        // По интервалу обновить isRead
        this.updateReadIntvl = setInterval(() => {
          // те, что прочитаны, но не были прочитаны, пометить прочитанными и записать в массив на отправку для отметки как прочитанные
          let readIds = [];
          this.notifications.forEach(n => {
            if (!n.isRead && n.wasRead) {
              readIds.push(n.id);
            }
          });
          // отправить их. Если получилось, отметить как прочитанные
          if (readIds.length) {
            this.http.post<any>(AjaxMethods.PostNotificationsReadStatus, { ids: readIds })
              .subscribe(() => readIds.forEach(i => {
                let nn = this.notifications.find(nt => nt.id == i);
                if (nn) nn.isRead = true;
              }));
          }
        }, 800);
      });
  }
  notifications: SentNotification[];
  dates: {
    [dt: string]: SentNotification[]
  } = {};
  get datesKeys(): string[] {
    return Object.keys(this.dates);
  }

  onGotoUrlClick($event, url) {

     $event.preventDefault()
     $event.stopPropagation();

     this.commonSrv.navigateAction(url);

     return false;
  }
  onScroll($event) {
  }

  getMessageVisibility(msgItem: HTMLElement, msg: SentNotification) {
    try {
      
      var msgRect = msgItem?.getBoundingClientRect();
      var parentRect = msgItem?.closest(".screen-body")?.getBoundingClientRect();
      if (msgRect && parentRect) {
        // visible, если целиком виден. 
        var visible = msgRect.y >= parentRect.y
          && (msgRect.y + msgRect.height) <= (parentRect.y + parentRect.height);
        msg.wasRead = msg.wasRead || visible;
        return visible ? 'true' : 'false';
      }
      return 'false';
    }
    catch (exc) {
      console.error(`getMessageVisibility. Error:${exc.message}, ${exc}`);
      return 'false';
    }
  }
}
