import { HttpClient } from '@angular/common/http';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { ApplicationRef, Component, ElementRef, OnInit, NgZone, QueryList, TemplateRef, ViewChild, ViewChildren, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroupDirective, FormBuilder, FormGroup, FormControl, NgForm, Validators } from '@angular/forms';
// import { DatePipe } from '@angular/common'
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ErrorStateMatcher } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
// import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ChatService, ChatMessageType } from '../../services/chat.service';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
// import { subscribe, isSupported } from 'on-screen-keyboard-detector';
// import { Utils } from '../../utils';

// Режимы отображения
enum DisplayMode {
  list,
  view,
  edit,
  chat,
  // participant
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export class GuidGenerator {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}


@Component({
  selector: 'app-public-event-chat',
  templateUrl: './public-event-chat.component.html',
  styleUrls: ['./public-event-chat.component.less']
})
export class PublicEventChatComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private chatSrv: ChatService, private title: Title, private formBuilder: FormBuilder, private commonData: CommonDataService, private dialog: MatDialog, private appRef: ApplicationRef, private _ngZone: NgZone, private sanitizer: DomSanitizer) {
    title.setTitle(this.mainTitle);
  }

  mainTitle = "Чат";
  panelReset = '';

  tmpl: TemplateRef<any>; // текущий шаблон
  
  @ViewChild("tmplList")
  tmplList: TemplateRef<any>;
  @ViewChild("tmplViewItem")
  tmplViewItem: TemplateRef<any>; // просмотр 
  @ViewChild("tmplEditItem")
  tmplEditItem: TemplateRef<any>; // редактирование 
  @ViewChild("tmplChat")
  tmplChat: TemplateRef<any>; // просмотр содержимого чата
  @ViewChild("tmplParticipant")
  tmplParticipant: TemplateRef<any>; // просмотр профиля участника

  
  matcher = new MyErrorStateMatcher();
  chatForm: FormGroup;
  items: PublicEventChatItem[] = [];
  participants: UserAttrs[] = [];
  others: UserAttrs[] = [];

  syncModeMessages: PublicEventChatMessge[] = null; 
  messageForm: HTMLElement;
  
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
 
  @ViewChildren("chatBody") chatBody: QueryList<ElementRef<HTMLDivElement>> | undefined;
  @ViewChildren("chatContainer") chatContainer: QueryList<ElementRef<HTMLDivElement>> | undefined;
  @ViewChildren("chatBackground") chatBackground: QueryList<ElementRef<HTMLDivElement>> | undefined;
  @ViewChildren("chatTextarea") chatTextarea: QueryList<ElementRef<HTMLDivElement>> | undefined;
  @ViewChildren("chatMessgeForm") chatMessgeForm: QueryList<ElementRef<HTMLDivElement>> | undefined;
  @ViewChildren("chatHeaderInputHidden") chatHeaderInputHidden: QueryList<ElementRef<HTMLDivElement>> | undefined;


  tmpInitTimer: boolean = false;
  tmpInitMessages: PublicEventChatItem[] = null;

  ngOnInit(): void {
    //!!! [disabled]="!keyboardVisible" - не работает
    this.chatForm = this.formBuilder.group({
      'message': [null, Validators.required]
    });

    //!!!CHAT
    this.tmpInitTimer = false;   // признак добавления с задержкой, чтобы пропустить вперед добавление неучтенных сообщений
    this.tmpInitMessages = null;  // массив неучтенных сообщений - пополняется в период выполнения запроса к серверу
    // подписка на события от Chat Hub
    if (this.chatSrv) {
      this.chatSrv.messageReceivedObservable.pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((prev, curr) => prev.lastMessageId === curr.lastMessageId)
      ).subscribe((it: PublicEventChatItem) => {
          // this._ngZone.onStable.pipe(take(1)).subscribe(() => this.addChatMessage(it));
          if (it) {
            console.debug(`Chat message received. EventId=${it.publicEventId}, ClientId=${it.clientId}, ChatClientId=${this.chatSrv.clientId},  Message=${it.lastMessageId}.`);
            if (it.messages?.length > 0 && this.chatSrv.clientId !== it.clientId) {
              this._ngZone.run(() => {
                // console.debug('Unread count badge update. EventId:' + it.publicEventId);
                if (this.tmpInitMessages) {
                  // Если еще не зашли на страницу или заново зачитываем список чатов, складываем сообщения в приемник
                  this.tmpInitMessages.push(it);
                }
                else if (this.items) {
                  if (this.tmpInitTimer) {
                    setTimeout(() => { this.addChatMessage(it); }, 500);
                  }
                  else {
                    this.addChatMessage(it);
                  }
                }
              });
            }
          }
      });
    }
    
    this.route.params.subscribe(prm => {

      this.tmpInitTimer = false;
      this.tmpInitMessages = null;
      this.publicEventId = +prm["id"];
      this.searchTextChangedSubs = this.searchTextChanged
        .pipe(
          debounceTime(400)
          , distinctUntilChanged()
        )
        .subscribe(() => {
          this.tmpInitTimer = false;
          this.tmpInitMessages = [];
          this.http.get<PublicEventChat>(AjaxMethods.PublicEventChat.replace("{id}", this.publicEventId + ''), { params: { searchStr: this.searchText } })
            .subscribe(data => {
              if (data) {
                this.loginId = data.loginId;
                this.isEditor = data.isEditor;
                this.items = data.items;
                this.participants = data.participants;
                this.others = data.others ? data.others : [];

                // На старте нужно добавить неучтенные сообщения
                if (this.tmpInitMessages) {
                  if (this.tmpInitMessages.length > 0) {
                    this.tmpInitTimer = true;
                    this.tmpInitMessages.forEach((it) => {
                      this.addChatMessage(it, true);
                    });
                  }
                  this.tmpInitTimer = false;
                  this.tmpInitMessages = null;
                }
                this.appRef.tick();


                // Проверка и восстановление соединения чата
                this._ngZone.runOutsideAngular(() => {
                  this.chatSrv.connect([this.publicEventId], this.loginId);
                });



              }
            },
              err => {
                console.log('HTTP GET Chat request error: ', err);
                this.tmpInitTimer = false;
                this.tmpInitMessages = null;
              },
              () => console.log('HTTP GET Chat request completed.'));

        });

      this.searchTextChanged.next(null); // инициировать начальное чтение списка 

    });


  }

  ngAfterViewInit() {

    // console.log(this.chatBody.nativeElement.name);

    this.tmplMode = [
      { mode: DisplayMode.list, tmpl: this.tmplList, isSaveCancel: false, showClose: false, showButtons: false, isEditable: false, title: "Чат" },
      { mode: DisplayMode.edit, tmpl: this.tmplEditItem, isSaveCancel: true, showClose: true, showButtons: true, isEditable: false, title: "Чат" },
      { mode: DisplayMode.view, tmpl: this.tmplViewItem, isSaveCancel: true, showClose: true, showButtons: false, isEditable: false, title: "Чат" },
      { mode: DisplayMode.chat, tmpl: this.tmplChat, isSaveCancel: true, showClose: false, showButtons: false, isEditable: false, title: "Чат" },
      // { mode: DisplayMode.participant, tmpl: this.tmplParticipant, isSaveCancel: true, showClose: true, showButtons: false, isEditable: false, title: "Участник" },
    ]
     

  }

  ngOnDestroy(): void {

    //!!!CHAT

    if (this.destroy$) {
      this.destroy$.next(true);
      this.destroy$.complete();
      this.destroy$.unsubscribe();

      console.log("PublicEvent Component Chat Unsubscribe");
    }
    //if (this.keyboardDetector) {
    //  console.debug(`Unsubscribe Keyboard Detector window.innerHeight:${window.innerHeight}`);
    //  this.keyboardDetector();
    //}

    if (this.searchTextChangedSubs) {
      this.searchTextChangedSubs.unsubscribe();
    }

    this.unsubscribeKeyboardDetector();
    this.intersectionObserverDestroy();

    // !!!
    //// this.chatSrv.messageReceivedObservable
    // this.chatSrv.close();
  }
   
    
  publicEventId: number;
  loginId: number;
  isEditor: boolean;
  isSaveCancel = false;
  isEditable = false;
  showClose = false;
  showButtons = false;
  // Режимы отображения для переключения
  tmplMode: { mode: DisplayMode, tmpl: TemplateRef<any>, isSaveCancel: boolean, showClose: boolean, showButtons: boolean, isEditable: boolean, title: string }[] = [];
  mode: DisplayMode = DisplayMode.list;
  // объект для редактирования
  editItem: PublicEventChatItem;
  chatItem: PublicEventChatItem;

  chatMessageDates: { dt: string, messages: PublicEventChatMessge[] }[];

  generateChatMessageDates(chat:PublicEventChatItem): number {

    this.chatMessageDates = [];
    let dt = null;
    let items: any[] = [];

    let idxNewFirst: number = -1;
    let readMessageOrder: number = chat.readMessageOrder;

    //if (!readMessageOrder) {
    //  chat.unreadCount = 0;
    //}

    //!!! LS
    // let lastSendDate: Date;
    //const lastDt = localStorage.getItem(`ChatLastMsg_${chat.chatId}_Login${this.loginId}`);
    //if (lastDt) {
    //  lastSendDate = new Date(lastDt);
    //}
    //else {
    //  chat.unreadCount = 0;
    //  lastSendDate = new Date(chat.lastSendDate);
      //!!! LS
      // localStorage.setItem(`ChatLastMsg_${chat.chatId}_Login${this.loginId}`, lastSendDate.toISOString()); 
    //}


    // DEV-35038
    const isParticipant = chat.isParticipant;
    this.chatItem.messages.forEach((msg, i) => {

      if (isParticipant) {
        // if (idxNewFirst < 0 && (readMessageOrder > 0 && msg.messageOrder > readMessageOrder)) {
        if (idxNewFirst < 0 && msg.messageOrder > readMessageOrder) {
          idxNewFirst = i;
          msg.isNew = true;
        }
      }

      const sendDate = moment(msg.sendDate).format("DD MMMM");
      if (dt != sendDate) { // новая дата
        if (dt != null) {// не первая итерация
            this.chatMessageDates.push({ dt: dt, messages: items });
        }
        dt = sendDate;
        items = [];
      }
      items.push(msg);
    });
    if (dt) {// последнюю
      this.chatMessageDates.push({ dt: dt, messages: items });
    }

    return idxNewFirst;
    

  }

  
 
  // строка поиска
  searchText: string = '';
  searchTextChanged = new Subject<string>();
  searchTextChangedSubs: Subscription;
  onSearchTextChange() {
    this.searchTextChanged.next(this.searchText);
  }

  searchTextClear() {
    this.searchText = '';
    this.onSearchTextChange();
  }


  // переключение режима
  switchMode(mode: DisplayMode) {

    var tm = this.tmplMode.find(t => t.mode == mode);
    if (!tm) throw new Error(`Invalid mode:${mode}`);
    this.tmpl = tm.tmpl;
    this.isEditable = tm.isEditable;
    this.isSaveCancel = tm.isSaveCancel;
    this.showClose = tm.showClose;
    this.showButtons = tm.showButtons;
    if (tm.title) this.mainTitle = tm.title;
    this.mode = mode;

    // this.appRef.tick();

  }

  // Кнопка "назад"
  onBackClick() {
    switch (this.mode) {
      case DisplayMode.list: // на уровень вверх
        this.router.navigate([`/public-events/${this.publicEventId}`]);
        break;
       case DisplayMode.chat:// к списку
        this.items.sort((a, b) => {
          if (a.lastSendDate < b.lastSendDate) return 1;
          if (a.lastSendDate > b.lastSendDate) return -1;
          if (a.isRoot < b.isRoot) return 1;
          if (a.isRoot > b.isRoot) return -1;
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        // Гасим badge, если все они видны на экране
        if (!this.showChatToEnd) {
          this.onChatToEndClick(false);
        }

        // Отправить сообщение что пользователь покинул чат
        this.joinChatMessage(this.chatItem, ChatMessageType.Leave);

        this.unsubscribeKeyboardDetector();
        this.intersectionObserverDestroy();
        this.switchMode(DisplayMode.list);
        this.chatItem = null;

        // Перезачитываем заново список чатов
        //setTimeout(() => {
        //  this.searchTextChanged.next(this.searchText);
        //}, 10);
        // this.appRef.tick();
        break;
      //case DisplayMode.edit:// к списку
      //  this.switchMode(DisplayMode.chat);
      //  //this.editItem = null;
      //  break;
      //case DisplayMode.view:// к списку
      //  this.switchMode(DisplayMode.chat);
      //  //this.editItem = null;
      //  break;
      //case DisplayMode.participant: // к просмотру чата
      //  this.switchMode(DisplayMode.view);
      //  break;
    }
  }

  // Добавить новый чат
  onNewClick() {

    this.editItem = <any>{ isRoot: false, isPrivate: false, messages: [], participants: [] };
    this.switchMode(DisplayMode.edit);
    this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';//возвращение панели на место (когда виден заголовок)
  }

  // Редактирование чата
  onEditItemClick(it: PublicEventChatItem) {
    if (it && !it.isRoot) {
      if (it.isPrivate) {
        // Смотрим профиль участника
        let isActive: boolean = true;
        let pp = this.participants.find(y => y.loginId === it.loginId);
        if (!pp && this.others) {
          isActive = false;
          // Ищем в списке неактивных пользователей
          pp = this.others.find(y => y.loginId === it.loginId);
        }
        if (pp) {
          this.onParticipantClick(false, isActive, pp);
        }
      }
      else {

         // Гасим badge, если все они видны на экране
        //if (!this.showChatToEnd) {
        //  this.onChatToEndClick(false);
        //}
        this.unsubscribeKeyboardDetector();
        this.intersectionObserverDestroy();

        this.editItem = it;
        if (this.isEditor) {
          this.switchMode(DisplayMode.edit);
        }
        else {
          this.switchMode(DisplayMode.view);
        }
      }
      this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';//возвращение панели на место (когда виден заголовок)
    }
  }


  // Клик в пользователя - показать содержимое чата
  onChatClick(it: PublicEventChatItem) {
    
    this.chatItem = null;

    if (it.chatId) {
      this.syncModeMessages = [];
      this.http.get<PublicEventChat>(AjaxMethods.PublicEventChatMessage.replace("{id}", this.publicEventId + ''), { params: { chatId: it.chatId } })
        .subscribe(data => {
          if (data && data.items && data.items.length > 0) {
            
            if (data.others && data.others.length > 0) {
              // Обновляем список неактивных пользователей
              this.others = this.others.concat(data.others.filter(x => !this.others.find(y => y.loginId === x.loginId)));
              // Для сложных объектов не работает
              // this.others = [...new Set([...this.others, ...data.others])];
            }

            // this.chatItem = Object.assign({}, data.items[0]);
            this.chatItem = data.items[0];
            this.chatItem.loginId = it.loginId;
            this.chatItem.name = it.name;
            this.chatItem.photo = it.photo;
            this.chatItem.notActive = it.notActive;
            this.chatItem.isParticipant = this.participants.some(pp => pp.loginId == this.loginId);
            if (this.syncModeMessages) {
              if (this.syncModeMessages.length > 0) {
                this.syncModeMessages.forEach((msg) => {
                  if (!this.chatItem.messages.find(x => x.messageId == msg.messageId)) {
                    this.chatItem.messages.push(Object.assign({}, msg));
                  }
                });
              }
              this.syncModeMessages = null;
            }
            
            let idx = this.generateChatMessageDates(this.chatItem);
            this.switchMode(DisplayMode.chat)
            this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';  //возвращение панели на место (когда виден заголовок)
            
             // Отправить сообщение что пользователь вошел в чат
            this.joinChatMessage(it, ChatMessageType.Join);

            setTimeout(() => {
              if (idx >= 0) {
                this.scroll2Message(idx)
              }
              else {
                this.scroll2Message();
              }
              this.intersectionObserverInitialize();
              this.subscribeKeyboardDetector();
            }, 10);
          }
        },
          err => {
            this.syncModeMessages = null;
            console.log('HTTP Error', err);
          },
          () => {
            this.syncModeMessages = null;
          });
    }
    else {
      // возможно это private чат, который еще не создан, т.к. никто не начинал общения в нем
      this.chatItem = Object.assign({}, it);
      this.chatItem.notActive = it.notActive;
      this.chatItem.isParticipant = this.participants.some(pp => pp.loginId == this.loginId);
      this.chatMessageDates = [];
      this.syncModeMessages = null;
      this.switchMode(DisplayMode.chat)
      this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';  //возвращение панели на место (когда виден заголовок)
      // Отправить сообщение что пользователь вошел в чат
      this.joinChatMessage(it, ChatMessageType.Join);

      setTimeout(() => {
        this.scroll2Message();
        this.intersectionObserverInitialize();
        this.subscribeKeyboardDetector();
      }, 10);

    }
  }

  onChatSubmit(form: any) {

    return new Promise<void>((resolve) => {

      // if (this.getErrorMessages().length) return;

      const message = Object.assign({}, form);
      this.chatForm = this.formBuilder.group({
        'message': [null, Validators.required]
      });

      message.sendDate = new Date();
      message.loginId = this.loginId;
      message.messageStatus = 0;
      let lastTuple = this.addChatMessageDate(message);
      if (lastTuple.lastIndex < 1) {
        console.error('Append message error. LastIndex is empty.');
      }
      else {


        let sendItem = Object.assign({}, this.chatItem);
        sendItem.clientId = this.chatSrv.clientId;
        sendItem.messages = [];
        sendItem.messages.push(message);


        this.http.post<any>(AjaxMethods.PublicEventChatMessage.replace('{id}', this.publicEventId + ''), sendItem)
          .subscribe(data => {
            if (data && data.items && data.items.length > 0 && data.items[0].messages.length > 0) {
              this.chatSrv.send(data.items[0]);
              if (!this.chatItem.chatId && data.items[0].chatId) {
                this.chatItem.chatId = data.items[0].chatId;
              }
              this.chatItem.unreadCount = 0;
              this.chatItem.lastStatus = data.items[0].lastStatus;
              this.chatItem.lastSendDate = data.items[0].lastSendDate;
              this.chatItem.lastSendDateStr = data.items[0].lastSendDateStr;
              this.chatItem.lastMessageId = data.items[0].lastMessageId;
              this.chatItem.lastMessageOrder = data.items[0].messageOrder;
              this.chatItem.readMessageId = data.items[0].messageId;
              this.chatItem.readMessageOrder = data.items[0].messageOrder;

              this.chatItem.messages[lastTuple.lastIndex - 1] = data.items[0].messages[0];
              if (lastTuple.dtIndex >= 0 && lastTuple.msgIndex >= 0) {
                this.chatMessageDates[lastTuple.dtIndex].messages[lastTuple.msgIndex] = this.chatItem.messages[lastTuple.lastIndex - 1];
              }

              // Выполняем поиск либо по  chatId или по loginId
              // let findIdx = this.items.findIndex(x => (x.chatId === this.chatItem.chatId) || (x.loginId && x.isPrivate && this.chatItem.isPrivate && (x.loginId === this.chatItem.loginId || x.participants.find(p => p === this.chatItem.loginId))));
              let findIdx = this.items.findIndex(x => (x.chatId === this.chatItem.chatId) || (x.loginId && x.isPrivate && this.chatItem.isPrivate && x.loginId === this.chatItem.loginId));
              if (findIdx >= 0) {
                this.items[findIdx] = Object.assign({}, this.chatItem);
              }
              resolve();
            }
            else {
              console.error('Append message error. Get data is empty.');
            }
          }, (error) => {

            console.error(`Append message error. Http query is failed: ${error}.`);
          });
      }
    });

  }


  onChatToEndClick(isScroll?: boolean) {

    return new Promise<void>((resolve) => {
      if (this.chatItem) {
        const findIt = this.items.find(x => x.chatId === this.chatItem.chatId);
        this.chatItem.unreadCount = 0;
        if (findIt) {
          findIt.unreadCount = 0;
        }
        if (this.chatItem.messages.length > 0) {
          // DEV-35038
          if (this.chatItem.isParticipant) {
            const lastMessage = this.chatItem.messages[this.chatItem.messages.length - 1];
            this.chatItem.readMessageId = lastMessage.messageId;
            this.chatItem.readMessageOrder = lastMessage.messageOrder;

            if (findIt) {
              findIt.readMessageId = lastMessage.messageId;
              findIt.readMessageOrder = lastMessage.messageOrder;
            }

            //!!! LS 
            // let sendDate: Date = new Date(lastMessage.sendDate);
            // localStorage.setItem(`ChatLastMsg_${findIt.chatId}_Login${this.loginId}`, sendDate.toISOString());
            // При нажатии кнопки "в конец списка" нужно найти сообщение с признаком "новое" и сбросить признак
            const findMsg = this.chatItem.messages.find(x => x.isNew);
            if (findMsg) {
              findMsg.isNew = false;
              this.http.post<any>(AjaxMethods.SetPublicEventChatMessage.replace('{id}', this.publicEventId + '').replace('{chatId}', this.chatItem.chatId + ''), null)
                .subscribe(data => {
                  if (data) {

                    // console.info('Read chat message successful.');
                    resolve();
                  }
                  else {
                    console.error('Append message error. Get data is empty.');
                  }
                }, (error) => {

                  console.error(`Append message error. Http query is failed: ${error}.`);
                });
            }
          }

          if (isScroll) {
            setTimeout(() => {
              this.scroll2Message();
            }, 10);
          }
        }
      }
    });
  }

  joinChatMessage(it: PublicEventChatItem, type: ChatMessageType.Join | ChatMessageType.Leave) {

    if (this.chatSrv) {

      // Проверка и восстановление соединения чата
      this._ngZone.runOutsideAngular(() => {
        this.chatSrv.connect([this.publicEventId], this.loginId, true);
      });


      if (this.chatSrv.isconnected) {
        const sendItem = Object.assign({}, it);
        const message: any = {
          sendDate: new Date(),
          loginId: this.loginId,
          messageStatus: 0,
          messageType: 0 + type,
          message: type === ChatMessageType.Join ? `Пользователь ${this.loginId} присоединился к чату.` : `Пользователь ${this.loginId} вышел из чата.`
        };
        sendItem.clientId = this.chatSrv.clientId;
        sendItem.photo = null;
        sendItem.participants = [];

        sendItem.messages = [];
        sendItem.messages.push(message);
        this.chatSrv.send(sendItem);
      }
    }

  }

  addChatMessage(it: PublicEventChatItem, isUnreadInc?: boolean): void {

    // if (!isNaN(Number(it.chatId))) {
    if (it && it.messages?.length > 0 && this.publicEventId && this.publicEventId === it.publicEventId) {

      const senderLoginId: any = it.messages[0].loginId;
      if (!senderLoginId) return;

      if (this.chatItem &&
        ((it.chatId && this.chatItem.chatId === it.chatId) ||
        (it.isPrivate && this.chatItem.isPrivate && it.loginId === this.loginId && this.chatItem.loginId === senderLoginId))) {

        if (!this.chatItem.chatId && it.chatId) {
          this.chatItem.chatId = it.chatId;
        }

       
        this.chatItem.messages = this.chatItem.messages.concat(it.messages);
        // Исключение повторов
        //this.chatItem.messages = this.chatItem.messages.map(itm => ({
        //  ...it.messages.find((item) => (item.messageId === itm.messageId) && item),
        //  ...itm
        //}));


        if (this.chatItem.messages.length > 0) {
          const lastMessage = this.chatItem.messages[this.chatItem.messages.length - 1];
          this.chatItem.lastStatus = lastMessage.messageStatus;
          this.chatItem.lastSendDate = lastMessage.sendDate;
          this.chatItem.lastSendDateStr = lastMessage.sendDateStr;
          this.chatItem.lastMessageId = lastMessage.messageId;
          this.chatItem.lastMessageOrder = lastMessage.messageOrder;
          this.chatItem.readMessageId = lastMessage.messageId;
          this.chatItem.readMessageOrder = lastMessage.messageOrder;
        }
        this.chatItem.unreadCount = 0;

        const findIdx = this.items.findIndex(x => (x.chatId === this.chatItem.chatId) || (x.isPrivate && this.chatItem.isPrivate && x.loginId === this.chatItem.loginId));
        if (findIdx >= 0) {
          this.items[findIdx] = Object.assign({}, this.chatItem);
        }

        //!!! LS
        // const sendDate: Date = new Date(this.chatItem.messages[this.chatItem.messages.length - 1].sendDate);
        // localStorage.setItem(`ChatLastMsg_${this.chatItem.chatId}_Login${this.loginId}`, sendDate.toISOString());
        // При добавлении сообщения нужно найти сообщение с признаком "новое" и сбросить признак
        const findMsg = this.chatItem.messages.find(x => x.isNew);
        if (findMsg) findMsg.isNew = false;

        this.generateChatMessageDates(this.chatItem);  // let idx =

        setTimeout(() => {
          //if (idx > 0) {
          //  this.scroll2Message(idx);
          //}
          //else {
          this.scroll2Message();
          //}
        }, 10);


      }
      else {
        let findIt: PublicEventChatItem
        if (it.chatId) {
          findIt = this.items.find(x => x.chatId === it.chatId);
        }
        if (!findIt) {
          if (it.isPrivate && it.loginId === this.loginId) {
            findIt = this.items.find(x => x.isPrivate && (x.loginId === senderLoginId));
          }
        }
        if (findIt) {

          if (!findIt.chatId && it.chatId) {
            findIt.chatId = it.chatId;
          }

          let isAddMessage = true;
          if (isUnreadInc) {
            // На старте нужно добавить неучтенные сообщения
            if (findIt.readMessageOrder === 0 || it.lastMessageOrder <= findIt.readMessageOrder) {
              isAddMessage = false;
            }
          }


          if (isAddMessage) {

            findIt.messages = findIt.messages.concat(it.messages);
            if (this.syncModeMessages) {
              this.syncModeMessages = this.syncModeMessages.concat(it.messages);
            }

            if (findIt.messages.length > 0) {
              const lastMessage = findIt.messages[findIt.messages.length - 1];
              findIt.lastStatus = lastMessage.messageStatus;
              findIt.lastSendDate = lastMessage.sendDate;
              findIt.lastSendDateStr = lastMessage.sendDateStr;
              findIt.lastMessageId = lastMessage.messageId;
              findIt.lastMessageOrder = lastMessage.messageOrder;
              //findIt.readMessageId = lastMessage.messageId;
              //findIt.readMessageOrder = lastMessage.messageOrder;

            }
            findIt.unreadCount++;
            findIt.totalCount++;


            // this.items = Object.assign({}, this.items.sort((a, b) => {
            this.items.sort((a, b) => {
              if (a.lastSendDate < b.lastSendDate) return 1;
              if (a.lastSendDate > b.lastSendDate) return -1;
              if (a.isRoot < b.isRoot) return 1;
              if (a.isRoot > b.isRoot) return -1;
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            });

            this.appRef.tick();
          }
          
        }
      }
    }
  }
  
  addChatMessageDate(message: any): { dtIndex: number, msgIndex: number, lastIndex: number } {

    let dtIndex: number = -1;
    let msgIndex: number = -1;
    let lastIndex = this.chatItem.messages.push(message);

    //!!! LS
    // let sendDate: Date = new Date(this.chatItem.messages[lastIndex - 1].sendDate);
    // localStorage.setItem(`ChatLastMsg_${this.chatItem.chatId}_Login${this.loginId}`, sendDate.toISOString());
     // При добавлении сообщения нужно найти сообщение с признаком "новое" и сбросить признак
    const findMsg = this.chatItem.messages.find(x => x.isNew);
    if (findMsg) findMsg.isNew = false;

  
    if (this.chatMessageDates && this.chatMessageDates.length > 0) {
      dtIndex = this.chatMessageDates.length - 1;
    }

    if (dtIndex >= 0 && this.chatMessageDates[dtIndex].dt === moment(message.sendDate).format("DD MMMM")) {
       msgIndex = this.chatMessageDates[dtIndex].messages.push(message) - 1;
    }
    else {

      this.generateChatMessageDates(this.chatItem);
      dtIndex = this.chatMessageDates.length - 1;
      msgIndex = this.chatMessageDates[dtIndex].messages.length - 1;

    }

    setTimeout(() => this.scroll2Message(), 10);

    return { dtIndex, msgIndex, lastIndex };


  }
  

  // Сохранить чат
  onSave(): Promise<void> {
    return new Promise<void>((resolve) => {

      if (this.getErrorMessages().length) return;

      this.http.post<any>(AjaxMethods.PublicEventChat.replace('{id}', this.publicEventId + ''),
        this.editItem).subscribe(d => {

          var isChatNew = !this.editItem.chatId;
          if (d.newId) {
            this.editItem.chatId = d.newId;

            this.editItem.publicEventId = this.publicEventId;
            Object.assign(this.editItem, d);
          }

          var findIdx = this.items.findIndex(x => (x.chatId === this.editItem.chatId) || (x.loginId && x.isPrivate && this.editItem.isPrivate && x.loginId === this.editItem.loginId));
          if (findIdx >= 0) {
            this.items[findIdx] = Object.assign({}, this.editItem);
          }

          if (isChatNew) {
            this.items.push(this.editItem);
            this.items = this.items.sort((a, b) =>
              (a.name == b.name) ? 0
                : (a.name < b.name) ? -1
                  : 1);
          }




          this.switchMode(DisplayMode.list); // к просмотру

          this.appRef.tick();
          resolve();
        });
    });
  }

  onCancelEdit() {
    switch (this.mode) {

      case DisplayMode.edit:// к списку
        if (this.editItem?.chatId) {
          if (this.chatItem) {
            this.onChatClick(this.chatItem);
          }
          // this.switchMode(DisplayMode.chat);
        }
        else {
          this.switchMode(DisplayMode.list);
          this.chatItem = null;
        }
        //this.editItem = null;
        break;
      case DisplayMode.view:// к списку
        if (this.editItem?.chatId) {
          this.onChatClick(this.chatItem);
        }
        // this.switchMode(DisplayMode.chat);
        // this.editItem = null;
        break;
      //case DisplayMode.participant: // к просмотру чата
      //  this.switchMode(DisplayMode.view);
      //  break;
    }
  }

  get isDeleteEnabled(): boolean {
    return Boolean(this.editItem?.chatId);
  }

  // Удалить пункт
  onDelete() {
    this.http.delete<any>(AjaxMethods.PublicEventChat.replace('{id}', this.publicEventId + ''), { params: { itemId: this.editItem.chatId } })
      .subscribe(d => {
        var ix2del = this.items.findIndex(i => i.chatId == this.editItem.chatId);
        if (ix2del >= 0) {
          this.items.splice(ix2del, 1);
          this.switchMode(DisplayMode.list);
        }
      });
  }

  isParticipantsPanelVisible = false;// панель выбора спикеров
  // DEV-35674
  changingParticipants: Subject<number> = new Subject();
  // Выбрали/сняли выбор у участника
  onParticipantCbxChange(usr: UserAttrs) {
    var ixUsr = this.editItem.participants.findIndex(id => usr.loginId == id);
    if (ixUsr >= 0) { // был участник. удалить
      this.editItem.participants.splice(ixUsr, 1);
    }
    else {// добавить
      this.editItem.participants.push(usr.loginId);
    }
    // DEV-35674
    this.changingParticipants.next(this.editItem.participants.length);
  }

  onParticipantsPanelVisibleChange(on: boolean) {
    if (!on) {
      this.isParticipantsPanelVisible = false;
    }
  }
  // включен ли пользователь в список участников
  isParticipant = function (usr: UserAttrs): boolean {
    return Boolean(usr.isOwner || this.editItem?.participants.find(id => id == usr.loginId));
  }.bind(this);

  // является ли пользователь автором МРП
  isParticipantOwner = function (usr: UserAttrs): boolean {
    return Boolean(usr.isOwner);
  }.bind(this);



  showBtnGoChat = false;
  isProfileActive = true;
  isViewParticipantVisible = false;
  viewParticipant: UserAttrs = <any>{};  // пользователь, которого в данный момент показываем

  onViewParticipantVisibleChange(on: boolean) {
    if (!on) {
      this.isViewParticipantVisible = false;
    }
  }

  // Переход к просмотру спикера
  onParticipantClick(showBtnGoChat: boolean, isProfileActive: boolean, usr: UserAttrs) {
    this.viewParticipant = usr;
    this.showBtnGoChat = showBtnGoChat && usr && usr.loginId !== this.loginId;  // usr &&
    this.isProfileActive = isProfileActive;
    this.isViewParticipantVisible = true;

    // this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';//возвращение панели на место (когда виден заголовок)
    // this.switchMode(DisplayMode.participant);
    // this.appRef.tick();
  }


  onParticipantGoChatClick(usr: UserAttrs) {

    //this.viewParticipant = usr;
    //this.switchMode(DisplayMode.participant);
    if (usr && usr.loginId) {
      var findIt = this.items.find(x => x.isPrivate && x.loginId === usr.loginId);
      if (findIt) {
        this.onChatClick(findIt);
        this.isViewParticipantVisible = false;
        // this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';//возвращение панели на место (когда виден заголовок)
      }
    }
  }



  getErrorMessages(): string[] {

    let msgs = [];
    if (!this.editItem?.name) {
      msgs.push("Должно быть задано наименование чата");
    }
    return msgs;
  }

  get bodyInnerPadding() {
    return this.mode == DisplayMode.chat ? 0 : 20;
  }


  // Фотографии участников
  get safePhoto() {
    if (this.editItem.isRoot) {
      return 'assets/tch-profile.png';
    }
    return this.editItem?.photo ? this.sanitizer.bypassSecurityTrustResourceUrl(this.editItem.photo) : null;
  }
  // Фото есть
  get photoExists(): boolean { return this.editItem && (Boolean(this.editItem.photo) || Boolean(this.editItem.isRoot)); }


  getChatParticipants(it: PublicEventChatItem): UserAttrs[] {

    if (it) {
      if (it.isRoot) {
        return Object.assign({}, this.participants);
      }
      else {
        let result: UserAttrs[] = [];
        it.participants.forEach(x => {
          let participant = this.participants.find(y => y.loginId == x);
          if (participant) result.push(participant);
        });
        return result.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      }
    }
    return [];
    

  }


  rexpURL: RegExp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
  getMessageWithLink(text: string): string {

    // Put the URL to variable $1 after visiting the URL
    // Replace the RegExp content by HTML element
    return text.replace(this.rexpURL,
      "<a href='$1' target='_blank'>$1</a>");
  }


  getChatUserCount(it: PublicEventChatItem): string {

    if (it.isPrivate) {
      return '2 участника';
    }
    else if (it.participants.length > 0) {

      return this.getNumDeclension(it.participants.length, '{0} участник', '{0} участников', '{0} участника');

      //if (it.participants.length % 100 / 10 === 1) {
      //  return it.participants.length + ' участников';
      //}
      //switch (it.participants.length % 10) {
      //  case 1:
      //    return it.participants.length + ' участник';
      //  case 2:
      //  case 3:
      //  case 4:
      //    return it.participants.length + ' участника';
      //  default:
      //    return it.participants.length + ' участников';
      //}
    }

    return '';
  }

  getChatPersonCount(it: PublicEventChatItem): string {

    if (it.isPrivate) {
      return 'Выбрано 2 человека';
    }
    else if (it.participants.length > 0) {


      return this.getNumDeclension(it.participants.length, 'Выбран {0} человек', 'Выбрано {0} человек', 'Выбрано {0} человека');

      //if (it.participants.length % 100 / 10 === 1) {
      //  return it.participants.length + ' человек';
      //}
      //switch (it.participants.length % 10) {
      //  case 1:
      //    return it.participants.length + ' человек';
      //  case 2:
      //  case 3:
      //  case 4:
      //    return it.participants.length + ' человека';
      //  default:
      //    return it.participants.length + ' человек';
      //}
    }

    return '';
  }


  getNumDeclension(num: number, nominative: string, plural: string, genitive: string = null): string {

    if (!genitive) {
      return num === 1 ? nominative : plural;
    }

    const cases = [2, 0, 1, 1, 1, 2];
    const titles = [ nominative, genitive, plural ];

    return titles[num % 100 > 4 && num % 100 < 20 ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]].replace("{0}", num.toString());

  }


  getChatUserRole(it: PublicEventChatItem): string {
    if (it.isPrivate && it.loginId) {
      let participant = this.participants.find(x => x.loginId === it.loginId)
      if (!participant && this.others) {
        participant = this.others.find(x => x.loginId === it.loginId)
      }
      return participant && participant.isEditor ? 'Организатор' : '';
    }
    return '';
  }

  existsChatUserRole(it: PublicEventChatItem): boolean {
    if (it.isPrivate && it.loginId) {
      let participant = this.participants.find(x => x.loginId === it.loginId)
      if (!participant && this.others) {
        participant = this.others.find(x => x.loginId === it.loginId)
      }
      return participant && participant.isEditor
    }

    return false;
  }

  getChatItemMessage(it: PublicEventChatItem): string {

    if (it.messages && it.messages?.length > 0) {
      var msg = it.messages?.slice(-1)[0];
      if (it.isPrivate || msg.loginId == this.loginId) {
        return msg.message;
      }
      if (msg.userName)
          return msg.userName + ': ' + msg.message;
      else
          return msg.message;
    }
    return '';
  }
  

 


  getPhotoPath(it: PublicEventChatItem): string {
    if (it.isRoot) {
      // return 'assets/tch-profile.svg';
      return 'assets/chat.svg';
    }
    if (it.photo) {
      return 'assets/' + it.photo + (/.svg$/i.test(it.photo) ? '' : '.svg');
    }
    return it.isPrivate ? 'assets/user-profile-big.svg' : 'assets/chat.svg';
  }


  getPhotoSafe(it: PublicEventChatItem) {
    if (it.isRoot) {
      return 'assets/tch-profile.png';
    }
    return this.getPhotoIsImage(it) ? this.sanitizer.bypassSecurityTrustResourceUrl(it.photo) : null;
  }

  // Фото есть картинка
  getPhotoIsImage(it: PublicEventChatItem): boolean {
    // return !it.isRoot && Boolean(it.photo) && /base64/i.test(it.photo);
    return Boolean(it.isRoot) || (Boolean(it.photo) && /base64/i.test(it.photo));
  }


  // Фото есть картинка
  // getPhotoIsImageChat(photo: string): boolean {
  getPhotoIsImageChat(loginId: number): boolean {
   
    // Поиск участника
    let photo: string
    if (this.lastPhoto && this.lastLoginId > 0 && this.lastLoginId == loginId) {
      photo = this.lastPhoto;
    }
    else if (this.chatItem) {
      var it = this.participants.find(x => x.loginId == loginId);
      if (!it && this.others) {
        it = this.others.find(x => x.loginId == loginId);
      }
      if (it) {
        this.lastPhoto = photo = it.photo;
        this.lastLoginId = loginId;
      }
    }

    // return (Boolean(photo) && /base64/i.test(photo));
    return Boolean(photo);
  }

  lastPhoto: string;
  lastLoginId: number = 0;
  getPhotoSafeChat(loginId: number) {

    // Поиск участника
    let photo: string

    if (this.lastPhoto && this.lastLoginId > 0 && this.lastLoginId == loginId) {
      photo = this.lastPhoto;
    }
    else if (this.chatItem) {
      var it = this.participants.find(x => x.loginId == loginId);
      if (!it && this.others) {
        it = this.others.find(x => x.loginId == loginId);
      }
      if (it) {
        this.lastPhoto = photo = it.photo;
        this.lastLoginId = loginId;
      }
    }
    // return (Boolean(photo) && /base64/i.test(photo)) ? this.sanitizer.bypassSecurityTrustResourceUrl(photo) : 'assets/user-profile.svg';
    return Boolean(photo) ? this.sanitizer.bypassSecurityTrustResourceUrl(photo) : 'assets/user-profile.svg';
  }



  // Работа с файлами фотографий
  @ViewChild("fileInput")
  fileInputElement: ElementRef<HTMLInputElement>;


  //-------- Загрузить фото
  onUploadPhotoClick() {
    if (this.photoExists) {

      this.commonData.confirmationDialog.confirm({ message: "Удалить фотографию?", title: 'Удаление' })
        .then((confirmed) => {
          if (!confirmed) return;
          this.editItem.photo = null;
          /*
          this.http.post<any>(AjaxMethods.postProfileAttrs, this.getPostModel())
            .subscribe(null,
              (err) => {
                console.error(`onUploadPhotoClick. savePhoto error:${err}`);
              });
          */
        });
    }
    else {
      this.fileInputElement.nativeElement.click();
    }
  }


  // UserProfileAttrs для post
  getPostModel() {
    const ret: PublicEventChatItem = {
      chatId: this.editItem.chatId,
      clientId: this.editItem.clientId,
      loginId: this.editItem.loginId,
      publicEventId: this.editItem.publicEventId,
      totalCount: this.editItem.totalCount,
      unreadCount: this.editItem.unreadCount,
      lastMessageId: this.editItem.lastMessageId,
      lastSendDate: this.editItem.lastSendDate,
      lastStatus: this.editItem.lastStatus,
      name: this.editItem.name,
      photo: this.editItem.photo,
      messages: this.editItem?.messages,
      participants: this.editItem?.participants
    };
    return ret;
  }

  // Загрузили файл
  maxFileSize = 10 << 20;
  onFileInputChange($event: Event) {
    let inputElement = (<HTMLInputElement>$event.target);
    let files: FileList = inputElement.files;
    if (files.length) {
      let uploadedFile = files[0];
      let isImage = uploadedFile.type.toLowerCase().startsWith("image") && (uploadedFile.name.toLowerCase().endsWith(".jpg") || uploadedFile.name.toLowerCase().endsWith(".jpeg")
        || uploadedFile.name.toLowerCase().endsWith(".png") || uploadedFile.name.toLowerCase().endsWith(".gif") || uploadedFile.name.toLowerCase().endsWith(".bmp"));
      if (!isImage) {
        this.commonData.confirmationDialog.msgBox({
          message: `Файл не является допустимым типом изображения`
        });
        inputElement.value = '';
        return;
      }
      if (uploadedFile.size > this.maxFileSize) {
        this.commonData.confirmationDialog.msgBox({
          message: `Размер файла ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize >> 20}Mb`
        });
        inputElement.value = '';
        return;
      }


      uploadedFile.arrayBuffer().then(arr => {
        let bl = new Blob([arr]);
        let rdr = new FileReader();
        rdr.onloadend = () => {
          this.editItem.photo = <string>rdr.result;
          this.appRef.tick();

          // Отправить на сохранение - позже при нажатии на Save
         
          // this.http.post<any>(AjaxMethods.PublicEventChat.replace('{id}', this.publicEventId + ''), this.getPostModel())
          //  .subscribe(null, (err) => { this.editItem.photo = null; });        
        }
        rdr.readAsDataURL(bl);
      });
      inputElement.value = '';
    }
  }

  


  @ViewChildren("chatMessage")
  chatMessage: QueryList<ElementRef>;
  @ViewChildren("chatMessageDateLast")
  chatMessageDateLast: QueryList<ElementRef>;

  itemObservervable: Element;
  intersectionObserver: IntersectionObserver;

  showChatToEnd: boolean = false;
  intersectionObserverInitialize(): void {

    if (!this.intersectionObserver) {
      this.intersectionObserver = new IntersectionObserver((entries, observer) => {
          if (entries && entries.length > 0) {
            var entry = entries[0];
            console.log("Intersecting : ", entry.isIntersecting);
            this.showChatToEnd = !entry.isIntersecting;
          }
        },
        {
          root: document.querySelector('#chat-body-id'),
          //  rootMargin: '0px',
          //  threshold: 1.0
        });
    }

    if (this.itemObservervable) {
      this.intersectionObserver.unobserve(this.itemObservervable);
    }
    //if (this.chatMessageDateLast.first) {
    // this.itemObservervable = this.chatMessageDateLast.first.nativeElement;
    this.itemObservervable = document.querySelector('#chat-list-date');
    if (this.itemObservervable) {
      this.intersectionObserver.observe(this.itemObservervable);
    }
    //}
  }

  intersectionObserverDestroy(): void {

    if (this.intersectionObserver) {
      if (this.itemObservervable) {
        this.intersectionObserver.unobserve(this.itemObservervable);
        this.itemObservervable = null;
      }
      this.intersectionObserver.disconnect();
      this.intersectionObserver = null;
    }
  }
  
  scroll2Message(msgIx?: number) {

    let el: Element;
    let htmlElements: any;
    if (msgIx != null && msgIx >= 0) {
      htmlElements = this.chatMessage.toArray();
      if (htmlElements.length > 0) {
        // if (!msgIx) msgIx = htmlElements.length - 1;
        if (msgIx < htmlElements.length) {
          el = <HTMLDivElement>htmlElements[msgIx].nativeElement;

        }
      }
      if (el) {
        // console.debug(`scroll to ix:${msgIx} dt:${this.chatItem.messages[msgIx].sendDateHeader}`, htmlElements[msgIx]);
        el.scrollIntoView({ block: "end" });  // , behavior: "smooth"
      }

    }
    else {

      this.chatBody.first.nativeElement.scrollTop = this.chatBody.first.nativeElement.scrollHeight

      //htmlElements = this.chatMessageDateLast.toArray();
      //if (htmlElements.length > 0) {
      //  el = <HTMLDivElement>htmlElements[0].nativeElement;
      //}
    }
 
 

  }


  // Keyboard
  keyboardTimer: any;
  keyboardDetect: boolean = false;
  keyboardVisible: boolean = true;
  windowInnerHeight: number = 0;

  listenerKeyboardVisible = (event: Event): void => {

    var self = this;
    if (!self.keyboardVisible) {

      // event.stopPropagation();
      setTimeout(function () {
        console.info(`Keyboard Detector Visible - windowInnerHeight: ${self.windowInnerHeight} / window.innerHeight:${window.innerHeight}`);

        // Запускаем проверку на сокрытие клавиатуры только на версиях,
        // где поддерживается изменение св-ва window.innerHeight при появлении клавиатуры
        if (self.windowInnerHeight !== window.innerHeight) {
          if (!self.keyboardTimer) {
            self.keyboardTimer = setInterval(() => {
              if (self.keyboardVisible && (window.innerHeight - self.windowInnerHeight) > 100) {
                console.info(`Keyboard Timer Hidden - windowInnerHeight: ${self.windowInnerHeight} / window.innerHeight:${window.innerHeight}`);

                if (self.keyboardTimer) {
                  clearInterval(self.keyboardTimer);
                  self.keyboardTimer = null;
                }

                //self.chatContainer.first.nativeElement.style["margin-top"] = "0px";
                //self.chatContainer.first.nativeElement.style["height"] = "100%";

                self.chatContainer.first.nativeElement.setAttribute('style', 'margin-top: 0px; height: 100%');

                // window.scrollTo(0, 0);
                self.windowInnerHeight = window.innerHeight;
                self.keyboardVisible = false;
                setTimeout(function () { self.scroll2Message(); }, 10);

              }
            }, 500);
          }


          // window.scrollTo(0, 0);
          //self.chatContainer.first.nativeElement.style["margin-top"] = (self.windowInnerHeight - window.innerHeight) + "px";
          //self.chatContainer.first.nativeElement.style["height"] = (window.innerHeight) + "px";

          self.chatContainer.first.nativeElement.setAttribute('style', 'margin-top: ' + (self.windowInnerHeight - window.innerHeight) + 'px; height: ' + window.innerHeight + 'px');

          self.windowInnerHeight = window.innerHeight;
          self.keyboardVisible = true;

          setTimeout(function () { self.scroll2Message(); }, 10);

        }

      }, 300);
    }
  }


  listenerKeyboardHidden = (event: Event): void => {

    var self = this;

    if (self.keyboardVisible && !self.messageForm.contains(event.target as HTMLElement)) {
      setTimeout(function () {
        if (self.windowInnerHeight !== window.innerHeight) {
          console.info(`Keyboard Detector Hidden - windowInnerHeight: ${self.windowInnerHeight} / window.innerHeight:${window.innerHeight}`);
          //self.chatContainer.first.nativeElement.style["margin-top"] = "0px";
          //self.chatContainer.first.nativeElement.style["height"] = "100%";
          self.chatContainer.first.nativeElement.setAttribute('style', 'margin-top: 0px; height: 100%');
          //window.scrollTo(0, 0);
          self.windowInnerHeight = window.innerHeight;
          self.keyboardVisible = false;
          if (self.keyboardTimer) {
            clearInterval(self.keyboardTimer);
            self.keyboardTimer = null;
          }

          setTimeout(function () {
            self.scroll2Message();
          }, 10);
        }
      }, 300);
    }

  }




  subscribeKeyboardDetector() {



    if (!(this.commonData.platform === 'ios' || (this.commonData.platform === 'web' && this.commonData.operatingSystem === 'ios'))) return;

    if (!this.keyboardDetect) {
      console.info(`Subscribe Keyboard Detector window.innerHeight:${window.innerHeight}`);

      this.keyboardVisible = false;
      this.windowInnerHeight = window.innerHeight;
      this.messageForm = document.querySelector<HTMLElement>('form.message-form');


      this.chatTextarea.first.nativeElement.addEventListener('focus', this.listenerKeyboardVisible);
      // this.chatTextarea.first.nativeElement.addEventListener('blur', this.listenerKeyboardHidden);
      this.chatBackground.first.nativeElement.addEventListener("click", this.listenerKeyboardHidden);

      this.keyboardDetect = true;
    }
  }


  unsubscribeKeyboardDetector() {


    if (this.keyboardDetect) {
      console.info(`Unsubscribe Keyboard Detector window.innerHeight:${window.innerHeight}`);

      this.chatTextarea.first.nativeElement.removeEventListener('focus', this.listenerKeyboardVisible);
      // this.chatTextarea.first.nativeElement.removeEventListener('blur', this.listenerKeyboardHidden);
      this.chatBackground.first.nativeElement.removeEventListener("click", this.listenerKeyboardHidden);
      this.keyboardDetect = false;
    }

    if (this.keyboardTimer) {
      clearInterval(this.keyboardTimer);
      this.keyboardTimer = null;
    }

  }






  /*
  @HostListener("window:resize")
  onWindowResize() {
    console.debug(`onWindowResize - window.innerHeight:${window.innerHeight}`);
    this.setHeight();
  }
  
  setHeight() {
    // this.htmlEl.nativeElement.style["height"] = window.innerHeight + "px";
   
  }
  */
}
