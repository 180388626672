import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';
import { PublicEventComponent } from '../public-event/public-event.component';
import * as moment from 'moment';

@Component({
  selector: 'app-public-event-edit',
  templateUrl: './public-event-edit.component.html',
  styleUrls: ['./public-event-edit.component.less']
})
export class PublicEventEditComponent extends PublicEventComponent implements OnInit {

  constructor(route: ActivatedRoute, router: Router, http: HttpClient, title: Title, commonData: CommonDataService) {
    super(route, router, http, title, commonData);
    this.NOTIFICATIONS_ENABLED = commonData.NOTIFICATIONS_ENABLED;
  }
  NOTIFICATIONS_ENABLED;
  panelReset = '';
  // если это не редактор, то выйти 
  initComplete() {
    if (!this.publicEvents?.isEditor) {
      this.router.navigate([this.backUrl]);
    }
  }
  // Назад - в отображение события
  get backUrl(): string {
    let segs = this.route.snapshot.url.slice(0, 2);
    if (segs.length == 2 && segs[1].toString() == "0") {
      segs[1].path = this.publicEvent.id + '';
    }
    return '/' + segs.join('/') + '/';
  }
  // отказ от редактирования. Если новое мероприятие, вернуться на список мероприятий 
  onCancelEditClick() {
    let url = this.backUrl; // по умолчанию
    if (!Number(this.publicEvent?.id)) {
      url = '/public-events';
    }
    this.router.navigate([url]);
  }
  // Сохранить
  onSaveClick(){
    // required
    let requiredFields = ["title", "eventTypeId"];
    if (requiredFields.some(i => !this.publicEvent[i])) return;
    if (this.isDateRangeInvalid() || this.isPublishDateRangeInvalid() || this.isPublishDateBeginInvalid() || this.isPublishDateEndInvalid() || this.isPublishDateNullError()) return;

    // даты из moment в Date
    Utils.moment2Date(this.publicEvent, "beginDate", "beginDateNfyDate", "endDate", "endDateNfyDate", "publishDateBegin", "publishDateBeginNfyDate", "publishDateEnd", "publishDateEndNfyDate");
    //alert(JSON.stringify({ begin: this.publicEvent.beginDate, end: this.publicEvent.endDate }));
    //return;
    
    // включенные компоненты - в components
    this.publicEvent.components = this.eventComponents.filter(p => p.isChecked).map(p => p.key).join(' ');
    // На сервер
    this.http.post<{newId:number}>(AjaxMethods.postPublicEvent, this.publicEvent)
      .subscribe((data) => {
        this.publicEvent.id = data.newId;
        this.router.navigate([this.backUrl]);
      });
  }
  // Удалить
  onDeleteClick() {
    let now = moment(new Date());
    let publishDateBegin = moment(this.publicEvent.publishDateBegin);
    let publishDateEnd = moment(this.publicEvent.publishDateEnd);
    let doDelete = () => {
      this.http.delete<any>(AjaxMethods.deletePublicEvent, { params: { ids: [this.publicEvent.id + ''] } }).subscribe(() => {
        // Наш Url: "public-events/24/edit".
        this.router.navigate(['/public-events']);
      });
    };
    if (this.publicEvent?.isAnyVote) {
      this.commonData.confirmationDialog.msgBox({ message: "По одному или нескольким голосованиям был принят голос(а). Удаление невозможно" });
    }
    // Если не опубликовано, то спросить
    else if ((publishDateBegin.isValid && now.isBefore(publishDateBegin))
      || (publishDateEnd.isValid() && now.isAfter(publishDateEnd))) { // вне публикации - спросить подтверждение
      this.commonData.confirmationDialog.confirm({ message: "Вы уверены, что хотите удалить мероприятие?", title: 'Удаление' })
        .then(confirmed => {
          if (confirmed) {
            doDelete();
          }
        });
    }
    // Если опубликовано, ошибка
    else if ((publishDateBegin.isValid && now.isSameOrAfter(publishDateBegin))
      || (publishDateEnd.isValid() && now.isSameOrBefore(publishDateEnd))) {
      this.commonData.confirmationDialog.msgBox({ message: "Опубликованное мероприятие удалить нельзя" });
    }
    else {
      this.commonData.confirmationDialog.confirm({ message: "Удалить мероприятие?" })
        .then(confirmed => {
          if (confirmed) {
            doDelete();
          }
        });
    }
  }
  isDateRangeInvalid() {
    return Utils.isDateRangeInvalid(this.publicEvent.beginDate, this.publicEvent.endDate);
  }
  isPublishDateRangeInvalid() {
    return Utils.isDateRangeInvalid(this.publicEvent.publishDateBegin, this.publicEvent.publishDateEnd);
  }
  isPublishDateBeginInvalid() {
    return this.publicEvent?.publishDateBegin && this.publicEvent?.beginDate && moment(this.publicEvent.publishDateBegin).isValid()
      && moment(this.publicEvent.publishDateBegin).isAfter(moment(this.publicEvent.beginDate));
  }
  isPublishDateEndInvalid() {
    return this.publicEvent?.publishDateEnd && this.publicEvent?.endDate && moment(this.publicEvent.publishDateEnd).isValid()
      && moment(this.publicEvent.publishDateEnd).isBefore(moment(this.publicEvent.endDate));
  }
  isPublishDateNullError() {
    return !!this.publicEvent?.publishDateBegin != !!this.publicEvent?.publishDateEnd;
  }

  tmplVar: TemplateRef<any>; // template - само мероприятие или конфигурация notification
  @ViewChild("bodyTmpl")
  bodyTmpl: TemplateRef<any>;
  @ViewChild("cfgNotifications")
  cfgNotificationsTmpl: TemplateRef<any>;

  ngAfterViewInit() {
    this.tmplVar = this.bodyTmpl;
  }
  get isSaveCancel(): boolean {
    return this.tmplVar == this.bodyTmpl;
  }
  cfgNotificationObjName: string;
  onCfgNotificationClick(objName: string) {
    this.cfgNotificationObjName = objName;
    this.cfgNotificationSelection = this.publicEvent[objName + "NfySet"];
    this.cfgNotificationDate = this.publicEvent[objName + "NfyDate"];
    this.tmplVar = this.cfgNotificationsTmpl;
    this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';//возвращение панели на место (когда виден заголовок)
  }
  onBackClicked() {// кнопка "Назад". Или на backUrl, или сменить tmpl на bodyTmpl
    if (this.tmplVar == this.bodyTmpl) {
      this.router.navigate([this.backUrl]);
    }
    else {
      let objName = this.cfgNotificationObjName;
      this.publicEvent[objName + "NfySet"] = this.cfgNotificationSelection;
      this.publicEvent[objName + "NfyDate"] = this.cfgNotificationDate;
      this.tmplVar = this.bodyTmpl;
      console.debug("cfgNotificationDate:", this.cfgNotificationDate);
    }
  }
  cfgNotificationSelection = 0;
  cfgNotificationDate = new Date();
}
