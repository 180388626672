import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventTouch]'
})
export class PreventTouchDirective {

  constructor(private htmlEl: ElementRef) { }

  @HostListener("touchstart")
  onTouchStart() {
    event.stopPropagation();
  }
  @HostListener("mousedown")
  onMouseDown() {
    event.stopPropagation();
  }

}
