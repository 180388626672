import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-user-profile-display',
  templateUrl: './user-profile-display.component.html',
  styleUrls: ['./user-profile-display.component.less']
})
export class UserProfileDisplayComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  @Input()
  userDisplay: UserAttrs;

  @Input()
  btnActionName: string = null;

  @Input()
  showBtnAction: boolean = false;

  @Input()
  isViewAlt: boolean = false;

  @Input()
  isProfileActive: boolean = true;


  @Output()
  onActionClick = new EventEmitter<UserAttrs>();


  get safePhoto() {
    return this.userDisplay?.photo ? this.sanitizer.bypassSecurityTrustResourceUrl(this.userDisplay?.photo) : null;
  }

  getUserNameWithCRLF() {
    return this.userDisplay?.name?.replace( / /g, ' <br/>');
  }

  onActionBtnClick() {
    this.onActionClick.emit(this.userDisplay);
  }
}
