<div appHeight100vh class="wrapper" id="appHeight100vh">
  <div #topNav class="top-nav-area" [hidden]="isSaveCancel || currentPosition == 'up'">
    <app-screen-top-header btnBackground="white" [backUrl]="backUrl" (onBackClicked)="onBackClicked()"></app-screen-top-header>
  </div>
  <div class="panel-wrap" [ngClass]="isSaveCancel ? 'save-cancel' : ''">
    <!--<div #header class="header" [hidden]="isSaveCancel || currentPosition == 'up'">-->
    <div #header id="screenPanelHeader" class="header" [hidden]="isSaveCancel">
      <div class='screen-title'>
        <!--кнопка "редактировать"-->
        <div class="edit-button" *ngIf="isEditable" (click)="onEditClick.emit()">
          <svg-icon src="assets/pencil.svg" [applyClass]="true" svgClass="edit-icon"></svg-icon>
        </div>
        {{title}}
      </div>
      <ng-container [ngTemplateOutlet]="headerPartTmpl"></ng-container>
    </div>

    <!--<div #bodyEl class="panel-body" [appDragPanel]="{onDrop: onDrop, allow: allowDragDirection}">-->
    <div #bodyEl class="panel-body">
      <!--Полоска сверху-->
      <div class="info-bar" *ngIf="infoBar" [ngClass]="infoBar?.isHighlighted? '' : 'grey'">
        <div class="info-bar-text">
          {{infoBar.text}}
        </div>
      </div>
      <!-- ======  Основная панель ============== -->
      <h2 *ngIf="innerTitle">{{innerTitle}}</h2>
      <div class="panel-body-inner" [ngClass]="isInnerScroll? 'panel-body-inner-scroll' : ''" [ngStyle]="{ 'padding.px': bodyInnerPadding  }">
        <!--<div class="panel-body-inner" [ngStyle]="{ 'padding.px': bodyInnerPadding  }">-->
        <!-- "крестик" - закрыть -->
        <div *ngIf="isSaveCancel && showClose" class="close-icon" (click)="onCancelBtnClick()">
          <svg-icon src="assets/cross.svg" [applyClass]="true"></svg-icon>
        </div>
        <ng-container [ngTemplateOutlet]="bodyTmpl"></ng-container>
        <!-- Кнопки save/cancel-->
        <div *ngIf="isSaveCancel && showButtons">
          <div class="btn-row">
            <button class="dialog-button cancel-red" (click)="onCancelBtnClick()">{{btnCancelName}}</button>
            <button class="dialog-button action-green" (click)="onSaveBtnClick()">{{btnSaveName}}</button>
          </div>
          <div class="btn-row">
            <button [ngClass]="isDeleteEnabled ? '' : 'disabled'" class="dialog-button delete-red" *ngIf="isDeleteVisible" (click)="isDeleteEnabled && onDeleteBtnClick()">Удалить {{deleteBtnSubject}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="absbackdrop">
    <div class="upper-part">

    </div>
    <div class="grey-backdrop"></div>
  </div>

</div>

