import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, NgZone, Output, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AjaxMethods } from '../../models/AjaxMethods';

@Component({
  selector: 'app-public-event-user-list',
  templateUrl: './public-event-user-list.component.html',
  styleUrls: ['./public-event-user-list.component.less']
})
export class PublicEventUserListComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private sanitizer: DomSanitizer, private ngZone: NgZone) {
    this.isUserChecked = this.isChecked.bind(this)
    this.isUserDisabled = this.isUserDisabled.bind(this)
  }

  @Input()
  publicEventId: number;
  // Только участники
  @Input()
  isParticipants = false;
  // Только с правом голоса
  @Input()
  isVotingOnly = false;
  @Input()
  isCbx = false;
  @Input()
  showIcon = true;
  @Input()
  showPhoto = false;
  @Input()
  excludeLoginId: number | number[];
  // По клику открыть карточку пользователя
  @Input()
  showUserRole: boolean;
  // По клику открыть карточку пользователя
  @Input()
  showUserDetailsOnClick: boolean;
  // Функция, по UserAttrs говорит, выбран ли он
  @Input()
  isUserChecked = this.isChecked.bind(this);
  // DEV-35674
  @Input()
  userChanging: Subject<number>;

  // Функция, по UserAttrs говорит, выбран ли он
  @Input()
  isUserDisabled = this.isDisabled.bind(this);

  @Output()
  userClick = new EventEmitter<UserAttrs>();
  // Передернули checkbox
  @Output()
  cbxChange = new EventEmitter<UserAttrs>();


 escapeRegex = /(\+|\(|\)|\?|\.|\*)/g;


  ngOnDestroy(): void {
    if (this.searchTextChangedSubs) {
      this.searchTextChangedSubs.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.tmpl = this.userListTmplRef;
  }
  // exclude loginId в виде массива
  get excludeLoginIds(): number[] {
    if (this.excludeLoginId) {
      if (Array.isArray(this.excludeLoginId)) {
        return this.excludeLoginId;
      }
      return [this.excludeLoginId];
    }
    return [];
  }

  ngOnInit(): void {
    // DEV-35674
    if (this.userChanging && this.useOuterList) {
      this.userChanging.pipe(
        debounceTime(100),
        distinctUntilChanged()
      ).subscribe(v => {
          // console.log('value is changing', v);
        if (this.searchText) {

            const wb = `[A-Za-zА-Яа-я\\d_+-]`;
            const searchRegex = new RegExp(this.searchText.split(" ").map(s => `(?<!${wb})${s.replace(this.escapeRegex, '$1')}(${wb}*)(?!${wb}).*`).join(""), "i");
            // const searchRegex = new RegExp(this.searchText.split(" ").map(s => `\\b${s.replace(this.escapeRegex, '$1')}[^\\b]*\\b.*`).join(""), "i");
            this.users = this.outerUsers.filter(u => searchRegex.test(`${u.name} ${u.company} ${u.position}`));

            // const arrayOfSearch = this.searchText.split(" ");
            // this.users = this.outerUsers.filter(u => arrayOfSearch.some(x => (u.name && u.name.toLowerCase().indexOf(x.toLowerCase()) !== -1) || (u.company && u.company.toLowerCase().indexOf(x.toLowerCase()) !== -1) || (u.position && u.position.toLowerCase().indexOf(x.toLowerCase()) !== -1)));
          }
          else {
            this.users = Object.assign([], this.outerUsers);
          }
      });
    }


    this.searchTextChangedSubs = this.searchTextChanged
      .pipe(
        debounceTime(400)
        , distinctUntilChanged()
      )
      .subscribe(() => {

        if (this.useOuterList) {
          if (this.searchText) {

            
            
            const wb = `[A-Za-zА-Яа-я\\d_+-]`;
            const searchRegex = new RegExp(this.searchText.split(" ").map(s => `(?<!${wb})${s.replace(this.escapeRegex, '$1')}(${wb}*)(?!${wb}).*`).join(""), "i");
            // const searchRegex = new RegExp(this.searchText.split(" ").map(s => `\\b${s.replace(this.escapeRegex, '$1')}[^\\b]*\\b.*`).join(""), "i");
            this.users = this.outerUsers.filter(u => searchRegex.test(`${u.name} ${u.company} ${u.position}`));

            // const arrayOfSearch = this.searchText.split(" ");
            // this.users = this.outerUsers.filter(u => arrayOfSearch.some(x => (u.name && u.name.toLowerCase().indexOf(x.toLowerCase()) !== -1) || (u.company && u.company.toLowerCase().indexOf(x.toLowerCase()) !== -1) || (u.position && u.position.toLowerCase().indexOf(x.toLowerCase()) !== -1)));

        
          }
          else {
            this.users = Object.assign([], this.outerUsers);
          }

        }
        else {

          let getUrl = AjaxMethods.GetPublicEventUsers.replace("{publicEventId}", this.publicEventId + '');
          let _excludeLoginIds = this.excludeLoginIds;// чтобы не дергать getter в цикле
          this.http.get<UserAttrs[]>(getUrl, { params: { searchStr: this.searchText, isPartsOnly: this.isParticipants, isVotingOnly: this.isVotingOnly } })
            .subscribe(data => {
              this.users = data.filter(u => _excludeLoginIds.indexOf(u.loginId) < 0);
            });
        }
      });


      if (!this.showStaticListOnly) {
         this.searchTextChanged.next(null);// инициировать начальное чтение списка пользователей
      }
  }

  searchTextChanged = new Subject<string>();
  searchTextChangedSubs: Subscription;
  onSearchTextChange() {
    this.searchTextChanged.next(this.searchText);
  }
  getJointAttrs(it: UserAttrs): string {
    let ret = '';
    const sepSz = ', ';
    let sep = '';
    const props = ["position", "company", "phone", "email"];
    props.forEach(p => {
      if (it[p]) {
        ret += sep + it[p];
        sep = sepSz;
      }
    });
    return ret;
  }
  @ViewChild("userList")
  userListTmplRef: TemplateRef<any>;
  @ViewChild("userDetails")
  userDetailsTmplRef: TemplateRef<any>;

  tmpl: TemplateRef<any>;

  userDisplay: UserAttrs;
  // Клик в пользователя - показать details
  onUserClick(it: UserAttrs) {
    if (this.showUserDetailsOnClick) {
      this.tmpl = this.userDetailsTmplRef;
      this.userDisplay = it;
    }
    this.userClick.emit(it);
  }

  // Из карточки клиента вернуться на список пользователей. Метод предназначен для родительского компонента
  backToUserList() {
    this.tmpl = this.userListTmplRef;
  }

  // Дернули переключатель
  onCbxChange(it: UserAttrs) {
    this.cbxChange.emit(it);
  }

  isChecked(it: UserAttrs): boolean {
    return this.prm == "event" ? it.hasAccess : it.isVoting;
  }

  isDisabled(it: UserAttrs): boolean {
    return false;
  }

  get prm(): 'event' | 'voting' { // какой ACL редактируем
    return this.isParticipants ? "voting" : "event";
  }
  searchText: string = ''; // строка поиска

  searchTextClear() {
    this.searchText = '';
    this.onSearchTextChange();
  }
  
  @Input()
  users: UserAttrs[] = []; // результат поиска - список пользователей

  @Input()
  outerUsers: UserAttrs[] = [];

  @Input()
  useOuterList = false;

  @Input()
  showStaticListOnly = false;



  getPhotoPath(photo: string): string {
    return photo ? 'assets/' + photo + (/.svg$/i.test(photo) ? '' : '.svg') : 'assets/user-profile-big.svg';
  }


  getPhotoSafe(photo: string) {
    return this.getPhotoIsImage(photo) ? this.sanitizer.bypassSecurityTrustResourceUrl(photo) : null;
  }

  // Фото есть картинка
  getPhotoIsImage(photo: string): boolean { return Boolean(photo) && /base64/i.test(photo); }

}


