<app-screen-with-panel
                       innerTitle="Мероприятие"
                       [bodyTmpl]="tmplVar || bodyTmpl"
                       (onBackClicked)="onBackClicked()"
                       [isSaveCancel]="isSaveCancel"
                       (onSaveClick)="onSaveClick()"
                       (onCancelEditClick)="onCancelEditClick()"
                       (onDeleteClick)="onDeleteClick()"
                       deleteBtnSubject="мероприятие"
                       [doConfirmDelete]="false"
                       [isDeleteEnabled]="!!publicEvent?.id"
                       [reset]="panelReset"
                       >
  <ng-template #bodyTmpl>
    <!--Тип мероприятия-->
    <mat-form-field>
      <mat-label>Тип</mat-label>
      <mat-select [(ngModel)]="publicEvent.eventTypeId" required>
        <mat-option *ngFor="let it of ddEventType" [value]="it.key">{{it.value}}</mat-option>
      </mat-select>
    </mat-form-field>
    <!--Название мероприятия-->
    <mat-form-field>
      <mat-label>Название мероприятия</mat-label>
      <input matInput [(ngModel)]="publicEvent.title" autocomplete="off" required maxlength="200" />
      <button mat-button *ngIf="publicEvent.title" matSuffix mat-icon-button aria-label="Clear" (click)="publicEvent.title=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <!--Место проведения-->
    <mat-form-field>
      <mat-label>Место проведения</mat-label>
      <input matInput [(ngModel)]="publicEvent.locationText" autocomplete="off" maxlength="1024" />
      <button mat-button *ngIf="publicEvent.locationText" matSuffix mat-icon-button aria-label="Clear" (click)="publicEvent.locationText=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!--модули-->
    <h3>Модули</h3>
    <div class="hr"></div>
    <div class="event-components">
      <div class="event-component" *ngFor="let it of eventComponents">
        <div class="event-component-icon">
          <svg-icon [src]="'assets/' + it.icon + '.svg'" [applyClass]="true" svgClass="text-icon" [viewBox]="'auto'" [class.polling-icon-align]="it.icon == 'polling' ? true : false"></svg-icon>
        </div>
        <div class="event-component-text">{{it.text}}</div>
        <div class="slide-toggle">
          <mat-slide-toggle [checked]="it.isChecked" (change)="it.isChecked = !it.isChecked" [color]="'primary'"></mat-slide-toggle>
        </div>
      </div>
    </div>

    <!-- Время проведения -->
    <h3>Время проведения</h3>
    <div class="date-range">
      <app-date-and-time label="Начало" [(editValue)]="publicEvent.beginDate"></app-date-and-time>
      <app-date-and-time label="Окончание" [(editValue)]="publicEvent.endDate"></app-date-and-time>
      <div class="mat-error" *ngIf="isDateRangeInvalid()">Дата окончания не может быть ранее даты начала</div>
    </div>

    <!--Уведомление о начале и об окончании-->
    <app-nav-list-item *ngIf="NOTIFICATIONS_ENABLED" text="Уведомление о начале" (onIconClick)="onCfgNotificationClick('beginDate')"></app-nav-list-item>
    <app-nav-list-item *ngIf="NOTIFICATIONS_ENABLED" text="Уведомление об окончании" (onIconClick)="onCfgNotificationClick('endDate')"></app-nav-list-item>

    <!--Опубликовать-->
    <h3>Опубликовать</h3>
    <div class="date-range">
      <app-date-and-time label="С" [(editValue)]="publicEvent.publishDateBegin" [max]="publicEvent?.beginDate" [allowClear]="true"
                         [onFocusDefaultValue]="publicEvent.minPollingBeginDate || publicEvent.beginDate">
      </app-date-and-time>
      <app-date-and-time label="ПО" [(editValue)]="publicEvent.publishDateEnd" [min]="publicEvent?.endDate" [allowClear]="true"
                         [onFocusDefaultValue]="publicEvent.maxPollingEndDate || publicEvent.endDate"></app-date-and-time>
      <div class="mat-error" *ngIf="isPublishDateNullError()">Даты публикации должны быть заполнены или не заполнены одновременно</div>
      <div class="mat-error" *ngIf="isPublishDateRangeInvalid()">Дата "ПО" не может быть ранее даты "С"</div>
      <div class="mat-error" *ngIf="isPublishDateBeginInvalid()">Дата и время публикации «С» не могут быть позднее начала мероприятия</div>
      <div class="mat-error" *ngIf="isPublishDateEndInvalid()">Дата и время публикации «ПО» не могут быть ранее окончания мероприятия</div>
    </div>

    <!--Уведомление о начале и об окончании публикации-->
    <app-nav-list-item text="Уведомление о начале публикации" (onIconClick)="onCfgNotificationClick('publishDateBegin')"></app-nav-list-item>
    <app-nav-list-item text="Уведомление об окончании публикации" (onIconClick)="onCfgNotificationClick('publishDateEnd')"></app-nav-list-item>
    <hr/>
  </ng-template>

  <!-- Конфигурация оповещений -->
  <ng-template #cfgNotifications>
    <app-configure-notification [(selected)]="cfgNotificationSelection" [(selectedDate)]="cfgNotificationDate"></app-configure-notification>
  </ng-template>
</app-screen-with-panel>
