import { Input } from '@angular/core';
import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTextEllipsis]'
})
export class TextEllipsisDirective implements AfterViewChecked{

  constructor(private htmlEl: ElementRef) {
    this.ellipsis.innerHTML = "...";
    this.ellipsis.style.position = "absolute";
    this.ellipsis.style.right = "5px";
    this.ellipsis.style.bottom = "0px";
  }
  @Input()
  appTextEllipsis: any;

  ellipsis: HTMLElement = document.createElement("div");

  ngAfterViewChecked() {
    if (this.appTextEllipsis != null && this.appTextEllipsis != undefined && typeof (this.appTextEllipsis) == 'boolean' && !this.appTextEllipsis) return;
    //console.debug("Ellipsis value:" + this.appTextEllipsis);
    let el: HTMLElement = this.htmlEl.nativeElement;
    // родительскому элементу и нашему - правильный стиль
    const h = "2.7em";
    el.style.maxHeight = h;
    //el.style.height = h;
    //el.style.minHeight = h;
    el.style.overflow = "hidden";
    el.style.position = "relative";
    el.style.paddingRight = "1em";
    el.style.wordBreak = "break-word";
    let parentEl = el.parentElement;
    parentEl.style.position = "relative";
    if (el.offsetHeight + 1 < el.scrollHeight) {
      // добавляем многоточие
      if (!(<any>el).isEllipsisAppended) {
        el.insertAdjacentElement("beforeend", this.ellipsis);
        (<any>el).isEllipsisAppended = true;
      }
    }
    else {
      if ((<any>el).isEllipsisAppended) {
        el.removeChild(this.ellipsis);
        (<any>el).isEllipsisAppended = false;
      }

    }

  }
}
