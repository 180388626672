
<div class="user-details-wrapper">
  <div class="fullname-n-photo" *ngIf="!isViewAlt">
    <div class="fullname" [innerHtml]="getUserNameWithCRLF()"></div>
    <div class="photo-wrapper">
      <div class="photo">
        <svg-icon *ngIf="!userDisplay.photo" src="assets/user-profile-big.svg" [applyClass]="true" svgClass="photo-svg"></svg-icon>
        <div class="img-wrap" *ngIf="userDisplay.photo"><img [src]="safePhoto" *ngIf="userDisplay.photo" /></div>
      </div>
    </div>
  </div>
  <div class="fullname-n-photo-alt" *ngIf="isViewAlt">
    <div class="photo-wrapper">
      <div class="photo">
        <svg-icon *ngIf="!userDisplay.photo" src="assets/user-profile-big.svg" [applyClass]="true" svgClass="photo-svg"></svg-icon>
        <div class="img-wrap" *ngIf="userDisplay.photo"><img [src]="safePhoto" *ngIf="userDisplay.photo" /></div>
      </div>
    </div>
    <div class="fullname" [innerHtml]="getUserNameWithCRLF()"></div>
    <div class="status" *ngIf="!isProfileActive">Профиль неактивен</div>
  </div>


  <div class="field-block">
    <label>Организация</label>
    <div style="min-height:1.5em;">{{userDisplay.company}}</div>
  </div>

  <div class="field-block">
    <label>Должность</label>
    <div style="min-height:1.5em;">{{userDisplay.position}}</div>
  </div>

  <div class="field-block" *ngIf="userDisplay.login">
    <label>Логин</label>
    <div>{{userDisplay.login}}</div>
  </div>

  <ng-container *ngIf="userDisplay.email || userDisplay.phone">
    <div class="field-block">
      <label>Почта</label>
      <div>{{userDisplay.email}}</div>
    </div>
    <div class="field-block">
      <label>Телефон</label>
      <div>{{userDisplay.phone}}</div>
    </div>
  </ng-container>
  <!-- Кнопки save/cancel-->
  <div class="btn-row" *ngIf="showBtnAction">
    <button class="dialog-button action-violet" (click)="onActionBtnClick()">{{btnActionName}}</button>
  </div>


</div>
