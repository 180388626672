<div class="wrapper">
  <div>
    <mat-form-field>
      <mat-label>Login пользователя</mat-label>
      <input matInput [(ngModel)]="login" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Заголовок сообщения</mat-label>
      <input matInput [(ngModel)]="title" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Текст сообщения</mat-label>
      <textarea matInput [(ngModel)]="message"></textarea>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Текст ссыылки</mat-label>
      <input matInput [(ngModel)]="urlText" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Сcылка (http://... - внешняя)</mat-label>
      <input matInput [(ngModel)]="url" />
    </mat-form-field>
  </div>


  <div>
    <button (click)="onSendClick()">Отправить</button>
  </div>
</div>
