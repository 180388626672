<div #ultimateWrapper>
  <app-screen-with-panel [title]="mainTitle"
                         [bodyTmpl]="tmpl || tmplList"
                         (onBackClicked)="onBackClick()"
                         (onSaveClick)="onSave()"
                         (onCancelEditClick)="onCancelEdit()"
                         (onDeleteClick)="onDelete()"
                         [isSaveCancel]="isSaveCancel"
                         deleteBtnSubject="пункт программы"
                         [isDeleteEnabled]="isDeleteEnabled"
                         [reset]="panelReset"
                         [isInnerScroll]="true"
                         [isEditable]="isEditable"
                         (onEditClick)="onEditClick()">
    <!-- Программа as such -->
    <ng-template #tmplList>
      <app-new-delete-buttons *ngIf="isEditor" (onNewClick)="onNewClick()"></app-new-delete-buttons>
         
      <div class="grey-notice" *ngIf="!items.length && isEditor">
        Нажмите на «+» внизу экрана чтобы добавить первый пункт программы
      </div>
      <div>
        <div *ngFor="let dtItems of programDates; let ix=index" class="program-date" #programDateHdr>
          <div class="date-hdr" (click)="scroll2Date(ix)">
            {{dtItems.dt}}
          </div>
          <div class="items-wrapper">
            <app-nav-list-item *ngFor="let it of dtItems.items" [textTmpl]="programItemTmpl" (onIconClick)="onEditItemClick(it)" [isTopBorder]="true">
              <ng-template #programItemTmpl>
                <div class="program-item-tmpl">
                  <div class="svg-wrapper">
                    <svg-icon [src]="'assets/' + it.icon" [applyClass]="true" svgClass="icon"></svg-icon>
                  </div>
                  <div class="text-wrapper">
                    <div [style.font-weight]="getItemSubjectFontWeight(it)">{{it.subject}}</div>
                    <div class="second-line">{{it.beginTimeStr + '&nbsp;&#8211;&nbsp;' + it.endTimeStr}}</div>
                    <div class="location" *ngIf="it.location">
                      {{it.location}}
                    </div>
                    <div class="speakers" [appTextEllipsis]="it?.speakers.length > 3" *ngIf="it?.speakers.length">{{getInlineSpeakers(it)}}</div>
                  </div>
                </div>
              </ng-template>
            </app-nav-list-item>
          </div>

        </div>
      </div>

    </ng-template>

    <!--Просмотр пункта программы-->
    <ng-template #tmplViewItem>
      <div class="top-header">{{editItem.subject}}</div>
      <div class="topheader-text">
        <div class="icon-wrapper"><svg-icon src="assets/clock-filled.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon></div>
        <div [innerHTML]="editItem?.timeIntervalStr" class="text"></div>
      </div>
      <div class="topheader-text">
        <div class="icon-wrapper"><svg-icon src="assets/compass.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon></div>
        <div [innerHTML]="editItem?.location" class="text"></div>
      </div>

      <!--Спикеры-->
      <div class="subhdr">Спикеры</div>
      <app-public-event-user-list [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="false"
                                  [showIcon]="true" [showPhoto]="true" [isUserChecked]="isSpeaker" [showUserDetailsOnClick]="false"
                                  [users]="editItem.speakers" [showStaticListOnly]="true" (userClick)="onSpeakerClick($event)">

      </app-public-event-user-list>

      <!--Материалы-->
      <div class="subhdr">Материалы</div>
      <app-public-event-files-int [publicEventId]="publicEventId" [programItemId]="editItem.id" [moveOutElement]="ultimateWrapper" (onEditBeginClick)="onEditBeginClick()" (onEditEndClick)="onEditEndClick()"></app-public-event-files-int>

    </ng-template>

    <!-- Редактирование пункта программы -->
    <ng-template #tmplEditItem>
      <!--<div class="hdr">{{editItem.subject ?? 'Новый пункт программы' }}</div>-->
      <div class="hdr" style="font-weight:700">{{editItem.subject ? '' : 'Новый пункт программы' }}</div>

      <div class="form-wrapper">
        <!--тема-->
        <mat-form-field>
          <mat-label>Тема</mat-label>
          <input matInput [(ngModel)]="editItem.subject" required />
        </mat-form-field>
        <!--Тип-->
        <mat-form-field>
          <mat-label>Тип</mat-label>
          <mat-select [(ngModel)]="editItem.itemType">
            <mat-option *ngFor="let opt of itemTypeOptions" [value]="opt.key">
              <div [style.font-Weight]="opt.value.value ? 600 : 400">{{opt.value.key}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!--Иконка-->
        <mat-form-field (click)="onChooseIconClick()">
          <mat-label>Иконка</mat-label>
          <input matInput value=" " readonly style="height:0px !important;" />
          <div class="icons-wrapper main" >
            <svg-icon *ngIf="editItem.icon" [src]="'assets/' + editItem.icon" [applyClass]="true" svgClass="icon" style="flex: 0"></svg-icon>
            <svg-icon (click)="onClearIconClick();$event.stopPropagation()" *ngIf="editItem.icon" src="assets/cross.svg" [applyClass]="true" svgClass="icon"></svg-icon>
          </div>
        </mat-form-field>
        <!--Место проведения-->
        <mat-form-field>
          <mat-label>Место проведения</mat-label>
          <input matInput [(ngModel)]="editItem.location" />
        </mat-form-field>
        <!--Начало-->
        <div class="date-range">
          <app-date-and-time label="Дата" [(editValue)]="editItem.beginDate" (editValueChange)="onEditItemBeginDateChange($event)" [min]="publishBeginDate" [max]="publishEndDate" [ngStyle]="{'flex':1}" required></app-date-and-time>
          <app-date-and-time label="" [(editValue)]="editItem.endDate" [min]="publishBeginDate" [max]="publishEndDate" [showDate]="false" required></app-date-and-time>
          <!--
      <app-time-picker [(editVal)]="editItem.endDate" required></app-time-picker>
      -->
        </div>
        <!--Сообщения об ошибках в датах-->
        <div class="mat-error" *ngFor="let msg of getDateRangeErrorMessages()">{{msg}}</div>
        <!--Уведомление о начале и об окончании-->
        <ng-container *ngIf="NOTIFICATIONS_ENABLED">
          <app-nav-list-item text="Уведомление о начале" (onIconClick)="onCfgNotificationClick('beginDate')"></app-nav-list-item>
          <app-nav-list-item text="Уведомление об окончании" (onIconClick)="onCfgNotificationClick('endDate')"></app-nav-list-item>
        </ng-container>
        <!-- Спикеры -->
        <mat-label>Спикеры</mat-label>
        <app-nav-list-item text="Выбрать спикеров" (onIconClick)="isSpeakersPanelVisible = true"></app-nav-list-item>
        <!-- список выбранных спикеров -->
        <div class="inline-speaker-list">
          <div class="one-speaker" *ngFor="let spk of editItem?.speakers">
            <div class="photo-wrapper">
              <div class="photo">
                <div class="img-wrap">
                  <svg-icon *ngIf="!getPhotoIsImage(spk.photo)" [src]="getPhotoPath(spk.photo)" [applyClass]="true" svgClass="photo-svg"></svg-icon>
                  <img [src]="getPhotoSafe(spk.photo)" *ngIf="getPhotoIsImage(spk.photo)" />
                </div>
              </div>
            </div>
            <div class="name-wrapper">
              <div class="speaker-name">{{spk.name}}</div>
              <div class="speaker-details">{{spk.position}}, {{spk.company}}</div>
            </div>
            <div class="del-svg" (click)="onSpeakerRemove(spk)">
              <svg-icon src="assets/cross.svg" [applyClass]="true" svgClass="del-svg-svg"></svg-icon>
            </div>
          </div>
        </div>
        <!-- Добавить файл -->
        <mat-label>Материалы</mat-label>
        <app-nav-list-item text="Добавить материал" (onIconClick)="onFilesEdit()"></app-nav-list-item>
      </div>
      <!-- Конфигурация оповещений -->
      <app-child-panel [isVisible]="isNotificationsPanelVisible" (isVisibleChange)="onNotificationVisibleChange($event)" header="Оповещения">
        <app-configure-notification [(selected)]="cfgNotificationSelection" [(selectedDate)]="cfgNotificationDate"></app-configure-notification>
      </app-child-panel>
      <!--Спикеры-->
      <app-child-panel [isVisible]="isSpeakersPanelVisible" (isVisibleChange)="onSpeakersPanelVisibleChange($event)">
        <div class="choose-speaker-wrapper">
          <app-public-event-user-list *ngIf="isSpeakersPanelVisible" [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="true"
                                      [showIcon]="false" [showPhoto]="true" [isUserChecked]="isSpeaker" (cbxChange)="onSpeakerCbxChange($event)">
          </app-public-event-user-list>
        </div>
      </app-child-panel>
    </ng-template>

    <!-- Диалог выбора иконки -->
    <ng-template #tmplChooseIcon>
      <h2 mat-dialog-title>Иконка</h2>
      <mat-dialog-content>
        <div class="icons-wrapper dialog">
          <div class="svg-wrapper" [ngClass]="i == editItem.icon ? 'selected' : ''" *ngFor="let i of iconsAvl">
            <svg-icon [applyClass]="true" svgClass="icon" [src]="'assets/' + i" (click)="editItem.icon = i"></svg-icon>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <ng-container>
          <button mat-button mat-dialog-close class="dialog-button cancel">Отменить</button>
          <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="dialog-button action">Сохранить</button>
        </ng-container>

      </mat-dialog-actions>
    </ng-template>

    <!--Редактирование списка файлов-->
    <ng-template #tmplFiles>
      <app-public-event-files-int [publicEventId]="publicEventId" [programItemId]="editItem.id" (onEditBeginClick)="onEditBeginClick()" (onEditEndClick)="onEditEndClick()">
      </app-public-event-files-int>
    </ng-template>

    <!--Просмотр одного спикера-->
    <ng-template #tmplSpeaker>
      <app-user-profile-display [userDisplay]="viewSpeaker"></app-user-profile-display>
    </ng-template>

  </app-screen-with-panel>
</div>
