<app-screen-with-panel [title]="'Делегирование голоса'"
                       [bodyTmpl]="tmplBody || main"
                       [backUrl]="backUrl"
                       [isSaveCancel]="isSaveCancel"
                       [isDeleteVisible]="false"
                       (onCancelEditClick)="onCancelEdit()"
                       [doNavigateBackOnCancel]="false"
                       [showButtons]="false"

                       >
  <ng-template #main>
    <!-- делегирование -->
    <ng-container *ngIf="!isProxySet; else revokeProxy">
      <div class="warn-block warn" *ngIf="hasVotingBegun">Голосование началось, поэтому, делегировать голос уже нельзя</div>
      <!--<div class="vote-found-notice" *ngIf="isVoteFound">Вы начали голосовать, поэтому, делегировать голос уже нельзя</div>-->
      <div class="form-wrapper" [ngClass]="isVoteFound || hasVotingBegun ? 'disabled' : '' ">
        <mat-form-field>
          <mat-label>Кому делегируете</mat-label>
          <input matInput [(ngModel)]="proxyName" readonly (click)="onSelectPerson(selectPerson,main)" #proxyNameNgModel="ngModel" required />
        </mat-form-field>
        <mat-form-field>
          <mat-label>№ доверенности</mat-label>
          <input matInput [(ngModel)]="paNumber" #PANumberNgModel="ngModel" required />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Дата доверенности</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="paDate" #PADateNgModel="ngModel" autocomplete="off" required>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi>
          </mat-datepicker>
        </mat-form-field>
        <div class="notice">Для уточнения требований к доверенности обратитесь, пожалуйста, к Организатору мероприятия</div>
        <!--Кнопка "Делегировать"-->
        <div class="btn-row">
          <button class="dialog-button action-violet" (click)="onSaveClick(proxyNameNgModel, PANumberNgModel, PADateNgModel)">Делегировать голос</button>
        </div>
      </div>
    </ng-container>
    <!-- снять делегирование -->
    <ng-template #revokeProxy>
      <div class="revoke-notice1">Голос делегирован</div>
      <div class="revoke-notice2" *ngIf="!hasVotingBegun">Делегирование голоса можно отозвать до начала голосования, {{votingBeginDateStr}}</div>
      <div class="vote-found-notice" *ngIf="hasVotingBegun">Голосование началось, поэтому отозвать делегирование голоса нельзя</div>
      <mat-form-field>
        <mat-label>За вас будет голосовать</mat-label>
        <input matInput [value]="proxyName" readonly />
      </mat-form-field>
      <mat-form-field>
        <mat-label>№ доверенности</mat-label>
        <input matInput [value]="paNumber" readonly/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Дата доверенности</mat-label>
        <input matInput [value]="formatDate(paDate)"  autocomplete="off" readonly>
      </mat-form-field>
      <!--Кнопка "Отозвать делегирование"-->
      <div class="btn-row">
        <button class="dialog-button action-red" [ngClass]="hasVotingBegun? 'disabled' : ''" (click)="onRevokeClick()">Отозвать делегирование</button>
      </div>
    </ng-template>
  </ng-template>

  <!--Выбор участника-->
  <ng-template #selectPerson>
    <app-public-event-user-list [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="false" [showIcon]="false"
                                (userClick)="userClick($event)" [excludeLoginId]="userLoginId" [isVotingOnly]="false">

    </app-public-event-user-list>
  </ng-template>
</app-screen-with-panel>
