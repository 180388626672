import { AfterViewChecked, Directive, ElementRef, HostListener, NgZone } from '@angular/core';
// import { CommonDataService } from '../common-data.service';
// import { fromEvent, Observable, Subscription } from "rxjs";

@Directive({
  selector: '[appHeight100vh]'
})
export class Height100vhDirective implements AfterViewChecked {

  // insets: any = { top: 0, right: 0, bottom: 0, left: 0 };
  // constructor(private htmlEl: ElementRef, private commonSrv: CommonDataService, private ngZone: NgZone) { }
  constructor(private htmlEl: ElementRef) {  }

  //resizeObservable$: Observable<Event>
  //resizeSubscription$: Subscription


  setHeight() {
    this.htmlEl.nativeElement.style["height"] = window.innerHeight + "px";
    //if (this.insets && this.insets.top !== undefined) {
    //  this.htmlEl.nativeElement.style["height"] = window.innerHeight - this.insets.to + "px";   // window.screen.availHeight window.innerHeight;
    //}
    //else {
    //  this.htmlEl.nativeElement.style["height"] = window.innerHeight + "px";
    //}
  }

  //setPadding() {
  //  if (this.insets && this.insets.top !== undefined) {
  //      this.htmlEl.nativeElement.style["padding-top"] = this.insets.top + "px";
  //      this.htmlEl.nativeElement.style["padding-left"] = this.insets.left + "px";
  //  }
  //}

  //getInsets() {
  //    let self = this;
  //    this.commonSrv.safeAreaPromise.then(insets => {
  //      self.insets = insets;
        //this.setHeight();
        //this.setPadding();
  //    })
  //    .catch(err => {
  //      console.error(`Error getting safe area insets:${err}. Navigator.userAgent:${navigator.userAgent} Navigator.platform:${navigator.platform}`);
  //    });
  //}

  //ngOnInit() {
    //this.resizeObservable$ = fromEvent(window, 'resize')
    //this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
    //  console.debug(`resizeObservable$ - event: ${evt},  window.innerHeight:${window.innerHeight}`);
    //  this.setHeight();
    //})
  //}


  ngAfterViewInit(): void {
     this.setHeight();
  }



  ngAfterViewChecked(): void {

    // this.setHeight();
    // this.setPadding();

    //let self = this;
    //this.ngZone.runOutsideAngular(() => { self.getInsets(); });
    // Выполнение чего-либо асинхронного в ngAfterViewChecked вызовет другой рендеринг с повторным запуском ngAfterViewChecked.
    // Чтобы обойти это, убедитесь, что вы не вызываете еще один рендер, пока вам это не понадобится
    // const currentZone = Zone.current;
    // const rootZone = (currentZone.parent || currentZone);
    // rootZone.run(() => { self.getInsets();   });
  }

  
  @HostListener("window:resize")
  onWindowResize() {
    console.debug(`onWindowResize - window.innerHeight:${window.innerHeight}`);
    this.setHeight();
  }
  
}
