import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';
import { ShowVotingResultsEn } from './ShowVotingResultsEn';

@Component({
  selector: 'app-public-event-question',
  templateUrl: './public-event-question.component.html',
  styleUrls: ['./public-event-question.component.less']
})
export class PublicEventQuestionComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private commonData: CommonDataService) { }

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.pollingId = data["pollingId"];
      let ixTab = data['ixTab'];
      if (ixTab != undefined) {
        this.ixTabActive = ixTab;
      }
      this.http.get<Questions>(AjaxMethods.getPublicEventQuestions.replace("{pollingId}", this.pollingId)).subscribe(data => {
        this.polling = data.polling;
        this.questions = data;
        this.doesProxyForExist = data.doesProxyForExist;
      });
    });
  }
  pollingId: any;
  polling: Polling;
  questions: Questions;
  doesProxyForExist: boolean;
  // Показывать результаты
  showUser: any;
  showResults: ShowVotingResultsEn = ShowVotingResultsEn.showAllVotes;
  showAllVotes = ShowVotingResultsEn.showAllVotes;
  showOneParticipant = ShowVotingResultsEn.showOneParticipant;
  // Хоть в одном вопросе есть хоть один голос
  isAnyVote(): boolean{
    return this.questions?.items.some(q => !!q.allVotes.length);
  }
  // функция для отображения userName из объекта
  showUserFn(u) {
    //var u = this.questions?.participants.find(p => p.userAccountId == uid);
    //
    return u?.userName ? u.userName : ' ';
  }
  onUserSelected(v) {
    this.inputAutocomplete?.nativeElement.blur();
  }
  @ViewChild("inputAutocomplete")
  inputAutocomplete: ElementRef;

  filteredParticipants(): { userName: string, userAccountId: number }[]{
    var searchStr = ((this.showUser || '')+'').trim().toLowerCase();

    return this?.questions?.participants.filter(p => !this.showUser || p.userName.toLowerCase().indexOf(searchStr) >= 0);
  }
  //Результат голосования одного участника showuser
  vote4ShownUser(questionId: number): {text:string, class:string} {
    if (!this.questions || this.showResults != this.showOneParticipant || !this.showUser) return <any>{};
    let q = this.questions.items.find(q => q.id == questionId);
    if (q) {
      let u = q.allVotes.find(v => v.userAccountId == this.showUser.userAccountId);
      if (u) {
        return { text: u.voteText, class: u.voteEnText };
      }
    }
    return {text: 'Не голосовал', class:'NoVoting'};
  }
  // Участник один, выбран и нет ни одного голоса
  didntVote(): boolean {
    return this.showResults == this.showOneParticipant && this.showUser && this.showUser.userAccountId 
      && !this.questions.items.some(q => q.allVotes.some(v => v.userAccountId == this.showUser.userAccountId));
  }
  // Для выбранного участника. Количество вопросов и количество его голосов
  private getVoteCounts(): { totalCt: number; voteCt: number } {
    if (!this.questions) return <any>{};
    let totalCt = this.questions.items.length;
    let votes = this.questions.items.reduce((prev, q) => prev
      + q.allVotes.filter(v => v.userAccountId == this.showUser?.userAccountId).reduce((p, v) => p + 1, 0), 0);
    return { totalCt: totalCt, voteCt: votes };
  }
  // Вопросов отвечено "2 из 3"
  getQuestionCountText() {
    if (!this.questions) return "";
    let counts = this.getVoteCounts();
    return `${counts.voteCt} из ${counts.totalCt}`;
  }
  // Принят ли голос
  getVoteAccepted(): { class: string, text: string }{
    let counts = this.getVoteCounts();
    if ((counts.voteCt ?? 0) > 0 &&
      (this.questions.polling.isUncompletedResultsCountedIn || counts.voteCt == counts.totalCt)) { // принят
      return { class: 'counted-in-yes', text: 'Голос учтен' };
    }
    else {
      return { class: 'counted-in-no', text: 'Голос не учтен' };
    }
  }
  // Удалить голос
  onDeleteVoteClick() {
    this.commonData.confirmationDialog.confirm({ message: 'Удалить голос из результатов?' })
      .then((v) => {
        if (v) {
          this.http.delete<any>(AjaxMethods.DeletePublicEventPollingVote.replace('{pollingId}', this.questions.polling.id + '')
            .replace('{userAccountId}', this.showUser.userAccountId))
            .subscribe(()=> {
              this.ngOnInit();// перечитать
            });
        }
      });

  }

  // Вопросы,результаты которых разрешены к показу
  get itemsWithResults(): Question[] {
    if (!this.questions || (!this.showUser && this.showResults == ShowVotingResultsEn.showOneParticipant)) return null;
    return this.questions.items;
  }
  // Сгенерировать URL для кнопки back
  get backUrl(): string {
    if (this.questions?.isSinglePolling) { // единственный опрос - вернуться на мероприятие
      return `public-events/${this.polling.publicEventId}/`;
    }
    // убрать последний, 4-ий сегмент
    return '/' +  this.route.snapshot.url.slice(0,3).join('/') + '/';
  }
  ixTabActive = 0;
  // Падеж для "голоса"
  getVoicesDecl(ct: number) {
    return Utils.NumDecl(ct, ['голос', 'голоса', 'голосов']);
  }
  // Новый опрос
  onNewClick() {
    // -> public-events/:id/polling/:pollingId/question/0
    this.router.navigate(["question/0"], {relativeTo: this.route});
  }
  // Редактировать опрос
  onEditClick() {
    //public-events/polling/{pollingId}/edit
    this.router.navigate(['public-events/' + this.polling.publicEventId + '/polling/' + this.pollingId + "/edit"]);
  }
  // Предупреждение о необходимости проголосовать
  getWarn(): { type: 'warn' | 'ok' | 'ok violet' | null, text: string } {
    if (!this.questions || this.questions.isEditor || !this.questions.items?.length) return null;
    if (this.questions.items.every(p => !!p.voting) && this.questions.hasProxy) return { type: 'ok violet', text: 'Голос принят' }; // все вопросы отвечены
    if (this.questions.polling.isUncompletedResultsCountedIn) return null;// вопросы необязательны
    if (this.questions.hasProxy)return null;
    if (this.questions.items.every(p => !!p.voting)) return { type: 'ok violet', text: 'Спасибо, что ответили на все обязательные вопросы' }; // все вопросы отвечены
    if (!this.questions.items.some(p => Boolean(p.voting))) { // не ответил ни на один обязательный вопрос
      return { type: 'warn', text: '<strong>Все вопросы обязательны к ответу.</strong><br/>Голосование, в котором хотя бы один вопрос остался без ответа, не учитывается в итоговых результатах' };
    }
    let ct = this.questions.items.reduce((prevS, polling) => prevS + (!polling.voting ? 1 : 0), 0);// сколько без ответа
    return { type: 'warn', text: `<strong>Все вопросы обязательны к ответу.</strong><br/>Осталось ответить на ${ct} ${Utils.NumDecl(ct,['вопрос','вопроса', 'вопросов'])}, чтобы ваш голос был учтен` };
  }
  // Ссылка на результаты. Если один пользователь и допускается множество голосований, то на историю его голосований.
  // Если один пользователь и не допускается множество голосований, то ссылки нет вообще
  // Если много пользователей - на детали голосования. Если 
  resultsNavLink(it: Question): string {
    if (this.showResults == this.showAllVotes) {
      return '/public-events/' + this.questions.polling.publicEventId + '/polling/' + this.pollingId + '/vote-result/' + it.id;
    }
    // один пользователь
    if (this.showUser && this.polling.isMultipleVotingAllowed) { // множественное голосование разрешено
      return `public-events/${this.questions.polling.publicEventId}/polling/${this.pollingId}/vote-history/${it.id}/${this.showUser.userAccountId}/back2results`;
    }
    return '';
  }

  HTML2Text = Utils.HTML2Text;
  // Делегирование
  onSetProxyClick() {
    this.router.navigate([`public-events/${this.polling.publicEventId}/${this.questions.isEditor ? 'adminproxy' : 'setproxy'}/${this.pollingId}`]);
  }
}
