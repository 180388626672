import { ApplicationRef, Component, ElementRef, Input, Output, NgZone, OnInit, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicEventComponent } from '../public-event/public-event.component';
import { HttpClient } from '@angular/common/http';
import { DownloadService, Download } from '../../services/download.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { NgModel } from '@angular/forms';
import { Utils } from '../../utils';
import { ScreenWithPanelComponent } from '../../screen-with-panel/screen-with-panel.component';
/*import { EventEmitter } from 'selenium-webdriver';*/
import { Subject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-public-event-files-int',
  templateUrl: './public-event-files-int.component.html',
  styleUrls: ['./public-event-files-int.component.less']
})
//----------- Материалы, aka Files мероприятия "public-events/:id/files"
export class PublicEventFilesIntComponent  implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private title: Title, private commonData: CommonDataService, private downloadSrv: DownloadService, private applicationRef: ApplicationRef, private common: CommonDataService, private sanitizer: DomSanitizer) {

    var readFileList = () => {
      let publicEventId = this.publicEventId;
      if (publicEventId) {
        this.http.get<PublicEventFiles>(AjaxMethods.getPublicEventFiles.replace("{id}", publicEventId + '').replace('{programItemId}', this.programItemId + ''))
          .subscribe(data => {
            this.files = data.files;
            this._isEditor = data.isEditor;
          });
      }
    }
    // прочитать список файлов при изменении publicEventId/programItemId
    this.publicEventIdChange
      .pipe(debounceTime(50))
      .subscribe(newId => readFileList());
    //this.programItemIdChange
    //  //.pipe(debounceTime(50))
    //  .subscribe(newId => readFileList());

  }

  // frame для просмотра вынести следом за данным елементом
  @Input()
  moveOutElement: any;

  @Output()
  onEditBeginClick = new EventEmitter<void>();

  @Output()
  onEditEndClick = new EventEmitter<void>();

  ngOnInit(): void {
    
  }

  publicEventIdChange = new Subject<number>();
  _publicEventId: number;
  @Input()
  get publicEventId(): number {
    return this._publicEventId;
  }
  set publicEventId(v: number) {
    this._publicEventId = v;
    this.publicEventIdChange.next(v);
  }

  programItemIdChange = new Subject<number>();
  _programItemId: number;
  @Input()
  get programItemId(): number { return this._programItemId; }
  set programItemId(v:number) {
    this._programItemId = v;
    this.programItemIdChange.next(v);
  }

  files: PublicEventFile[] = [];
  _isEditor: boolean;
  isIFameHidden = true;

  get isDeleteEnabled(): boolean {
    return this.files.some(fl => fl.isSelected);
  }

  // Назад. К событию. Наш url "public-events/:id/files". Оставить только 2 сегмента
  get backUrl() {
    return "/" + this.route.snapshot.url.slice(0, 2).join("/") + "/";
  }

  // Добавить файл
  onNewClick() {
    this.file = <any>{};
    this.isEditPanelVisible = true;
  }

  @ViewChild('openFileForm')
  openFileForm: ElementRef<HTMLFormElement>;
  openFileAction: string;
  JWTToken: string;
  isPDFViewerHidden = true;
  pdfSrc: string;

  @ViewChild("pdfViewer")
  pdfViewer;

  fileDataUrl;
  fileDataUrlSrc;
  isIframeElVisible = true;
  isImg = false;
  isOther = false;
  isAudio = false;
  isVideo = false;
  downloadFileName: string;

  @ViewChild("downloadButton")
  downloadButtonElement: ElementRef<HTMLElement>;

  download$: Observable<Download>
  onFileDownload(dataUrl: string, filename?: string, mimetype?: string) {
    if (this.commonData.platform !== "web") {
      this.download$ = this.downloadSrv.download(dataUrl, filename, mimetype, true);
    }
    else {
      // DEV-35477
      if (this.commonData.platform == 'web' && this.commonData.operatingSystem == 'ios') {
        window.open(this.fileDataUrl, "_self");
      }
      else {
        this.downloadButtonElement.nativeElement.click();
      }
    }
    return false;
  }


  // Открыть файл. 
  onFileOpenClick(fl: PublicEventFile) {
    this.JWTToken = this.commonData.JWTToken;
    this.pdfSrc = `/PublicEvent/file/${fl.guid}`;
    this.isIframeElVisible = true;
    if (this.screenWithPanel) {
      this.screenWithPanel.setCurrentPosition("down"); // схлопнуть его, а то, открыв файл, пользователь забывает, что он его оттянул вверх
    }

    //убираем просмотр в зависимости от типа файла
    /*if (fl.extension == ".pdf") {
      this.http.get<any>(`/PublicEvent/file/${fl.guid}`, { responseType: 'blob' as "json" }).subscribe(data => {
        this.isPDFViewerHidden = false;
        this.pdfSrc = data;
        this.applicationRef.tick();
        this.pdfViewer.refresh();
      });
    }
    else if (fl.extension == ".docx") {
      this.openFileAction = `/PublicEvent/${this.publicEventId}/file/${fl.id}/get`;
      this.isIFameHidden = false;
      this.applicationRef.tick();
      this.openFileForm.nativeElement.submit();
    }
    else {*/

      this.http.get<any>(`/PublicEvent/file/${fl.guid}`, { responseType: 'blob' as "json", observe: "response" })
        .subscribe((resp) => {
          let reader = new FileReader();
          reader.addEventListener("loadend", () => {
            fl.extension = fl.extension.toLowerCase();
            this.isIframeElVisible = false;
            this.fileDataUrlSrc = this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
            // DEV-35477
            if (this.commonData.platform === 'web' && this.commonData.operatingSystem === 'ios') {
              this.fileDataUrl = window.URL.createObjectURL(resp.body);
            }
            else {
              this.fileDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
            }
            

            /*this.isIFameHidden = false;*///не показываем дополнительный экран

            // let contentType = resp.headers.get("content-type");
            //this.isImg = contentType.startsWith("image") || [".jpg", ".png"].indexOf(fl.extension) >= 0;
            //this.isAudio = contentType.startsWith("audio") || [".mp3"].indexOf(fl.extension) >= 0;
            //this.isVideo = contentType.startsWith("video") || [".mov", ".mp4"].indexOf(fl.extension) >= 0;
            //this.isOther = !this.isImg && !this.isAudio && !this.isVideo;

            this.isImg = false;
            this.isAudio = false;
            this.isVideo = false;
            this.isOther = true;

            //content-disposition:"attachment; filename="____ ___________ 2021 - 03 - 09.docx"; filename*=UTF-8''План мероприятий 2021-03-09.docx"
            let disposition = decodeURI(resp.headers.get('content-disposition'));
            let p = disposition.lastIndexOf("'");
            if (p > 0) {
              this.downloadFileName = disposition.substring(p + 1);
            }
            else {
              this.downloadFileName = fl.fileName;
            }
            this.applicationRef.tick();

            // console.log(`DownloadFileName:${this.downloadFileName}. FileDataUrl:${this.fileDataUrl}`);
            // console.log(`DownloadFileName:${this.downloadFileName}.`);

            this.onFileDownload(<string>reader.result, fl.fileName, resp.body.type);
          });
          reader.readAsDataURL(resp.body);
        });
    /*}*/
  }
  // click в крестик у iframe 
  onIframeCloseClick(ifr: HTMLIFrameElement) {
    this.isIFameHidden = true;
    ifr.contentDocument?.body?.remove();
    this.isImg = false;
    this.isOther = false;
    this.isAudio = false;
    this.isVideo = false;
  }
  // редактировать файл
  onFileEditClick(it: PublicEventFile) {
    /*this.isEditPanelVisible = true;*/
    this.file = it || <any>{};
    /*this.router.navigate([`/public-events/${this.publicEventId}/file-detail/${it.id}`]);*/
    this.isViewScreenVisible = true;
    this.copyFileParams();
    if (this.onEditBeginClick.observers.length) {
      this.onEditBeginClick.emit();
    }
  }

  editIconClicked() {
    this.isEditPanelVisible = true;
    /*alert('hi');*/
  }

  onBackClicked() {
    if (this.isViewScreenVisible) {
      this.isViewScreenVisible = false;
      //Восстанавливаем значения, на случай, если окно редактирования было просто закрыто. А если было сохранение изменений, то результат останется.
      this.restoreFileParams();
      if (this.onEditEndClick.observers.length) {
        this.onEditEndClick.emit();
      }
    }
  }

  isViewScreenVisible = false;

  isEditPanelVisible = false;

  // Отмена редактирования
  onEditCancelClick() {
    this.isEditPanelVisible = false;
    this.restoreFileParams();
  }


  @ViewChild("fileInput")
  fileInputElement: ElementRef<HTMLInputElement>;

  @ViewChild("fileForm")
  formElement: ElementRef<HTMLFormElement>;
  @ViewChild("titleField")
  titleField: NgModel;
  @ViewChild("fileNameField")
  fileNameField: NgModel;

  file: PublicEventFile = <any>{};
  fileParamsCopy: PublicEventFile = <any>{};

  copyFileParams() {
    if (this.file && this.file.id > 0) {
      this.fileParamsCopy = <any>{};
      this.fileParamsCopy.extension = this.file.extension;
      this.fileParamsCopy.fileName = this.file.fileName;
      this.fileParamsCopy.fileSize = this.file.fileSize;
      this.fileParamsCopy.title = this.file.title;
    }
  }

  restoreFileParams() {
    if (this.fileParamsCopy) {
      this.file.extension = this.fileParamsCopy.extension;
      this.file.fileName = this.fileParamsCopy.fileName;
      this.file.fileSize = this.fileParamsCopy.fileSize;
      this.file.title = this.fileParamsCopy.title;
    }
  }

  // Сохранить
  onSaveClick() {
    //let formData = new FormData(this.formElement.nativeElement);
    if (!this.file?.title || this.file?.title.trim()?.length == 0) {
      this.titleField?.control.markAsTouched();
      return;
    }
    if (!this.file.fileName) {
      this.fileNameField?.control.markAllAsTouched();
      return;
    }
    this.file.publicEvent_Id = this.publicEventId;
    this.file.programItemId = this.programItemId ? this.programItemId : null;
    this.http.post<{ newId: number, guid: string }>(AjaxMethods.PostPublicEventFileInfo.replace('{id}', String(this.publicEventId))
      , this.file)
      .subscribe(data => {
        if (!this.file.id) {// добавить файл в список
          if (this.programItemId) {
            this.files.push(this.file);
          }
          else {
            this.files.unshift(this.file);
          }
        }
        this.file.id = data.newId;
        this.file.guid = data.guid;

        if (!this.file.programItemId) {
          this.files = this.files.sort((a, b) => this.compareFiles(a, b));
        }

        this.isEditPanelVisible = false;
        if (this.fileInputElement) {
          this.fileInputElement.nativeElement.value = ''; // очистить загруженные файлы
        }

        this.copyFileParams();
      });
  }

  compareFiles(a: PublicEventFile, b: PublicEventFile): number {
    if ((a.programItemId && b.programItemId) || (!a.programItemId && !b.programItemId)) {
      return a.id - b.id;
    }
    else if (a.programItemId) {
      return 1;
    }
    else {
      return -1;
    }
  }

  maxFileSize = 30 << 20;
  // Загрузился файл
  onFileInputChange($event: Event) {
    let inputElement = (<HTMLInputElement>$event.target);
    let files: FileList = inputElement.files;

    console.log(`length:${files.length}.`);
   

    if (files.length) {
      let uploadedFile = files[0];
      console.log(`name:${uploadedFile.name}, size: ${uploadedFile.size}, type: ${uploadedFile.type}.`);
      let isImage = uploadedFile.type.toLowerCase().startsWith("image") && (uploadedFile.name.toLowerCase().endsWith(".jpg") || uploadedFile.name.toLowerCase().endsWith(".jpeg")
         || uploadedFile.name.toLowerCase().endsWith(".png") || uploadedFile.name.toLowerCase().endsWith(".gif") || uploadedFile.name.toLowerCase().endsWith(".bmp"));
      let isPdf = uploadedFile.name.toLowerCase().endsWith(".pdf");
      if (!isImage && !isPdf) {
        this.commonData.confirmationDialog.msgBox({
          message: `Вы можете загрузить файлы только в форматах PDF, BMP, GIF, JPEG, JPG, PNG`
        });
        inputElement.value = '';
        return;
      }
      if (uploadedFile.size > this.maxFileSize) {
        this.common.confirmationDialog.msgBox({
          message: `Размер файла ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize >> 20}Mb`
        });
        inputElement.value = '';
        return;
      }
      this.file.fileName = uploadedFile.name;
      let p = uploadedFile.name.lastIndexOf('.');
      this.file.extension = p >= 0 ? uploadedFile.name.substring(p) : '.';
      this.file.fileSize = uploadedFile.size;
      this.file.lastModified = new Date(uploadedFile.lastModified);
      uploadedFile.arrayBuffer().then(arr => {
        let bl = new Blob([arr]);
        let rdr = new FileReader();
        rdr.onloadend = () => this.file.content = <string>rdr.result;
        rdr.readAsDataURL(bl);
      });
      inputElement.value = '';
    }
  }
  // Удалить файл
  onDeleteFileClick() {
    let self = this;
    var ids = [this.file.id + ''];
    this.common.confirmationDialog.confirm({ message: "Удалить материал?", title: 'Удаление' })
      .then((confirmed) => {
        if (!confirmed) return;
        this.http.delete(AjaxMethods.DeletePublicEventFile.replace("{id}", String(self.publicEventId))
          , { params: { publicEventId: self.publicEventId + '', ids: ids } }).subscribe(() => {
            self.isEditPanelVisible = false;
            let ixDel = self.files.findIndex(f => f.id == self.file.id);
            if (ixDel >= 0) {
              self.files.splice(ixDel, 1);
            }
            //вернуться к списку
            self.onBackClicked();
          });
      });
  }
  // Очистить загруженный файл
  onLoadedFileClearClick() {
    this.file.fileName = '';
    this.file.content = null;
  }

  formatDate(v) {
    return Utils.formatDateLong(v);
  }

  getFileSizeSz(it: PublicEventFile) {
    return it.fileSize >= (2 << 20) ? (it.fileSize >> 20) + 'Mb'
      : (it.fileSize >> 10) + 'Kb';
  }
  @ViewChild(ScreenWithPanelComponent)
  screenWithPanel: ScreenWithPanelComponent;
}
