import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';

@Component({
  selector: 'app-public-event-polling-edit',
  templateUrl: './public-event-polling-edit.component.html',
  styleUrls: ['./public-event-polling-edit.component.less']
})
export class PublicEventPollingEditComponent implements OnInit {

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private common: CommonDataService) {
    this.NOTIFICATIONS_ENABLED = common.NOTIFICATIONS_ENABLED;
  }

  NOTIFICATIONS_ENABLED;
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // параметры public-events/:id/polling/:pollingId/edit
      this.publicEventId = params["publicEventId"];
      this.pollingId = params["pollingId"];
      // прочитать опрос с хоста
      this.http.get<Pollings>(AjaxMethods.getPublicEventPolling.replace('{id}', this.publicEventId+''))
        .subscribe(data => {
          this.pollings = data;
          if (!this.pollings.isEditor) {
            this.router.navigate([this.backToVotingUrl]);
          }
          else {
            this.polling = data.items.find(it => it.id == this.pollingId);
            if (this.polling == null && this.pollings.isEditor) { // new
              this.polling = this.initEmpty();
            }
          }
        });
    });
  }
  // Создание нового голосования
  initEmpty(): any {
    return {
      id: 0,
      topic: '',
      fullText: '',
      beginDate: null,
      endDate: null,
      publicEventId: this.publicEventId,
      isUncompletedResultsCountedIn: true
    };
  }

  publicEventId: number;
  pollingId: number;
  pollings: Pollings;
  polling: Polling = <any>{};

  get isAllQuestionsRequired(): boolean {
    return ! this.polling?.isUncompletedResultsCountedIn;
  }

  set isAllQuestionsRequired(v) {
    if (this.polling) {
      this.polling.isUncompletedResultsCountedIn = !v;
    }
  }

  get backUrl() { return ""; }
  // url для возврата к просмотру опроса
  get backToVotingUrl(): string {
    return `public-events/${this.publicEventId}/polling/${this.pollingId}`;
  }
  // Сохранить
  onSaveClick() {
    if (!this.polling.topic || this.isDateRangeInvalid() || this.isOutOfEventPeriod() || this.isDateNotSpecified()) return;
    // даты из moment
    Utils.moment2Date(this.polling, 'beginDate', 'endDate', 'beginDateNfyDate', 'endDateNfyDate');
    this.http.post<{ newId: number }>(AjaxMethods.PostPublicEventPolling, this.polling)
      .subscribe(data => {
        this.polling.id = data.newId;
        this.pollingId = data.newId;
        // выйти из редактирования. Текущий url: public-events/:id/polling/:pollingId/edit -> public-events/:id/vote/:pollingId
        this.router.navigate([this.backToVotingUrl]);
      });
  }
  // Отмена сохранения. Вернуться , как при save или, если не сохраняли новый, то на корень голосования
  onCancelEditClick() {
    if (this.polling.id) {
      this.router.navigate([this.backToVotingUrl]);
    }
    else {
      this.router.navigate([`public-events/${this.publicEventId}/polling`]);
    }
  }
  // Удалить
  onDeleteClick() {
    // Если есть отвеченные вопросы, ошибка
    if (this.polling?.isAnyVote) {
      this.common.confirmationDialog.msgBox({ message: "По голосованию был принят голос(а). Удаление невозможно" });
    }
    else {
      this.common.confirmationDialog.confirm({ message: "Удалить голосование?", title: 'Удаление' })
        .then(confirmed => {
          if (confirmed) {
            this.http.delete<any>(AjaxMethods.DeletePublicEventPolling.replace('{id}', this.publicEventId + ''),
              { params: { publicEventId: this.publicEventId + '', ids: [this.pollingId + ''] } }).subscribe(() => {
                this.router.navigate([`public-events/${this.publicEventId}/polling`])
              });
          }
        });
    }
  }
  // Даты - ошибка в периоде
  isDateRangeInvalid() {
    return Utils.isDateRangeInvalid(this.polling.beginDate, this.polling.endDate);
  }
  // Даты должны быть указаны/не указаны одновременно
  isDateNotSpecified() {
    var b =  (this.polling.beginDate || this.polling.endDate)
      &&
      (!moment(this.polling.beginDate).isValid() || !moment(this.polling.endDate).isValid());
    return b;
  }

  // даты за пределами мероприятия
  isOutOfEventPeriod(): boolean {
    if (!this.polling?.beginDate || !this.polling?.endDate) return false;
    let eventFrom = moment(this.pollings.publicEvent.publishDateBegin || new Date('1970-01-01'));
    let eventTo = moment(this.pollings.publicEvent.publishDateEnd || new Date('3500-01-01'));
    let dtFrom = moment(this.polling.beginDate);
    let dtTo = moment(this.polling.endDate);
    return !eventFrom.isSameOrBefore(dtFrom) || !eventTo.isSameOrAfter(dtTo);
  }

  cfgNotificationObjName: string;
  cfgNotificationSelection = 0;
  cfgNotificationDate = null; // new Date();
  isNotificationsPanelVisible = false;
  // Переход к редактированию оповещений
  onCfgNotificationClick(objName: string) {
    this.cfgNotificationObjName = objName;
    this.cfgNotificationSelection = this.polling[objName + "NfySet"];
    this.cfgNotificationDate = this.polling[objName + "NfyDate"];
    this.isNotificationsPanelVisible = true;
  }

  onNotificationVisibleChange($event: boolean) {
    if (!$event) {
      this.polling[this.cfgNotificationObjName + "NfySet"] = this.cfgNotificationSelection;
      this.polling[this.cfgNotificationObjName + "NfyDate"] = this.cfgNotificationDate;
      this.isNotificationsPanelVisible = false;
    }

  }

}
