<app-screen-general header="TCH-Link" backUrl="/">


  <!--Блок поиска для пользователя ТКП-->
  <ng-container *ngIf="isTCHUser">
    <mat-form-field appearance="fill" style="width:100%;">
      <mat-label>Код или название агентства</mat-label>
      <input type="text" matInput [(ngModel)]="searchText" (ngModelChange)="onSearchTextChange($event)" [matAutocomplete]="auto"
             (optionSelected)="onCompanySelected($event)" #inpSearch>
      <!--кнопка "Очистить"-->
      <button mat-button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="onClearSearch()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onCompanySelected($event)">
        <mat-option *ngFor="let option of filteredOptions" [value]="option">
          {{option.comp_code}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </ng-container>

  <!--Отображение обеспечительного платежа-->
  <div class="company" *ngIf="showAmount && isTCHUser">{{selectCompany}}</div>
  <div class="amount" *ngIf="showAmount || !isTCHUser">
    <div class="upper-text">Остаток управляемой суммы</div>
    <div class="amount-per-se">{{amountFmt}}</div>
    <div class="lower-text">{{dtFmt}}</div>
  </div>

  <!--Информация об обеспечительном платеже-->
  <!--<app-nav-list-item text="Информация об обеспечительном платеже" navLink="tch-link/edit" navIcon="open-popup" navIconClass="icon-square" [isTopBorder]="true" *ngIf="!isTCHUser">-->
  <app-nav-list-item text="Информация об обеспечительном платеже в Личном кабинете" (click)="onClick()" navIcon="open-popup" navIconClass="icon-square" [isTopBorder]="true" *ngIf="!isTCHUser || showAmount">

  </app-nav-list-item>
</app-screen-general>
