<div #ultimateWrapper>
  <app-screen-with-panel [title]="mainTitle"
                         [bodyTmpl]="tmpl || tmplList"
                         (onBackClicked)="onBackClick()"
                         (onSaveClick)="onSave()"
                         (onCancelEditClick)="onCancelEdit()"
                         (onDeleteClick)="onDelete()"
                         [isSaveCancel]="isSaveCancel"
                         [btnSaveName]="btnSaveName"
                         deleteBtnSubject="уведомление"
                         [isDeleteEnabled]="isDeleteEnabled"
                         [isInnerScroll]="true">
    <!-- Редактирование уведомления -->
    <ng-template #tmplEditItem>
      <div class="hdr">Уведомление</div>
      <br />
      <div class="form-wrapper">
        <h3 id="send-radio-group-label">Отправить</h3>
        <mat-radio-group aria-labelledby="send-radio-group-label"
                         id="send-radio-group"
                         class="form-radio-group"
                         [(ngModel)]="editItem.sendType"
                         (change)="radioChange($event)">
          <mat-radio-button class="form-radio-button" *ngFor="let sendType of notificationSendTypes" [value]="sendType.id" [title]="sendType.title">
            {{sendType.label}}
          </mat-radio-button>
        </mat-radio-group>
        <div>
          <app-date-and-time label="" [(editValue)]="editItem.sendDate" [min]="minDate" [max]="maxDate" [allowClear]="true" [isDisabled]="editItem?.sendType != 1">
          </app-date-and-time>
        </div>
        <!-- Повторять уведомления -->
        <h3>Повторять</h3>
        <app-nav-list-item text="Повторять уведомления" (onIconClick)="onRepeateEdit()"></app-nav-list-item>
        <!-- Подписчики -->
        <h3 id="member-radio-group-label">Кому</h3>
        <mat-radio-group aria-labelledby="member-radio-group-label"
                         id="member-radio-group"
                         class="form-radio-group"
                         [(ngModel)]="editItem.memberType"
                         (change)="radioChange($event)">
          <mat-radio-button class="form-radio-button" *ngFor="let memberType of notificationMemberTypes" [value]="memberType.id" [title]="memberType.title">
            {{memberType.label}}
          </mat-radio-button>
        </mat-radio-group>
        <app-nav-list-item text="Кого уведомлять" [isDisabled]="editItem?.memberType != 3" (onIconClick)="isRecipientsPanelVisible = true"></app-nav-list-item>

        <!-- список выбранных спикеров -->
        <div class="inline-recipient-list">
          <div class="one-recipient" *ngFor="let rcp of editItem?.recipients">
            <div class="name-wrapper">
              <div class="recipient-name">{{rcp.name}}</div>
              <div class="recipient-details">{{rcp.position}}, {{rcp.company}}</div>
            </div>
            <div class="del-svg" (click)="onRecipientRemove(rcp)">
              <svg-icon src="assets/cross.svg" [applyClass]="true" svgClass="del-svg-svg"></svg-icon>
            </div>
          </div>
        </div>

        <!--текст сообщения-->
        <h3 style="margin-bottom: 20px">Текст уведомления</h3>
        <mat-form-field class="mat-form-field-textarea">
          <textarea matInput [(ngModel)]="editItem.message" rows="8" maxlength="256" required></textarea>
        </mat-form-field>
        <!--ссылка-->
        <mat-form-field>
          <mat-label>Ссылка</mat-label>
          <input matInput [(ngModel)]="editItem.url" maxlength="512" />
        </mat-form-field>

        <!--Сообщения об ошибках в датах-->
        <div class="mat-error" *ngFor="let msg of getErrorMessages(false)">{{msg}}</div>
        <!--Сообщения об ошибках в датах-->
        <div class="mat-error" [ngStyle]="{ 'margin-bottom.px':'15' }" *ngFor="let msg of getWarningMessages()">{{msg}}</div>
      </div>

      <!--Подписчики-->
      <app-child-panel [isVisible]="isRecipientsPanelVisible" (isVisibleChange)="onRecipientsPanelVisibleChange($event)">
        <div class="choose-recipient-wrapper">
          <app-public-event-user-list *ngIf="isRecipientsPanelVisible" [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="true"
                                      [showIcon]="false" [isUserChecked]="isRecipient" (cbxChange)="onRecipientCbxChange($event)">

          </app-public-event-user-list>
        </div>
      </app-child-panel>

    </ng-template>


    <!-- Список уведомлений -->
    <ng-template #tmplList>
      <app-new-delete-buttons *ngIf="isEditor" (onNewClick)="onNewClick()"></app-new-delete-buttons>

      <div class="grey-notice" *ngIf="!items.length && isEditor">
        Нажмите на «+» внизу экрана чтобы добавить уведомление
      </div>
      <div *ngIf="items.length && isEditor">
        <div *ngFor="let grp of notificationGroups">
          <div>
            <h3>{{grp.name}}</h3>
            <div class="items-wrapper">
              <app-nav-list-item *ngFor="let it of filterItemsOfType(grp.id)" [textTmpl]="notifiacationItemTmpl" (onIconClick)="onEditItemClick(it)" [isTopBorder]="false" [navIcon]="it.id>0 ? (!it.isSent ? 'pencil-inline' : 'copy') : ''" navIconClass="icon-square">
                <ng-template #notifiacationItemTmpl>
                  <div class="notification-item-tmpl">
                    <div class="text-wrapper">
                      <div [style.font-weight]="getItemSubjectFontWeight(it)">{{it.message}}</div>
                      <div class="second-line" *ngIf="it.id>0">{{(!it.isSent ?  'Будет отправлено  ' : '') + it.sendDateStr}}&nbsp;&nbsp;<svg-icon *ngIf="it.id>0 && it.repeatCount > 1" src="assets/repeat.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon></div>
                      <div class="recipients" [appTextEllipsis]="it?.recipients.length > 3" *ngIf="it.id>0 && it?.recipients.length">{{getInlineRecipients(it)}}</div>
                    </div>
                  </div>
                </ng-template>
              </app-nav-list-item>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!--Редактирование повторов-->
    <ng-template #tmpRepeate>
      <h3>Повторять уведомления</h3>
      <br />
      <mat-form-field>
        <mat-label>Количество повторений</mat-label>
        <input matInput [(ngModel)]="editItem.repeatCount" required />
      </mat-form-field>
      <!--Тип-->
      <mat-form-field>
        <mat-label>Частота повторений</mat-label>
        <mat-select [(ngModel)]="editItem.repeatFrequency">
          <mat-option *ngFor="let opt of notificationFrequencyTypes" [value]="opt.id">
            <div [title]="opt.title"> {{opt.label}}</div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

  </app-screen-with-panel>
</div>
