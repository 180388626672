<div>
  <div class="hdr">Уведомлять {{topHeaderText}}</div>

  <div class="it" *ngFor="let it of items">
    <mat-checkbox [ngModel]="isChecked(it.id)" (ngModelChange)="setChecked($event, it.id)"></mat-checkbox>
    <div class="label">{{it.text}}</div>
  </div>
  <div>
    <app-date-and-time label="" [(editValue)]="selectedDate" (editValueChange)="onSelectedDateChange($event)" [min]="minDate" [max]="maxDate" [allowClear]="true" [isDisabled]="!isChecked(0)">

    </app-date-and-time>
  </div>
</div>
