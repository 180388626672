import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { PublicEventComponent } from '../public-event/public-event.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utils } from '../../utils';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-public-event-registration',
  templateUrl: './public-event-registration.component.html',
  styleUrls: ['./public-event-registration.component.less']
})
export class PublicEventRegistrationComponent extends PublicEventComponent implements OnInit {

  constructor(route: ActivatedRoute, router: Router, http: HttpClient, title: Title, commonData: CommonDataService, private snackBar: MatSnackBar, private clipboard: Clipboard) {
    super(route, router, http, title, commonData);
  }
  isEditMode = false;
  isParticipant = false;
  canRegister = false;
  eventLink = '';

  initComplete() {
    if (this.publicEvent?.isEditor && !this.publicEvent?.hasParticipantRole) {
      //this.router.navigate([this.backUrl]);
      this.isEditMode = true;
    }
    if (this.publicEvent?.hasParticipantRole) {
      this.canRegister = true;
    }
    if (this.publicEvent?.isParticipant) {
      this.isParticipant = true;
    }

    var href = location.href.replace('/registration', '');
    var hpos = href.indexOf('localhost');
    if (hpos > 0) {
      href = this.commonData.baseUrl + href.substring(hpos + 9);
    }
    else {
      hpos = href.indexOf('local');
      if (hpos > 0) {
        href = this.commonData.baseUrl + href.substring(hpos + 5);
      }
    }
    //if (href && href.length > 0) {
    //  href = this.commonData.baseUrl + href;
    //}
    this.eventLink = href;
    //alert();
  }



  onCancelEditClick() {
    if (this.publicEvent?.isEditor && !this.publicEvent?.hasParticipantRole) {
      this.router.navigate([this.backUrl]);
    }
    else {
      this.isEditMode = false;
    }
  }

  onRegisterBtnClick() {
    // изменить на сервере "PublicEvent/AlterEventAccess/{grantOrRevoke}/{publicEventId}"
    this.http.get<any>(AjaxMethods.PublicEventRegister.replace("{grantOrRevoke}", "grant").replace("{publicEventId}", this.publicEvent?.id + ''))
      .subscribe(() => {
        this.publicEvent.isParticipant = true;
        this.isParticipant = true;
      });
  }

  onCancelRegisterBtnClick() {
    // изменить на сервере "PublicEvent/AlterEventAccess/{grantOrRevoke}/{publicEventId}"
    this.http.get<any>(AjaxMethods.PublicEventRegister.replace("{grantOrRevoke}", "revoke").replace("{publicEventId}", this.publicEvent?.id + ''))
      .subscribe(() => {
        this.publicEvent.isParticipant = false;
        this.isParticipant = false;
      });
  }

  onCopyBtnClick() {
    this.clipboard.copy(this.eventLink);
    this.snackBar.open("Ссылка скопирована", "Закрыть", Utils.snacBarInfoConfig);
    //alert('copy');
  }

  // Сохранить
  onSaveClick() {
    // включенные компоненты с доступом без регистрации - в componentsNoReg
    this.publicEvent.componentsNoReg = this.eventComponentsNoReg.filter(p => p.isChecked).map(p => p.key).join(' ');
    this.http.post<any>(AjaxMethods.postPublicEventRegistrationSettings, {
      publicEventId: this.publicEvent?.id,
      componentsNoReg: this.publicEvent?.componentsNoReg,
      isLinkAccess: this.publicEvent?.isLinkAccess,
      accessLink: 'test'
    })
      .subscribe(() => {
        if (this.publicEvent?.isEditor && !this.publicEvent?.hasParticipantRole) {
          this.router.navigate([this.backUrl]);
        }
        else {
          this.isEditMode = false;
        }
      });
  }

  // Ссылка назад
  get backUrl() {
    return '/' + this.route.snapshot.url.slice(0, 2).join('/') + '/';
  }
}
