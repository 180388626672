<app-screen-with-panel [title]="'Голосования и опросы'"
                       [bodyTmpl]="bodyTmpl"
                       [backUrl]="backUrl"
                       [isInnerScroll]="true">


  <ng-template #bodyTmpl>

    <app-new-delete-buttons *ngIf="pollings?.isEditor"
                            (onNewClick)="onNewClick()"
    >
    </app-new-delete-buttons>


    <app-nav-list-item *ngFor="let it of pollings?.items; let ix=index" [param]="{it:it}"
                       [navLink]="'/public-events/' + publicEventId + '/polling/' + it.id"
                       [sequenceNo]="(ix+1) + '.'"
                       [textTmpl]="textTmpl">
      <!--topic опроса -->
      <ng-template #textTmpl>
        <div class="topic-tmpl">
          <div class="text-block">
            <div>{{it.topic}}</div>
            <div [innerHTML]="it.dateStr"></div>
            <div appTextEllipsis class="full-text">{{it.fullText}}</div>
          </div>
        </div>
      </ng-template>
    </app-nav-list-item>

  </ng-template>

  <!--Установка делегирования-->
  <ng-template #setProxy>

  </ng-template>

</app-screen-with-panel>
