<app-screen-with-panel [title]="'Материал'" [bodyTmpl]="bodyTmpl" [isEditable]="true"
                       (onEditClick)="isEditMode = true"
                       (onCancelEditClick)="isEditMode = false"
                       [isSaveCancel]="isEditMode"
                       (onSaveClick)="onSaveClick()"
                       [backUrl]="backUrl"
                       (onDeleteClick)="onDeleteClick()"
                       deleteBtnSubject="материал"
                       [isDeleteEnabled]="!!file?.id"
                       >
  <ng-template #bodyTmpl>
    
    <form #fileForm class="form-wrap">
      <mat-form-field>
        <mat-label>Название материала</mat-label>
        <input matInput type="text" [(ngModel)]="file.title" name="title" [readonly]="!isEditMode" required #titleField="ngModel"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Имя файла</mat-label>
        <input matInput readonly type="text" [value]="file.fileName||''"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Расширение файла</mat-label>
        <input matInput readonly type="text" [value]="file.extension||''"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Размер файла</mat-label>
        <input matInput readonly type="text" [value]="file.fileSize||''" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Дата изменения файла</mat-label>
        <input matInput readonly type="text" [value]="formatDate(file.lastModified)" />
      </mat-form-field>
      <!-- загрузка файла -->
      <ng-container *ngIf="isEditMode">
        <input #fileInput type="file" style="display:none;" name="publicEventFileInput" (change)="onFileInputChange($event)" />
        <button mat-raised-button (click)="fileInput.click()">Загрузить файл</button>
      </ng-container>
    </form>
  </ng-template>

</app-screen-with-panel>
