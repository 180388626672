<div #wrapper>
  <app-screen-with-panel [title]="'Фотогалерея'"
                         [bodyTmpl]="bodyTmpl"
                         [isEditable]="false"
                         [backUrl]="backUrl"
                         [isInnerScroll]="true">

    <ng-template #bodyTmpl>
      <app-new-delete-buttons *ngIf="isEditor" (onNewClick)="onNewClick()"></app-new-delete-buttons>
      <div class="grey-notice" *ngIf="!items.length && isEditor">
        Нажмите на «+» внизу экрана чтобы добавить фотографию
      </div>
      <div class="items-wrapper">
        <div id="gallery-id">
          <table style="width: 100%;">
            <tbody *ngFor="let it of items; let index=index">
              <tr *ngIf="index % 3 == 0">
                <td style="width: 33%">
                  <div class="photo-wrapper">
                    <div class="photo" (click)="onFileOpenClick(items[index])">
                      <div><img [src]="safeThumbnail(index)" class="thumbnail" /></div>
                    </div>
                    <div *ngIf="isEditor" class="delete-photo-svg-wrapper"><svg-icon src="assets/crossrnd.svg" (click)="onDeletePhotoItemClick(items[index].id)"></svg-icon></div>
                  </div>
                </td>
                <td style="width: 33%">
                  <div class="photo-wrapper" *ngIf="index + 1 < items.length">
                    <div class="photo" (click)="onFileOpenClick(items[index + 1])">
                      <div><img [src]="safeThumbnail(index + 1)" class="thumbnail" /></div>
                    </div>
                    <div *ngIf="isEditor" class="delete-photo-svg-wrapper"><svg-icon src="assets/crossrnd.svg" (click)="onDeletePhotoItemClick(items[index + 1].id)"></svg-icon></div>
                  </div>
                </td>
                <td style="width: 33%">
                  <div class="photo-wrapper" *ngIf="index + 2 < items.length">
                    <div class="photo" (click)="onFileOpenClick(items[index + 2])">
                      <div><img [src]="safeThumbnail(index + 2)" class="thumbnail" /></div>
                    </div>
                    <div *ngIf="isEditor" class="delete-photo-svg-wrapper"><svg-icon src="assets/crossrnd.svg" (click)="onDeletePhotoItemClick(items[index + 2].id)"></svg-icon></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div id="gallery-footer-id" style="height: 1px;">
          </div>
          <!--<div class="photo-wrapper">
        <div class="photo" (click)="onFileOpenClick(items[index])">
          <div><img [src]="safeThumbnail(index)" /></div>
        </div>
        <div class="delete-photo-svg-wrapper"><svg-icon src="assets/crossrnd.svg" (click)="onDeletePhotoItemClick(items[index].id)"></svg-icon></div>
      </div>-->
        </div>
      </div>

      <!--Панель добавления фотографии-->
      <div class="backdrop" *ngIf="isEditPanelVisible"></div>
      <div class="panel-wrapper position-fixed" *ngIf="isEditPanelVisible">
        <div class="header">
          <div class="text">Фотография</div>
          <div class="icon" (click)="onPanelCloseBtnClick()"><svg-icon src="assets/cross.svg" [applyClass]="true"></svg-icon></div>
        </div>
        <div class="main-content">
          <div class="edit-wrapper">
            <div class="file-load" (click)="onUploadPhotoClick()" *ngIf="!photoExists">
              <div class="icon">
                <svg-icon src="assets/plus.svg" [applyClass]="true"></svg-icon>
              </div>
              <div class="load-text">Загрузить фотографию</div>
            </div>
            <div *ngIf="photoExists && !photoMultiExists" style="margin-top: 20px; margin-bottom: 20px; background-color: #F4F4F7">
              <div class="photo-wrapper">
                <div class="photo">
                  <div style="text-align: center"><img [src]="safePhoto" id="photo-up" /></div>
                </div>
                <div class="delete-photo-svg-wrapper"><svg-icon src="assets/crossrnd.svg" (click)="onClearPhotoClick()"></svg-icon></div>
              </div>
            </div>
            <div *ngIf="photoMultiExists">
              Файлов выбрано: {{photosList.length}}
            </div>
            <mat-form-field *ngIf="!photoMultiExists">
              <mat-label>Автор</mat-label>
              <input matInput [(ngModel)]="photoItem.author" />
            </mat-form-field>
            <mat-form-field *ngIf="!photoMultiExists">
              <mat-label>Название фотографии</mat-label>
              <input matInput [(ngModel)]="photoItem.title" />
            </mat-form-field>
            <div class="btns-row">
              <button (click)="onEditCancelClick()" class="dialog-button action-violet">Отменить</button>
              <button (click)="onSaveClick()" class="dialog-button action-green">Сохранить</button>
            </div>
            <!--Сообщения об ошибках-->
            <div class="mat-error" *ngFor="let msg of getErrorMessages()">{{msg}}</div>
          </div>
        </div>
      </div>
      <input #fileInput type="file" multiple accept="image/*" style="display:none;" name="gallery-file-input" (change)="onFileInputChange($event)" />
      <!--<input #fileMobInput type="file" accept="image/*" style="display:none;" name="gallery-file-mob-input" (change)="onFileInputChange($event)" />-->
    </ng-template>
    <ng-template #snackBarTemplate>
      <!--<div *ngFor="let msg of messages; let index=index">{{msg}}</div>
  <div (click)="dismissSnackbar()">Закрыть</div>-->

      <div class="mat-simple-snackbar ng-star-inserted">
        <div *ngFor="let msg of messages; let index=index">{{msg}}</div>
        <div class="mat-simple-snackbar-action ng-star-inserted" style="margin: -8px -8px -8px 8px;">
          <button mat-button="" class="mat-focus-indicator mat-button mat-button-base" (click)="dismissSnackbar()">
            <span class="mat-button-wrapper">Закрыть</span>
          </button>
        </div>
      </div>
    </ng-template>
  </app-screen-with-panel>

  <div [ngClass]="isFullSize ? 'iframe-wrap-full' : 'iframe-wrap'" *ngIf="!isIFrameHidden">
    <div class="top-bar">
      <div class="cross-icon-wrapper" (click)="onIframeCloseClick()">
        <svg-icon src="assets/cross.svg" viewPort="auto" [applyClass]="true" svgClass="cross-icon"></svg-icon>
      </div>
      <div class="download-icon-wrapper" (click)="downloadButtonClick()">
        <svg-icon src="assets/photo-download.svg" viewPort="auto" [applyClass]="true" svgClass="download-icon"></svg-icon>
      </div>
    </div>
    <!--<div class="middle-bar"></div>-->
    <!--<div class="bottom-bar" *ngIf="!isFullSize"></div>-->
    <div class="inner-frame">
      <div class="img-wrap">
        <img [src]="fileDataUrlSrc" (click)="onImageClick()" />
      </div>
      <div class="button-wrapper" *ngIf="!isFullSize">
        <div class="descr-text-author">{{fileAuthor}}</div>
        <div class="descr-text" style="margin-bottom: 12px;">{{fileTitle}}</div>
        <!--<div style="margin-left: calc(50% - 168px);">-->
        <div style="display: none;">
          <a #downloadButton class="button" [href]="fileDataUrl" [download]="downloadFileName">Скачать фотографию</a>
        </div>
      </div>
    </div>
  </div>
</div>
