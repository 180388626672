import { Injectable, EventEmitter, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription, Observable } from 'rxjs';
// import { registerWebPlugin } from "@capacitor/core";
import { LocalNotifications, NotificationChannel, LocalNotificationSchema } from '@capacitor/local-notifications';
import { ActionPerformed, PushNotifications, PushNotificationSchema, PushNotificationActionPerformed } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Plugins } from '@capacitor/core/';
import { TextZoom } from "@capacitor/text-zoom";
import { Browser } from '@capacitor/browser';
// import { Storage } from '@capacitor/storage';
//!!! import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
// import { SafeArea } from "capacitor-plugin-safe-area";
// const { Device } = Plugins;
import { Utils } from './utils';

// const { BackgroundTask } = Plugins;
// type SafeArea = {
//   top: number,
//   right: number,
//   bottom: number,
//   left: number
// }

@Injectable({
  providedIn: 'root'
})
export class CommonDataService implements OnDestroy {
  NOTIFICATIONS_ENABLED = true;
  onDestroyed = new Subject<boolean>();
  loginId: number = 0;
  baseUrl: string; // базовый URL для backend
  mobileToken: string; // token подписки для мобильного приложения
  notificationInit: boolean = false;
  platformPromise: Promise<string>;
  navigateAction: any;
  platform: "web" | "ios" | "android";
  operatingSystem: any;
  notificationRegistration: EventEmitter<string> = new EventEmitter();


  private buckButtonSubject = new Subject<boolean>();
  public get buckButtondObservable(): Observable<boolean> {
    return this.buckButtonSubject?.asObservable();
  }


  // safeAreaPromise: Promise<SafeArea>;
  // insets: SafeArea = {top: 0, right: 0, bottom: 0, left: 0};
  publicEventsCCS: boolean; // последняя активированная ссылка на список publicEvents была на НСАВ
  constructor(public swPush: SwPush, private router: Router, private swUpdate: SwUpdate, private snackBar: MatSnackBar, private ngZone: NgZone) {
    // Платформа
    this.platformPromise = new Promise<string>(resolve => {
        Device.getInfo().then(info => {
          console.log(`Platform:${info?.platform}. Got device info:${JSON.stringify(info)}`);
          this.platform = info.platform;
          this.operatingSystem = info.operatingSystem;
          resolve(this.platform);
        })
        .catch(err => {
            console.error(`Error getting platform:${err}. Navigator.userAgent:${navigator.userAgent} Navigator.platform:${navigator.platform}`);
            this.platform = 'ios';
            this.operatingSystem = 'ios';
            resolve(this.platform);
        });
    });

    let self = this;
    let Navigate2LocalUrl = (url_: string) => {
      let url = url_.replace("#", "/");

      console.log(`Goto Mobile Norm Local Url: ${url}`);
      if (url.indexOf('public-events') >= 0) {
        url = url.substring(url.indexOf('public-events'));
      }
      else if (url.indexOf('notifications') >= 0) {
        url = url.substring(url.indexOf('notifications'));
      }
      else if (url.indexOf('tch-mailing') >= 0) {
        url = url.substring(url.indexOf('tch-mailing'));
      }
      
      var jwtToken = self.JWTToken;
      if (!jwtToken) {// посмотрим в cookies
        jwtToken = Utils.getCookieByName("JWT");
      }
      if (jwtToken) {
        ngZone.run(() => self.router.navigate([url]));
      }
      else {
        ngZone.run(() => self.router.navigate(['/login', url]));
      }

      
    }


    let Navigate2BrowserUrl = (url_: string) => {
      let url = url_.replace("#", "/");
      console.log(`Goto Mobile Norm Browser Url: ${url}`);
      ngZone.run(() => Browser.open({ url: url }) );
    }



    // Навигация
    this.navigateAction = (url_: string) => {

      let url = url_;
      if (url && url.startsWith("openUrl:")) {
        url = url.substring(url.indexOf(':') + 1);
      }

      if (url) {
        if (this.platform === "web") {

          console.log(`Goto Web Url: ${url}`);

          if (url) {
            if (url.indexOf('public-events') > 0) {
              url = url.substring(url.indexOf('public-events'));
              self.router.navigate([url])
            }
            else if (url.indexOf('tch-mailing') > 0) {
              url = url.substring(url.indexOf('tch-mailing'));
              self.router.navigate([url])
            }
            
            else if (/^https?:/.test(url)) {
              window.open(url, "_blank");
            }
          }
        }
        else {
          if (url.indexOf('public-events') > 0 || url.indexOf('tch-mailing') > 0) {
            Navigate2LocalUrl(url);
          }
          else  {
            Navigate2BrowserUrl(url);
          }
        }
      }
    };


    

    //this.safeAreaPromise = new Promise<SafeArea>(resolve => {
    //    SafeArea.getSafeAreaInsets().then(result => {
    //      console.log(`Got device safe area insets:${JSON.stringify(result)}`);
    //      this.insets = result.insets;
    //      resolve(this.insets);
    //    })
    //    .catch(err => {
    //      console.error(`Error getting safe area insets:${err}. Navigator.userAgent:${navigator.userAgent} Navigator.platform:${navigator.platform}`);
    //      resolve(this.insets);
    //    });
    //});

  

    // baseUrl из body.baseUrl или из текущего
    this.baseUrl = window["APIBaseUrl"];
    if (!this.baseUrl) {// взять из текущего
      this.baseUrl = new window.URL(window.document.location.toString()).origin;
    }
    

    this.platformPromise.then(platform => {
      if (platform === "web") {
        if (!self.notificationInit) {

          self.notificationInit = true;

          if (this.swPush?.isEnabled) {
            this.swPush?.messages
              .pipe(takeUntil(this.onDestroyed))
              .subscribe(msg => {
                console.log('getNotificationMessages', msg);
              });

            this.swPush?.notificationClicks
              .pipe(takeUntil(this.onDestroyed))
              .subscribe((obs) => {
                console.log('getNotificationClicks', obs);
                let _obs = obs;
                if (_obs) {
                  let url = null;
                  if (_obs.action && _obs.action.startsWith("openUrl:")) {
                    url = _obs.action.substring(_obs.action.indexOf(':') + 1);
                  }
                  else if (_obs.notification && _obs.notification.tag && _obs.notification.tag.startsWith("openUrl:")) {
                    url = _obs.notification.tag.substring(_obs.notification.tag.indexOf(':') + 1);
                  }
                  if (url) {
                    if (url.indexOf('public-events') > 0) {
                      url = url.substring(url.indexOf('public-events'));
                      self.router.navigate([url])
                    }
                    else if (url.indexOf('tch-mailing') > 0) {
                      url = url.substring(url.indexOf('tch-mailing'));
                      self.router.navigate([url])
                    }
                    else if (/^https?:/.test(url)) {
                      window.open(url, "_blank");
                    }

                  }
                }
              });
          }

        }


      }
      else {

        if (platform === "android") {
          App.addListener('backButton', ({ canGoBack }) => {
              if (canGoBack && self.buckButtonSubject && self.buckButtonSubject.observers.length > 0) {
                console.log('Navigate to back page');
                self.ngZone.run(() => {
                  self.buckButtonSubject.next(canGoBack);
                });
              }
              else {
                //this.snackBar.open("Вы действительно хотите выйти?", "Да", Utils.snacBarConfirmConfig)
                //  .onAction().subscribe(() => { App.exitApp(); });
                // DEV-35361
                if (!self.JWTToken) {
                  App.exitApp();
                }
                else {
                  self.ngZone.run(() => {
                    this.confirmationDialog.confirm({ message: "Вы действительно хотите выйти?", title: 'Выход', yesText: 'Ok', noText: 'Отмена', duration: 3000, focusInitial: false })
                      .then((confirmed) => {
                        if (!confirmed) return;
                        App.exitApp();
                      });
                  });
                }
              }
          });
        }

  
        // После location.reload будет стерт выбор пользователя
        //var supportsOrientationChange = "onorientationchange" in window,
        //  orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";
        //window.addEventListener(orientationEvent, function () {
        //  window.location.reload(true);
        //  // Navigate2LocalUrl(window.location.href);
        //}, false);

        if (!self.notificationInit) {

          //console.log("LocalNotifications requestPermissions.");
          //LocalNotifications?.requestPermissions().then(permStatus => {
          //  console.log(`LocalNotifications. RequestPermissions permission status:  ${JSON.stringify(permStatus)}`);
          //}).catch(error => { console.error(`LocalNotifications. RequestPermissions error:${JSON.stringify(error)}`); });

          console.log('PushNotifications registration');
          self.notificationInit = true;

          if (platform === "android") {
            const notificationChannel: NotificationChannel = {
              id: 'pop-notifications',// id must match android/app/src/main/res/values/strings.xml's default_notification_channel_id
              name: 'Pop notifications',
              description: 'Pop notifications',
              importance: 5,
              visibility: 1
            };
            LocalNotifications.createChannel(notificationChannel);

            LocalNotifications.registerActionTypes({
              types: [
                {
                  id: 'EVENT_ACTION',
                  actions: [
                    {
                      id: 'url',
                      title: 'Перейти'
                    }
                  ]
                }
              ]
            });

          }
          
               

    


          // Подписаться на mobile PushNotifications
          PushNotifications.addListener(
            'registration',
            (token) => {
              console.log('My token: ' + JSON.stringify(token));
              self.mobileToken = token?.value;
              if (self.notificationRegistration && this.mobileToken) {
                self.notificationRegistration.emit(this.mobileToken);
              }
            }
          );
          PushNotifications.addListener('registrationError',
            (error: any) => {
              alert('Error on registration: ' + JSON.stringify(error));
            }
          );


          PushNotifications.addListener('pushNotificationReceived', (msg: PushNotificationSchema) => {

            try {
              

              let url: string = '';
              if (msg.data?.action && msg.data?.action.startsWith("openUrl:")) {
                url = msg.data?.action.substring(msg.data?.action.indexOf(':') + 1);
              }
              else {
                url = msg.data?.action;
              }

              console.log(`Push notification received. Title:${msg.title}. Body:${msg.body}. Url:${url}. Msg:${JSON.stringify(msg)}`);

              if (platform === 'android') {
                LocalNotifications.schedule({
                  notifications: [
                    {
                      title: `Local ${msg.title}`,
                      body: msg.body,
                      id: Date.now(),
                      schedule: { at: new Date(Date.now() + 1000 * 5) },
                      channelId: 'pop-notifications',
                      iconColor: '#0000FF',
                      sound: null,
                      attachments: null,
                      actionTypeId: 'EVENT_ACTION',
                      extra: { data: url }

                    }
                  ]
                }).catch(error => {
                  console.error(`LocalNotifications. Schedule error:${JSON.stringify(error)}`);
                });
              }

              //else {
              //  LocalNotifications.schedule({
              //    notifications: [
              //      {
              //        title: `Local ${msg.title}`,
              //        body: msg.body,
              //        id: Date.now(),
              //        schedule: { at: new Date(Date.now() + 1000 * 5) },
              //        iconColor: '#0000FF',
              //        sound: null,
              //        attachments: null,
              //        actionTypeId: 'EVENT_ACTION',
              //        extra: { data: url }
              //      }
              //    ]
              //  }).catch(error => {
              //    console.error(`LocalNotifications. Schedule error:${JSON.stringify(error)}`);
              //  });
              //}
           

            }
            catch (exc) {
              console.error('Error on LocalNotifications schedule: ' + JSON.stringify(exc));
            }
            
          });

          PushNotifications.addListener("pushNotificationActionPerformed", (notificationAction: ActionPerformed) => {
            
            console.log(`pushNotificationActionPerformed. data:${JSON.stringify(notificationAction)}`);

            if (notificationAction.notification.data.action) {

              var url = notificationAction.notification.data.action;
              if (url.indexOf('public-events') < 0 && url.indexOf('tch-mailing') < 0 ) {
                url = "notifications";
              }
              Navigate2LocalUrl(url);
              
            }
            
           
            //PushNotifications.getDeliveredNotifications()
            //  .then(delivered => {
            //    if (delivered) {
            //      delivered.notifications.forEach(msg => console.log(`Delivered message.Title:${msg.title}`));
            //    }
            //  });
              
          });

          LocalNotifications?.addListener("localNotificationReceived", (nfy) => {
            console.log(`localNotificationReceived. ${JSON.stringify(nfy)}`);
            //if (nfy.extra.data) {
            //  Navigate2LocalUrl(nfy.extra.data);
            //}

          });
          LocalNotifications?.addListener("localNotificationActionPerformed", (nfy) => {
            console.log(`localNotificationActionPerformed. ${JSON.stringify(nfy)}`);
            if (nfy?.actionId === 'url' && nfy?.notification?.extra?.data) {

              var url = nfy.notification.extra.data;
              if (url.indexOf('public-events') < 0 && url.indexOf('tch-mailing') < 0) {
                url = "notifications";
              }

              Navigate2LocalUrl(url);

              //!!!
              //SecureStoragePlugin.get({
              //  key: this.localStorageTokenKey,
              //}).then(x => {
              //    if (x && x.value) {
              //      this.JWTToken = x.value.split('').reverse().join('');
              //      Navigate2LocalUrl(nfy.notification.extra.data);
              //      console.log(`Storage get successful.`);
              //    }
              //  })
              //  .catch(error => console.error(`Storage get error:${JSON.stringify(error)}`));
                
            }

            //if (nfy.notification.actionTypeId) {
            //  Navigate2LocalUrl(nfy.notification.actionTypeId);
            //}
          });

        }

        if (TextZoom) {
          console.log(`TextZoomPlugin defined. Get() is:${JSON.stringify(TextZoom.get())} getPreferred() is:${JSON.stringify(TextZoom.getPreferred())}`);
          TextZoom.set({ value: 1. });
        }

      }
   

    });
 
  }


  ngOnDestroy(): void {
    this.onDestroyed.next(true);
    this.onDestroyed.complete();
  }

  // vConsole: any;
  JWTToken: string = <any>null;
  localStorageTokenKey = "jwttoken";
  isSpinnerVisibleEvt = new EventEmitter<boolean>();
  confirmationDialog: ConfirmationDialogComponent;
  // VAPIDPublicKey = "BOGg6X2yTlgh-lDIfi9qopmjcKjp7hwdIyOMZQedZS0HcQJUG6aHT91daAF5Y6I1sOc1dIvFfOuEpc6vNWRcUNQ";
  VAPIDPublicKey = "BMujiDTNNlzLM02bJ1mfi77hvulyrlCGv8qStiyBZyimr_sSDiKcjtgAUa7y2tKQBAIwdKECivVr60vFgzXfEoc";

  //TCHLinkEditBackUrl: string; // куда возвращаться из /tch-link/edit
}
