import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../../utils';
import { PublicEventVoteComponent } from '../public-event-vote/public-event-vote.component';

@Component({
  selector: 'app-public-event-vote-result',
  templateUrl: './public-event-vote-result.component.html',
  styleUrls: ['./public-event-vote-result.component.less']
})
export class PublicEventVoteResultComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient) { }
  defaultShowNamesCt = 5;
  ngOnInit(): void {
    if (history.state?.data?.questions) {
      this.questions = history.state.data.questions;
      this.question = history.state.data.question;
      this.namesToSummary();
    }
    else {
      PublicEventVoteComponent.GetQuestions(this.activatedRoute, this.http)
        .subscribe((data) => {
          this.questions = data;
          this.activatedRoute.params.subscribe(params => {
            let questionId = params["questionId"];
            this.question = this.questions.items.find(q => q.id == questionId);
            if (!this.question) {
              console.error(`Invalid questionId:${questionId} pollingId:${this.questions.polling.id}`);
            }
            else {
              this.namesToSummary();
            }
          });
        });
    }
  }
  questions: Questions;
  question: Question;

  // распихать имена под summaryResults
  namesToSummary() {
    if (!this.question) return;
    this.question.summaryResults.forEach(sr => {
      sr.names = [];
      sr.showNamesCt = this.defaultShowNamesCt;
    });
    this.question.allVotes.filter(v => !this.questions.uncompletedUsers.includes(v.userAccountId))
      .forEach(v => {
      let sr = this.question.summaryResults.find(s => s.vote == v.voting);
      if (sr) {
        sr.names.push({
          name: v.fullName,
          userAccountId: v.userAccountId,
          userCompanyName: v.userCompanyName,
          isHistoryAvl: v.isHistoryAvl,
          isProxyVote: v.proxyUserAccountId && v.proxyUserAccountId != v.userAccountId,
          navLink: `/public-events/${this.questions?.polling.publicEventId}/polling/${this.questions?.polling.id}/vote-history/${this.question?.id}/${v.userAccountId}`
        });
      }
    });
  }


  // url на верхний уровень
  get backUrl():string {
    //   { path: "public-events/:publicEventId/polling/:pollingId/:ixTab", component: PublicEventQuestionComponent },
    return `public-events/${this.questions?.polling.publicEventId}/polling/${this.questions?.polling.id}/1`;
  }
  // Падеж для "голоса"
  getVoicesDecl(ct: number) {
    return Utils.NumDecl(ct, ['голос', 'голоса', 'голосов']);
  }

  
  // Показывать имена
  get isShowVoteNames(): boolean {
    return this.questions?.isEditor || this.questions?.polling.isShowVoteNames;
  }
  onToggleNamesClick(res) {
    if (this.isShowVoteNames) {
      res.isExpanded = !res.isExpanded;
      if (!res.isExpanded) {
        res.showNamesCt = this.defaultShowNamesCt;
      }
    }
  }
}
