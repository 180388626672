import { Component, Input, OnInit, Output, EventEmitter, HostListener, ElementRef, ViewChildren, QueryList } from '@angular/core';
import * as moment from 'moment';
import { pipe, Subject, timer } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.less']
})
export class TimePickerComponent implements OnInit {
  nElsMargin = 2; //элементов сверху и снизу
  static _hhItems :string[] = [];
  static _mmItems :string[] = [];
  constructor() {
    TimePickerComponent.allInstances.push(this);
    // проинициализирвать static списком часов и минут
    if (!TimePickerComponent._hhItems.length) {
      for (var i = 0; i <= 23; i++) {
        TimePickerComponent._hhItems.push(("0" + i).substring(i <= 9 ? 0 : 1))
      }
      this.pushExtraEls(TimePickerComponent._hhItems);
      for (var i = 0; i <= 59; i++) {
        TimePickerComponent._mmItems.push(("0" + i).substring(i <= 9 ? 0 : 1))
      }
      this.pushExtraEls(TimePickerComponent._mmItems);
    }
  }
  // добавить пустые элементы до и после
  pushExtraEls(arr: any[]) {
    for (var i = 0; i < this.nElsMargin; i++) {
      arr.push(-1);
      arr.unshift(-1);
    }
    arr.push(-1);
  }
  static allInstances: TimePickerComponent[] = [];

  ngOnInit(): void {
  }

  @Input()
  editVal;

  @Output()
  editValChange = new EventEmitter<any>();

  @Input()
  defaultValue;

  isHHActive: boolean;
  isMMActive: boolean;
  isDlgActivated: boolean;

  // click вне нас
  @HostListener("window:click")
  onOutClick() {
    TimePickerComponent.allInstances.forEach(i => {
      i.isHHActive = false;
      i.isMMActive = false;
    });
  }
  elHeight = 20;
  listEl: HTMLDivElement;
  onHHFocus(ev: Event, isHH:boolean) {
    this.onOutClick();
    if (isHH) {
      this.isHHActive = true;
    }
    else {
      this.isMMActive = true;
    }
    var v = moment(this.editVal, "HH.mm");
    if (!v.isValid()) v = moment(new Date());
    var hh = v.hour();
    var mm = v.minute();
  
    setTimeout(() => {
      if ((<HTMLDivElement>ev.target)?.nextElementSibling) {
        this.listEl = <any> (<HTMLDivElement>ev.target)?.nextElementSibling;
      }
      var oneElHeight = (<HTMLDivElement>ev.target)?.nextElementSibling?.scrollHeight / ((isHH ? 24 : 60) + this.nElsMargin * 2);
      this.elHeight = oneElHeight || this.elHeight;
      var scrollY = oneElHeight * ((isHH ? hh : mm));
      (<HTMLDivElement>ev.target)?.nextElementSibling?.scrollTo(0, scrollY);
    });
    ev.stopPropagation();
  }
  get hhItems(): string[] {
    if (this.isDlgActivated) return TimePickerComponent._hhItems;
    return [];
  }
  get mmItems(): string[] {
    if (this.isDlgActivated) return TimePickerComponent._mmItems;
    return [];
  }

  @ViewChildren("listWrap")
  listWraps: QueryList<ElementRef>;

  // открыть popup
  showDialog() {
    let v = moment(this.editVal, "HH.mm");
    if (!v.isValid() && this.defaultValue) {
      v = moment(this.defaultValue);
      this.editVal = v;
      this.editValChange.emit(v);
    }

    this.isDlgActivated = true;
    if (this.listWraps?.length == 2) { 
      setTimeout(() => {
        this.setListScroll(this.listWraps.get(0).nativeElement, Number(this.hh));
        this.setListScroll(this.listWraps.get(1).nativeElement, Number(this.mm));
      });
    }
  }

  // Установка scroll для списка
  setListScroll(lst: HTMLElement, val: number) {
    let list = <HTMLDivElement>lst;
    let nChildren = list.childElementCount;
    var oneElHeight = list.scrollHeight / nChildren;
    this.elHeight = oneElHeight;
    list.scrollTo(0, oneElHeight * val + 1);
  }


  scrollSubj: Subject<{ ev: Event, isHH: boolean, self: TimePickerComponent }> =
    <any>new Subject<{ ev: Event, isHH: boolean, self: TimePickerComponent }>()
      .pipe(debounceTime(50))
     ;
  scrollSubs = this.scrollSubj.subscribe(
    (prm) => {
      setTimeout(() => {
        let ev = prm.ev;
        let isHH = prm.isHH;
        let self = prm.self;
        let listEl = <HTMLDivElement>ev.target;
        let scrollPos = listEl.scrollTop;
        let elHeight = listEl.scrollHeight / listEl.childElementCount;
        let elIx = Math.floor(scrollPos / elHeight);
        if (elIx >= 0) {
          console.debug("scrollPos:" + scrollPos + " elX:" + elIx);
          let child = listEl.childNodes.item(elIx + self.nElsMargin );
          if (child) {
            let val = (<HTMLElement>child).innerText;
            if (!isNaN(Number(val))) {
              console.debug("setting scroll current val:" + val, child);
              self.onElClick(val, isHH, listEl, false);
            }
          }
        }
      }, 40);
    })
  ;
  // Scroll в списке сделать средний элемент текущим
  onListScroll(ev: Event, isHH: boolean) {
    this.scrollSubj.next({ev: ev, isHH: isHH, self: this} );
  }
  // относительная позиция списка top.px
  getListTopPx(isDlg: boolean): number {
    if (isDlg) return 0;
    return -this.elHeight * this.nElsMargin;
  }

  get hh(): string {
    let v = moment(this.editVal, "HH.mm");
    if (!this.editVal || !v.isValid()) return "00";
    return v.format("HH");
  }

  get mm(): string {
    let v = moment(this.editVal, "HH.mm");
    if (!this.editVal || !v.isValid()) return "00";
    return v.format("mm");
  }
  // Выбран элемент
  onElClick(val, isHH: boolean, listRef: HTMLDivElement, dontSetScroll?: boolean) {
    if (val < 0) return;
    let v = moment(this.editVal, "HH.mm");
    if (!v.isValid()) v = moment(this.defaultValue || new Date());
    if (isHH) {
      v.hour(val);
      setTimeout(() => this.isHHActive = false);
    }
    else {
      v.minute(val);
      setTimeout(() =>this.isMMActive = false);
    }
    if (!dontSetScroll) {
      this.setListScroll(listRef, val);
    }
    this.editVal = v;
    this.editValChange.emit(v);
  }
  getItemHtml(i) {
    return (i + 0) >= 0 ? i : '&nbsp;&nbsp;';
  }

  hhKbState = { pos: 0 };
  mmKbState = { pos: 0 };

  // обработчик клавиатуры
  @HostListener("document:keydown", ['$event'])
  onKey(ev: KeyboardEvent) {
    if (!this.isHHActive && !this.isMMActive) return;
    var ch = ev.key;
    if (isNaN(Number(ch))) return;
    var kbState = this.isHHActive ? this.hhKbState : this.mmKbState;
    var rangeHH = [0, 23];
    var rangeMM = [0, 59];
    var range: number[];
    if (this.isHHActive) {
      range = rangeHH;
    }
    else {
      range = rangeMM;
    }
    var v = moment(this.editVal, 'HH.mm');
    if (!v.isValid()) v = moment(new Date());
    var vv: number = this.isHHActive ? v.hours() : v.minutes();
    if (kbState.pos) { // вторая цифра
      vv = Math.round(vv / 10)*10 + Number(ch);
    }
    else { // поставить первую цифру 
      vv = (vv - Math.round(vv / 10) * 10) + Number(ch) * 10;
    }
    if (vv >= range[0] && vv <= range[1]) {
      if (this.isHHActive) {
        v.hours(vv);
      }
      else {
        v.minutes(vv);
      }
      this.editVal = v;
      this.editValChange.emit(v);
      if (this.listEl) {
        setTimeout(() => {
          var scrollY = this.elHeight * vv;
          this.listEl.scrollTo(0, scrollY);
        });
      }
      kbState.pos++;
      if (kbState.pos > 1) kbState.pos = 0;
    }
  }
}
