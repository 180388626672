<app-screen-with-panel [title]="'Участники'" [bodyTmpl]="userListTmpl" (onBackClicked)="onBackClicked()">


  <ng-template #userListTmpl>
    <app-public-event-user-list [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="false" [showIcon]="true" [showUserDetailsOnClick]="true">

    </app-public-event-user-list>
  </ng-template>


</app-screen-with-panel>
