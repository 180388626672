<div class="top-level-screen" appHeight100vh>
  <div class="screen-top-icon-bar">
    <div [matBadge]="unreadNotificationsCount" style="display:inline-block;">
      <svg-icon src="assets/bell.svg" [applyClass]="true" [svgClass]="isAccess ? 'svg-class' : 'svg-class-disable'" (click)="onNotificationsClick()" viewBox="auto"></svg-icon>
    </div>
    <svg-icon src="assets/question-circle.svg" [applyClass]="true" [svgClass]="isAccess ? 'svg-class' : 'svg-class-disable'" (click)="onHelpClick()"></svg-icon>
    <svg-icon src="assets/user-profile.svg" [applyClass]="true" [svgClass]="isAccess ? 'svg-class' : 'svg-class-disable'" (click)="onUserProfileClick()"></svg-icon>
  </div>
  <div class="screen-body">
    <div class="tch-icon">
      <svg-icon src="assets/tch-logo.svg" [svgStyle]="{ 'width.px':'113', 'height.px':'36' }"></svg-icon>
    </div>
    <div class="menu-wrap">
      <app-menu-big-button icon="нсав" text="НСАВ" path="/public-events-ccs" *ngIf="hasCCSAccess" (click)="onClickedPublicEvents(true)">
      </app-menu-big-button>
      <app-menu-big-button icon="events" text="Мероприятия" path="/public-events" *ngIf="hasPublicEventsAccess"
                           (click)="onClickedPublicEvents(false)">
      </app-menu-big-button>
      <app-menu-big-button icon="tch-link" text="TCH-Link" path="/tch-link" *ngIf="hasTCHLinkAccess"></app-menu-big-button>
      <app-menu-big-button icon="tch-mailing" text="Официальные рассылки ТКП" path="/tch-mailing" *ngIf="hasTCHMailingAccess"></app-menu-big-button>
    </div>
    <div class="padding-space"></div>
  </div>
</div>
