import { MatSnackBarConfig } from '@angular/material/snack-bar';
import * as moment from "moment";
import "moment/locale/ru.js";

moment.locale("ru");

export class Utils {
  // Склонение числительных
  // NumDecl(ct, ["продукт", "продукта", "продуктов"])
  static NumDecl(number, titles:string[]) {
    var cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
  };

  static formatDateLong(dt) {
    if (!dt) return "";
    return moment(dt).format("DD MMMM yyyy, dd, HH:mm");
  }
  static formatDate(dt) {
    if (!dt) return "";
    return moment(dt).format("DD.MM.yyyy");
  }

  static moment2Date(obj: any, ...fieldNames: string[]): void {
    fieldNames.forEach(i => {
      if (!obj[i]) {
      }
      else if (moment.isMoment(obj[i])) {
        obj[i] = obj[i].toDate();
      }
      else if (typeof obj[i] == "string") {
        obj[i] = moment(obj[i]).toDate();
      }
      else if (typeof(obj[i].getFullYear) == 'function') {
      }
      else {
        obj[i] = null;
      }
    });
  }

  static snacBarConfig: MatSnackBarConfig = { panelClass: "snackbar-error"/*, duration:4000*/ };

  static snacBarInfoConfig: MatSnackBarConfig = { panelClass: "snackbar-info" };

  static snacBarConfirmConfig: MatSnackBarConfig = { panelClass: "snackbar-confirm", duration: 3 * 1000, verticalPosition: 'bottom'  };



  // Недопустимый диапазон дат
  static isDateRangeInvalid(from: any, to: any): boolean {
    if (!from || !to) return false;
    let dtFrom = moment(from);
    let dtTo = moment(to);
    return !dtFrom.isSameOrBefore(dtTo);
  }

  // HTML - в текст
  static HTML2Text(html: string): string {
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText;
  }

  // добавить cookie
  static setCookieByName(name: string, value: string) {

    let cookies = document.cookie;
    if (cookies) {
      let p1 = (";" + cookies + ";").indexOf(name + '=');
      if (p1 < 0) {
        document.cookie = cookies + ";" + name + "=" + value;
      }
    }
    

  }

  // cookie по имени
  static getCookieByName(name: string): string {
    let cookies = document.cookie;
    let ret = '';
    if (cookies) {
      cookies = ";" + cookies + ";";
      let kw = name + '=';
      let p1 = cookies.indexOf(kw);
      if (p1 > 0) {
        let p2 = cookies.indexOf(';', p1);
        if (p2 > p1) {
          ret = cookies.substring(p1 + kw.length, p2);
        }
      }
    }
    return ret;
  }


  // array buffer to base64
  static arrayBufferToBase64(buffer: any) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  static cloneDeep<T>(o: T): T {
    return <T> JSON.parse(JSON.stringify(o));
  }
}

