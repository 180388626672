import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicEventUserListComponent } from '../public-event-user-list/public-event-user-list.component';

@Component({
  selector: 'app-public-event-participants',
  templateUrl: './public-event-participants.component.html',
  styleUrls: ['./public-event-participants.component.less']
})
export class PublicEventParticipantsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(prm => {
      this.publicEventId = prm["id"];
    });
  }

  ngAfterViewInit() {
  }

  publicEventId: number;

 
  onBackClicked() {
    if (this.userListComponent) {
      if (this.userListComponent.tmpl == this.userListComponent.userListTmplRef)
        this.router.navigate([`/public-events/${this.publicEventId}`]);
      else {
        this.userListComponent.backToUserList();
      }
    }
  }

  @ViewChild(PublicEventUserListComponent)
  userListComponent: PublicEventUserListComponent;
}
