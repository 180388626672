
<ng-template #confirmationTmpl>
  <h2 mat-dialog-title>{{title}}</h2>
  <mat-dialog-content>
    {{confirmationText}}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close class="dialog-button action" *ngIf="isMessageBox">Продолжить</button>

    <ng-container *ngIf="!isMessageBox">
      <!--class="confirm-dialog-btn-row"-->
      <div *ngIf="focusInitial">
        <button mat-button mat-dialog-close class="dialog-button cancel">{{noText}}</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="dialog-button action">{{yesText}}</button>
      </div>
      <div *ngIf="!focusInitial">
        <button mat-button  tabindex="-1" mat-dialog-close class="dialog-button cancel">{{noText}}</button>
        <button mat-button  tabindex="-1" [mat-dialog-close]="true" class="dialog-button action">{{yesText}}</button>
      </div>
    </ng-container>

  </mat-dialog-actions>
</ng-template>
