
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';

@Component({
  selector: 'app-public-event-polling-set-proxy',
  templateUrl: './public-event-polling-set-proxy.component.html',
  styleUrls: ['./public-event-polling-set-proxy.component.less']
})
export class PublicEventPollingSetProxyComponent implements OnInit {

  constructor(private route: ActivatedRoute, private http: HttpClient, private common: CommonDataService, private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(p => {
      this.publicEventId = p["publicEventId"];
      this.pollingId = p["pollingId"];
      // прочитать текущую установку proxy
      this.http.get<SetProxy>(AjaxMethods.Proxy.replace("{id}", this.pollingId+'').replace("{principalId}", "")).subscribe(data => {
        if (data) {
          for (var v in data) {
            this[v] = data[v];
          }
          // если не установлено, дату = сегодня
          if (!this.proxyUserLogin_id) {
            this.paDate = new Date();
          }
          this.isProxySet = Boolean(this.proxyUserLogin_id);
        }
      });
    });
  }

  proxyUserLogin_id: number;
  proxyName = "";
  paNumber = "";
  paDate = new Date();
  isVoteFound = false;
  hasVotingBegun = false;
  userLoginId: number;
  votingBeginDateStr: string;

  publicEventId: number;

  getPublicEventId() { return this.publicEventId; }
  pollingId: number;
  isProxySet: boolean;// делегирование установлено. Можно только снять

  get backUrl(): string{
    return `public-events/${this.publicEventId}/polling/${this.pollingId}`;
  }
  isSaveCancel = false;
  tmplBody: TemplateRef<any>;
  tmplSelectPerson: TemplateRef<any>;
  tmplMain: TemplateRef<any>;
  // Выбрали, proxy person
  onSelectPerson(tmplSelectPerson: TemplateRef<any>, tmplMain: TemplateRef<any>) {
    this.tmplSelectPerson = tmplSelectPerson;
    this.tmplMain = tmplMain;
    this.tmplBody = this.tmplSelectPerson;
    this.isSaveCancel = true;
  }

  toMainPanel() {
    this.tmplBody = this.tmplMain;
    this.isSaveCancel = false;
  }

  onCancelEdit() {
    this.toMainPanel();
  }
  
  //---- Сохранить
  onSaveClick(...flds: NgModel[]) {
    if (flds.every(f => f.valid) && this.proxyUserLogin_id) {
      this.http.post<any>(AjaxMethods.Proxy.replace("{id}", this.pollingId + ''),
        <SetProxy>{
          pollingId: Number(this.pollingId),
          paDate: moment(this.paDate).toDate(),
          paNumber: this.paNumber,
          proxyUserLogin_id: this.proxyUserLogin_id,
        })
        .subscribe(() => this.navigateBack());
    }
    else {
      flds.forEach(f => f.control.markAsTouched());
    }
  }
  //Выбрали proxy
  userClick(u: UserAttrs) {
    console.debug('UserClick', u);
    this.proxyName = u.name;
    this.proxyUserLogin_id = u.loginId;
    this.onCancelEdit();
  }

  // вернуться на уровень назад - по backUrl
  navigateBack() {
    this.router.navigate([this.backUrl]);
  }
  
  // Отозвать делегирование
  onRevokeClick() {
    this.common.confirmationDialog.confirm({ message: 'Отозвать делегирование?', title: 'Отзыв делегирования', noText: 'Отменить', yesText: 'Отозвать' })
      .then(yes => {
        if (yes) {
          this.http.delete<any>(AjaxMethods.Proxy.replace('{id}', this.pollingId + '')).subscribe(() => this.navigateBack() );
        }
      });
  }
  formatDate = Utils.formatDate;
}
