<app-screen-general header="Уведомления TCH-Link" [backUrl]="backUrl">
  <!--Уведомлять о размере управляемой суммы-->
  <app-nav-list-item text="Уведомлять о размере управляемой суммы" navLink="tch-link/edit/shdl" [isTopBorder]="true" [param]="{backUrl: backUrl}">
  </app-nav-list-item>

  <div class="nfy">
    <div class="cbx-wrapper">
      <div class="txt">Уведомлять о достижении порогового значения управляемой суммы</div>
      <div class="cbx">
        <mat-slide-toggle [checked]="isNotifyThreshold" (change)="onNotifyThresholdChnage()" [color]="'primary'"></mat-slide-toggle>
      </div>
    </div>
    <div class="notes" *ngIf="isAmount">
      Установлено пороговое значение <br />{{thresholdAmountFmt}}.
      <br />Изменить значение можно в Личном кабинете.
    </div>
    <div class="notes" *ngIf="!isAmount">
      Пороговое значение - отсутствует.<br />Изменить значение можно в Личном кабинете.
    </div>
  </div>
</app-screen-general>
