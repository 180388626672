import { HttpClient } from '@angular/common/http';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { ApplicationRef, Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';
import { switchMap } from 'rxjs/operators';



// Режимы отображения
enum DisplayMode {
  list,
  edit,
  repeate,
  recipient
}
@Component({
  selector: 'app-public-event-notification',
  templateUrl: './public-event-notification.component.html',
  styleUrls: ['./public-event-notification.component.less']
})
export class PublicEventNotificationComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private title: Title, private commonData: CommonDataService, private dialog: MatDialog, private appRef: ApplicationRef) {
      title.setTitle(this.mainTitle);
  }

  tmpl: TemplateRef<any>; // текущий шаблон
  @ViewChild("tmplList")
  tmplList: TemplateRef<any>;
  @ViewChild("tmplEditItem")
  tmplEditItem: TemplateRef<any>; // редактирование уведомления
  @ViewChild("tmpRepeate")
  tmplRepeate: TemplateRef<any>; // настройка повторов
  @ViewChild("tmplRecipient")
  tmplRecipient: TemplateRef<any>; // панель просмотра данных подписчика


  mainTitle = "Уведомления";
  publicEventId: number;
  isEditor: boolean;
  isShowError: boolean = false;
  items: PublicEventNotificationItem[] = [];

  isSaveCancel = false;
  isEditable = false;

  minDate: Date;
  maxDate: Date;
  mode: DisplayMode = DisplayMode.list;

  // Группы уведомлений
  notificationGroups: { id: boolean, name: string }[];
  // Типы уведомлений
  notificationSendTypes: { id: number, label: string, title: string }[];
  // Типы получателей уведомлений
  notificationMemberTypes: { id: number, label: string, title: string }[];
  // Частота уведомлений
  notificationFrequencyTypes: { id: number, label: string, title: string }[];
  // Режимы отображения для переключения
  tmplMode: { mode: DisplayMode, tmpl: TemplateRef<any>, isSaveCancel: boolean, isEditable: boolean, title: string }[] = [];


  
  

  ngOnInit() {

    this.notificationGroups = [
      { id: false, name: "Запланированные" },
      { id: true, name: "Отправленные" }
    ];


    this.notificationSendTypes = [
      { id: 0, label: "Сразу после создания", title: "Сразу после создания" },
      { id: 1, label: "В определенное время", title: "В определенное время" }
    ];

    this.notificationMemberTypes = [
      { id: 0, label: "Всем", title: "Всем, имеющим доступ к мероприятию" },
      { id: 1, label: "Организаторам", title: "Организаторам" },
      { id: 2, label: "Участникам", title: "Участникам" },
      { id: 3, label: "Выборочно", title: "Выборочно" }
    ];


    this.notificationFrequencyTypes = [
      { id: 0, label: "Каждые 15 минут", title: "Каждые 15 минут" },
      { id: 1, label: "Каждый час", title: "Каждый час" },
      { id: 2, label: "Каждый день", title: "Каждый день" },
      { id: 3, label: "Каждую неделю", title: "Каждую неделю" },
      { id: 4, label: "Каждый месяц", title: "Каждый месяц" }
    ];


    // Зачитываем список
    //this.route.paramMap.pipe(
    //  switchMap(params => params.getAll('id'))
    //).subscribe(data => {
    //      this.publicEventId = +data
    //});
    this.route.params.subscribe(prm => {
      // this.publicEventId = Number(prm["id"]);
      this.publicEventId = +prm["id"];
      this.http.get<PublicEventNotification>(AjaxMethods.PublicEventNotification.replace("{id}", this.publicEventId + ''))
        .subscribe(data => {
          if (data) {
            let now = moment(new Date());
            this.isEditor = data.isEditor;
            // this.minDate = data.minDate,
            this.minDate = now.toDate();
            this.maxDate = data.maxDate;
            this.items = data.items;

            this.appRef.tick();//!!!! провести Change Detection, чтобы DOM перестроился.
          }
        });
    });
  }

  ngAfterViewInit() {
  
    this.isShowError = false;
    this.tmplMode = [
      { mode: DisplayMode.list, tmpl: this.tmplList, isSaveCancel: false, isEditable: false, title: "Уведомления" },
      { mode: DisplayMode.edit, tmpl: this.tmplEditItem, isSaveCancel: true, isEditable: false, title: "Уведомления" },
      { mode: DisplayMode.repeate, tmpl: this.tmplRepeate, isSaveCancel: false, isEditable: false, title: "Повтор уведомления" },
      { mode: DisplayMode.recipient, tmpl: this.tmplRecipient, isSaveCancel: false, isEditable: false, title: "Подписчик" },
    ]
  }

  findItemOfType(isSent: boolean) {

    return (this.items.find(x => x.isSent == isSent) !== undefined)

  }



  filterItemsOfType(isSent: boolean) {
    var itms = this.items.filter(x => x.isSent == isSent);
    if (itms?.length == 0 && !isSent) {
      itms.push(Object.assign(<PublicEventNotificationItem>{}, <any>{ message: 'Отсутствуют запланированные', id: 0, isSent: false, recipients: [] }));
    }
    return itms;

  }
    

  getItemSubjectFontWeight(it: PublicEventNotificationItem) {
    return "400";
  }

  radioChange($event: MatRadioChange) {
    console.log($event.source.name, $event.value);

    if ($event.source.id === 'send-radio-group') {
      if ($event.value === 1) {

      }
      
    }
  }


  // переключение режима
  switchMode(mode: DisplayMode) {
    var tm = this.tmplMode.find(t => t.mode == mode);
    if (!tm) throw new Error(`Invalid mode:${mode}`);
    this.tmpl = tm.tmpl;
    this.isEditable = tm.isEditable;
    this.isSaveCancel = tm.isSaveCancel;
    if (tm.title) this.mainTitle = tm.title;
    this.mode = mode;

    // this.appRef.tick();
  }

  // Кнопка "назад"
  onBackClick() {
    switch (this.mode) {
      case DisplayMode.edit:// к списку
        this.switchMode(DisplayMode.list);
        this.appRef.tick();
        break;
      case DisplayMode.list: // на уровень вверх
        this.router.navigate([`/public-events/${this.publicEventId}`]);
        break;
      case DisplayMode.repeate: // к редактированию уведомления
      case DisplayMode.recipient: // к редактированию уведомления
        this.isMessageFirstInput = true;
        this.switchMode(DisplayMode.edit);
        break;
    }
  }


  // объект для редактирования
  editItem: PublicEventNotificationItem;

  // Добавить новое уведомление
  onNewClick() {

    let now = moment(new Date());
    this.minDate = now.toDate();

    //this.editItem = <any>{ isSent: false, sendType: 0, memberType: 0, repeatFrequency: 2, repeatCount: 1, message: "Сообщение", recipients: [] };
    this.editItem = <any>{ isSent: false, sendType: 0, memberType: 0, repeatFrequency: 2, repeatCount: 1, message: "", recipients: [] };
    this.isMessageFirstInput = true;
    this.switchMode(DisplayMode.edit);
  }

  // редактирование уведомления
  onEditItemClick(it: PublicEventNotificationItem) {

    if (it.id > 0) {
      this.editItem = it;
      if (this.isEditor) {
        this.isMessageFirstInput = true;
        this.switchMode(DisplayMode.edit);
      }
    }
    
  }

  // Сохранить пункт программы
  onSave(): Promise<void> {
    return new Promise<void>((resolve) => {

      this.isShowError = true;
      if (this.getErrorMessages(true).length) return;

      this.http.post<any>(AjaxMethods.PublicEventNotification.replace('{id}', this.publicEventId + ''),
        this.editItem).subscribe(d => {
          if (this.editItem.isSent) {
            // Копия
            this.editItem = Object.assign(<PublicEventNotificationItem>{}, this.editItem, <any>{ id: 0, isSent: false});
          }

          var isNew = !this.editItem.id;
          if (d.newId) {
            this.editItem.id = d.newId;
            Object.assign(this.editItem, d);
          }
          if (isNew) {
            this.items.push(this.editItem);
            this.items = this.items.sort((a, b) =>
              (a.id == b.id) ? 0
                : (a.id < b.id) ? -1
                  : 1);
          }

          this.switchMode(DisplayMode.list); // к просмотру
          this.appRef.tick();
          resolve();
        });
    });
  }

  onCancelEdit() {
    this.switchMode(DisplayMode.list);
  }
  get isDeleteEnabled(): boolean {
    return Boolean(this.editItem?.id && !this.editItem?.isSent);
  }

  get btnSaveName(): string {

    if (this.editItem && this.editItem.isSent) {
      return 'Копировать';
    }

    return "Сохранить";
  }


  // Удалить пункт
  onDelete() {
    this.http.delete<any>(AjaxMethods.PublicEventNotification.replace('{id}', this.publicEventId + ''), { params: { itemId: this.editItem.id } })
      .subscribe(d => {

        var ix2del = this.items.findIndex(i => i.id == this.editItem.id);
        if (ix2del >= 0) {
          this.items.splice(ix2del, 1);
          this.switchMode(DisplayMode.list);
        }
      });
  }

  // Редактирование повтора
  async onRepeateEdit() {
    this.switchMode(DisplayMode.repeate);
  }
  onCancelRepeate() {
    this.isMessageFirstInput = true;
    this.switchMode(DisplayMode.edit);
  }

  isRecipientsPanelVisible = false;// панель выбора подписчиков
  // Выбрали/сняли выбор у подписчика
  onRecipientCbxChange(usr: UserAttrs) {
    var ixUsr = this.editItem.recipients.findIndex(u => usr.loginId == u.loginId);
    if (ixUsr >= 0) { // был подписчиком. удалить
      this.editItem.recipients.splice(ixUsr, 1);
    }
    else {// добавить
      this.editItem.recipients.push(usr);
    }
  }

  onRecipientsPanelVisibleChange(on: boolean) {
    if (!on) {
      this.isRecipientsPanelVisible = false;
    }
  }
  // Выключить подписчика
  onRecipientRemove(spk: UserAttrs) {
    var ix2remove = this.editItem?.recipients.findIndex(p => p.loginId == spk.loginId);
    if (ix2remove >= 0) {
      this.editItem.recipients.splice(ix2remove, 1);
    }
  }
  // ФИО подписчиков уведомления через запятую
  getInlineRecipients(it: PublicEventNotificationItem) {
    if (it) {
      return it.recipients.map(i => i.name).join(', ');
    }
    return '';
  }
  // включен ли пользователь в список подписчиков
  isRecipient = function (usr: UserAttrs): boolean {
    if (this.editItem?.recipients)
      return Boolean(this.editItem?.recipients.find(u => u.loginId == usr.loginId));
    return false;
  }.bind(this);

  viewRecipient: UserAttrs = <any>{};  // пользователь, которого в данный момент показываем
  // Переход к просмотру подписчика
  onRecipientClick(usr: UserAttrs) {
    this.viewRecipient = usr;
    this.switchMode(DisplayMode.recipient);
  }

  //признак того, что текст сообщения начали вводить или пытались сохранить
  isMessageFirstInput = true;

  getErrorMessages(isSave: boolean): string[] {
    let msgs = [];

    if (this.editItem.sendType === 1) {
      if (!this.editItem.sendDate || moment(this.editItem.sendDate).year() < 2000) {
        msgs.push("Дата отправки уведомления должна быть задана");
      }

      if (this.isShowError) {
        let now = moment(new Date());
        this.minDate = now.toDate();

        if (this.minDate && moment(this.minDate).isAfter(this.editItem.sendDate)) {
          msgs.push(['Дата отправки уведомления не должна быть ранее текущей даты']);
        }
      }

      if (this.maxDate && moment(this.maxDate).isBefore(this.editItem.sendDate)) {
        msgs.push(['Дата отправки уведомления не должна быть позднее даты окончания мероприятия']);
      }


    }
  

    if (this.editItem.memberType === 3 && (!this.editItem?.recipients || this.editItem?.recipients.length == 0)) {
      msgs.push("Должен быть задан список получателей уведомления");
    }
    if (this.editItem.message || isSave) {
      this.isMessageFirstInput = false;
    }
    if ((!this.editItem.message || this.editItem.message.trim().length == 0) && !this.isMessageFirstInput) {
      msgs.push("Должно быть задано непустое сообщение для уведомления");
    }
   
  

    return msgs;
  }

  getWarningMessages(): string[] {
    let msgs = [];
    if (this.editItem.repeatCount > 5) {
      msgs.push("Внимание! Убедитесь, что вам нужно большое количество повторений!");
    }
    return msgs;
  }
}
