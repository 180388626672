import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Component, ContentChildren, EventEmitter, HostListener, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../common-data.service';


@Component({
  selector: 'app-screen-with-panel',
  templateUrl: './screen-with-panel.component.html',
  styleUrls: ['./screen-with-panel.component.less']
})
export class ScreenWithPanelComponent implements OnInit {

  constructor(private winTitle: Title, private router: Router, private common: CommonDataService) {
    this.onDrop.subscribe((direction: DragDirection) => {
      this.currentPosition = direction;
    });
  }

  ngOnInit(): void {
  }
  //вспомогательное свойство для возвращения панели на место (когда виден заголовок)
  _reset = '';
  //заголовок окна и заголовок в верхней части
  _title = '';
  @Input()
  set title(v: string) { this._title = v; if (v) this.winTitle.setTitle(v); }
  get title(): string { return this._title; }
  @Input()
  set reset(v: string) {
    this._reset = v;
    this.currentPosition = 'down';
  }
  get reset(): string { return this._reset; }
  @Input()
  innerTitle = '';
  @Input()
  bodyInnerPadding: any = 20;
  @Input()
  headerPartTmpl: TemplateRef<any> = <any>null;
  @Input()
  bodyTmpl: TemplateRef<any> = <any>null;
  @Input()
  backUrl?: string; // URL для кнопки "Назад"
  @Input()
  isSaveCancel: boolean = false; // внизу кнопки "Сохранить"/ "Отказаться"
  @Input()
  btnSaveName: string = "Сохранить"; 
  @Input()
  btnCancelName: string = "Отменить";
  @Output()
  onSaveClick = new EventEmitter<void>();
  @Input()
  isEditable = false;
  @Output()
  onEditClick = new EventEmitter<void>();
  // Нажали на Cancel в режиме редактирования
  @Output()
  onCancelEditClick = new EventEmitter<void>();
  // Сообщение для редактора на зеленом или сером фоне
  @Input()
  infoBar: { text: string, isHighlighted: boolean };
  // Удалить
  @Output()
  onDeleteClick = new EventEmitter<void>();
  // суффикс на кнопке "Удалить [deleteBtnSubject]"
  @Input()
  deleteBtnSubject = ''
  // кнопка удалить доступна
  @Input()
  isDeleteEnabled: boolean = true;
  @Input()
  isDeleteVisible = true;
  // запрашивать подтверждение удаления
  @Input()
  doConfirmDelete = true;
  @Output("onBackClicked")
  onBackClickedEvent = new EventEmitter<void>()
  @Input()
  showButtons = true; // в режиме редактирования показывать кнопки
  @Input()
  showClose = true; // показывать кнопку "закрыть"
  @Input()
  isInnerScroll = false; //включать ли скроллинг внутренней панели

  isHeaderVisible = true;

  onBackClicked() {
    this.onBackClickedEvent.emit();
  }

  onSaveBtnClick() {
    this.onSaveClick.emit();
  }
  @Input()
  doNavigateBackOnCancel = true;
  // click в кнопку Cancel
  onCancelBtnClick() {
    if (this.doNavigateBackOnCancel) {
      if (this.backUrl) {
        this.router.navigate([this.backUrl]);
      }
      this.onBackClicked();
    }
    this.onCancelEditClick.emit();
  }

  lastDragEvent: CdkDragMove<any>;
  // drag
  onDragMoved($event: any) {
    //console.debug("dragMove:", $event);
    this.lastDragEvent = <any>$event;
  }
  
  _isDragDisabled = false;

  isDragDisabled() : boolean{
    return this._isDragDisabled;
  }

  _hdr: HTMLElement = null;
  _body: HTMLElement = null;
  _topNav: HTMLElement = null;
  // dropped
  onDropped($event: any, hdr: HTMLElement, body: HTMLElement, topNav: HTMLElement) {
    if ((this.isHeaderVisible && this.lastDragEvent.distance.y < 0)
      || (!this.isHeaderVisible && this.lastDragEvent.distance.y > 0)
    ) {
      this.isHeaderVisible = !this.isHeaderVisible;
    }
    this._hdr = hdr;
    this._body = body;
    this._topNav = topNav;
    //console.debug("Release", this.lastDragEvent);
    hdr.style.display = this.isHeaderVisible ? "block" : "none";
    topNav.style.display = this.isHeaderVisible ? "block" : "none";
    body.style.marginTop = this.isHeaderVisible ? "" : "0px";
    body.style.transform = "none";
  }

  // Удалить
  onDeleteBtnClick() {
    if (this.doConfirmDelete) { // запросить подтверждение
      this.common.confirmationDialog.confirm({ message: `Удалить ${this.deleteBtnSubject}?`, title: 'Удаление' })
        .then(confirmed => {
          if (confirmed) {
            this.onDeleteClick.emit();
          }
        });
    }
    else {
      this.onDeleteClick.emit();
    }
  }

  currentPosition: DragDirection = 'down';
  get allowDragDirection(): DragDirection {
    return this.currentPosition == 'up' ? 'down' : 'up';
  }
  onDrop = new EventEmitter<DragDirection>();

  setCurrentPosition(newPos: DragDirection) {
    this.currentPosition = newPos;
  }
}

type DragDirection = 'up' | 'down';
