import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AjaxMethods } from '../models/AjaxMethods';

@Component({
  selector: 'app-send-push',
  templateUrl: './send-push.component.html',
  styleUrls: ['./send-push.component.less']
})
export class SendPushComponent implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  login: string;
  title: string;
  message: string;
  url: string;
  urlText: string;

  onSendClick() {
    this.http.post(AjaxMethods.PushSend, { userName: this.login, title: this.title, message: this.message, url: this.url, urlText:this.urlText }).subscribe();
  }

}
