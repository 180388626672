import { HttpClient } from '@angular/common/http';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { ApplicationRef, Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';
import { DomSanitizer } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-public-event-location',
  templateUrl: './public-event-location.component.html',
  styleUrls: ['./public-event-location.component.less']
})
export class PublicEventLocationComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private title: Title, private commonData: CommonDataService, private sanitizer: DomSanitizer, private clipboard: Clipboard, private snackBar: MatSnackBar) {
  }
  isEditMode = false;
  isEditor = false;
  publicEventId: number;
  location: PublicEventLocation;

  ngOnInit() {
    this.route.params.subscribe(prm => {
      this.publicEventId = Number(prm["id"]);
      // прочитать программу
      this.http.get<PublicEventLocation>(AjaxMethods.PublicEventLocation.replace("{id}", this.publicEventId + ''))
        .subscribe(data => {
          if (data) {
            this.location = data;
            this.isEditor = this.location.isEditor;
          }
        });
    });
  }

  onSave(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.getErrorMessages().length) return; // ошибки
      this.http.post<any>(AjaxMethods.PublicEventLocation.replace('{id}', this.publicEventId + ''),
        this.location).subscribe(d => {
          if (d.newId) {
            this.location.id = d.newId;
          }
          this.isEditMode = false;
          resolve();
        });
    });
  }

  onCancelEditClick() {
    this.isEditMode = false;
    if (this.backUrl) { // указано, куда переходить. Туда и переходим
      this.router.navigate([this.backUrl]);
    }
  }

  // Ссылка назад
  get backUrl() {
    return `/public-events/${this.publicEventId}`;
  }

  get isDeleteEnabled(): boolean {
    return Boolean(this.location?.id);
  }
  // Удалить пункт
  onDelete() {
    this.http.delete<any>(AjaxMethods.PublicEventLocation.replace('{id}', this.publicEventId + ''))
      .subscribe(d => {
        this.location.id = 0;
        this.location.text = '';
        this.location.content = '';
        this.isEditMode = false;
      });
  }

  @ViewChild("fileInput")
  fileInputElement: ElementRef<HTMLInputElement>;

  get safePhoto() {
    return this.location?.content ? this.sanitizer.bypassSecurityTrustResourceUrl(this.location?.content) : null;
  }
  // Фото есть
  get photoExists(): boolean { return Boolean(this.location?.content); }

  maxFileSize = 10 << 20;
  // Загрузили файл
  onFileInputChange($event: Event) {
    let inputElement = (<HTMLInputElement>$event.target);
    let files: FileList = inputElement.files;
    if (files.length) {
      let uploadedFile = files[0];
      let isImage = uploadedFile.type.toLowerCase().startsWith("image") && (uploadedFile.name.toLowerCase().endsWith(".jpg") || uploadedFile.name.toLowerCase().endsWith(".jpeg")
        || uploadedFile.name.toLowerCase().endsWith(".png") || uploadedFile.name.toLowerCase().endsWith(".gif") || uploadedFile.name.toLowerCase().endsWith(".bmp"));
      if (!isImage) {
        this.commonData.confirmationDialog.msgBox({
          message: `Файл не является изображением`
        });
        inputElement.value = '';
        return;
      }
      if (uploadedFile.size > this.maxFileSize) {
        this.commonData.confirmationDialog.msgBox({
          message: `Размер файла ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize >> 20}Mb`
        });
        inputElement.value = '';
        return;
      }
      uploadedFile.arrayBuffer().then(arr => {
        let bl = new Blob([arr]);
        let rdr = new FileReader();
        rdr.onloadend = () => {
          this.location.content = <string>rdr.result;
        }
        rdr.readAsDataURL(bl);
      });
      inputElement.value = '';
    }
  }

  //-------- Загрузить фото
  onUploadPhotoClick() {
    this.fileInputElement.nativeElement.click();
  }

  onDeletePhotoClick() {
    this.commonData.confirmationDialog.confirm({ message: "Удалить схему?", title: 'Удаление' })
      .then((confirmed) => {
        if (!confirmed) return;
        this.location.content = '';
      });
  }

  onCopyBtnClick() {
    this.clipboard.copy(this.location?.text);
    this.snackBar.open("Адрес скопирован", "Закрыть", Utils.snacBarInfoConfig);
    //alert('copy');
  }

  // Сообщения об ошибках
  getErrorMessages(): string[] {
    let msgs = [];
    if (this.location?.text && this.location?.text.length > 200) {
      msgs.push("Адрес не должен быть длиннее 200 символов");
    }

    return msgs;
  }
}
