import { HttpClient } from '@angular/common/http';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { ApplicationRef, Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';
import { PublicEventFilesIntComponent } from '../public-event-files-int/public-event-files-int.component';

// Режимы отображения
enum DisplayMode {
  list,
  edit,
  files,
  view,
  speaker
}
@Component({
  selector: 'app-public-event-program',
  templateUrl: './public-event-program.component.html',
  styleUrls: ['./public-event-program.component.less']
})
export class PublicEventProgramComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private title: Title, private commonData: CommonDataService, private dialog: MatDialog, private appRef: ApplicationRef, private sanitizer: DomSanitizer) {
    title.setTitle(this.mainTitle);
    this.NOTIFICATIONS_ENABLED = commonData.NOTIFICATIONS_ENABLED;
  }
  NOTIFICATIONS_ENABLED;
  mainTitle = "Программа";
  panelReset = '';
  ngOnInit() {
    this.route.params.subscribe(prm => {
      this.publicEventId = Number(prm["id"]);
      if (prm["progId"]) {
        this.programmId = +prm["progId"];
      }
      // прочитать программу
      this.http.get<PublicEventProgram>(AjaxMethods.PublicEventProgram.replace("{id}", this.publicEventId + ''))
        .subscribe(data => {
          if (data) {
            this.isEditor = data.isEditor;
            this.items = data.items;
            this.itemTypeOptions = data.itemTypeOptions;
            this.publishBeginDate = data.publishBeginDate;
            this.publishEndDate = data.publishEndDate;
            this.generateProgramDates();// Построить programDates
            this.appRef.tick();//!!!! провести Change Detection, чтобы DOM перестроился. Иначе последующий scroll2Date не найдет HTML-элементы, куда scroll делать

            if (this.programmId > 0) {
              var fngProg = this.items.find(x => x.id == this.programmId);
              if (fngProg) {
                  setTimeout(() => this.onEditItemClick(fngProg), 10);
                  return;
              }
            }

            if (!this.programDates.length) return;// пустая программа
            // Scroll к дате, следующей или равной сегодня. Если программа неначалась, в начале (уже там), если закончиласть - на последний элемент
            let today = moment(moment().format("yyyy-MM-DD"));
            if (today.isBefore(moment(this.programDates[0].items[0].beginDate))) return; // программа не началась. Мы в начале. Ничего не делать

            // найти элемент с датой позже или равной сегодня
            if (this.programDates.length > 1) {
              let ix = this.programDates.findIndex(d => today.isSameOrBefore(moment(d.items[0].beginDate)));
              if (ix >= 0) {
                this.scroll2Date(ix);
              }
              else {
                this.scroll2Date(this.programDates.length - 1, true);
              }
            }
          
          }
        });
    });
  }
  ngAfterViewInit() {
    this.tmplMode = [
      { mode: DisplayMode.list, tmpl: this.tmplList, isSaveCancel: false, isEditable: false, title: "Программа" },
      { mode: DisplayMode.edit, tmpl: this.tmplEditItem, isSaveCancel: true, isEditable: false, title: "Программа" },
      { mode: DisplayMode.view, tmpl: this.tmplViewItem, isSaveCancel: false, isEditable: false, title: "Программа"},
      { mode: DisplayMode.files, tmpl: this.tmplFiles, isSaveCancel: false, isEditable: false, title: "Материалы" },
      { mode: DisplayMode.speaker, tmpl: this.tmplSpeaker, isSaveCancel: false, isEditable: false, title: "Спикер" },
    ]
  }

  publicEventId: number;
  programmId: number;
  isEditor: boolean;
  items: PublicEventProgramItem[] = [];
  publishBeginDate: Date;
  publishEndDate: Date;
  itemTypeOptions: {
    key: number, value: { key: string, value:boolean }} []= [];

  programDates: { dt: string, items: PublicEventProgramItem[] }[]; // Сгруппировать publicEvent.programItems по дате
  
  generateProgramDates(): any {
    this.programDates = [];
    let dt = null;
    let items: any[] = [];
    this.items.forEach((pi) => {
      if (dt != pi.beginDateStr) { // новая дата
        if (dt != null) {// не первая итерация
          this.programDates.push({dt: dt, items: items});
        }
        dt = pi.beginDateStr;
        items = [];
      }
      items.push(pi);
    });
    if (dt) {// последнюю
      this.programDates.push({ dt: dt, items: items });
    }
  }
  isSaveCancel = false;
  isEditable = false;
  

  // Режимы отображения для переключения
  tmplMode: { mode: DisplayMode, tmpl: TemplateRef<any>, isSaveCancel: boolean, isEditable: boolean, title:string }[] = [];

  mode: DisplayMode = DisplayMode.list;

  // переключение режима
  switchMode(mode: DisplayMode) {
    var tm = this.tmplMode.find(t => t.mode == mode);
    if (!tm) throw new Error(`Invalid mode:${mode}`);
    this.tmpl = tm.tmpl;
    this.isEditable = tm.isEditable;
    this.isSaveCancel = tm.isSaveCancel;
    if (tm.title) this.mainTitle = tm.title;
    this.mode = mode;
  }

  onEditBeginClick() {
    if (this.mode == DisplayMode.files) {
      this.isEditable = true;
      this.mainTitle = "Материал";
    }
  }

  onEditEndClick() {
    if (this.mode == DisplayMode.files) {
      this.isEditable = false;
      this.mainTitle = "Материалы";
    }
  }

  @ViewChild(PublicEventFilesIntComponent)
  filesIntComponent: PublicEventFilesIntComponent;

  onEditClick() {
    if (this.mode == DisplayMode.files) {
      this.filesIntComponent.editIconClicked();
    }
  }

  viewMode = DisplayMode.view;
  // Индекс даты в date2
  getDateIx(dt) {
    var ix = this.programDates.findIndex(p => p.items[0].beginDate == dt);
    return ix;
  }
  // Кнопка "назад"
  onBackClick() {
    switch (this.mode) {
      case DisplayMode.edit:// к списку
      case DisplayMode.view:// к списку
        this.generateProgramDates();
        this.switchMode(DisplayMode.list);
        this.appRef.tick();
        if (this.editItem.beginDate) {// подвести scroll на дату, которая была в редактируемом элементе
          let ix = this.getDateIx(this.editItem.beginDate);
          if (ix >= 0) {
            this.scroll2Date(ix);
          }
        }
        break;
      case DisplayMode.list:// на уровень вверх
        this.router.navigate([`/public-events/${this.publicEventId}`]);
        break;
      case DisplayMode.files: // на редактирование
        if (this.isEditable) {
          this.filesIntComponent.onBackClicked();
        }
        else {
          this.switchMode(DisplayMode.edit);
        }
        break;
      case DisplayMode.speaker: // к просмотру пункта программы
        this.switchMode(DisplayMode.view);
        break;
    }
  }
  // Доступные для выбора имена файлов иконок
  iconsAvl = ["program-report.svg", "program-calendar.svg ", "program-eye.svg", "program-food.svg", "program-coffee.svg"];
  // объект для редактирования
  editItem: PublicEventProgramItem;
  // Добавить пункт программы
  onNewClick() {
    this.editItem = <any>{ itemType: this.itemTypeOptions[0]?.key, speakers: [] };
    let now = moment(new Date());
    if (now.minutes() > 0) now = now.add("h", 1); // округлить до следующего часа
    now = now.minutes(0);
    this.editItem.beginDate = now.toDate();
    this.editItem.endDate = moment(this.editItem.beginDate).add("h", 1).toDate();
    this.switchMode(DisplayMode.edit);
    this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';//возвращение панели на место (когда виден заголовок)
  }
  // редактирование пункта программы
  onEditItemClick(it: PublicEventProgramItem) {
    this.editItem = it;
    if (this.isEditor) {
      this.switchMode(DisplayMode.edit);
    }
    else {
      this.switchMode(DisplayMode.view);
    }
    this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';//возвращение панели на место (когда виден заголовок)
    this.scroll2Top();
  }
  // Сохранить пункт программы
  onSave(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.getDateRangeErrorMessages().length) return; // ошибки в датах
      if (!this.editItem.subject) return;
      Utils.moment2Date(this.editItem, "beginDate", "endDate", 'beginDateNfyDate', 'endDateNfyDate');
      this.http.post<any>(AjaxMethods.PublicEventProgram.replace('{id}', this.publicEventId + ''),
        this.editItem).subscribe(d => {
          var isNew = !this.editItem.id;
          if (d.newId) {
            this.editItem.id = d.newId;
            // взять даты и время начала/окончания
            Object.assign(this.editItem, d);
          }
          if (isNew) {
            this.items.push(this.editItem);
          }
          this.items = this.items.sort((a, b) =>
            moment(a.beginDate).isSame(b.beginDate) ? 0
              : (moment(a.beginDate).isBefore(b.beginDate)) ? -1
                : 1);
          this.generateProgramDates();
          let dateIx = this.programDates.findIndex(p => p.dt == this.editItem.beginDateStr);
          if (dateIx >= 0) {
            setTimeout(() => this.scroll2Date(dateIx), 500);
          }
          this.switchMode(DisplayMode.list); // к списку
          resolve();
        });
    });
  }
  onCancelEdit() {
    this.switchMode(DisplayMode.list);
  }
  get isDeleteEnabled(): boolean {
    return Boolean(this.editItem?.id);
  }
  // Удалить пункт
  onDelete() {
    this.http.delete<any>(AjaxMethods.PublicEventProgram.replace('{id}', this.publicEventId + ''), { params: { itemId: this.editItem.id } })
      .subscribe(d => {
        var ix2del = this.items.findIndex(i => i.id == this.editItem.id);
        if (ix2del >= 0) {
          this.items.splice(ix2del, 1);
          this.generateProgramDates();
          this.switchMode(DisplayMode.list);
        }
    });
  }
 
  tmpl: TemplateRef<any>; // текущий шаблон
  @ViewChild("tmplList")
  tmplList: TemplateRef<any>;
  @ViewChild("tmplEditItem")
  tmplEditItem: TemplateRef<any>; // редактирование пункта программы
  @ViewChild("tmplViewItem")
  tmplViewItem: TemplateRef<any>; // просмотр пункта программы
  @ViewChild("tmplCfgNotifications")
  tmplCfgNotifications: TemplateRef<any>;// редактирование оповещений
  @ViewChild("tmplFiles")
  tmplFiles: TemplateRef<any>;// материалы для редактора
  @ViewChild("tmplSpeaker")
  tmplSpeaker: TemplateRef<any>;// просмотр одного спикера

  @ViewChild("tmplChooseIcon")
  tmplChooseIcon: TemplateRef<any>;
  // Кликнули иконку в диалоге выбора иконки
  onChooseIconClick() {
    var dlgRef = this.dialog.open(this.tmplChooseIcon);
    var saveIcon = this.editItem.icon;
    dlgRef.afterClosed().subscribe(result => {
      if (!result) {// отказались от выбора
        this.editItem.icon = saveIcon;// восстановить
      }
    });
  }
  // Очистка иконки
  onClearIconClick() {
    var that = this;
    setTimeout(function () {
      that.editItem.icon = null;
    }, 100);
  }
  cfgNotificationObjName: string;
  cfgNotificationSelection = 0;
  cfgNotificationDate = null; //new Date();

  isNotificationsPanelVisible= false;
  // Переход к редактированию оповещений
  onCfgNotificationClick(objName: string) {
    this.cfgNotificationObjName = objName;
    this.cfgNotificationSelection = this.editItem[objName + "NfySet"];
    this.cfgNotificationDate = this.editItem[objName + "NfyDate"];
    this.isNotificationsPanelVisible = true;
  }

  onNotificationVisibleChange($event:boolean) {
    if (!$event) {
      this.editItem[this.cfgNotificationObjName + "NfySet"] = this.cfgNotificationSelection;
      this.editItem[this.cfgNotificationObjName + "NfyDate"] = this.cfgNotificationDate;
      this.isNotificationsPanelVisible = false;
    }

  }
  isSpeakersPanelVisible = false;// панель выбора спикеров
  // Выбрали/сняли выбор у спикера
  onSpeakerCbxChange(usr: UserAttrs) {
    var ixUsr = this.editItem.speakers.findIndex(u => usr.loginId == u.loginId);
    if (ixUsr >= 0) { // был спикером. удалить
      this.editItem.speakers.splice(ixUsr, 1);
    }
    else {// добавить
      this.editItem.speakers.push(usr);
    }
  }

  onSpeakersPanelVisibleChange(on: boolean) {
    if (!on) {
      this.isSpeakersPanelVisible = false;
    }
  }
  // Выключить спикера - клик "крестик" в списке на панели пункта программы
  onSpeakerRemove(spk: UserAttrs) {
    var ix2remove = this.editItem?.speakers.findIndex(p => p.loginId == spk.loginId);
    if (ix2remove >= 0) {
      this.editItem.speakers.splice(ix2remove, 1);
    }
  }
  // ФИО спикеров пункта программы через запятую
  getInlineSpeakers(it: PublicEventProgramItem) {
    if (it) {
      return it.speakers.map(i => i.name).join(', ');
    }
    return '';
  }
  // включен ли пользователь в спикеры
  isSpeaker = function (usr: UserAttrs): boolean {
    return Boolean(this.editItem?.speakers.find(u => u.loginId == usr.loginId));
  }.bind(this);

  // Редактирование списка файлов
  async onFilesEdit() {
    if (!this.editItem.id) {
      await this.onSave();
    }
    this.switchMode(DisplayMode.files);
    this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';//возвращение панели на место (когда виден заголовок)
  }
  viewSpeaker: UserAttrs = <any> {};  // пользователь, которого в данный момент показываем
  // Переход к просмотру спикера
  onSpeakerClick(usr: UserAttrs) {
    this.viewSpeaker = usr;
    this.switchMode(DisplayMode.speaker);
    this.panelReset = this.panelReset == 'value1' ? 'value2' : 'value1';//возвращение панели на место (когда виден заголовок)
  }
  // В зависимости от типа пункта программы font-weight для subject пункта программы
  getItemSubjectFontWeight(it: PublicEventProgramItem) {
    return this.itemTypeOptions.find(opt => opt.key == it.itemType)?.value.value ? "600" : "400";
  }
  // Изменилась дата-время beginDate. Заменить дату в endDate, оставив время на месте
  onEditItemBeginDateChange(dt) {
    var dtm = moment(dt);
    var em = moment(this.editItem.endDate);
    var endDt = moment(dtm.format('yyyy-MM-DD')).add(moment.duration({ hours: em.hours(), minutes: em.minutes() }));
    console.debug(`setting endDate:${endDt.toDate()}`);
    this.editItem.endDate = endDt.toDate();
    this.editItem.beginDate = dt;
  }

  
  // Сообщения об ошибках на диапазон дат
  getDateRangeErrorMessages(): string[] {
    let msgs = [];
    if (!this.editItem.beginDate || moment(this.editItem.beginDate).year() < 2000) {
      msgs.push("Дата начала должна быть задана");
    }
    if (!this.editItem.endDate) {
      msgs.push("Дата окончания должна быть задана");
    }
    let beginDateMmt = moment(this.editItem.beginDate);
    let endDateMmt = moment(this.editItem.endDate);
    if (beginDateMmt.hours() * 100 + beginDateMmt.minutes() > endDateMmt.hours() * 100 + endDateMmt.minutes()) {
      msgs.push("Время окончания должно быть позднее времени начала");
    }
    // Если границы публикации заданы, то даты программы должны быть в диапаозоне
    if (this.publishBeginDate && moment(this.publishBeginDate).isAfter( this.editItem.beginDate )) {
      msgs.push(['Дата начала программы не должна быть ранее даты начала публикации мероприятия']);
    }

    if (this.publishEndDate && moment(this.publishEndDate).isBefore(endDateMmt)) {
      msgs.push(['Дата окончания программы не должна быть позднее даты окончания публикации мероприятия']);
    }

    return msgs;
  }
  scroll2Date(dateIx: number, positionAfterEl?:boolean) {
    var htmlElements = this.programDateHdr.toArray();
    if (dateIx >= 0 && dateIx < htmlElements.length) {
      console.debug(`scroll to ix:${dateIx} dt:${this.programDates[dateIx].items[0].beginDateStr}`, htmlElements[dateIx]);
      let el = <HTMLDivElement>htmlElements[dateIx].nativeElement;
      let parent: HTMLDivElement = <any>el.parentElement;


      if (dateIx > 0) {
        // el.offsetTop + высота el + высота следующих елементов + добавка = высоте parent'client
        let followingElsHeight = htmlElements.reduce((prev, curr, i) => i > dateIx ? prev + curr.nativeElement.clientHeight : prev, 0);
        console.log(`followingElsHeight:${followingElsHeight}`);

        let addition = (el.offsetTop + el.clientHeight + parent.parentElement.offsetHeight) - parent.clientHeight;
        if (addition < 0) addition = 0;

        let minHeight = `${parent.offsetHeight + addition}px`;
        console.log(`minHeight:${minHeight}`);
        parent.style.minHeight = minHeight;
      }

      el.scrollIntoView(true);
      if (positionAfterEl) {
        parent.scrollTop = parent.scrollTop + el.offsetHeight;
      }
    }
  }

  scroll2Top() {
    document.getElementById("screenPanelHeader").scrollIntoView();
  }

  getPhotoPath(photo: string): string {
    return photo ? 'assets/' + photo + (/.svg$/i.test(photo) ? '' : '.svg') : 'assets/user-profile-big.svg';
  }
  getPhotoSafe(photo: string) {
    return this.getPhotoIsImage(photo) ? this.sanitizer.bypassSecurityTrustResourceUrl(photo) : null;
  }
  // Фото есть картинка
  getPhotoIsImage(photo: string): boolean { return Boolean(photo) && /base64/i.test(photo); }



  @ViewChildren("programDateHdr")
  programDateHdr: QueryList<ElementRef>;
}
