<div class="wrapper">
  <mat-form-field *ngIf="showDate" class="date-field">
    <mat-label>{{label}}</mat-label>
    <input matInput [matDatepicker]="pickerBegin" [(ngModel)]="datePart" [matDatepickerFilter]="filter" [min]="min" [max]="max" [disabled]="isDisabled" autocomplete="off" (focus)="onDateFocus()">
    <mat-datepicker-toggle matSuffix [for]="pickerBegin">
      <mat-icon matDatepickerToggleIcon>
        <svg-icon src="assets/datepicker.svg" [svgStyle]="{ 'width.px':'22', 'height.px':'22' }"></svg-icon>
      </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #pickerBegin touchUi>
    </mat-datepicker>
  </mat-form-field>
  <h3 *ngIf="!showDate && showSep"> - </h3>
  <div *ngIf="showTime" class="clock-field">
    <!--<input type="text" matInput [(ngModel)]="timePart" mask="Hh:m0" [leadZeroDateTime]="true" autocomplete="off" />-->
    <!--<app-time-picker [(editVal)]="timePart" [defaultValue]="onFocusDefaultValue"></app-time-picker>-->
    <mat-form-field [ngClass]="{'field-without-underline': noUnderline }">
      <input matInput
             name="app_time_A"
             [format]="24"
             [(ngModel)]="timePart"
             [defaultValue]="onFocusDefaultValue"
             [ngxMatTimepicker]="pickerA"
             [disabled]="isDisabled" />

      <mat-icon matSuffix (click)="isDisabled ? doNothing() : pickerA.open()" [ngStyle]="{ 'width.px':'22', 'height.px':'22' }">
        watch_later
      </mat-icon>
    </mat-form-field>
    <ngx-mat-timepicker #pickerA></ngx-mat-timepicker>

  </div>
  <button mat-button *ngIf="allowClear" matSuffix mat-icon-button aria-label="Clear" [disabled]="isDisabled" (click)="onClearClick()" Style="margin-top: 8px;">
    <mat-icon>close</mat-icon>
  </button>
</div>
