import { Component } from "@angular/core";

@Component({
  selector: 'vote-snack-bar-component',
  template: `some template`,
  styles: [`
    .example-pizza-party {
      color: hotpink;
    }
  `],
})
export class VoteSnackBarComponent { }
