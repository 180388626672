import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-cancel-save-delete-btns',
  templateUrl: './cancel-save-delete-btns.component.html',
  styleUrls: ['./cancel-save-delete-btns.component.less']
})
export class CancelSaveDeleteBtnsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @Input()
  isDeleteEnabled: boolean;
  @Input()
  deleteBtnSubject: string;
  @Input()
  isDeleteAvailable: boolean;

  onCancelBtnClick() {
    this.onCancel.emit();
  }

  onSaveBtnClick() {
    this.onSave.emit();
  }

  onDeleteBtnClick() {
    this.onDelete.emit();
  }


  @Output()
  onCancel = new EventEmitter<void>();
  @Output()
  onSave = new EventEmitter();
  @Output()
  onDelete = new EventEmitter();

}
