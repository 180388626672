import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.less']
})
export class IconButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  icon;
  @Input()
  applaySm = false;
  @Input()
  showRedDot = false;
}
