// Методы сервера
export class AjaxMethods {
  static login = "Account/GetJWTToken";
  static logoff = "Account/Logoff";
  static postProfileAttrs = "Account/PostProfileAttrs";
  static dummyAuth = "Account/ProtectedDummy";
  static userProfile = "Account/GetUserProfileAttrs";
  static userPushSubscriptions = "Account/GetUserPushSubscriptions";
  static getPublicEventsAccessRights = "PublicEvent/GetHomeScreenAccessRights";
  static getPublicEvents = "PublicEvent/Get";
  static getPublicEventDescription = "PublicEvent/{id}/Description";
  static getPublicEventPolling = "PublicEvent/{id}/Polling";
  static getPublicEventQuestions = "PublicEvent/Polling/{pollingId}/Questions";
  static getPublicEventVoteHistory = "Publicevent/VoteHistory/{questionId}/{userAccountId}";
  static PostPublicEventPolling = "PublicEvent/Polling/";
  static PostPublicEventQuestion = "PublicEvent/Question/";
  static postVoting = "PublicEvent/PostVote/{id}?voteResult={v}&forLoginId={forLoginId}";
  static postPublicEvent = "PublicEvent/PublicEvent";
  static postPublicEventInfo = "PublicEvent/PostPublicEventInfo";
  static postPublicEventRegistrationSettings = "PublicEvent/PostPublicEventRegistrationSettings";
static  deletePublicEvent = "PublicEvent/Delete";
  static getPublicEventFiles = "PublicEvent/{id}/Files/get?programItemId={programItemId}";
  static getPublicEventFileDetail = "PublicEvent/{id}/Files/get/{fileId}?programItemId={programItemId}";
static  DeletePublicEventFile = "PublicEvent/{id}/Files";
static  DeletePublicEventPolling = "PublicEvent/{id}/Polling";
static  DeletePublicEventQuestion = "PublicEvent/Question/{questionId}";
static  DeletePublicEventPollingVote = "PublicEvent/Vote/{pollingId}/{userAccountId}";
static  PublicEventFileUpload = "PublicEvent/{id}/Files/upload/{fileId}";
  static PostPublicEventFileInfo = "PublicEvent/{id}/Files/post";
  static Proxy = "PublicEvent/Polling/{id}/Proxy?principalId={principalId}";
  static GetPublicEventUsers = "PublicEvent/{publicEventId}/GetUsers";
  static AlterPublicEventACL = "PublicEvent/AlterEventAccess/{grantOrRevoke}/{eventOrVote}/{publicEventId}/{loginId}";
  static PublicEventRegister = "PublicEvent/PublicEventRegister/{grantOrRevoke}/{publicEventId}";
static  SetPushSubscription = "/PushNotifications/SetSubscription";
static  RemovePushSubscription = "/PushNotifications/RemoveSubscription";
  static PushSend = "pushnotifications/send";
  static tchLinkGetAgencyAmount = "/tchlink/getAgencyAmount/{id}";
  static tchLinkGetAgencyThreshold = "/tchlink/getAgencyThreshold/";
  static tchLinkPostAgencyThresholdNfy = "/tchlink/postAgencyThresholdNfy?isEnabled={isEnabled}";
  static searchCompanyDict = "/tchlink/CompanySearch";
  static GetNotificationSchedule = "/tchlink/GetNotificationSchedule";
  static PostNotificationSchedule = "/tchlink/PostNotificationSchedule";
  static GetNotificationsHistory = "/account/GetNotificationsHistory";
  static PostNotificationsReadStatus = "/account/PostNotificationsReadStatus";
  static PublicEventProgram = "PublicEventProgram/{id}/Program";
  static PublicEventContacts = "PublicEventContacts/{id}/Contacts";
  static PublicEventNotification = "PublicEventNotification/{id}";
  static PublicEventLocation = "PublicEventLocation/{id}/Location";
  static PublicEventChat = "PublicEventChat/{id}";
  static PublicEventChatMessage = "PublicEventChat/Messages/{id}";
  static SetPublicEventChatMessage = "PublicEventChat/Message/{id}/{chatId}";
  static PublicEventGallery = "PublicEventGallery/{id}/Gallery";
  static PublicEventGalleryListItem = "PublicEventGallery/{id}/Gallery/{listId}";
  static PublicEventGalleryPhotosList = "PublicEventGallery/{id}/PhotosList";
  static PublicEventPartGallery = "PublicEventGallery/{id}/PartGallery/{skip}/{lastId}";
  static PublicEventGalleryFile = "PublicEventGallery/{id}/file/{fileId}/get";
  static LoginPageLinks = "Account/PageLinks";
  static TchMailingList = "TCHMailing/List";
  static TchMailingDetail = "TCHMailing/{id}/Info";
  static TchMailingChangeState = "TCHMailing/{id}/ChangeState";
  static TchMailingSignDocs = "TCHMailing/Sign";
}
