import { state } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { ScreenWithPanelComponent } from '../../screen-with-panel/screen-with-panel.component';
import { Download, DownloadService } from '../../services/download.service';
import { Utils } from '../../utils';

@Component({
  selector: 'app-tch-mailing-detail',
  templateUrl: './tch-mailing-detail.component.html',
  styleUrls: ['./tch-mailing-detail.component.less']
})
export class TchMailingDetailComponent implements OnInit {

  constructor(private http: HttpClient, public dialog: MatDialog, private router: Router, private title: Title, private route: ActivatedRoute,
    private applicationRef: ApplicationRef, private downloadSrv: DownloadService, private commonData: CommonDataService, public sanitizer: DomSanitizer) {
  }
  info: TchMailingDocumentInfo = {
    author : "",
    createDateText : "",
    documentId : 0,
    documentNumber : "",
    message : "",
    moveDateText : "",
    recipientsText : "",
    stateName : "",
    subject : "",
    nextStates : [],
    attachments : []
   };
  backUrl = "/tch-mailing";
  id: number = 0;
  isActionPanelVisible: boolean = false;
  actionName: string = "";
  changeRequest: TchMailingStateChangeRequest = {
    comment: "",
    slipId: 0
  }
  ngOnInit(): void {
    this.changeRequest.comment = "";
    this.changeRequest.slipId = 0;
    this.route.params.subscribe(params => {
      this.id = params["id"];

      this.http.get<TchMailingDocumentInfo>(AjaxMethods.TchMailingDetail.replace("{id}", this.id + ''))
        .subscribe(data => {
          this.title.setTitle(data?.subject);
          this.info = data;
        });

    });
  }

  fileDataUrl;
  fileDataUrlSrc;
  // fileContentType;
  downloadFileName: string;
  isImg = false;
  isPdf = false;


  @ViewChild("downloadButton")
  downloadButtonElement: ElementRef<HTMLElement>;

  @ViewChild(ScreenWithPanelComponent)
  screenWithPanel: ScreenWithPanelComponent;

  download$: Observable<Download>
  onFileDownload(dataUrl: string, filename?: string, mimetype?: string) {
    if (this.commonData.platform !== "web") {
      this.download$ = this.downloadSrv.download(dataUrl, filename, mimetype, true);
    }
    else {

      // DEV-35477
      // setTimeout(() => {  this.downloadButtonElement.nativeElement.click(); });

      
      if (this.commonData.platform == 'web' && this.commonData.operatingSystem == 'ios') {
        if (this.isImg || this.isPdf) {
          window.open(this.fileDataUrl, "_self");
        }
        else {
          this.downloadButtonElement.nativeElement.click();
        }
      }
      else {
        this.downloadButtonElement.nativeElement.click();
      }
      
    }
    return false;
  }

  // Открыть файл. 
  onFileOpenClick(fl: TchMailingAttachmentInfo) {
    if (this.screenWithPanel) {
      this.screenWithPanel.setCurrentPosition("down"); // схлопнуть его, а то, открыв файл, пользователь забывает, что он его оттянул вверх
    }

    //this.isImg = fl.fileName.toLowerCase().endsWith(".jpg") || fl.fileName.toLowerCase().endsWith(".png");
    //this.isPdf = fl.fileName.toLowerCase().endsWith(".pdf");
    //if (this.commonData.platform === 'web' && this.commonData.operatingSystem === 'ios' && (this.isImg || this.isPdf)) {
    //if (this.isImg || this.isPdf) {
    //  window.open(`${this.commonData.baseUrl}/TCHMailing/${this.id}/file/${fl.attachmentId}/get`, "_self");
    //  return;
    //}


    this.http.get<any>(`/TCHMailing/${this.id}/file/${fl.attachmentId}/get`, { responseType: 'blob' as "json", observe: "response" })
        .subscribe((resp) => {

          let reader = new FileReader();
          reader.addEventListener("loadend", () => {


            const contentType = resp.headers.get("content-type");
            this.isImg = contentType.startsWith("image");
            this.isPdf = contentType.toLowerCase().endsWith("pdf");

            let disposition = decodeURI(resp.headers.get('content-disposition'));
            let p = disposition.lastIndexOf("'");
            if (p > 0) {
              this.downloadFileName = disposition.substring(p + 1);
            }
            else {
              this.downloadFileName = fl.fileName;
            }

            //this.fileContentType = contentType;
            //this.fileDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
            //this.downloadButtonElement.nativeElement.setAttribute("target", "_self");

            // DEV-35477
            if (this.commonData.platform === 'web' && this.commonData.operatingSystem === 'ios') {
              if (this.isImg || this.isPdf) {
                // const file = new File([<string>reader.result], this.downloadFileName, { type: contentType });
                // this.fileDataUrl = window.URL.createObjectURL(file);
                this.fileDataUrl = (window.webkitURL || window.URL).createObjectURL(resp.body);
              }
              else {
                this.fileDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
                this.downloadButtonElement.nativeElement.setAttribute("target", "_self");
              }
            }
            else {
              this.fileDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
              this.downloadButtonElement.nativeElement.setAttribute("target", "_self");
            }
            this.applicationRef.tick();

            // console.debug(`Platform:${this.commonData.platform}. System:${this.commonData.operatingSystem}. ContentType:${contentType}. DownloadFileName:${this.downloadFileName}. FileDataUrl:${this.fileDataUrl}`);
            // console.debug(`Platform:${this.commonData.platform}. System:${this.commonData.operatingSystem}. ContentType:${contentType}. DownloadFileName:${this.downloadFileName}. `);

            this.onFileDownload(<string>reader.result, fl.fileName, resp.body.type);

          });
          reader.readAsDataURL(resp.body);
    });
  }



  onActionClick(stateInfo: TchMailingState) {
    this.actionName = stateInfo.actionName;
    this.changeRequest.slipId = stateInfo.slipId;
    this.isActionPanelVisible = true;
  }

  onCancelClick() {
    this.isActionPanelVisible = false;
  }

  onDoClick() {
    var that = this;
    this.http.post<any>(AjaxMethods.TchMailingChangeState.replace('{id}', this.id + ''),
      this.changeRequest).subscribe(d => {
        that.isActionPanelVisible = false;
        that.router.navigate([that.backUrl]);
      });
  }
}
