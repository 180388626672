<!--голосование по вопросу-->
<app-screen-with-panel
                       [title]="'Вопрос № ' + (question?.no||'')"
                       [bodyTmpl]="bodyTmpl"
                       [backUrl]="backUrl"
                       [isEditable]="questions?.isEditor"
                       (onEditClick)="onEditClick()">
  <ng-template #bodyTmpl>

    <div class="question-tmpl-body">
      <!-- Вы не можете голосовать, потому что делегировали голос -->
      <div class="warn-block" *ngIf="questions?.hasProxy">
        <strong>Голос делегирован</strong>
        <br/>Вы не можете участвовать в голосовании
      </div>
      <!--вопрос-->
      <div class="full-text" >
        <div class="result-ql-editor" [innerHTML]="this.question?.fullText"></div>
      </div>
      <!-- За кого голосует -->
      <div class="vote-for-wrapper" *ngIf="questions?.doesProxyForExist">
        <!--есть, за кого-->
        <div class="vote-for-label">От имени кого голосуете</div>
        <div class="user-vote-for-selected">
          <div class="selected-name">{{voteForSelected?.name}}</div>
          <div class="svg-wrapper" (click)="isChildPanelVisible = !isChildPanelVisible"
               [ngClass]="{disabled: questions?.doesProxyForExist && questions?.proxyFor.length == 1}">
            <svg-icon src="assets/select-box.svg" [applyClass]="true" svgClass="svg-icon"></svg-icon>
          </div>
        </div>
      </div>
      <!--кнопки голосования-->
      <div class="btn-block" *ngIf="!questions?.hasProxy">
        <button *ngFor="let opt of questions?.options" (click)="onVoteClick(opt.val, opt.enumVal, opt.text)" [ngClass]="getOptClasses(opt)">{{opt.text}}</button>
      </div>
      <!--Блок "следующий/предыдущий вопрос"-->
      <div class="prev-next">
        <div [ngClass]="{disabled: question?.no == 1}" (click)="onPrevQuestionClick()">
          <div class="btn-icon"><svg-icon src="assets/chevron-left.svg" [applyClass]="true" svgClass="icon" [viewBox]="'auto'"></svg-icon></div>
          <div class="btn-text">Предыдущий вопрос</div>
        </div>
        <div [ngClass]="{disabled: question?.no >= questions?.items.length}" (click)="onNextQuestionCLick()">
          <div class="btn-text">Следующий вопрос</div>
          <div class="btn-icon"><svg-icon src="assets/chevron-right.svg" [applyClass]="true" svgClass="icon" [viewBox]="'auto'"></svg-icon></div>
        </div>
      </div>
    </div>

    <!--Панель выбора пользователя, за кого голосует-->
    <app-child-panel [(isVisible)]="isChildPanelVisible" header="За кого голосуете">
      <div class="child-panel-users">
        <div class="one-user" *ngFor="let opt of questions?.proxyFor" (click)="onVoteForSelected(opt)" [ngClass]="{'is-myself': opt.isMyself}">
          <div class="one-user-text">
            <div class="full-name">{{opt.name}}</div>
            <div class="position">{{opt.position}} {{opt.company}}</div>
            <div class="questions-progress">{{opt.questionsCompleted}} из {{opt.questionsTotal}} {{questionsDecl(opt.questionsTotal)}}</div>
          </div>
          <div class="one-user-svg-wrap" *ngIf="opt.isSelected">
            <svg-icon src="assets/vote-tick.svg" [applyClass]="true" svgClass="svg"></svg-icon>
          </div>
        </div>
      </div>
    </app-child-panel>

  </ng-template>
</app-screen-with-panel>
