import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';

@Component({
  selector: 'app-public-event-file',
  templateUrl: './public-event-file.component.html',
  styleUrls: ['./public-event-file.component.less']
})
export class PublicEventFileComponent implements OnInit {

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private common: CommonDataService) { }

  isEditMode = true;
  isEditor: boolean;
  file: PublicEventFile = <any>{};

  ngOnInit(): void {
    var stateData: PublicEventFiles = history.state?.data;
    if (stateData) {
      this.file = stateData.files[0];
      this.isEditor = stateData.isEditor;
      if (!this.isEditor) {
        this.router.navigate([this.backUrl]);
      }
      else {
        if (!this.file.id) {
          this.isEditMode = true;
        }
      }
    }
    else {
      // прочитать список файлов
      this.route.params.subscribe(params => {
        let id = params["id"];
        this.http.get<PublicEventFiles>(AjaxMethods.getPublicEventFiles.replace("{id}", id)).subscribe(data => {
          if (data.files?.length) {
            let fileId = params["fileId"];
            this.file = data.files.find(f => f.id == fileId);
            if (!this.file) throw new Error(`File not found. PublicEventId:${id} FileId:${fileId}`);
          }
          this.isEditor = data.isEditor;
          if (!data.isEditor) {
            this.router.navigate([this.backUrl]);
          }
        });
      });
    }
  }

  @ViewChild("fileInput")
  fileInputElement: ElementRef<HTMLInputElement>;

  @ViewChild("fileForm")
  formElement: ElementRef<HTMLFormElement>;
  @ViewChild("titleField")
  titleField: NgModel;

  // Сохранить
  onSaveClick() {
    //let formData = new FormData(this.formElement.nativeElement);
    if (!this.file?.title) {
      if (this.titleField) {
        this.titleField.control.markAsTouched();
      }
      return;
    }
    this.http.post<{ newId: number }>(AjaxMethods.PostPublicEventFileInfo.replace('{id}', this.file.publicEvent_Id + '')
      .replace('{fileId}', this.file.id + ''),
      this.file).subscribe(data => {
        this.file.id = data.newId;
        this.isEditMode = false;
        this.fileInputElement.nativeElement.value = ''; // очистить загруженные файлы
      });
  }
  maxFileSize = 3 << 20; 
  // Загрузился файл
  onFileInputChange($event: Event) {
    let inputElement = (<HTMLInputElement>$event.target);
    let files: FileList = inputElement.files;
    if (files.length) {
      let uploadedFile = files[0];
      if (uploadedFile.size > this.maxFileSize) {
        this.common.confirmationDialog.msgBox({
          message: `Размер файла ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize>>20}Mb`
        });
        inputElement.value = '';
        return;
      }
      this.file.fileName = uploadedFile.name;
      let p = uploadedFile.name.lastIndexOf('.');
      this.file.extension = p >= 0 ? uploadedFile.name.substring(p) : '.';
      this.file.fileSize = uploadedFile.size;
      this.file.lastModified = new Date(uploadedFile.lastModified);
      uploadedFile.arrayBuffer().then(arr => {
        let bl = new Blob([arr]);
        let rdr = new FileReader();
        rdr.onloadend = () => this.file.content = <string>rdr.result; 
        rdr.readAsDataURL(bl);
      });
      inputElement.value = '';
    }
  }
  formatDate(v) {
    return Utils.formatDateLong(v);
  }

  // Наш URL: "public-events/:id/files/:fileId". Оставить 3 сегмента
  get backUrl() {
    return '/' + this.route.snapshot.url.slice(0,3).join('/') + '/';
  }

  // Удалить файл
  onDeleteClick() {
    var ids = [this.file.id+''];
    this.http.delete(AjaxMethods.DeletePublicEventFile, { params: { publicEventId: this.file.publicEvent_Id + '', ids: ids } }).subscribe(() => {
      this.router.navigate([this.backUrl]);
    });
  }
}
