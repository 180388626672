<div appHeight100vh class="screen-wrap" #screenWrap>
  <app-screen-top-header btnBackground="violet" [backUrl]="backUrl"></app-screen-top-header>
  <div class="absbackdrop">
    <div class="upper-part">

    </div>
    <div class="grey-backdrop"></div>
  </div>

  <div class="screen-panel" #screenPanel>

    <div class="header">
      <div class="text">Официальные рассылки ТКП</div><!-- {{screenWrap.style.height}} client:{{screenWrap.clientHeight}} screenPanelH:{{screenPanel.clientHeight}}-->
    </div>
    <div class="sub-header">За последние 7 дней</div>

    <div class="screen-body">
      <div class="status-type">На согласовании</div>
      <app-nav-list-item *ngFor="let it of itemsToApprove; let ix = index" [textTmpl]="tmpl" [navLink]="'/tch-mailing/' + it.documentId "
                         [param]="null" [isTopBorder]="ix == 0">
        <ng-template #tmpl>
          <div class="mailing-item">
            <div class="text-block">
              <div class="first-line" [innerHTML]="it.subject" appTextEllipsis></div>
              <div class="second-line" [innerHTML]="it.moveDateText"></div>
              <div class="publish-status grey">{{it.documentNumber}}</div>
            </div>
          </div>
        </ng-template>
      </app-nav-list-item>
      <div *ngIf="!(itemsToApprove) || (itemsToApprove.length == 0)" class="no-data">Нет рассылок на согласовании</div>
      <div class="status-type">На подписании</div>
      <app-nav-list-item *ngFor="let it of itemsToSign; let ix = index" [textTmpl]="tmpl" [navLink]="'/tch-mailing/' + it.documentId "
                         [param]="null" [isTopBorder]="ix == 0">
        <ng-template #tmpl>
          <div class="mailing-item">
            <div class="text-block">
              <table>
                <tr>
                  <td style="width: 20px; vertical-align:top">
                    <mat-checkbox (click)="$event.stopPropagation()" type="text" class="first-line" [(ngModel)]="it.isChecked" (ngModelChange)="processChecked($event, it.documentId)" labelPosition="after">
                    </mat-checkbox>
                  </td>
                  <td>
                    <div class="first-line" [innerHTML]="it.subject"></div>
                    <div class="second-line" [innerHTML]="it.moveDateText"></div>
                  </td>
                </tr>
              </table>
              <!--<div class="publish-status grey">{{it.documentNumber}}</div>-->
            </div>
          </div>
        </ng-template>
      </app-nav-list-item>
      <div *ngIf="!(itemsToSign) || (itemsToSign.length == 0)" class="no-data">Нет рассылок на подписании</div>
      <div *ngIf="itemsToSign && (itemsToSign.length > 0)" style="height: 100px; width: 100%"></div>
    </div>

    <div class="panel-wrapper-fixed" *ngIf="isSignPanelVisible">
      <div class="btn-row">
        <button class="dialog-button action-violet" (click)="onSendClick()">Отправить</button>
      </div>
    </div>

  </div>
</div>
