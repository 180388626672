<div class="wrapper" (click)="showDialog()">

  <div class="sel">{{hh}}</div>
  <div class="colon">:</div>
  <div class="sel">{{mm}}</div>

</div>


<ng-template #hhTmpl let-items="items" let-isHH="isHH" let-isActive="isActive" let-isDlg="isDlg">
  <div class="hh" [ngClass]="isActive ? 'active' : ''" (click)="false && onHHFocus($event,isHH)">
    <div class="list-wrap" #listWrap [style.height.px]="elHeight*(nElsMargin*2 + 1)" [style.top.px]="getListTopPx(isDlg)" (scroll)="onListScroll($event,isHH)">
      <div class="list-el" [ngClass]="i == (isHH?hh:mm) ?'curr' : ''" *ngFor="let i of items" (click)="onElClick(i,isHH,listWrap)" [innerHTML]="getItemHtml(i)"></div>
    </div>
  </div>
</ng-template>

<!--popup окно-->
<div #dlg class="dlg-wrap" [ngClass]="isDlgActivated ? 'show' : ''"  appPreventTouch>
  <div class="backdrop"></div>
  <div class="dlg-body">
    <div class="icon-row" (click)="isDlgActivated = false">
      <svg-icon src="assets/cross.svg" [applyClass]="true" svgClass="icon"></svg-icon>
    </div>
    <div class="edit-row">
      <ng-container *ngTemplateOutlet="hhTmpl;context:{items:hhItems, isHH:true, isActive: true, isDlg: true}"></ng-container>
      <ng-container *ngTemplateOutlet="hhTmpl;context:{items:mmItems, isHH:false, isActive: true, isDlg: true}"></ng-container>
    </div>
  </div>
</div>
