<app-screen-with-panel
                       innerTitle="Вопрос"
                       [bodyTmpl]="bodyTmpl"
                       [backUrl]="backUrl"
                       [isSaveCancel]="true"
                       (onSaveClick)="onSaveClick()"
                       (onCancelEditClick)="onCancelEditClick()"
                       (onDeleteClick)="onDeleteClick()"
                       [doConfirmDelete]="false"
                       deleteBtnSubject="вопрос"
                       [isDeleteEnabled]="!!question.id"
                       >
  <ng-template #bodyTmpl>
    <div class="form-wrapper">
      <div>
        <mat-label>Вопрос</mat-label>
        <quill-editor [(ngModel)]="question.fullText"
                      (onEditorCreated)="$event.focus()"
                       style="touch-action:none;">
        </quill-editor>
      </div>
    </div>
  </ng-template>
</app-screen-with-panel>
