<app-screen-general header="Профиль" backUrl="/">

  <input #fileInput type="file" style="display:none;" name="user-profile-file-input" (change)="onFileInputChange($event)" />

  <div>
    <mat-form-field>
      <div class="fullname-n-photo">
        <input matInput style="display:none;" />
        <div class="fullname"><div [innerHtml]="getUserNameWithCRLF()"></div></div>
        <div class="photo-wrapper">
          <div class="photo">
            <svg-icon *ngIf="!photoExists" src="assets/user-profile-big.svg" [applyClass]="true" svgClass="photo-svg"></svg-icon>
            <div class="img-wrap"><img [src]="safePhoto" *ngIf="photoExists" /></div>
          </div>
          <div class="edit-photo-svg-wrpper"><svg-icon [src]="photoExists ? 'assets/crosssm.svg' : 'assets/camerasm.svg'" [applyClass]="true" svgClass="edit-photo-svg" (click)="onUploadPhotoClick()"></svg-icon></div>
        </div>
      </div>
    </mat-form-field>
  </div>

  <div class="field-block">
    <label>Организация</label>
    <div style="min-height:1.5em;">{{company}}</div>
  </div>

  <div class="field-block">
    <label>Должность</label>
    <div style="min-height:1.5em;">{{position}}</div>
  </div>

  <div class="field-block">
    <label>Логин</label>
    <div>{{login}}</div>
  </div>

  <div class="field-block">
    <label>Почта</label>
    <div>{{email}}</div>
  </div>

  <div class="field-block">
    <label>Телефон</label>
    <div>{{phone}}</div>
  </div>

  <div class="form-field border-bottom">
    <mat-label style="margin-right: 20px;">Показывать контактные данные другим участникам</mat-label>
    <mat-slide-toggle [checked]="showContactData" (change)="onCbxChange('showContactData')" [color]="'primary'"></mat-slide-toggle>
  </div>

  <!--
     <div class="subheader" *ngIf="NOTIFICATIONS_ENABLED">
      Уведомления
    </div>
  -->
  <div class="form-field border-bottom" *ngIf="isBioAvailable">
    <mat-label>Вход при помощи {{bioTypeNameCases(5)}}</mat-label>
    <mat-slide-toggle [checked]="useBioCredential" (change)="onUseBioCredential()" [color]="'primary'"></mat-slide-toggle>
  </div>

  <div class="form-field form-field-header border-bottom" *ngIf="NOTIFICATIONS_ENABLED">
    <mat-label>Разрешить уведомления</mat-label>
    <mat-slide-toggle [checked]="isPushSubscribed" (change)="onSubscriptionManage()" [color]="'primary'"></mat-slide-toggle>
  </div>

  <!--Информация об обеспечительном платеже-->
  <!--
    <app-nav-list-item text="Уведомления TCH–Link" navLink="tch-link/edit" navIcon="open-popup" navIconClass="icon-square" [isTopBorder]="true" [isDisabled]="!hasTCHLinkAccess"  *ngIf="NOTIFICATIONS_ENABLED">
  -->
  <app-nav-list-item text="Уведомления TCH–Link" navLink="tch-link/edit" [isDisabled]="!hasTCHLinkAccess || !isPushSubscribed" *ngIf="NOTIFICATIONS_ENABLED && hasTCHLinkAccess"> </app-nav-list-item>

  <div class="form-field border-bottom" *ngIf="NOTIFICATIONS_ENABLED && hasParticipantRole">
    <mat-label [ngClass]="{'item-disabled':!isPushSubscribed}">Уведомления от мероприятий</mat-label>
    <mat-slide-toggle [checked]="showEventMessages" (change)="onCbxChange('showEventMessages')" [disabled]="!isPushSubscribed" [color]="'primary'"></mat-slide-toggle>
  </div>
  <div class="form-field border-bottom" *ngIf="NOTIFICATIONS_ENABLED && hasTchMailingRole">
    <mat-label [ngClass]="{'item-disabled':!isPushSubscribed}">Уведомления о рассылках ТКП</mat-label>
    <mat-slide-toggle [checked]="isTchMailingSubscribed" (change)="onCbxChange('isTchMailingSubscribed')" [disabled]="!isPushSubscribed" [color]="'primary'"></mat-slide-toggle>
  </div>


  <div class="btn-container">
    <button (click)="onExitClick()" class="btn-exit">Выйти</button>
  </div>

</app-screen-general>
