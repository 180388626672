<!-- Администрирование списка делегирований голоса для голосования -->
<app-screen-with-panel [title]="'Делегирование голоса'"
                       [bodyTmpl]="tmplBody"
                       (onBackClicked)="onBackClicked()"
                       [isEditable]="isEditable"
                       (onEditClick)="onEditProxyClick()"
                       [isInnerScroll]="true">

  <ng-template #tmplBody>
    <app-new-delete-buttons *ngIf="displayProxyList" (onNewClick)="onNewClick()" [newEnabled]="isNewEnabled"></app-new-delete-buttons>

    <div class="grey-notice" *ngIf="!proxies.length">Нажмите на «+» внизу экрана чтобы добавить делегирование</div>

    <div class="warn-block warn" *ngIf="false && hasVotingBegun">Голосование началось.<br /> Делегировать, изменить либо отозвать делегирование нельзя</div>

    <!--Список делегирований-->
    <div class="proxies-wrpper" *ngIf="displayProxyList">
      <app-nav-list-item [textTmpl]="navItemText" (onIconClick)="onNavProxyClick(ix)" *ngFor="let it of proxies; let ix=index">
        <!--Отображение делегирования-->
        <ng-template #navItemText>
          <div class="nav-item">
            <div class="nav-item-user">{{it.userName}}</div>
            <div class="nav-item-proxy">→&nbsp;{{it.proxyName}}</div>
          </div>
        </ng-template>
      </app-nav-list-item>
    </div>

    <!--Детали одного proxy-->
    <div *ngIf="!displayProxyList">
      <div class="one-user-wrapper">
        <div class="green-notice">Голос делегирован</div>
        <div class="notice" *ngIf="!hasVotingBegun">Делегирование голоса можно отозвать до начала голосования, {{votingBeginDateFmt}}</div>
        <div class="hr"></div>
        <mat-form-field>
          <mat-label>Доверитель</mat-label>
          <input matInput [value]="proxies[ixCurrProxy].userName" readonly />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Поверенный</mat-label>
          <input matInput [value]="proxies[ixCurrProxy].proxyName" readonly required />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Номер доверенности</mat-label>
          <input matInput [value]="proxies[ixCurrProxy].paNumber" readonly required />
        </mat-form-field> <mat-form-field>
          <mat-label>Дата доверенности</mat-label>
          <input matInput [value]="formatDate(proxies[ixCurrProxy].paDate)" readonly required />
        </mat-form-field>
      </div>
    </div>

    <!-- Нижняя панель "Делегирование" - редактирование -->
    <app-child-panel header="Делегирование" [isVisible]="isChildPanelVisible" (isVisibleChange)="isChildPanelVisible = $event">
      <div class="proxy-panel-tmpl-wrapper">
        <ng-container *ngTemplateOutlet="childTmpl">
        </ng-container>
      </div>
      <ng-template #main>
        <!-- делегирование -->
        <ng-container>
          <div class="form-wrapper">
            <mat-form-field>
              <mat-label>Доверитель</mat-label>
              <input matInput [(ngModel)]="principalUser.name" readonly (click)="onChoosePerson('principal')" #principalNameNgModel="ngModel" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Поверенный</mat-label>
              <input matInput [(ngModel)]="proxyUser.name" readonly (click)="onChoosePerson('proxy')" #proxyNameNgModel="ngModel" required />
            </mat-form-field>
            <mat-form-field>
              <mat-label>№ доверенности</mat-label>
              <input matInput [(ngModel)]="PANumber" #PANumberNgModel="ngModel" required autocomplete="off" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Дата доверенности</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="PADate" #PADateNgModel="ngModel" autocomplete="off" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker touchUi>
              </mat-datepicker>
            </mat-form-field>
            <!--Кнопка "Делегировать"-->
            <div class="btn-row" *ngIf="isEditNew">
              <button class="dialog-button action-violet new-proxy" (click)="onSaveClick(principalNameNgModel, proxyNameNgModel, PANumberNgModel, PADateNgModel)">Делегировать голос</button>
            </div>
            <!--сохранить / отказаться-->
            <div class="btn-row" *ngIf="!isEditNew">
              <button class="dialog-button action-violet" (click)="onCancelEdit()">Отменить</button>
              <button class="dialog-button action-green" (click)="onSaveClick(principalNameNgModel, proxyNameNgModel, PANumberNgModel, PADateNgModel)">Сохранить</button>
            </div>
            <!--Кнопка "Отозвать делегирование"-->
            <div class="btn-row" *ngIf="!isEditNew">
              <button class="dialog-button action-red revoke-btn" [ngClass]="hasVotingBegun? 'disabled' : ''" (click)="onRevokeClick()">Отозвать делегирование</button>
            </div>
          </div>
        </ng-container>
      </ng-template>

      <!--Выбор участника-->
      <ng-template #selectPerson>
        <app-public-event-user-list [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="false" [showIcon]="false"
                                    (userClick)="userClick($event)" [excludeLoginId]="userListExcludeIds" [isVotingOnly]="isVotingOnly"> 

        </app-public-event-user-list>
      </ng-template>
    </app-child-panel>

  </ng-template>

</app-screen-with-panel>

