<div #wrapper>
  <app-screen-with-panel [title]="'Материал'"
                         [bodyTmpl]="bodyTmpl"
                         (onBackClicked)="onBackClicked()"
                         [isEditable]="isEditable"
                         (onEditClick)="onFileEditClick()">

    <ng-template #bodyTmpl>

      <div class="title-line" *ngIf="fileDetail" appTextEllipsis>{{displayTitle}}</div>
      <div class="second-line" *ngIf="fileDetail" (click)="onFileOpenClick()">{{displayFilename}} {{getFileSizeSz(displayFileSize)}}</div>

      <!--Открытие файла без дополнительного экрана-->
      <div style="display: none;">
        <a #downloadButton [href]="fileDataUrl" [download]="downloadFileName">Скачать файл</a>
      </div>

      <!--Панель редактирования-->
      <app-child-panel header="Материал" [(isVisible)]="isEditPanelVisible" [isPositionFixed]="true">
        <div class="edit-wrapper">
          <form #fileForm>
            <mat-form-field>
              <mat-label>Название</mat-label>
              <input matInput type="text" [(ngModel)]="fileDetail.title" required #titleField="ngModel" name="title" maxlength="1024" />
              <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="fileDetail.title = ''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!--имя файла-->
            <mat-form-field>
              <mat-label>Имя файла</mat-label>
              <input matInput readonly type="text" [value]="fileDetail.fileName || ''" />
              <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onLoadedFileClearClick()" *ngIf="fileDetail?.fileName" required #fileNameField>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!-- загрузка файла -->
            <ng-container *ngIf="!fileDetail?.fileName">
              <input #fileInput type="file" accept="image/*,.pdf" style="display:none;" name="publicEventFileInput" (change)="onFileInputChange($event)" />
              <div class="file-load" (click)="fileInput.click()">
                <div class="icon">
                  <svg-icon src="assets/plus.svg" [applyClass]="true"></svg-icon>
                </div>
                <div class="load-text">Загрузить файл</div>
              </div>
              <div class="label-hint">
                Вы можете загрузить файлы в форматах PDF, BMP, GIF, JPEG, JPG, PNG
              </div>
            </ng-container>
          </form>
          <!-- Скачивание файла -->
          <div class="btns-row" *ngIf="fileDetail.id" (click)="onFileOpenClick()">
            <div class="download-text">Скачать файл</div>
          </div>
          <!--Кнопки-->
          <div class="btns-row">
            <button (click)="onEditCancelClick()" class="dialog-button action-violet">Отменить</button>
            <button (click)="onSaveClick()" class="dialog-button action-green">Сохранить</button>
          </div>
          <div class="btns-row" *ngIf="fileDetail.id">
            <button (click)="onDeleteFileClick()" class="dialog-button action-red">Удалить материал</button>
          </div>
        </div>
      </app-child-panel>
    </ng-template>
    
  </app-screen-with-panel>
</div>
