import { Component, Input, OnInit, Output, TemplateRef, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-screen-general',
  templateUrl: './screen-general.component.html',
  styleUrls: ['./screen-general.component.less']
})
export class ScreenGeneralComponent implements OnInit {

  constructor() { }

  @Input()
  header: string = "";

  ngOnInit(): void {
  }
  @Input()
  backUrl: string;
  @Input()
  showFilter: boolean = false;
  @Input()
  isFilterApplied = false;
  @Input()
  filterTmpl: TemplateRef<any>;
  // Template для помещения в непрокручиваемую область
  @Input()
    fixedTmpl: TemplateRef<any>
  @Output()
  onFilterApply = new EventEmitter<void>();
  @Output()
  onFilterReset = new EventEmitter<void>();
  @Output()
  onScroll = new EventEmitter<any>();

  isFilterVisible = false;

  onFilterApplyClick() {
    this.onFilterApply.emit();
    this.isFilterVisible = false;
  }
  onFilterResetClick() {
    this.onFilterReset.emit();
    this.isFilterVisible = false;
  }
  debugStr() {
    return `window.screen.availH: ${window.screen.availHeight} windowInnerH:${window.innerHeight}`;
  }
  //@HostListener('scroll', ['$event'])
  onScreenBodyScroll($event) {
    this.onScroll.emit($event);
  }
}
