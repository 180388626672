<div class="wrap fix-buttons-right">
  <!--удалить - убрано -->
  <div class="btn-del icon-red" [ngClass]="(deleteEnabled? '' : 'disabled')" (click)="onDelBtnClick()" [hidden]="true">
    <app-icon-button icon="trash" ></app-icon-button>
  </div>
  <div class="btn-new icon-green" [ngClass]="{ 'icon-green': newEnabled, 'disabled': !newEnabled}" (click)="onNewBtnClick()">
    <app-icon-button icon="plus"></app-icon-button>
  </div>
</div>


