import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonDataService } from '../../common-data.service';

@Component({
  selector: 'app-new-delete-buttons',
  templateUrl: './new-delete-buttons.component.html',
  styleUrls: ['./new-delete-buttons.component.less']
})
export class NewDeleteButtonsComponent implements OnInit {

  constructor(private common: CommonDataService) { }

  ngOnInit(): void {
  }

  @Input()
  deleteEnabled: boolean = false;

  @Input()
  newEnabled: boolean = true;

  @Output()
  onDeleteClick = new EventEmitter<void>();

  @Output()
  onNewClick = new EventEmitter<void>();



  onDelBtnClick() {
    this.common.confirmationDialog.confirm({ message: this.deleteConfirmationText })
      .then(result => {
        if (result) {
          this.onDeleteClick.emit();
        }
      });
  }

  onNewBtnClick() {
    this.onNewClick.emit();
  }

  @Input()
  deleteConfirmationText: string;
}
