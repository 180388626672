import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonDataService } from '../../common-data.service';
//import { MatSnackBar } from '@angular/material/snack-bar';
//import { Utils } from '../../utils';
//import { ConfirmationDialogComponent } from '../../common/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-screen-top-header',
  templateUrl: './screen-top-header.component.html',
  styleUrls: ['./screen-top-header.component.less']
})
export class ScreenTopHeaderComponent implements OnInit {

  // constructor(private location: Location, private router: Router, private commonData: CommonDataService, private snackBar: MatSnackBar) { }
  constructor(private location: Location, private router: Router, private commonData: CommonDataService) {  }

  @Input()
  btnBackground: "white" | "violet" = "violet";

  @Input()
  backUrl?: string = undefined;

  @Output()
  onBackClicked = new EventEmitter<void>();

  backSub: Subscription;
  destroy$: Subject<boolean>;
  ngOnInit(): void {
    const self = this;
    if (this.commonData.platform !== "web") {
      this.destroy$ = new Subject<boolean>();
      this.backSub = this.commonData.buckButtondObservable.pipe(takeUntil(this.destroy$)).subscribe((canGoBack: boolean) => {
        if (canGoBack)  self.onBackClick();
      });
    }

  }

  ngOnDestroy(): void {

    if (this.destroy$) {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
    }
    
  }


  // Кнопка "Назад"
  onBackClick() {
    
    console.debug(`screenTopHeader. Click. BackUrl:${this.backUrl}`);
    if (this.backUrl) { // указано, куда переходить. Туда и переходим
      this.router.navigate([this.backUrl]);
    }
    else if (this.onBackClicked.observers.length) {
      this.onBackClicked.emit();
    }
    else {
      this.location.back();
    }

    /*
    this.commonData.confirmationDialog.confirm({ message: "Вы действительно хотите выйти?", title: 'Выход', yesText: 'Ok', noText: 'Отмена'  })
      .then((confirmed) => {
        if (!confirmed) return;

        console.debug(`screenTopHeader. Click. BackUrl:${this.backUrl}`);
        if (this.backUrl) { // указано, куда переходить. Туда и переходим
          this.router.navigate([this.backUrl]);
        }
        else if (this.onBackClicked.observers.length) {
          this.onBackClicked.emit();
        }
        else {
          this.location.back();
        }
      });
    */      


  }
  
  
}
