import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, TimeoutError, empty, of, throwError, timer } from 'rxjs';
import { timeout, map, catchError, delay, retryWhen, concatMap, mergeMap, take } from 'rxjs/operators';
import { CommonDataService } from './common-data.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Utils } from './utils';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
// const defaultTimeout = 5000; -- useValue

//-------------- Intercept http request для добавления в header Authorize="Bearer " + JWT 
@Injectable({
  providedIn: 'root'
})
export class JWTInterceptorService implements HttpInterceptor {

  constructor(public commonData: CommonDataService, private router: Router, private snackBar: MatSnackBar, @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.commonData.isSpinnerVisibleEvt.emit( true );
    let reqToHandle = req;
    if (!this.commonData.JWTToken) {// посмотрим в cookies
      this.commonData.JWTToken = Utils.getCookieByName("JWT");
    }
    // Перезаписать URL
    if (this.commonData.baseUrl && reqToHandle && reqToHandle.url && reqToHandle.url.indexOf('assets/') < 0) {
      reqToHandle = reqToHandle.clone({ url: this.commonData.baseUrl + (reqToHandle.url.startsWith('/') ? '' : '/') + reqToHandle.url });
    }

    // console.log(`ReqToHandle.url:${reqToHandle.url}`);
    // Записать JWTToken
    if (this.commonData.JWTToken) {
      let newReq = reqToHandle;
      if (newReq) {
        let newReq = reqToHandle.clone({
          headers: reqToHandle.headers.set("Authorization", "Bearer " + this.commonData.JWTToken) // .set("X-Request-Timeout", `${reqtimeout}`)
        });
        reqToHandle = newReq;
      }
    }

    // const reqtimeout = Number(req.headers.get('timeout')) || this.defaultTimeout;
    return next.handle(reqToHandle)
      .pipe(
        // timeout(reqtimeout),
        retryWhen(errors =>
          errors.pipe(
            concatMap((error, count) => {
              if (count < 3 && (error.status === 503 || error.status === 504)) {
                return of(error);
              }
              return throwError(error);
            }),
            delay(1000)
        )),
        catchError((err) => {
        this.commonData.isSpinnerVisibleEvt.emit(false);
        let msg = null;
        if (err.status == 401) {// not authorized
          this.router.navigate(['/login', this.router.url]);
        }
        else if (err.status == 403) { // forbidden
          msg = "Ошибка авторизации. Операция запрещена.";
        }
        else if (err.status == 500) { // app error
          if (err?.error instanceof Blob) { // запросили чтение Blob, получили ошибку в Blob
            msg = null;// мы сами выведем ошибку
            let rdr = new FileReader();
            rdr.onloadend = (d) => {
              try {
                let obj = JSON.parse(<string>rdr.result);
                if (obj.message) {
                  this.snackBar.open(obj.message, "Закрыть", Utils.snacBarConfig);
                }
              }
              catch (e) { }
            };
            rdr.readAsText(err.error);
          }
          else {
            if (err.error?.message) { // наше сообщение
              msg = err.error.message;
            }
            else {
              msg = err.message || 'Ошибка. Код:' + err.status;
            }
          }
        }
        else {
          if (err instanceof TimeoutError) {
            msg = `Ошибка timeout, повторите запрос`;
            console.error(`Timeout has occurred:${JSON.stringify(err)}. Url:${reqToHandle.url}. Navigator UserAgent:${navigator.userAgent}. Navigator Platform:${navigator.platform}`);
          }
          else if (err.status == 504) {
            msg = `Ошибка timeout gateway (504), повторите запрос`;
            console.error(`Timeout has occurred:${JSON.stringify(err)}. Url:${reqToHandle.url}. Navigator UserAgent:${navigator.userAgent}. Navigator Platform:${navigator.platform}`);
          }
          else {

            var shortUrl = '';
            var posHttps = reqToHandle.url.indexOf("https:");
            var posTchru = reqToHandle.url.indexOf(".tch.ru");
            if (posHttps >= 0 && posTchru > posHttps) {
              shortUrl = reqToHandle.url.substring(6, posTchru);
            }

            msg = `Системная ошибка ${err.status}, повторите запрос ${shortUrl}`;
            console.error(`Error set header JWT token:${JSON.stringify(err)}. Url:${reqToHandle.url}. Navigator.userAgent:${navigator.userAgent} Navigator.platform:${navigator.platform}`);
          }
        }
        if (msg) {
          let snackBarRef = this.snackBar.open(msg, "Закрыть", Utils.snacBarConfig);
          if (err.status === 403) {
            snackBarRef.afterDismissed().subscribe(() => {
              if (this.router.url.indexOf('/public-events') >= 0) {
                this.router.navigate(['/login', '/public-events']);
              }
              else {
                this.router.navigate(['/login']);
              }
            });
            // snackBarRef.dismiss();
          }
        }
        // return err;
        return empty();
      }))
      .pipe(delay(0))
      .pipe(map<any, HttpEvent<any>>((evt: any) => {
        this.snackBar.dismiss();

        if (evt instanceof HttpResponse) {
          this.commonData.isSpinnerVisibleEvt.emit(false);
        }
        return evt;
      }));
    
  }
}
