import { HttpClient } from '@angular/common/http';
import { Component, Input, ElementRef, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
// import { MatAutocomplete } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../common-data.service';
import { BiometricService } from '../services/biometric.service';
import { SecureStorageService } from '../services/secure-storage.service';
import { AjaxMethods } from '../models/AjaxMethods';
import { Browser } from '@capacitor/browser';
// import VConsole from 'vconsole';
// import { Storage } from '@capacitor/storage';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {


  formGroup: FormGroup;
  constructor(private http: HttpClient, public commonSrv: CommonDataService, private bioSrv: BiometricService, private router: Router, private route: ActivatedRoute, private ngZone: NgZone) {

    const baseUrl = localStorage['userProfile'];
    //if (baseUrl) {
    //  this.commonSrv.baseUrl = baseUrl;
    //}
    this.formGroup = new FormGroup({
      userName: new FormControl(""),
      userPassword: new FormControl(""),
      //userName: new FormControl("", Validators.required),
      //userPassword: new FormControl("", Validators.required),
      //userName: new FormControl("", [this.loginValidator()]),
      //userPassword: new FormControl("", [this.loginValidator()]),
      userProfile: new FormControl(baseUrl && baseUrl !== "null" ? baseUrl : ""),
      // useDebug: new FormControl(this.commonSrv.vConsole !== null && this.commonSrv.vConsole !== undefined)
    });
    // this.formGroup.controls['userProfile'].setValue(this.commonSrv.baseUrl, { onlySelf: true });
    //<mat-checkbox type="text" formControlName="useDebug" name="useDebug" labelPosition="after" *ngIf="userProfileVisible">
    //  Режим отладки
    //</mat-checkbox>

  }

  private loginValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value || this.isBioCredentialAvailable ? null : { forbidden: { value: control.value } };
  };
}

  // @Input()
  isLoginRequired: boolean = true;
  touchstartY: number = 0;
  touchendY: number = 0;
  userProfileVisible: boolean = false;
  gesuredZone: HTMLElement | null = null; // document.getElementsByClassName('padding-space');

  // Варианты контуров
  profileTypes: { id: string; label: string; title: string }[];

  passForgotLink: string = "";
  passChangeLink: string = "";

  loginId: number = 0;
  pageLogin: number = 0;
  useBioCredential?: boolean = null;
  

  ngOnInit(): void {
    this.profileTypes = [
      { id: "https://app.tch.ru", label: "Промышленный ТКП", title: "Промышленный стенд ТКП" },
      { id: "https://appt.tch.ru", label: "Тестовый ТКП", title: "Тестовый стенд ТКП" },
      { id: "https://appdev.tch.ru", label: "Разработка", title: "Разработческий стенд" }
    ];

    this.touchInitialize();


    //if (this.commonSrv.baseUrl === "https://appdev.tch.ru") {
      this.commonSrv.platformPromise.then(platform => {
        if (platform !== "web") {
          const strUseBioCredential = localStorage["useBioCredential"];
          console.log(`strUseBioCredential: ${strUseBioCredential}`);
          if (strUseBioCredential) {
            this.useBioCredential = JSON.parse(strUseBioCredential);

            console.log(`useBioCredential init: ${this.useBioCredential}`);
          }

          // Проверка существования BIO Credential
          // this.bioSrv.checkCredential(null, () => { this.useBioCredential = false; });
          this.bioSrv.checkCredential();

        }
      });
    //}
    
  }
  
  isShowPassword: boolean = false;
    toggleShowPassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  forgotPassClick() {

    const self = this;

    this.setBaseUrl();
    // прочитать ссылки
    this.http.get<LoginPageLinks>(AjaxMethods.LoginPageLinks)
      .subscribe(data => {
        if (data) {
          self.passForgotLink = data.passResetLink;
          self.passChangeLink = data.passChangeLink;
          if (this.passForgotLink) {
            if (self.commonSrv.platform === "web") {
              document.location.href = this.passForgotLink;
            }
            else {
              self.ngZone.run(() => Browser.open({ url: self.passForgotLink }));
            }
          }
        }
      });
    
    
  }

  changePassClick() {
    const self = this;

    this.setBaseUrl();

    // прочитать ссылки
    this.http.get<LoginPageLinks>(AjaxMethods.LoginPageLinks)
      .subscribe(data => {
        if (data) {
          self.passForgotLink = data.passResetLink;
          self.passChangeLink = data.passChangeLink;
          if (self.passChangeLink) {
            if (self.commonSrv.platform === "web") {
              document.location.href = self.passChangeLink;
            }
            else {
              self.ngZone.run(() => Browser.open({ url: self.passChangeLink }));
            }
          }
        }
      });
  }
  
  get isBioAvailable(): boolean {
    //!!! BIO

    //  && this.commonSrv.baseUrl === "https://appdev.tch.ru"
    if (this.commonSrv.platform !== "web")  {
      return this.bioSrv ? this.bioSrv.isAvailable : false;
    }
    return false;
  }

  get isBioCredentialAvailable(): boolean {
    return this.isBioAvailable && this.useBioCredential ? this.bioSrv.isCredentialAvailable : false;
  }

  get isBioTypeFace(): boolean {

    return this.isBioAvailable ? this.bioSrv.isBiometryTypeFace : false;
  }

  get isBioTypeTouch(): boolean {

    return this.isBioAvailable ? this.bioSrv.isBiometryTypeTouch : false;
  }

  get bioTypeName(): string {
    return this.isBioAvailable ? this.bioSrv.biometryTypeName : "";
  }


  bioTypeNameCases(casenumber, iscapitalise = false): string {
     return this.isBioAvailable ? this.bioSrv.biometryTypeNameCase(casenumber, iscapitalise) : "";
  }

  

  // кнопка "Войти по BIO"
  onBioSubmit() {

    //!!! DEBUG
    //if (this.formGroup.controls['useDebug'].value == true) {
    //  this.commonSrv.vConsole = new VConsole();  // { theme: 'dark' }
    //  console.log(`Debug mode enabled.`)
    //}

    if (this.isBioCredentialAvailable) {
      this.isLoginRequired = false;

      this.bioSrv.loginByCredential((loginId, token) => {
        this.isLoginRequired = true;
        this.navigateReturnUrl(loginId, token);
      }, () => {
        //setTimeout(() => {
        this.isLoginRequired = true;
        this.pageLogin = 0;
        //}, 500);
        
      });
    }
  }


  // кнопка "Войти"
  onSubmit(select?: number) {


    if (!this.formGroup.valid) {
      return;
    }

    //!!! DEBUG
     //if (this.formGroup.controls['useDebug'].value == true) {
    //  this.commonSrv.vConsole = new VConsole();  // { theme: 'dark' }
    //  console.log(`Debug mode enabled.`)
    //}
    const self = this;
    if (this.isBioAvailable && this.useBioCredential !== false && !this.isBioCredentialAvailable) {
      // Если BIO доступно, явно не выключено в профиле и еще не установлены Bio Credential
      // Входим либо с сохранением  Bio Credential, либо с помощью пароля (без сохранения)
      this.setBaseUrl();
      this.http.post<{ loginId: number, token: string }>(AjaxMethods.login, { ...{}, ...this.formGroup.value, ...{ timeZoneOffset: - (new Date().getTimezoneOffset() / 60) } })
        .subscribe(data => {
          if (select === 2) {
            // входим с сохранением Bio Credential и включаем в профиле пользования использование BIO
            const user = this.formGroup.value.userName;
            const passw = this.formGroup.value.userPassword;
            this.bioSrv.setCredential(this.commonSrv.baseUrl, (typeof user === 'string' ? user : user?.name), passw, () => {
              self.useBioCredential = true;
              localStorage.setItem("useBioCredential", JSON.stringify(self.useBioCredential));

              // Запрос BIO
              setTimeout(() => {
                this.onBioSubmit();
              }, 500);
            });
          }
          else if (select === 1) {
            // входим с помощью пароля и выключаем в профиле пользования использование BIO
            self.useBioCredential = false;
            localStorage.setItem("useBioCredential", JSON.stringify(self.useBioCredential));
            this.navigateReturnUrl(data.loginId, data.token);
          }
          else if (!select) {
            // При первом нажатии на вход переходим на другую страницу
            this.pageLogin = 1;
          }
        });
      
    }
    else {
        // Входим с помощью пароля
        this.setBaseUrl();
        this.http.post<{ loginId: number, token: string }>(AjaxMethods.login, { ...{}, ...this.formGroup.value, ...{ timeZoneOffset: - (new Date().getTimezoneOffset() / 60) } })
          .subscribe(data => {
            this.navigateReturnUrl(data.loginId, data.token);
          });
    }
  }

  setBaseUrl() {
    const baseUrl = this.formGroup.controls['userProfile'].value;
    if (baseUrl && baseUrl !== "null") {
      this.commonSrv.baseUrl = baseUrl;
      localStorage['userProfile'] = this.commonSrv.baseUrl;
    }
  }

  navigateReturnUrl(loginId?, token?) {

    if (loginId) this.commonSrv.loginId = loginId;
    if (token) this.commonSrv.JWTToken = token;
    this.route.params.subscribe(params => {
      const fromUrl = params["returnurl"];
      if (fromUrl) {
        console.log(`Goto Home. Url:${fromUrl}.`);
        this.router.navigate([fromUrl]);
      }
      else {
        console.log(`Goto Home. Url:/home.`);
        this.router.navigate(["/home"]);
      }
    });

  }


 


  /*
  onUseDebugCheck(check: boolean) {

    this.ngZone.run(() => {
      // if (this.formGroup.controls['useDebug'].value === true) {
      if (check) {
        if (!this.commonSrv.vConsole) {
          this.commonSrv.vConsole = new VConsole();  // { theme: 'dark' }
          console.log(`Debug mode enabled.`);
        }
      }
      else {
        if (this.commonSrv.vConsole) {

          // After destroy Error: Failed to complete negotiation with the server: TypeError: Cannot read properties of undefined (reading 'scrollHeight')
          (this.commonSrv.vConsole as VConsole).hide();
          (this.commonSrv.vConsole as VConsole).destroy();
         
          this.commonSrv.vConsole = null;
          console.log(`Debug mode disabled.`);
        }
      }

    });


  }
  */

  touchInitialize() {

    const self = this;
    this.gesuredZone = document.getElementById("split-row");
    
    //!!! TOUCH MacBook 
    this.gesuredZone.addEventListener("mousedown", function (event) {
      self.touchstartY = event.clientY;
      //self.gesuredZone.classList.add("split-row-active");
      //event.preventDefault();
      event.stopPropagation();
    }, false);
 
    this.gesuredZone.addEventListener("mouseup", function (event) {
      self.touchstartY = 0;
      //self.gesuredZone.classList.remove("split-row-active");
      event.preventDefault();
      event.stopPropagation();
    }, false);
  
    this.gesuredZone.addEventListener("mousemove", function (event) {
        if (self.touchstartY > 0) {
          self.touchendY = event.clientY;
          if (self.touchendY < self.touchstartY - 2 && self.userProfileVisible) {
            self.userProfileVisible = false;
          }
          else if (self.touchendY > self.touchstartY + 2 && !self.userProfileVisible) {
            self.userProfileVisible = true;
          }
        }
        event.preventDefault();
        event.stopPropagation();
      }, false);
    //!!! END TOUCH MacBook




    this.gesuredZone.addEventListener("touchstart", function (event) {
      const touches = event.touches; // array of all touch data
      //var target = touches[0].target; // what DOM element was touched
      self.touchstartY = touches[0].screenY;

      event.stopPropagation();
    }, false);

    this.gesuredZone.addEventListener("touchend", function (event) {
      self.touchstartY = 0;
      event.preventDefault();
      event.stopPropagation();
    }, false);

    this.gesuredZone.addEventListener("touchmove", function (event) {
      const touches = event.touches; // array of all touch data
      self.touchendY = touches[0].screenY;
      if (self.touchendY < self.touchstartY - 2 && self.userProfileVisible) {
        self.userProfileVisible = false;
      }
      else if (self.touchendY > self.touchstartY + 2 && !self.userProfileVisible) {
        self.userProfileVisible = true;
      }
      event.preventDefault();
      event.stopPropagation();
    }, false);

  }

}
