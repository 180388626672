<app-screen-general header="Служба поддержки" backUrl="/">
  <div class="form-wrapper">
    <mat-form-field>
      <mat-label>Почта</mat-label>
      <input matInput value="support@tch.ru" readonly />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Телефон</mat-label>
      <input matInput value="+7 495 788 12 22" readonly />
    </mat-form-field>
  </div>
</app-screen-general>
