import { HttpClient } from '@angular/common/http';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ChatService, ChatMessageType } from '../services/chat.service';
import { CommonDataService } from '../common-data.service';
import { BiometricService } from '../services/biometric.service';
import { AjaxMethods } from '../models/AjaxMethods';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  constructor(private commonSrv: CommonDataService, private router: Router, private http: HttpClient, private bioSrv: BiometricService, private chatSrv: ChatService, private _ngZone: NgZone) { }

  isAccess = false;


  ngOnInit(): void {

    //if (this.commonSrv.platform === "web" || this.commonSrv.JWTToken) {
      console.log(`Goto Web Home Url`);

      // Получить права, чтобы понять, какие кнопки показать
      this.http.get<HomeScreenAccessRights>(AjaxMethods.getPublicEventsAccessRights)
        .pipe(
          map(data => data),
          catchError(err => {
            // console.log('caught mapping error and rethrowing');
            // console.log('caught mapping error and rethrowing', err);
            // return throwError(err);
            return of([]);
          }))
        .subscribe(
          (data: HomeScreenAccessRights) => {
            if (this.commonSrv.JWTToken) {
              this.isAccess = true;
            }
            this.loginId = data.loginId;

            // !!!CHAT
            // Соединение для чата
            if (this.chatSrv) {
              if (data.publicEventsAccess && data.publicEventsAccess.length > 0) {
                this._ngZone.runOutsideAngular(() => {
                    this.chatSrv.connect(data.publicEventsAccess, this.loginId);
                });
                
              }
            }

            this.hasCCSAccess = data.hasCCSAccess;
            this.hasPublicEventsAccess = data.hasPublicEventsAccess;
            this.hasTCHLinkAccess = data.hasTCHLinkAccess;
            this.hasTCHMailingAccess = data.hasTCHMailingAccess;
            this.unreadNotificationsCount = data.unreadNotificationsCount > 9 ? "9+" : data.unreadNotificationsCount ? String(data.unreadNotificationsCount) : null;
          },
          err => console.log('HTTP error: ', err),
          () => console.log('HTTP request completed.')
        );
    //}
    //else {
    //  //!!! BIO временный комментарий
    //  this.bioSrv.checkCredential(null, () => {
    //    console.log(`Goto Web Login. Url:${this.router.url}.`);
    //    this.router.navigate(['/login', this.router.url]);
    //  });
    //}
  }

  loginId: number;
  hasCCSAccess: boolean;
  hasPublicEventsAccess: boolean;
  hasTCHLinkAccess: boolean;
  hasTCHMailingAccess: boolean;
  unreadNotificationsCount: string;


  
  // Профайл пользователя
  onUserProfileClick() {
    if (this.isAccess)
        this.router.navigate(["/user-profile"]);
  }

  // Служба поддержки
  onHelpClick() {
    if (this.isAccess)
        this.router.navigate(["home/help"]);
  }

  // Оповещения
  onNotificationsClick() {
    if (this.isAccess)
        this.router.navigate(["notifications"]);
  }

  onClickedPublicEvents(CCS: boolean) {
    if (this.isAccess) {
      console.debug(`onClickedPublicEvents:${CCS}`);
      this.commonSrv.publicEventsCCS = CCS;
    }
  }

}
