import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AjaxMethods } from '../../models/AjaxMethods';
import { CommonDataService } from '../../common-data.service';
import { Utils } from '../../utils';
import { VoteSnackBarComponent } from './VoteSnackBarComponent';

@Component({
  selector: 'app-public-event-vote',
  templateUrl: './public-event-vote.component.html',
  styleUrls: ['./public-event-vote.component.less']
})
export class PublicEventVoteComponent implements OnInit {


  private clickNavigateSubject: Subject<number> = null;
  private clickNavigateSubscription: Subscription = null;

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private commonData: CommonDataService, private snackBar: MatSnackBar, private appRef: ApplicationRef) {

    if (this.commonData.platform === 'ios') {
      // var self = this;
      this.clickNavigateSubject = new Subject();
      this.clickNavigateSubscription = new Subscription();
      this.clickNavigateSubscription.add(
        // Here you can set your debounce time
        this.clickNavigateSubject.pipe(debounceTime(500)).subscribe((questionNo) => {
          this.NavigateToPollingByIx(questionNo);
        })
      );
    }

  }

  ngOnInit(): void {
    // Параметры из url
    this.route.params.subscribe(params => {
      let publicEventId = Number(params["publicEventId"]);
      let pollingId = Number(params["pollingId"]);
      let questionId = Number(params["questionId"]);
      if (!this.questions) {// еще не читали
        // Модель - из history state или запросить с сервера
        if (history.state.data?.questions) {
          this.questions = history.state.data?.questions;
          this.questionId = history.state.data?.question.id;
          this.SetVoteProxyFor();
          this.PrepareQuestions4User();
        }
        else { // пришли откуда-то еще. Загрузить все вопросы
          PublicEventVoteComponent.GetQuestions(this.route, this.http)
            .subscribe((data) => {
              this.questions = data;
              this.questionId = questionId;
              this.SetVoteProxyFor();
              this.PrepareQuestions4User();
            });
        }
      }
      else {
        this.appRef.tick();
      }
    });
  }


  // unsubscribe
  ngOnDestroy(): void {
    if (this.clickNavigateSubscription) {
      this.clickNavigateSubscription.unsubscribe();
    }
  }



  // Установить voteProxyFor = первому пользователю, если в списке один только
  SetVoteProxyFor() {
    if (this.questions?.doesProxyForExist && this.questions.proxyFor.length) {
      this.voteForSelected = this.questions.proxyFor[0];
      this.questions.proxyFor[0].isSelected = true;
    }
  }
  // Записать Question[] и proxyFor в questions4User
  PrepareQuestions4User() {
    if (Object.keys(this.questions4User).length) return; // только один раз. Иначе при навигации между вопросами затрутся результаты голосования из allVotes
    this.questions4User = {};
    if (this.questions?.doesProxyForExist) {// голосую за кого-то
      this.questions.proxyFor.forEach(p => this.questions4User[p.loginId] = {
        questions: this.questions.items.map(q => {
          var qn = Utils.cloneDeep(q);
          // найти голосование текущего пользователя и проставить в qn
          var vote = qn.allVotes.find(v => v.userAccountId == p.loginId);
          if (vote) {
            qn.voting = vote.voting;
            qn.voteText = vote.voteText;
            qn.voteEnText = vote.voteEnText;
          }
          else {
            qn.voting = null;
            qn.voteText = null;
            qn.voteEnText = null;
          }
          return qn;
        })
      });
    }
    else {// сам за себя - просто с 0 вопросы в одном экземпляре, вырожденный случай
      this.questions4User[0] = { questions: this.questions.items };
    }
  }
  // Прочитать Questions
  static GetQuestions(route: ActivatedRoute, http: HttpClient): Subject<Questions> {
    let subj = new Subject<Questions>();
    
    //route.params.subscribe(params => {
    let pollingId = route.snapshot.params["pollingId"];
      http.get<Questions>(AjaxMethods.getPublicEventQuestions.replace("{pollingId}", pollingId))
        .subscribe(data => {
          subj.next(data);
          subj.complete();
        });
    //});
    return subj;
  }
  questions: Questions;
  questionId: number; // текущий ИД вопроса, по нему и по loginId вычисляем question
  // Получить Question 
  get question(): Question {
    if (!this.questionId || !this.questions) return null;
    return this.questions4User[this.loginId]?.questions.find(q => q.id == this.questionId);
  }
  questions4User: { [logonId: number]: { questions: Question[] } } = {};
  isChildPanelVisible = false;

  // loginId выбранного за кого голосую или 0, если только за себя
  get loginId(): number {
    if (!this.questions?.doesProxyForExist) {// только за себя
      return 0;
    }
    return this.voteForSelected?.loginId;
  }
  // question для выбранного пользователя
  // Выбранный proxyFor
  voteForSelected;
  
  get backUrl(): string {
    let params = this.route.snapshot.params;
    let publicEventId = params["publicEventId"];
    let pollingId = params["pollingId"];
    return `public-events/${publicEventId}/polling/${pollingId}/`;
  }

  // Переход к вопросу pollings[ix]
  private NavigateToPollingByIx(ix: number) {
    if (ix >= 0 && ix < this.questions.items.length) {
      // parse на текущий URL, замена ID в последнем сегменте на указанный в параметрах
      this.route.url
        .subscribe(segs => {
          this.questionId = this.questions.items[ix].id;
          let newPath = '/' + segs.map(s => s.path).slice(0, segs.length - 1).join('/') + '/' + this.questions.items[ix].id;
          this.router.navigate([newPath]
            , {
              state: {
                //data: { question: this.questions.items[ix], questions: this.questions }
              }
            });
        });
    }
  }
  // Следующий вопрос
  onNextQuestionCLick() {
    if (this.question && this.questions) {
      if (this.question.no < this.questions.items.length) {
        if (this.commonData.platform === 'ios') {
          this.clickNavigateSubject.next(this.question.no);
        }
        else {
          this.NavigateToPollingByIx(this.question.no);
        }
      }
    }
  }
  // Предыдущий вопрос
  onPrevQuestionClick() {
    if (this.question && this.questions) {
      if (this.question.no > 1) {
        // uses an ever-changing value in order to always trigger the update
        // no считается от 1, (-1) на переход к индексу и (-1) - предыдущий по отн. к текущему
        if (this.commonData.platform === 'ios') {
          this.clickNavigateSubject.next(this.question.no - 2);
        }
        else {
          this.NavigateToPollingByIx(this.question.no - 2);
        }
      }
    }
  }
  // Классы для кнопки
  getOptClasses(opt: any): any {
    let ret: any = {};
    let voting = this.question?.voting;
    if (opt.enumVal == this.question?.voteEnText) {// голосование было. Подсветить
      ret['active'] = 1;
    }
    ret[opt.enumVal] = 1;
    return ret;
  }
  // Голосование
  onVoteClick(v: number, enTextV: string, textV:string) {
    if (this.questions.doesProxyForExist && !this.voteForSelected) return;
    if (this.questions.polling.isMultipleVotingAllowed || !this.question.voting) {
      if (this.question.voting != v) {/*с одним и тем же результатом нехрен*/
        this.http.post<any>(AjaxMethods.postVoting.replace("{id}", this.question?.id + '').replace("{v}", v + '')
          .replace('{forLoginId}', this.voteForSelected?.loginId), '').subscribe(() => {
          // если все вопросы обязателны и все вопросы отвечены, то дать сообщение
            let isThisVoted = Boolean(this.question.voting); // этот вопрос был отвечен и раньше
            this.question.voteEnText = enTextV;
            this.question.voting = v;
            this.question.voteText = textV;
            
            if (!isThisVoted // этот вопрос еще не был отвечен
              && !this.questions.polling.isUncompletedResultsCountedIn
              && this.questions4User[this.loginId]?.questions.every(it => Boolean(it.voting)))
            {
              this.snackBar.open("Спасибо, вы ответили на все вопросы, ваш голос принят"
                 , "Закрыть"
                //, "Вернуться к списку вопросов"
                , Utils.snacBarInfoConfig)
                //.onAction().subscribe(() => this.router.navigate([this.backUrl]))
                ;
            }
        });
      }
    }
    else {
      let snackbarRef = this.snackBar.open('Голосование более одного раза не разрешено.', "Закрыть", Utils.snacBarConfig);
      
    }
  }
  // Перейти в режим редактирования вопроса
  onEditClick() {
    this.route.params.subscribe(params => {
      let publicEventId = params["publicEventId"];
      let pollingId = params["pollingId"];
      this.router.navigate([`public-events/${publicEventId}/polling/${pollingId}/question/${this.question.id}`]);
    });
  }
  // Выбрали пользователя "за кого голосует"
  onVoteForSelected(usr) {
    this.voteForSelected = usr;
    this.isChildPanelVisible = false;
    this.questions.proxyFor.forEach(opt => opt.isSelected = false);// всем сбросить isSelected
    usr.isSelected = true;
  }
  // вопрос/вопросов/вопроса
  questionsDecl(n: number) {
    return Utils.NumDecl(n, ['вопроса', 'вопросов', 'вопросов']);
  }
}
