import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';

@Component({
  selector: 'app-tchlink-edit',
  templateUrl: './tchlink-edit.component.html',
  styleUrls: ['./tchlink-edit.component.less']
})
export class TCHLinkEditComponent implements OnInit {

  constructor(private http: HttpClient, private commonSrv: CommonDataService) { }

  get backUrl() {
    return "user-profile";
    //return this.commonSrv.TCHLinkEditBackUrl;
  }

  ngOnInit(): void {
    // Загрузить модель
    this.http.get<{ isEnabled: boolean, threshold?: number, thresholdFmt:string }>(AjaxMethods.tchLinkGetAgencyThreshold)
      .subscribe(data => {
        this.isNotifyThreshold = data.isEnabled;
        this.thresholdAmount = data.threshold;
        this.thresholdAmountFmt = data.thresholdFmt;
        if (this.thresholdAmountFmt && this.thresholdAmountFmt.length > 0 && this.thresholdAmountFmt != '?') {
          this.isAmount = true;
        }
        else {
          this.isAmount = false;
        }
      },
      err => console.log('HTTP Error', err));
  }

  isNotifyThreshold: boolean;
  thresholdAmount = null;
  thresholdAmountFmt = "";
  isAmount = false;

  onNotifyThresholdChnage() {
    // переключить подписку на обновление
    this.http.post<any>(AjaxMethods.tchLinkPostAgencyThresholdNfy.replace("{isEnabled}", String(!this.isNotifyThreshold)), "")
      .subscribe(() => this.isNotifyThreshold = !this.isNotifyThreshold);
  }
}
