import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSetPosLeftTop]'
})
export class SetPosLeftTopDirective implements AfterViewChecked {

  constructor(private htmlEl: ElementRef) { }
  done: boolean;
  moveLeftTop() {
    if (!this.done) {
      let el: HTMLElement = this.htmlEl.nativeElement;
      let rect = el.getBoundingClientRect();
      var topNav = document.querySelector(".top-nav-area");
      let topnavHeight = topNav?.clientHeight || 0;
      if (rect.x > 0) {
        el.style.left = -rect.x + 'px';
        el.style.top = (-rect.y + topnavHeight) + 'px';
      }
      this.done = true;
    }
  }

  ngAfterViewChecked(): void {
    this.moveLeftTop();
  }

  @HostListener("window:resize")
  onWindowResize() {
    this.moveLeftTop();
  }

}
