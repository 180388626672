import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonDataService } from '../common-data.service';
import { AjaxMethods } from '../models/AjaxMethods';
import { Utils } from '../utils';

@Component({
  selector: 'app-tch-mailing',
  templateUrl: './tch-mailing.component.html',
  styleUrls: ['./tch-mailing.component.less']
})

export class TchMailingComponent implements OnInit {

  constructor(private http: HttpClient, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private commonSrv: CommonDataService) {
  }

  itemsToApprove: TchMailingItem[];
  itemsToSign: TchMailingItem[];
  backUrl = "/";
  isSignPanelVisible = false;
  checkedNumber = 0;
  signRequest: TchMailingSignRequest = {
    documentIds: []
  }

  ngOnInit(): void {
    this.route.url.subscribe(urls => {
      this.http.get<TchMailingItems>(AjaxMethods.TchMailingList)
        .subscribe(data => {
          this.itemsToApprove = data.documentsToApprove;
          this.itemsToSign = data.documentsToSign;
          this.isSignPanelVisible = false;
          this.checkedNumber = 0;
        });
    });
  }

  processChecked(ev: boolean, id: number) {
    if (ev) {
      this.checkedNumber++;
      this.signRequest.documentIds.push(id);
    }
    else {
      var index = this.signRequest.documentIds.indexOf(id);
      if (index !== -1) {
        this.signRequest.documentIds.splice(index, 1);
      }
      this.checkedNumber--;
    }
    this.isSignPanelVisible = (this.checkedNumber > 0);
  }

  onSendClick() {
    var that = this;
    this.http.post<any>(AjaxMethods.TchMailingSignDocs,
      this.signRequest).subscribe(d => {
        that.http.get<TchMailingItems>(AjaxMethods.TchMailingList)
          .subscribe(data => {
            that.itemsToApprove = data.documentsToApprove;
            that.itemsToSign = data.documentsToSign;
            that.isSignPanelVisible = false;
            that.checkedNumber = 0;
          });
      });
  }
}
