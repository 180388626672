import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http'
// import { download, Download } from './download'
import { distinctUntilChanged, scan, map, tap } from "rxjs/operators";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
// import { FileOpener } from '@ionic-native/file-opener';
// import { FileOpener } from 'cordova-plugin-file-opener2';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { of, Observable } from 'rxjs'


export interface Download {
  content: Blob | null;
  progress: number;
  state: "PENDING" | "IN_PROGRESS" | "DONE";
}

// remove: android.permission.REQUEST_INSTALL_PACKAGES from plugins.xml
// https://github.com/pwlin/cordova-plugin-file-opener2
// https://github.com/pwlin/cordova-plugin-file-opener2/issues/329
// https://github.com/capacitor-community/file-opener
@Injectable({ providedIn: 'root' })
export class DownloadService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
  }

  download(url: string, filename?: string, mimetype?: string, isprogress: boolean = false): Observable<Download> {

    
    if (/base64/i.test(url)) {
      this.saveData(url, filename, mimetype);
      return of({
        progress: 100,
        state: "DONE",
        content: null
      });
    }
    

    return this.http.get(url, {
      reportProgress: isprogress,
      observe: 'events',
      responseType: 'blob'
    }).pipe(this.downloadBlob(blob => {
      this.saveBlob(blob, filename, mimetype);
    }))
  }

  private saveBlob(data: Blob, filename?: string, mimetype?: string) {

    var self = this;
    var reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = async function () {

      var base64data = reader.result;
      await self.saveData(base64data, filename);

    }

  }

  private async saveData(base64data: string | ArrayBuffer, filename?: string, mimetype?: string) {

    try {
      // console.log('Write file begin: ', filename);

      const result = await Filesystem.writeFile({
        path: filename,
        data: <string>base64data,
        directory: Directory.Data,
        // directory: Directory.Documents,
        recursive: true,
        // encoding: Encoding.UTF8,
      });

      console.log('Wrote file', result.uri);

      var fileOpener = new FileOpener();
      fileOpener.showOpenWithDialog(result.uri, mimetype)
        .then(() => console.log('File is opened'))
        .catch(e => console.log('Error opening file', e));

      
    } catch (e) {
      console.error('Unable to write file', e);
    }
  }




  private downloadBlob( saver?: (b: Blob) => void): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
    return (source: Observable<HttpEvent<Blob>>) =>
      source.pipe(
        scan(
          (download: Download, event): Download => {
            if (this.isHttpProgressEvent(event)) {
              return {
                progress: event.total
                  ? Math.round((100 * event.loaded) / event.total)
                  : download.progress,
                state: "IN_PROGRESS",
                content: null
              };
            }
            if (this.isHttpResponse(event)) {
              if (saver) {
                saver(event.body);
              }
              return {
                progress: 100,
                state: "DONE",
                content: event.body
              };
            }
            return download;
          },
          { state: "PENDING", progress: 0, content: null }
        ),
        distinctUntilChanged((a, b) => a.state === b.state
          && a.progress === b.progress
          && a.content === b.content
        )
      );
  }


  private isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  private isHttpProgressEvent(
    event: HttpEvent<unknown>
  ): event is HttpProgressEvent {
    return (
      event.type === HttpEventType.DownloadProgress ||
      event.type === HttpEventType.UploadProgress
    );
  }




}
