import { Component, Input, OnInit, Output, TemplateRef, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-list-item',
  templateUrl: './nav-list-item.component.html',
  styleUrls: ['./nav-list-item.component.less']
})
export class NavListItemComponent implements OnInit {

  constructor(private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  @Input()
  text = '';

  @Input()
  secondLine = '';

  @Input()
  navLink = '';

  @Input()
  param?: any;

  @Input()
  icon: string = '';

  @Input()
  photo: string = '';

  @Input()
  photoSize: any = 38;


  @Input()
  sequenceNo?: any = undefined;

  @Input()
  textTmpl: TemplateRef<any> | null = <any>null;

  // click чувствителен только на иконку
  @Input()
  isClickOnIconOnly: boolean;

  //Иконка, по клику на которую происходит навигация
  @Input()
  navIcon: string = 'chevron-right';
  // класс навигационной иконки. icon-right - уголок вправо - узкий и высокий, icon-square - квадрат
  @Input()
  navIconClass: 'icon-right' | 'icon-square' = 'icon-right';
  // линия над первым элементом в списке
  @Input()
  isTopBorder = false;
  // disabled
  @Input()
  isDisabled = false;
  @Input()
  showIcon = true
  @Input()
  showPhoto = false
  // click icon navLink
  @Output()
  onIconClick = new EventEmitter<void>();
  
  onClick(isWrap: boolean) {
    if (this.navLink && (!isWrap || !this.isClickOnIconOnly)) {
      if (!this.isDisabled) {
        this.router.navigate([this.navLink], { state: { data: this.param } });
      }
    }
    else if (!this.navLink && isWrap/*чтобы не попало в следующее условие if*/) {
      if (!this.isDisabled)
           this.onIconClick.emit();
    }
    if (!isWrap && !this.isDisabled) {
          this.onIconClick.emit();
    }
  }

  get iconPath(): string {
    return 'assets/' + this.icon + (this.icon && /.svg$/i.test( this.icon ) ? '' : '.svg');
  }

  get photoPath(): string {
      return this.photo ? 'assets/' + this.photo + (/.svg$/i.test(this.photo) ? '' : '.svg') : 'assets/user-profile-big.svg';
  }


  get photoSafe() {
      return this.photoIsImage ? this.sanitizer.bypassSecurityTrustResourceUrl(this.photo) : null;
  }

  // Фото есть картинка
  get photoIsImage(): boolean { return Boolean(this.photo) && /base64/i.test(this.photo); }

}
