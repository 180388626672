<div #ultimateWrapper>
  <app-screen-with-panel [title]="mainTitle"
                         [bodyTmpl]="tmpl || tmplList"
                         [isEditable]="isEditor && mode == viewModeNum"
                         (onEditClick)="switchMode(editModeNum)"
                         (onBackClicked)="onBackClick()"
                         (onSaveClick)="onSave()"
                         (onCancelEditClick)="onCancelEdit()"
                         (onDeleteClick)="onDelete()"
                         [isSaveCancel]="isSaveCancel"
                         deleteBtnSubject="организатора"
                         [isDeleteEnabled]="isDeleteEnabled"
                         [isInnerScroll]="true">
    <ng-template #tmplList>
      <app-new-delete-buttons *ngIf="isEditor" (onNewClick)="onNewClick()"></app-new-delete-buttons>

      <div class="grey-notice" *ngIf="!items.length && isEditor">
        Нажмите на «+» внизу экрана чтобы добавить организатора и способ связи с ним
      </div>
      <div>
        <div class="items-wrapper">
          <app-nav-list-item *ngFor="let it of items" [textTmpl]="contactItemTmpl" (onIconClick)="onEditItemClick(it)" [isTopBorder]="false">
            <ng-template #contactItemTmpl>
              <div class="contact-item-tmpl">
                <div class="text-wrapper">
                  <div [style.font-weight]="getItemSubjectFontWeight(it)">{{it.fullName}}</div>
                  <div appTextEllipsis class="full-text">{{it.responsibility}}</div>
                </div>
              </div>
            </ng-template>
          </app-nav-list-item>
        </div>
      </div>

    </ng-template>

    <!--Просмотр карточки контакта-->
    <ng-template #tmplViewItem>
      <div class="top-header" style="word-spacing: 9999px">
        <div class="fullname-n-photo">
          <div class="fullname">{{editItem.fullName}}</div>
          <div class="photo-wrapper">
            <div class="photo">
              <svg-icon *ngIf="!photoExists" src="assets/user-profile-big.svg" [applyClass]="true" svgClass="photo-svg"></svg-icon>
              <div class="img-wrap"><img [src]="safePhoto" *ngIf="photoExists" /></div>
            </div>
          </div>
        </div>
      </div>
      <div class="contact-resp">{{editItem?.responsibility}}</div>
      <div class="field-block" *ngIf="editItem?.email">
        <label>Почта</label>
        <div>{{editItem?.email}}</div>
      </div>
      <div class="field-block" *ngIf="editItem?.phone">
        <label>Телефон</label>
        <div>{{editItem?.phone}}</div>
      </div>
      <div class="field-block" *ngIf="editItem?.telegramUserName || editItem?.whatsappPhone">
        <h3>Мессенджеры</h3>
        <div class="topheader-text" *ngIf="editItem?.telegramUserName">
          <div class="icon-wrapper"><svg-icon src="assets/telegram.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon></div>
          <a href="https://t.me/{{editItem.telegramUserName}}" target="_blank">@{{editItem.telegramUserName}}</a>
        </div>
        <div class="topheader-text" *ngIf="editItem?.whatsappPhone">
          <div class="icon-wrapper"><svg-icon src="assets/whatsapp.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon></div>
          <a href="https://api.whatsapp.com/send?phone={{editItem.whatsappPhone}}" target="_blank">{{editItem.whatsappPhone}}</a>
        </div>
      </div>
    </ng-template>

    <!-- Редактирование карточки контакта -->
    <ng-template #tmplEditItem>
      <div class="top-header">Организатор</div>
      <br />
      <br />
      <div class="form-wrapper" style="margin-top: 20px; margin-bottom: 20px;">
        <mat-form-field (click)="onEditNameClick()">
          <mat-label>Ф.И.О. организатора</mat-label>
          <div class="fullname-n-photo">
            <div class="fullname"><input matInput [(ngModel)]="editItem.fullName" required /></div>
            <div class="photo-wrapper">
              <div class="photo">
                <svg-icon *ngIf="!photoExists" src="assets/user-profile-big.svg" [applyClass]="true" svgClass="photo-svg"></svg-icon>
                <div class="img-wrap"><img [src]="safePhoto" *ngIf="photoExists" /></div>
              </div>
            </div>
          </div>
        </mat-form-field>
        <!--<mat-form-field (click)="isUsersPanelVisible = true">
        <mat-label>Ф.И.О. организатора</mat-label>
        <input matInput [(ngModel)]="editItem.fullName" required />
      </mat-form-field>-->
        <mat-form-field>
          <mat-label>За что отвечает?</mat-label>
          <textarea matInput [(ngModel)]="editItem.responsibility" rows="5" maxlength="200"></textarea>
        </mat-form-field>
        <!--<mat-form-field>
        <mat-label>Почта</mat-label>
        <input matInput [(ngModel)]="editItem.email" />
      </mat-form-field>-->
        <div class="field-block" *ngIf="editItem?.email">
          <label>Почта</label>
          <div>{{editItem?.email}}</div>
        </div>
        <!--<mat-form-field>
        <mat-label>Телефон</mat-label>
        <input matInput [(ngModel)]="editItem.phone" />
      </mat-form-field>-->
        <div class="field-block" *ngIf="editItem?.phone">
          <label>Телефон</label>
          <div>{{editItem?.phone}}</div>
        </div>
        <h3>Мессенджеры</h3>
        <!--<div class="it">
        <div class="icon"><svg-icon src="assets/telegram.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon></div>
        <div class="label">Telegram</div>
      </div>-->
        <mat-form-field style="margin-top: 10px; height: 54px;">
          <mat-label>Telegram</mat-label>
          <input matInput [(ngModel)]="editItem.telegramUserName" maxlength="32" />
          <span matPrefix><svg-icon src="assets/telegram.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon>&nbsp;@&nbsp;</span>
        </mat-form-field>
        <div class="mat-error" *ngIf="isTelegramUserNameError()">От 5 до 32 символов, можно использовать a-z, A-Z, 0-9 и подчеркивание.</div>
        <!--<div class="it">
        <div class="icon"><svg-icon src="assets/whatsapp.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon></div>
        <div class="label">Whatsapp</div>
      </div>-->
        <mat-form-field style="margin-top: 22px; height: 54px;">
          <mat-label>Whatsapp</mat-label>
          <input matInput [(ngModel)]="editItem.whatsappPhone" (focus)="onWhatsappFocus()" maxlength="16" />
          <span matPrefix><svg-icon src="assets/whatsapp.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </mat-form-field>
        <div class="mat-error" *ngIf="isWhatsappPhoneError()">До 15 цифр со знаком "+" в начале.</div>
        <!--<div class="mat-error" *ngFor="let msg of getErrorMessages()">{{msg}}</div>-->
      </div>
      <!--Пользователи-->
      <!--<app-child-panel [isVisible]="isUsersPanelVisible" (isVisibleChange)="onUsersPanelVisibleChange($event)">
        <div class="choose-user-wrapper">
          <app-public-event-user-list *ngIf="isUsersPanelVisible" [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="false"
                                      [showIcon]="false" [showPhoto]="true" [showUserDetailsOnClick]="false"
                                      [showStaticListOnly]="false" (userClick)="onUserClick($event)">
          </app-public-event-user-list>
        </div>
      </app-child-panel>-->
    </ng-template>
    <ng-template #tmplEditName>
      <!--Пользователи-->
      <div class="choose-user-wrapper">
        <app-public-event-user-list [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="false"
                                    [showIcon]="false" [showPhoto]="true" [showUserDetailsOnClick]="false"
                                    [showStaticListOnly]="false" (userClick)="onUserClick($event)">
        </app-public-event-user-list>
      </div>
    </ng-template>

  </app-screen-with-panel>
</div>
