<div #ultimateWrapper>

  <app-screen-with-panel [title]="mainTitle"
                         [bodyTmpl]="tmpl || tmplList"
                         [bodyInnerPadding]="bodyInnerPadding"
                         (onBackClicked)="onBackClick()"
                         (onSaveClick)="onSave()"
                         (onCancelEditClick)="onCancelEdit()"
                          [doNavigateBackOnCancel]="false"
                         (onDeleteClick)="onDelete()"
                         [isSaveCancel]="isSaveCancel"
                         [showClose]="showClose"
                         [showButtons]="showButtons"
                         deleteBtnSubject="чат"
                         [isDeleteEnabled]="isDeleteEnabled"
                         [reset]="panelReset"
                         [isInnerScroll]="true">


    <ng-template #tmplList>
      <app-new-delete-buttons *ngIf="isEditor" (onNewClick)="onNewClick()"></app-new-delete-buttons>
      <div>
        <div class="form-wrapper">
          <mat-form-field>
            <mat-label>Кого ищем?</mat-label>
            <input matInput type="text" [(ngModel)]="searchText" (ngModelChange)="onSearchTextChange()" />
            <mat-icon matSuffix *ngIf="searchText" (click)="searchTextClear()">close</mat-icon>
          </mat-form-field>

          <!--для управления доступом-->
          <div class="list-wrapper">
            <div class="list-item" *ngFor="let it of items" (click)="onChatClick(it)">
              <div class="photo-wrapper">
                <div class="photo">
                  <div class="img-wrap" [ngStyle]="{'height.px': '56', 'width.px': '56'}">
                    <svg-icon *ngIf="!getPhotoIsImage(it)" [src]="getPhotoPath(it)" [applyClass]="true" svgClass="photo-svg" [svgStyle]="{'height.px': '50', 'width.px': '50'}"></svg-icon>
                    <img [src]="getPhotoSafe(it)" *ngIf="getPhotoIsImage(it)" [ngStyle]="{'height.px': '56', 'width.px': '56'}" />
                  </div>
                </div>
              </div>
              <div class="text-part">
                <div [class]="it.notActive ? 'user-name disabled' : 'user-name active'">{{it.name}}
                  <span class="last-msg-date" *ngIf="it.lastStatus == 1">{{it.lastSendDateStr}}</span>
                  <span class="last-msg-status" *ngIf="it.lastStatus != 1">
                    <mat-spinner [diameter]="'16'" *ngIf="it.lastStatus == 0"></mat-spinner>
                    <svg-icon src="assets/error.svg" [stretch]="true" [viewBox]="'auto'" *ngIf="it.lastStatus == 2"></svg-icon>
                  </span>
                </div>
                <div class="user-role" *ngIf="existsChatUserRole(it)">{{getChatUserRole(it)}}</div>
                <div [matBadge]="it.unreadCount > 9 ? '9+' : ''+it.unreadCount" [matBadgeHidden]="!it.unreadCount" matBadgeSize="medium" matBadgeOverlap="true" matBadgePosition="after" appTextEllipsis class="user-message">{{getChatItemMessage(it)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </ng-template>

    <!--Просмотр профиля группового чата -->
    <ng-template #tmplViewItem>
      <div class="fullname-n-photo">
        <div class="photo-wrapper">
          <div class="photo">
            <div class="img-wrap" [ngStyle]="{'width.px': '72', 'height.px': '72'}">
              <svg-icon *ngIf="!photoExists" src="assets/chat.svg" [applyClass]="true" svgClass="photo-svg" [svgStyle]="{'width.px': '72', 'height.px': '72'}"></svg-icon>
              <img [src]="safePhoto" *ngIf="photoExists" [ngStyle]="{'width.px': '72', 'height.px': '72'}" />
            </div>
          </div>
        </div>
      </div>
      <div class="sub-hdr">{{editItem?.name}}</div>
      <app-public-event-user-list [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="false"
                                  [showIcon]="true" [showPhoto]="true" [isUserChecked]="isParticipant" [showUserDetailsOnClick]="false" [showUserRole]="true"
                                  [outerUsers]="getChatParticipants(editItem)" [useOuterList]="true" [showStaticListOnly]="false" (userClick)="onParticipantClick(true, true, $event)">

      </app-public-event-user-list>
      <!--Просмотр одного участника-->
      <app-child-panel [isVisible]="isViewParticipantVisible" (isVisibleChange)="onViewParticipantVisibleChange($event)" [width]="'calc(100% - 40px)'" [height]="'70%'">
        <div class="participant-details-wrapper">
          <app-user-profile-display *ngIf="isViewParticipantVisible" [userDisplay]="viewParticipant" [btnActionName]="'Написать сообщение'" [showBtnAction]="showBtnGoChat" [isViewAlt]="true" [isProfileActive]="isProfileActive" (onActionClick)="onParticipantGoChatClick($event)"></app-user-profile-display>
        </div>
      </app-child-panel>
    </ng-template>
    <!-- Редактирование группового чата -->
    <ng-template #tmplEditItem>
      <div class="hdr" style="font-weight:700">Групповой чат</div>
      <br />
      <div class="form-wrapper">
        <input #fileInput type="file" style="display:none;" name="user-profile-file-input" (change)="onFileInputChange($event)" />
        <div>
          <mat-form-field class="field-without-underline" style="width:100%">
            <div class="fullname-n-photo">
              <input matInput style="display:none;" />
              <div class="photo-wrapper">
                <div class="photo">
                  <div class="img-wrap" [ngStyle]="{'width.px': '72', 'height.px': '72'}">
                    <svg-icon *ngIf="!photoExists" src="assets/chat-big.svg" [applyClass]="true" svgClass="photo-svg" [svgStyle]="{'width.px': '72', 'height.px': '72'}"></svg-icon>
                    <img [src]="safePhoto" *ngIf="photoExists" [ngStyle]="{'width.px': '72', 'height.px': '72'}" />
                  </div>
                </div>
                <div class="edit-photo-svg-wrpper"><svg-icon [src]="photoExists ? 'assets/crosssm.svg' : 'assets/camerasm.svg'" [applyClass]="true" svgClass="edit-photo-svg" (click)="onUploadPhotoClick()"></svg-icon></div>
              </div>
            </div>
          </mat-form-field>
        </div>
        <mat-form-field>
          <mat-label>Название чата</mat-label>
          <input matInput [(ngModel)]="editItem.name" maxlength="125" />
        </mat-form-field>
        <!-- Участники -->
        <mat-label>Участники</mat-label>
        <app-nav-list-item text="Выбрать участников" (onIconClick)="isParticipantsPanelVisible = true"></app-nav-list-item>
        <div class="participants" *ngIf="editItem?.participants?.length">{{getChatPersonCount(editItem)}}</div>
        <app-public-event-user-list [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="false" [userChanging]="changingParticipants" [showUserRole]="true"
                                    [showIcon]="true" [showPhoto]="true" [isUserChecked]="isParticipant" [showUserDetailsOnClick]="false"
                                    [outerUsers]="getChatParticipants(editItem)" [useOuterList]="true" [showStaticListOnly]="false" (userClick)="onParticipantClick(true, true, $event)">

        </app-public-event-user-list>
      </div>
      <!-- Участники - список для выбора -->
      <app-child-panel [isVisible]="isParticipantsPanelVisible" (isVisibleChange)="onParticipantsPanelVisibleChange($event)">
        <div class="choose-participant-wrapper">
          <app-public-event-user-list *ngIf="isParticipantsPanelVisible" [publicEventId]="publicEventId" [isParticipants]="true" [isCbx]="true" [showUserRole]="true"
                                      [outerUsers]="participants" [useOuterList]="true" [showIcon]="false" [showPhoto]="true" [isUserChecked]="isParticipant" [isUserDisabled]="isParticipantOwner" (cbxChange)="onParticipantCbxChange($event)">
          </app-public-event-user-list>
        </div>
      </app-child-panel>

      <!--Просмотр одного участника-->
      <app-child-panel [isVisible]="isViewParticipantVisible" (isVisibleChange)="onViewParticipantVisibleChange($event)" [width]="'calc(100% - 40px)'" [height]="'70%'">
        <div class="participant-details-wrapper">
          <app-user-profile-display *ngIf="isViewParticipantVisible" [userDisplay]="viewParticipant" [showBtnAction]="false" [isViewAlt]="true" [isProfileActive]="isProfileActive"></app-user-profile-display>
        </div>
      </app-child-panel>
    </ng-template>

    <!--Просмотр содержимого чата-->
    <ng-template #tmplChat>
      <div class="chat-background" #chatBackground>
        <div class="chat-container" #chatContainer>
          <div class="chat-header" (click)="onEditItemClick(chatItem)">
            <div class="icon-wrapper">
              <svg-icon src="assets/back-button.svg" [applyClass]="true" [svgClass]="'icon-button violet'" (click)="onBackClick()" [stretch]="true" [viewBox]="'0 0 45 45'"></svg-icon>
            </div>
            <div class="text-part">
              <div appTextEllipsis [class]="chatItem?.notActive ? 'chat-name disabled' : 'chat-name active'">{{chatItem.name}}</div>
              <div class="chat-count" *ngIf="!chatItem?.isPrivate && chatItem?.participants?.length">{{getChatUserCount(chatItem)}}</div>
              <div class="chat-status" *ngIf="chatItem?.isPrivate && chatItem?.notActive">Профиль неактивен</div>
            </div>
            <div class="photo-wrapper">
              <div class="photo">
                <div class="img-wrap" [ngStyle]="{'width.px': '45', 'height.px': '45'}">
                  <svg-icon *ngIf="!getPhotoIsImage(chatItem)" [src]="getPhotoPath(chatItem)" [applyClass]="true" svgClass="photo-svg" [svgStyle]="{'width.px': '45', 'height.px': '45'}"></svg-icon>
                  <img [src]="getPhotoSafe(chatItem)" *ngIf="getPhotoIsImage(chatItem)" [ngStyle]="{'width.px': '45', 'height.px': '45'}" />
                </div>
              </div>
            </div>
          </div>

          <div #chatBody class="chat-body" id="chat-body-id">
            <div class="chat-list-date" *ngFor="let dtItems of chatMessageDates; let ix=index">
              <div class="date-hdr">
                {{dtItems.dt}}
              </div>
              <div *ngFor="let message of dtItems.messages; let ixmsg=index" #chatMessage>
                <div class="new-hdr" *ngIf="message.isNew">&nbsp;Новые&nbsp;</div>
                <div class="chat-list-item">
                  <div class="photo-wrapper" *ngIf="!chatItem.isPrivate && message.loginId !== loginId">
                    <div class="photo">
                      <div class="img-wrap" [ngStyle]="{'height.px': '36', 'width.px': '36'}">
                        <svg-icon *ngIf="!getPhotoIsImageChat(message.loginId) && (ixmsg == 0 || message.loginId !== dtItems.messages[ixmsg-1].loginId)" [src]="'assets/user-profile-sm.svg'" [applyClass]="true" svgClass="photo-svg" [svgStyle]="{'height.px': '36', 'width.px': '36'}"></svg-icon>
                        <img *ngIf="getPhotoIsImageChat(message.loginId) && (ixmsg == 0 || message.loginId !== dtItems.messages[ixmsg-1].loginId)"  [src]="getPhotoSafeChat(message.loginId)" [ngStyle]="{'height.px': '36', 'width.px': '36'}" />
                      </div>
                    </div>
                  </div>
                  <div class="chat-message">
                    <div class="right-bubble" [ngClass]="{'right-bubble': message.loginId === loginId, 'left-bubble': message.loginId !== loginId}">
                      <div class="msg-name" *ngIf="!chatItem.isPrivate && message.loginId !== loginId && (ixmsg == 0 || message.loginId !== dtItems.messages[ixmsg-1].loginId)">{{message.userName}}</div>
                      <div class="msg-message">
                        <span class="msg-message-text" [innerHTML]="getMessageWithLink(message.message.trim())"></span>
                        <span class="msg-date" *ngIf="message.messageStatus == 1">{{message.sendTimeStr}}</span>
                        <span class="msg-status" *ngIf="message.messageStatus != 1">
                          <mat-spinner [diameter]="'16'" *ngIf="message.messageStatus == 0"></mat-spinner>
                          <svg-icon src="assets/error.svg" (click)="onBackClick()" [stretch]="true" [viewBox]="'auto'" [title]="message.message" *ngIf="message.messageStatus == 2"></svg-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="chat-list-date" class="chat-list-date" style="width:90%;height:10px;" #chatMessageDateLast></div>
            <div id="chat-to-end-id" class="chat-to-end" *ngIf="showChatToEnd" (click)="onChatToEndClick(true)">
              <svg-icon [src]="'assets/chevron-down.svg'" [applyClass]="true" svgClass="chat-to-end-img" [stretch]="true" [viewBox]="'auto'"></svg-icon>
            </div>
          </div>
          <footer class="sticky-footer" #chatFooter>
            <form class="message-form" [formGroup]="chatForm" (ngSubmit)="onChatSubmit(chatForm.value)" *ngIf="chatItem && !chatItem.notActive && chatItem.isParticipant">
              <mat-form-field class="message-form-field" floatLabel="never">
                <div #chatMessgeForm class="message-form-container">
                  <textarea matInput #chatTextarea
                            cdkTextareaAutosize
                            placeholder="Сообщение"
                            formControlName="message"
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="4"
                            [errorStateMatcher]="matcher"></textarea>
                </div>
                <button type="submit" [disabled]="chatForm.invalid" mat-button matSuffix mat-icon-button aria-label="Send">
                  <svg-icon [src]="!chatForm.invalid ? 'assets/send.svg' : 'assets/send-dis.svg'" [class]="!chatForm.invalid ? 'violet' : 'grey'" [applyClass]="true" [svgClass]="'icon-button'" [viewBox]="'auto'"></svg-icon>
                </button>
              </mat-form-field>
            </form>
          </footer>
          <!--Просмотр одного участника-->
          <app-child-panel [isVisible]="isViewParticipantVisible" (isVisibleChange)="onViewParticipantVisibleChange($event)" [width]="'calc(100% - 40px)'" [height]="'70%'">
            <div class="participant-details-wrapper">
              <app-user-profile-display *ngIf="isViewParticipantVisible" [userDisplay]="viewParticipant" [showBtnAction]="false" [isViewAlt]="true" [isProfileActive]="isProfileActive"></app-user-profile-display>
            </div>
          </app-child-panel>
        </div>
      </div>
    </ng-template>


    <!--Просмотр одного участника-->
    <!--
    <ng-template #tmplParticipant>
      <div class="participant-details-wrapper">
        <app-user-profile-display id="public-event-chat" [userDisplay]="viewParticipant" [btnActionName]="'Написать сообщение'" [showBtnAction]="showBtnGoChat" [isViewAlt]="true" [isProfileActive]="isProfileActive" (onActionClick)="onParticipantGoChatClick($event)"></app-user-profile-display>
      </div>
    </ng-template>
    -->
  </app-screen-with-panel>

</div>
