import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AjaxMethods } from '../../models/AjaxMethods';

@Component({
  selector: 'app-public-event-vote-history',
  templateUrl: './public-event-vote-history.component.html',
  styleUrls: ['./public-event-vote-history.component.less']
})
export class PublicEventVoteHistoryComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.questionId = Number(params['questionId']);
      this.userAccountId = params['userAccountId'];
      this.back2Results = !!params['back2Results'];
      this.http.get<VoteHistory>(AjaxMethods.getPublicEventVoteHistory
        .replace('{questionId}', String(this.questionId))
        .replace('{userAccountId}', this.userAccountId))
        .subscribe(data => this.voteHistory = data);
    });
  }
  voteHistory: VoteHistory;
  questionId: number;;
  userAccountId;
  //Индекс текущего вопроса в questionIds
  get questionIx(): number {
    return this.voteHistory?.questionIds?.indexOf(this.questionId);
  }
  back2Results: boolean;
  get backUrl(): string {
    // current: public-events/17/polling/16/vote-history/46/66257
    // target:  public-events/17/polling/16/vote-result/46
    if (!this.back2Results)
      return this.route.snapshot.url.slice(0, 4).join('/') + '/vote-result/' + this.route.snapshot.url[5];
    //"public-events/:publicEventId/polling/:pollingId/:ixTab"
    return this.route.snapshot.url.slice(0, 4).join('/') + '/1';
    //return `public-events/${this.voteHistory?.publicEventId}/polling/${this.voteHistory?.pollingId}/vote-result/${this.voteHistory?.questionId}`;
  }
  // Переход к вопросу по индексу в questionIds
  navigateToQuestionIx(ix: number) {
    if (ix >= 0 && ix < this.voteHistory.questionIds.length) {
      let qId = this.voteHistory.questionIds[ix];
      //public-events/71/polling/61/vote-history/114/56997
      // заменить 114 из примера
      this.router.navigate([`${this.route.snapshot.url.slice(0, 5).join('/')}/${qId}/${this.userAccountId}`]);
    }
  }

  onPrevQuestionClick() {
    var qix = this.questionIx;
    if (qix > 0) {
      this.navigateToQuestionIx(qix - 1);
    }
  }

  onNextQuestionCLick() {
    var qix = this.questionIx;
    if (qix < this.voteHistory.questionIds.length - 1) {
      this.navigateToQuestionIx(qix + 1);
    }
  }
}
