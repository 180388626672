<div class="labels">
   <div *ngFor="let p of params;let ix=index" class="label" (click)="onLabelClick(ix)" [ngClass]="{active: isTabActive(ix)}">
     <div class="tab-label">
       <div class="tab-icon" *ngIf="p.labelIcon" [ngClass]="{active : isTabActive(ix)}">
         <svg-icon [src]="'assets/' + p.labelIcon + '.svg'" [applyClass]="true"></svg-icon>
       </div>
       <div class="text" [ngClass]="{active : isTabActive(ix)}">{{p.label}}</div>
     </div>
   </div>
</div>
<div class="content">
  <div *ngFor="let p of params; let ix=index" class="content-body">
    <ng-container  *ngIf="ix==ixActive"><ng-container *ngTemplateOutlet="p.contentTmpl"></ng-container></ng-container>
  </div>
</div>
