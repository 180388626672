<ng-container *ngTemplateOutlet="bodyTmpl"></ng-container>

<ng-template #bodyTmpl>
  <div *ngIf="!isViewScreenVisible">
    <app-new-delete-buttons *ngIf="_isEditor" (onNewClick)="onNewClick()"></app-new-delete-buttons>

    <div class="items-wrapper">
      <div *ngFor="let it of files; let index=index">
        <div class="subhdr" *ngIf="!programItemId && it.programItemId && ( !files[index-1] || !!files[index-1].programItemId != !!files[index].programItemId)">Документы из программы</div>
        <app-nav-list-item
                           [textTmpl]="tmpl"
                           (onIconClick)="_isEditor && onFileEditClick(it) "
                           [param]="{isEditor: _isEditor, files: [it]}"
                           navIconClass="icon-square"
                           [showIcon]="_isEditor"
                           [isClickOnIconOnly]="true">
          <ng-template #tmpl>
            <div class="nav-item" (click)="!_isEditor && onFileOpenClick(it)">
              <div class="text-block">
                <div class="first-line" appTextEllipsis>{{it.title}}</div>
                <div class="second-line2">{{it.fileName}} {{getFileSizeSz(it)}}</div>
              </div>
            </div>
          </ng-template>
        </app-nav-list-item>
      </div>
    </div>
  </div>

  <div *ngIf="isViewScreenVisible">
    <div class="view-title-line" *ngIf="file" appTextEllipsis>{{fileParamsCopy.title}}</div>
    <div class="view-second-line" *ngIf="file" (click)="onFileOpenClick(file)">{{fileParamsCopy.fileName}} {{getFileSizeSz(fileParamsCopy)}}</div>
  </div>

  <form #openFileForm method="post" [action]="openFileAction" target="_file_browse_iframe">
    <input name="token" type="hidden" [value]="JWTToken" />
  </form>
</ng-template>

<!-- Просмотр PDF-->
<div class="iframe-wrap" *ngIf="!isPDFViewerHidden" [appMoveOutside]="moveOutElement">
  <div class="top-bar">
    <div class="cross-icon-wrapper" (click)="isPDFViewerHidden = true">
      <svg-icon src="assets/cross.svg" viewPort="auto" [applyClass]="true" svgClass="cross-icon"></svg-icon>
    </div>
  </div>
  <div class="pdf-viewer-wrap"><ng2-pdfjs-viewer #pdfViewer [pdfSrc]="pdfSrc" locale="ru"></ng2-pdfjs-viewer></div>
</div>

<!-- просмотр всего, кроме PDF -->
<div class="iframe-wrap" *ngIf="!isIFameHidden" [appMoveOutside]="moveOutElement">
  <div class="top-bar">
    <div class="cross-icon-wrapper" (click)="onIframeCloseClick(otherIframe)">
      <svg-icon src="assets/cross.svg" viewPort="auto" [applyClass]="true" svgClass="cross-icon"></svg-icon>
    </div>
  </div>
  <div class="inner-frame">
    <img [src]="fileDataUrlSrc" *ngIf="isImg" />
    <audio [src]="fileDataUrlSrc" *ngIf="isAudio" controls></audio>
    <video [src]="fileDataUrlSrc" *ngIf="isVideo" controls></video>
    <a [href]="fileDataUrl" *ngIf="isOther" [download]="downloadFileName" (click)="onFileDownload(fileDataUrl, downloadFileName)">Скачать файл</a>
  </div>
  <!--
  <div class="spinner-style" *ngIf="download$ | async as download">
    <mat-progress-spinner [mode]="'determinate'"
                          [value]="download.progress">
    </mat-progress-spinner>
  </div>
   -->
  <iframe #otherIframe name="_file_browse_iframe" [style.display]="isIframeElVisible? 'block' : 'none'">
  </iframe>
</div>

<!--Открытие файла без дополнительного экрана-->
<div style="display: none;">
  <a #downloadButton [href]="fileDataUrl" [download]="downloadFileName">Скачать файл</a>
</div>

<!--Панель редактирования-->
<app-child-panel header="Материал" [(isVisible)]="isEditPanelVisible" [isPositionFixed]="true">
  <div class="edit-wrapper">
    <form #fileForm>
      <mat-form-field>
        <mat-label>Название</mat-label>
        <input matInput type="text" [(ngModel)]="file.title" required #titleField="ngModel" name="title" maxlength="1024" />
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="file.title = ''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <!--имя файла-->
      <mat-form-field>
        <mat-label>Имя файла</mat-label>
        <input matInput readonly type="text" [value]="file.fileName || ''" />
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onLoadedFileClearClick()" *ngIf="file?.fileName" required #fileNameField>
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <!-- загрузка файла -->
      <ng-container *ngIf="!file?.fileName">
        <input #fileInput type="file" accept="image/*,.pdf" style="display:none;" name="publicEventFileInput" (change)="onFileInputChange($event)" />
        <div class="file-load" (click)="fileInput.click()">
          <div class="icon">
            <svg-icon src="assets/plus.svg" [applyClass]="true"></svg-icon>
          </div>
          <div class="load-text">Загрузить файл</div>
        </div>
        <div class="label-hint">
          Вы можете загрузить файлы в форматах PDF, BMP, GIF, JPEG, JPG, PNG
        </div>
      </ng-container>
    </form>
    <!-- Скачивание файла -->
    <div class="btns-row" *ngIf="file.id" (click)="onFileOpenClick(file)">
      <div class="download-text">Скачать файл</div>
    </div>
    <!--Кнопки-->
    <div class="btns-row">
      <button (click)="onEditCancelClick()" class="dialog-button action-violet">Отменить</button>
      <button (click)="onSaveClick()" class="dialog-button action-green">Сохранить</button>
    </div>
    <div class="btns-row" *ngIf="file.id">
      <button (click)="onDeleteFileClick()" class="dialog-button action-red">Удалить материал</button>
    </div>
  </div>
</app-child-panel>
