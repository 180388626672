import { HttpClient } from '@angular/common/http';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AjaxMethods } from '../../models/AjaxMethods';

@Component({
  selector: 'app-public-event-acl',
  templateUrl: './public-event-acl.component.html',
  styleUrls: ['./public-event-acl.component.less']
})
export class PublicEventACLComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private sanitizer: DomSanitizer) { }

  @Input()
  title = "Доступ";

  ngOnDestroy(): void {
  }
  isParticipants = false;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.publicEventId = Number(params["id"]);
      this.prm = params["eventOrVoting"];
      this.isParticipants = (this.prm == 'voting');// только участники для выбора голосующих
      this.title = this.prm == "event" ? "Доступ к мероприятию" : "Доступ к голосованию";
    });
  }
  onBackClicked() {
    console.debug("onBackClicked");
    this.router.navigate([this.backUrl])
  }

  // Дернули переключатель
  onCbxChange(it: UserAttrs) {
    let opt: boolean;
    if (this.prm == "event") {
      it.hasAccess = !it.hasAccess
      opt = it.hasAccess;
    }
    else {
      it.isVoting = !it.isVoting;
      opt = it.isVoting;
    }
    // изменить на серевере "PublicEvent/AlterEventAccess/{eventOrVote}/{grantOrRevoke}/{publicEventId}/{loginId}"
    this.http.get<any>(AjaxMethods.AlterPublicEventACL.replace("{eventOrVote}", this.prm).replace("{grantOrRevoke}", opt ? "grant" : "revoke").replace("{publicEventId}", this.publicEventId + '').replace("{loginId}", it.loginId + "")
      , { params: { login: it.login } })
      .subscribe();
  }
  isChecked(it: UserAttrs): boolean {
    return this.prm == "event" ? it.hasAccess : it.isVoting;
  }

  publicEventId: number;
  prm: 'event' | 'voting'; // какой ACL редактируем
  // ссылка назад. Наш url: /public-events/17/public-event-acl/event . Оставить 2 сегмента
  get backUrl(): string {
    return '/' + this.route.snapshot.url.slice(0, 2).join('/') + '/';
  }
}
