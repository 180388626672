<app-screen-with-panel [title]="publicEvent?.title"
                       [headerPartTmpl]="headerTmpl"
                       [bodyTmpl]="bodyTmpl"
                       [backUrl]="backUrl"
                       [isEditable]="isEditor"
                       (onEditClick)="onEditClick()"
                       [infoBar]="infoBar">
  <ng-template #headerTmpl>
    <div class="topheader-text">
      <svg-icon src="assets/clock-filled.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon>
      <div [innerHTML]="publicEvent?.dateStr" class="text"></div>
    </div>
    <div class="topheader-text">
      <svg-icon src="assets/compass.svg" viewPort="auto" [applyClass]="true" svgClass="icon"></svg-icon>
      <div [innerHTML]="publicEvent?.locationText" class="text"></div>
    </div>
  </ng-template>

  <ng-template #bodyTmpl>
    <!-- модули мероприятия -->
    <ng-container *ngIf="publicEvent.isParticipant || publicEvent.isEditor || canSeeModules">
      <!--
      <app-nav-list-item *ngFor="let it of eventComponentsChecked" [text]="it.text" [icon]="it.icon" [navLink]="'/public-events/' + publicEvent?.id + '/' + it.routePart" [isDisabled]="it.isDisabled">
      </app-nav-list-item>
      -->
      <app-nav-list-item *ngFor="let it of eventComponentsChecked" [icon]="it.icon" [textTmpl]="tmpl" [navLink]="'/public-events/' + publicEvent?.id + '/' + it.routePart" [isDisabled]="it.isDisabled">
        <ng-template #tmpl>
          <div *ngIf="it.key != 'chat'" class="components-item">
            <!--сам текст-->
            <div class="text" appTextEllipsis *ngIf="it.text && it.text != ''">{{it.text}}</div>
          </div>
          <div *ngIf="it.key == 'chat'" class="components-item" [matBadge]="publicEvent.unreadCount > 9 ? '9+' : ''+publicEvent.unreadCount" [matBadgeHidden]="!publicEvent.unreadCount" matBadgeSize="medium" matBadgeOverlap="true" matBadgePosition="after">
            <!--сам текст-->
            <div class="text" appTextEllipsis *ngIf="it.text && it.text != ''">{{it.text}}</div>
          </div>
        </ng-template>
      </app-nav-list-item>


    </ng-container>

    <!-- Доступ участников-->
    <ng-container *ngIf="publicEvent?.isAccessRightsEditor" >
      <h3>Доступ участников</h3>
      <app-nav-list-item text="Доступ к мероприятию" [navLink]="'/public-events/' + publicEvent?.id + '/public-event-acl/event'" [isTopBorder]="true">
      </app-nav-list-item>
      <app-nav-list-item text="Доступ к голосованию" [navLink]="'/public-events/' + publicEvent?.id + '/public-event-acl/voting'">
      </app-nav-list-item>
    </ng-container>
  </ng-template>


</app-screen-with-panel>


 

