import { HttpClient } from '@angular/common/http';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { ApplicationRef, Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonDataService } from '../../common-data.service';
import { DownloadService, Download } from '../../services/download.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';
import { DomSanitizer } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';
import { from, Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScreenWithPanelComponent } from '../../screen-with-panel/screen-with-panel.component';
/*import { promise } from 'protractor';*/
import { catchError, mergeMap, toArray } from 'rxjs/operators';
import { FilePicker } from '@robingenz/capacitor-file-picker';
/*import { forkJoin } from 'rxjs';*/

@Component({
  selector: 'app-public-event-gallery',
  templateUrl: './public-event-gallery.component.html',
  styleUrls: ['./public-event-gallery.component.less']
})
export class PublicEventGalleryComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private title: Title, private applicationRef: ApplicationRef, private commonData: CommonDataService, private downloadSrv: DownloadService, private sanitizer: DomSanitizer, private clipboard: Clipboard, private snackBar: MatSnackBar) {
  }
  publicEventId: number;
  isEditor: boolean;
  items: PublicEventGalleryItem[] = [];
  maxGalleryLoadAttempts: number;
  isEditPanelVisible = false;
  photoItem: PublicEventGalleryItem = <any>{};
  photosList: PublicEventGalleryItem[] = [];
  photosListRemaining: PublicEventGalleryItem[] = [];
  fileDataUrl: any;
  fileDataUrlSrc: any;
  fileDataUrlUnsafe: string;
  downloadFileName: string;
  downloadFileNameUnsafe: string;
  downloadMimeTypeUnsafe: string;
  fileAuthor: string;
  fileTitle: string;
  isFullSize: boolean;

  hasMoreItems: boolean = false;
  isLoading: boolean = false;

  ngOnInit() {
    this.route.params.subscribe(prm => {
      this.publicEventId = Number(prm["id"]);
      this.isLoading = true;
      // прочитать галерею
      this.http.get<PublicEventGallery>(AjaxMethods.PublicEventPartGallery.replace("{id}", this.publicEventId + '').replace("{skip}", '0').replace("{lastId}", '0'))
        .subscribe(data => {
          if (data) {
            this.isEditor = data.isEditor;
            this.items = data.items;
            this.hasMoreItems = data.hasMoreItems;
            if (data.maxGalleryLoadAttempts && data.maxGalleryLoadAttempts > 0) {
              this.maxGalleryLoadAttempts = data.maxGalleryLoadAttempts;
            }
            else {
              this.maxGalleryLoadAttempts = 2;
            }
          }
          var self = this;
          setTimeout(function () {
            self.isLoading = false;
          }, 100);
          if (this.hasMoreItems) {
            this.intersectionObserverInitialize();
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.intersectionObserverDestroy();
  }

  onPanelCloseBtnClick() {
    this.isEditPanelVisible = false;
  }

  onImageClick() {
    /*this.isFullSize = !this.isFullSize;*///пока отключаем
  }

  isIFrameHidden = true;
  onFileOpenClick(item: PublicEventGalleryItem) {
    if (this.screenWithPanel) {
      this.screenWithPanel.setCurrentPosition("down"); // схлопнуть его, а то, открыв файл, пользователь забывает, что он его оттянул вверх
    }
    this.isFullSize = false;

    this.http.get<any>(AjaxMethods.PublicEventGalleryFile.replace("{id}", this.publicEventId + '').replace("{fileId}", item.id + ''),
      { responseType: 'blob' as "json", observe: "response" })
      .subscribe((resp) => {
        let reader = new FileReader();
        reader.addEventListener("loadend", () => {
          this.fileDataUrlUnsafe = <string>reader.result;
          this.downloadMimeTypeUnsafe = resp.body.type;
          this.downloadFileNameUnsafe = item.fileName;

          this.fileDataUrlSrc = this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
          if (this.commonData.platform === 'web' && this.commonData.operatingSystem === 'ios') {
            this.fileDataUrl = window.URL.createObjectURL(resp.body);
          }
          else {
            this.fileDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
          }



          this.fileAuthor = item.author;
          this.fileTitle = item.title;
          this.isIFrameHidden = false;
          let disposition = decodeURI(resp.headers.get('content-disposition'));
          let p = disposition.lastIndexOf("'");
          if (p > 0) {
            this.downloadFileName = disposition.substring(p + 1);
          }
          this.applicationRef.tick();
        });
        reader.readAsDataURL(resp.body);
      });
  }

  onIframeCloseClick() {
    this.isIFrameHidden = true;
    this.isFullSize = false;
  }

  @ViewChild('snackBarTemplate')
  snackBarTemplate: TemplateRef<any>;

  public messages: string[];
  public dismissSnackbar(): void {
    this.snackBar.dismiss();
  }

  tryListLoad(attemptNumber: number) {
    var self = this;
    if (attemptNumber > self.maxGalleryLoadAttempts || attemptNumber < 1) {
      return;
    }
    const concurrentNum = 1;
    var listToLoad: Observable<PublicEventGalleryItem>;
    if (attemptNumber == 1) {
      listToLoad = from(self.photosList);
    }
    else {
      self.photosListRemaining = [];
      for (var i = 0; i < self.photosList.length; i++) {
        if (self.photosList[i].id > 0) {
          continue;
        }
        self.photosListRemaining.push(self.photosList[i]);
      }
      listToLoad = from(self.photosListRemaining);
    }
    const postResult = listToLoad.pipe(
      mergeMap(x => self.http.post<{ newId: number, thumbnail: string, index: number }>(AjaxMethods.PublicEventGalleryListItem.replace('{id}', String(self.publicEventId)).replace('{listId}', x.index + ''), x).pipe(catchError(err => of(err))), concurrentNum),
      toArray()
    );
    postResult.subscribe(result => {
      var successResultCount = 0;
      if (result && result.length > 0) {
        for (var i = 0; i < result.length && i < self.photosList.length; i++) {
          if (result[i] && result[i]['newId'] && result[i]['thumbnail'] && (result[i]['index'] || result[i]['index'] == 0)) {
            var index = result[i]['index'];
            self.photosList[index].id = result[i]['newId'];
            self.photosList[index].thumbnailContent = result[i]['thumbnail'];
            if (!self.hasMoreItems) {
              self.items.push(self.photosList[index]);
            }
            successResultCount++;
          }
        }
      }
      if (((attemptNumber == 1 && successResultCount < self.photosList.length) || (attemptNumber > 1 && successResultCount < self.photosListRemaining.length)) && (attemptNumber < self.maxGalleryLoadAttempts)) {
        self.tryListLoad(attemptNumber + 1);
      }
      else {
        self.isEditPanelVisible = false;
        if (self.fileInputElement) {
          self.fileInputElement.nativeElement.value = ''; // очистить загруженные файлы
        }
        var successTotalCount = 0;
        var failCount = 0;
        for (var i = 0; i < self.photosList.length; i++) {
          if (self.photosList[i].id && self.photosList[i].id > 0) {
            successTotalCount++;
          }
        }
        self.messages = ["Загружено файлов " + successTotalCount + " из " + self.photosList.length + ".", "Попыток: " + attemptNumber + " из " + self.maxGalleryLoadAttempts + "."];
        if (successTotalCount < self.photosList.length) {
          self.messages.push("Ошибка загрузки по сети:");
          
          for (var i = 0; i < self.photosList.length; i++) {
            if (!self.photosList[i].id || self.photosList[i].id == 0) {
              failCount++;
              if (failCount > 10) {
                self.messages.push("...");
                break;
              }
              self.messages.push(self.photosList[i].fileName);
            }
          }
        }
        /*if (successTotalCount > 0) {
          self.messages.push("Загружены:");
          for (var i = 0; i < self.photosList.length; i++) {
            if (self.photosList[i].id && self.photosList[i].id > 0) {
              self.messages.push(self.photosList[i].fileName);
            }
          }
        }*/
        self.snackBar.openFromTemplate(self.snackBarTemplate, Utils.snacBarInfoConfig);
      }
    });
  }

  onSaveClick() {
    if (!this.photoItem.photoContent && !(this.photosList.length > 0)) {
      this.commonData.confirmationDialog.msgBox({
        message: `Нужно загрузить изображение`
      });
      return;
    }
    if (this.getErrorMessages().length) return; // ошибки
    if (this.photosList.length > 0) {
      this.tryListLoad(1);
      /*const concurrentNum = 1;
      const listToLoad = from(this.photosList);
      const postResult = listToLoad.pipe(
        mergeMap(x => this.http.post<{ newId: number, thumbnail: string, index: number }>(AjaxMethods.PublicEventGalleryListItem.replace('{id}', String(this.publicEventId)).replace('{listId}', x.index + ''), x).pipe(catchError(err => of(err))), concurrentNum),
        toArray()
      );
      postResult.subscribe(result => {
        var successNum = 0;
        if (!this.hasMoreItems && result && result.length > 0) {
          for (var i = 0; i < result.length && i < this.photosList.length; i++) {
            if (result[i] && result[i]['newId'] && result[i]['thumbnail'] && (result[i]['index'] || result[i]['index'] == 0)) {
              var index = result[i]['index'];
              this.photosList[index].id = result[i]['newId'];
              this.photosList[index].thumbnailContent = result[i]['thumbnail'];
              this.items.push(this.photosList[index]);
              successNum++;
            }
          }
        }
        this.isEditPanelVisible = false;
        if (this.fileInputElement) {
          this.fileInputElement.nativeElement.value = ''; // очистить загруженные файлы
        }
        this.snackBar.open("Загружено файлов " + successNum + " из " + this.photosList.length, "Закрыть", Utils.snacBarInfoConfig);
      });*/
      /*var funcList = [];
      for (var i = 0; i < this.photosList.length; i++) {
        funcList.push(this.http.post<{ newId: number, thumbnail: string }>(AjaxMethods.PublicEventGallery.replace('{id}', String(this.publicEventId))
          , this.photosList[i]).pipe(catchError(err => of(err))));
      }
      forkJoin(funcList).subscribe(result => {
        var successNum = 0;
        if (!this.hasMoreItems && result && result.length > 0) {
          for (var i = 0; i < result.length && i < this.photosList.length; i++) {
            if (result[i] && result[i]['newId'] && result[i]['thumbnail']) {
              this.photosList[i].id = result[i]['newId'];
              this.photosList[i].thumbnailContent = result[i]['thumbnail'];
              this.items.push(this.photosList[i]);
              successNum++;
            }
          }
        }
        this.isEditPanelVisible = false;
        if (this.fileInputElement) {
          this.fileInputElement.nativeElement.value = ''; // очистить загруженные файлы
        }
        this.snackBar.open("Загружено файлов " + successNum + " из " + this.photosList.length, "Закрыть", Utils.snacBarInfoConfig);
      });*/
      /*this.http.post<PublicEventGalleryItemsPostResult>(AjaxMethods.PublicEventGalleryPhotosList.replace('{id}', String(this.publicEventId))
        , { items: this.photosList }).pipe(catchError(err => of(err)))
        .subscribe(data => {
          if (!this.hasMoreItems && data && data.resultItems && data.resultItems.length > 0) {
            for (var i = 0; i < data.resultItems.length && i < this.photosList.length; i++) {
              this.photosList[i].id = data.resultItems[i].newId;
              this.photosList[i].thumbnailContent = data.resultItems[i].thumbnail;
              this.items.push(this.photosList[i]);
            }
          }
          this.isEditPanelVisible = false;
          if (this.fileInputElement) {
            this.fileInputElement.nativeElement.value = ''; // очистить загруженные файлы
          }
        });*/
    }
    else {
      this.http.post<{ newId: number, thumbnail: string, index: number }>(AjaxMethods.PublicEventGalleryListItem.replace('{id}', String(this.publicEventId)).replace('{listId}', '0')
      , this.photoItem)
      .subscribe(data => {
        if (!this.photoItem.id && !this.hasMoreItems) {// добавить файл в список
          //this.items.unshift(this.photoItem);
          this.items.push(this.photoItem);
        }
        this.photoItem.id = data.newId;
        this.photoItem.thumbnailContent = data.thumbnail;
        this.isEditPanelVisible = false;
        if (this.fileInputElement) {
          this.fileInputElement.nativeElement.value = ''; // очистить загруженные файлы
        }
      });
    }
  }

  safeThumbnail(index) {
    return this.items[index]?.thumbnailContent ? this.sanitizer.bypassSecurityTrustResourceUrl(this.items[index]?.thumbnailContent) : null;
  }

  // Ссылка назад
  get backUrl() {
    return `/public-events/${this.publicEventId}`;
  }

  onNewClick() {
    this.photoItem = <any>{};
    this.photosList = [];
    this.photosListRemaining = [];
    this.isEditPanelVisible = true;
  }

  onEditCancelClick() {
    this.isEditPanelVisible = false;
  }

  @ViewChild("fileInput")
  fileInputElement: ElementRef<HTMLInputElement>;

  @ViewChild("downloadButton")
  downloadButtonElement: ElementRef<HTMLElement>;

  @ViewChild(ScreenWithPanelComponent)
  screenWithPanel: ScreenWithPanelComponent;

 private getBase64File(file: File, index: number) {
   const reader = new FileReader();
   return new Promise(resolve => {
     reader.onload = ev => {
       resolve({ result: ev.target.result, index: index });
     }
     try {
       reader.readAsDataURL(file);
     }
     catch (exc) {
       console.error('Error onFileInputChange getBase64File readAsDataURL: ' + JSON.stringify(exc));
     }
   });
}



  maxFileSize = 10 << 20;
  // Загрузился файл
  onFileInputChange($event: Event) {
    try {
      let inputElement = (<HTMLInputElement>$event.target);
      let files: FileList = inputElement.files;
      var self = this;

      console.log(`files length:${files.length}.`);

 

      if (files.length > 1) {
        for (var i = 0; i < files.length; i++) {
          let uploadedFile = files[i];
          let isImage = uploadedFile.type.toLowerCase().startsWith("image") && (uploadedFile.name.toLowerCase().endsWith(".jpg") || uploadedFile.name.toLowerCase().endsWith(".jpeg")
          /*|| uploadedFile.name.toLowerCase().endsWith(".tiff")*/ || uploadedFile.name.toLowerCase().endsWith(".png") || uploadedFile.name.toLowerCase().endsWith(".gif") || uploadedFile.name.toLowerCase().endsWith(".bmp"));
          if (!isImage) {
            this.commonData.confirmationDialog.msgBox({
              message: `Файл ${uploadedFile.name} не является допустимым изображением`
            });
            inputElement.value = '';
            return;
          }
          if (uploadedFile.size > this.maxFileSize) {
            this.commonData.confirmationDialog.msgBox({
              message: `Размер файла ${uploadedFile.name} ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize >> 20}Mb`
            });
            inputElement.value = '';
            return;
          }
        }
        var promises = [];
        for (var i = 0; i < files.length; i++) {
          let uploadedFile = files[i];
          /*let newItem: PublicEventGalleryItem = <any>{};
          //
          newItem.fileName = uploadedFile.name;
          let p = uploadedFile.name.lastIndexOf('.');
          newItem.extension = p >= 0 ? uploadedFile.name.substring(p) : '.';*/

          promises.push(this.getBase64File(uploadedFile, i));
          /*var results = await Promise.all(promises);
          newItem.photoContent = results[0];
          newItem.index = i;
          this.photosList.push(newItem);*/
        }

        console.log(`promises length:${promises.length}.`);

        Promise.all(promises).then(results => {
          try {
            if (results.length > 0) {
              console.log(`results length:${results.length}.`);
              results.sort((a, b) => {
                if (a.index < b.index) return -1;
                if (a.index > b.index) return 1;
                return 0;
              });
              for (var i = 0; i < results.length; i++) {
                let uploadedFile = files[results[i].index];
                let newItem: PublicEventGalleryItem = <any>{};
                //
                newItem.fileName = uploadedFile.name;
                let p = uploadedFile.name.lastIndexOf('.');
                newItem.extension = p >= 0 ? uploadedFile.name.substring(p) : '.';

                newItem.photoContent = results[i].result;
                newItem.index = i;
                self.photosList.push(newItem);
              }
              inputElement.value = '';
              console.log(`photosList length:${self.photosList.length}.`);
            }
          }
          catch (exc) {
            console.error('Error onFileInputChange Promise.all: ' + JSON.stringify(exc));
            inputElement.value = '';
          }
        });

        /*let isImage = uploadedFile.type.toLowerCase().startsWith("image") && (uploadedFile.name.toLowerCase().endsWith(".jpg") || uploadedFile.name.toLowerCase().endsWith(".jpeg")
          || uploadedFile.name.toLowerCase().endsWith(".png") || uploadedFile.name.toLowerCase().endsWith(".gif") || uploadedFile.name.toLowerCase().endsWith(".bmp"));
        if (!isImage) {
          this.commonData.confirmationDialog.msgBox({
            message: `Файл не является изображением`
          });
          inputElement.value = '';
          return;
        }
        if (uploadedFile.size > this.maxFileSize) {
          this.commonData.confirmationDialog.msgBox({
            message: `Размер файла ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize >> 20}Mb`
          });
          inputElement.value = '';
          return;
        }*/

        //inputElement.value = '';
      }
      else if (files.length) {
        let uploadedFile = files[0];
        console.log(`1 file chosen`);
        let isImage = uploadedFile.type.toLowerCase().startsWith("image") && (uploadedFile.name.toLowerCase().endsWith(".jpg") || uploadedFile.name.toLowerCase().endsWith(".jpeg")
        /*|| uploadedFile.name.toLowerCase().endsWith(".tiff")*/ || uploadedFile.name.toLowerCase().endsWith(".png") || uploadedFile.name.toLowerCase().endsWith(".gif") || uploadedFile.name.toLowerCase().endsWith(".bmp"));
        if (!isImage) {
          this.commonData.confirmationDialog.msgBox({
            message: `Файл не является допустимым изображением`
          });
          inputElement.value = '';
          return;
        }
        if (uploadedFile.size > this.maxFileSize) {
          this.commonData.confirmationDialog.msgBox({
            message: `Размер файла ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize >> 20}Mb`
          });
          inputElement.value = '';
          return;
        }
        this.photoItem.fileName = uploadedFile.name;
        let p = uploadedFile.name.lastIndexOf('.');
        this.photoItem.extension = p >= 0 ? uploadedFile.name.substring(p) : '.';
        uploadedFile.arrayBuffer().then(arr => {
          try {
            let bl = new Blob([arr]);
            let rdr = new FileReader();
            rdr.onloadend = () => self.photoItem.photoContent = <string>rdr.result;
            rdr.readAsDataURL(bl);
            console.log(`1 file reading`);
          }
          catch (exc) {
            console.error('Error onFileInputChange uploadedFile.arrayBuffer: ' + JSON.stringify(exc));
          }
        });
        inputElement.value = '';
      }
    }
    catch (exc) {
      console.error('Error onFileInputChange: ' + JSON.stringify(exc));
    }
  }

  onDeletePhotoItemClick(itemId: number) {
    let self = this;
    this.commonData.confirmationDialog.confirm({ message: "Удалить фотографию?", title: 'Удаление' })
      .then((confirmed) => {
        if (!confirmed) return;
        this.http.delete(AjaxMethods.PublicEventGallery.replace("{id}", String(self.publicEventId)), { params: { itemId: itemId } })
          .subscribe(() => {
            self.isEditPanelVisible = false;
            let ixDel = self.items.findIndex(f => f.id == itemId);
            if (ixDel >= 0) {
              self.items.splice(ixDel, 1);
            }
          });
      });
  }

  onClearPhotoClick() {
    this.commonData.confirmationDialog.confirm({ message: "Удалить фотографию?", title: 'Удаление' })
      .then((confirmed) => {
        if (!confirmed) return;
        this.photoItem.fileName = '';
        this.photoItem.photoContent = null;
      });
    
  }

 

  onUploadPhotoClick() {

    if (this.commonData.platform === "web") {
      this.fileInputElement.nativeElement.click();
    }
    else {
      FilePicker.pickFiles({
        types: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
        multiple: true,
        readData: true
      }).then((result) => {
        const files = result.files;
        console.log(`files length:${files.length}.`);
        if (files.length > 1) {
          for (let i = 0; i < files.length; i++) {
            const uploadedFile = result.files[i];
            if (uploadedFile.size > this.maxFileSize) {
              this.commonData.confirmationDialog.msgBox({
                message: `Размер файла ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize >> 20}Mb`
              });
              return;
            }
            const isImage = (uploadedFile.mimeType.toLowerCase().startsWith("image") && uploadedFile.name.toLowerCase().endsWith(".jpg") || uploadedFile.name.toLowerCase().endsWith(".jpeg") || uploadedFile.name.toLowerCase().endsWith(".png") || uploadedFile.name.toLowerCase().endsWith(".gif") || uploadedFile.name.toLowerCase().endsWith(".bmp"));
            if (!isImage) {
              this.commonData.confirmationDialog.msgBox({
                message: `Файл ${uploadedFile.name} не является допустимым изображением`
              });
              return;
            }

            const newItem: PublicEventGalleryItem = <any>{};
            newItem.fileName = uploadedFile.name;
            const p = uploadedFile.name.lastIndexOf('.');
            newItem.extension = p >= 0 ? uploadedFile.name.substring(p) : '.';

            newItem.photoContent = "data:application/octet-stream;base64," + uploadedFile.data;
            newItem.index = i;
            this.photosList.push(newItem);
          }
        }
        else {


          const uploadedFile = result.files[0];
          if (uploadedFile.size > this.maxFileSize) {
            this.commonData.confirmationDialog.msgBox({
              message: `Размер файла ${uploadedFile.size >> 20}Mb превышает допустимые ${this.maxFileSize >> 20}Mb`
            });
            return;
          }
          const isImage = (uploadedFile.mimeType.toLowerCase().startsWith("image") && uploadedFile.name.toLowerCase().endsWith(".jpg") || uploadedFile.name.toLowerCase().endsWith(".jpeg") || uploadedFile.name.toLowerCase().endsWith(".png") || uploadedFile.name.toLowerCase().endsWith(".gif") || uploadedFile.name.toLowerCase().endsWith(".bmp"));
          if (!isImage) {
            this.commonData.confirmationDialog.msgBox({
              message: `Файл ${uploadedFile.name} не является допустимым изображением`
            });
            return;
          }
          this.photoItem.fileName = uploadedFile.name;
          const p = uploadedFile.name.lastIndexOf('.');
          this.photoItem.extension = p >= 0 ? uploadedFile.name.substring(p) : '.';
          this.photoItem.photoContent = "data:application/octet-stream;base64," + uploadedFile.data;
      }

      });



    }
  }

  download$: Observable<Download>
  downloadButtonClick() {
    if (this.commonData.platform !== "web") {
      this.download$ = this.downloadSrv.download(this.fileDataUrlUnsafe, this.downloadFileNameUnsafe, this.downloadMimeTypeUnsafe, true);
    }
    else {
      // this.downloadButtonElement.nativeElement.click();
      if (this.commonData.platform === 'web' && this.commonData.operatingSystem === 'ios') {
        window.open(this.fileDataUrl, "_self");
      }
      else {

        this.downloadButtonElement.nativeElement.click();
      }
    }
    return false;
  }

  get photoExists(): boolean { return Boolean(this.photoItem?.photoContent || this.photosList?.length > 0); }

  get photoMultiExists(): boolean { return Boolean(this.photosList?.length > 0); }

  get safePhoto() {
    return this.photoItem?.photoContent ? this.sanitizer.bypassSecurityTrustResourceUrl(this.photoItem?.photoContent) : null;
  }

  // Сообщения об ошибках
  getErrorMessages(): string[] {
    let msgs = [];
    /*if (this.location?.text && this.location?.text.length > 200) {
      msgs.push("Адрес не должен быть длиннее 200 символов");
    }*/

    return msgs;
  }

  itemObservervable: Element;
  intersectionObserver: IntersectionObserver;

  //showGalleryFooter: boolean = true;
  intersectionObserverInitialize(): void {

    if (!this.intersectionObserver) {
      this.intersectionObserver = new IntersectionObserver((entries, observer) => {
        if (entries && entries.length > 0) {
          var entry = entries[0];
          //грузим, только если уже была загрузка
          if (this.items && this.hasMoreItems && !this.isLoading && entry.isIntersecting) {
            console.log("Intersecting : ", entry.isIntersecting);
            // прочитать галерею
            var skip = 0;
            /*if (this.items) {*/
            skip = this.items.length;
            /*}*/
            var lastId = this.items[this.items.length - 1].id;
            this.http.get<PublicEventGallery>(AjaxMethods.PublicEventPartGallery.replace("{id}", this.publicEventId + '').replace("{skip}", skip + '').replace("{lastId}", lastId + ''))
              .subscribe(data => {
                if (data) {
                  this.isEditor = data.isEditor;
                  this.items = this.items.concat(data.items);
                  this.hasMoreItems = data.hasMoreItems;
                }
                var self = this;
                setTimeout(function () {
                  self.isLoading = false;
                }, 100);
                if (!this.hasMoreItems) {
                  this.intersectionObserverDestroy();
                }
              });
          }
        }
      },
        {
          root: document.querySelector('#appHeight100vh'),
          //root: document.querySelector('#gallery-id'),
          //  rootMargin: '0px',
          //  threshold: 1.0
        });
    }

    if (this.itemObservervable) {
      this.intersectionObserver.unobserve(this.itemObservervable);
    }
    //this.showGalleryFooter = true;
    this.itemObservervable = document.querySelector('#gallery-footer-id');
    if (this.itemObservervable) {
      this.intersectionObserver.observe(this.itemObservervable);
    }
  }

  intersectionObserverDestroy(): void {

    if (this.intersectionObserver) {
      if (this.itemObservervable) {
        this.intersectionObserver.unobserve(this.itemObservervable);
        this.itemObservervable = null;
      }
      this.intersectionObserver.disconnect();
      this.intersectionObserver = null;
    }
  }
}
