// Администрирование списка делегирований голоса для голосования

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';
import { Utils } from '../../utils';

@Component({
  selector: 'app-public-event-polling-admin-proxy',
  templateUrl: './public-event-polling-admin-proxy.component.html',
  styleUrls: ['./public-event-polling-admin-proxy.component.less']
})
export class PublicEventPollingAdminProxyComponent implements OnInit {

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private common: CommonDataService) { }

  ngOnInit(): void {
    this.route.params.subscribe(prm => {
      this.pollingId = prm["pollingId"];
      this.publicEventId = prm["publicEventId"];
      // список делегирований
      this.http.get<{ hasVotingBegun:boolean, votingBeginDateFmt:string, proxies: SetProxy[] }>(AjaxMethods.Proxy.replace('{id}', this.pollingId + ''))
        .subscribe(data => {
          this.proxies = data.proxies;
          this.hasVotingBegun = data.hasVotingBegun;
          this.votingBeginDateFmt = data.votingBeginDateFmt;
        });
    });
  }
  get backUrl() {
    return `/public-events/${this.publicEventId}/polling/${this.pollingId}`;
  }
  pollingId: number;
  publicEventId: number;
  hasVotingBegun: boolean;
  get isNewEnabled(): boolean { return !this.hasVotingBegun; }// "добавить" доступно, если не началось голосование
  proxies: SetProxy[] = [];
  votingBeginDateFmt: string;
  displayProxyList = true; // отображаем список proxy, иначе - детали одного proxy

  ixCurrProxy = -1;
  // список proxy - нажали переход к просмотру записи
  onNavProxyClick(ix) {
    this.ixCurrProxy = ix;
    this.displayProxyList = false;
    this.isEditable = !this.hasVotingBegun;
  }
  isEditable = false; // можно редактировать назначение
  // Редактировать назначение, которое показываем
  onEditProxyClick() {
    this.openEditor(this.proxies[this.ixCurrProxy]);
  }
  // кнопка "назад". В режиме просмотра назначения - перейти в список. Иначе - навигация backUrl
  onBackClicked() {
    if (!this.displayProxyList) {
      this.displayProxyList = true;
      this.isEditable = false;
    }
    else {
      this.router.navigate([this.backUrl]);
    }
  }
  @ViewChild("main")
  tmplMain: TemplateRef<any>;
  @ViewChild("selectPerson")
  tmplChoosePerson: TemplateRef<any>;

  get childTmpl(): TemplateRef<any>{
    return this.childTmplMode == "main" ? this.tmplMain : this.tmplChoosePerson;
  }

  // Добавить делегирование
  onNewClick() {
    if (!this.hasVotingBegun) {
      this.openEditor();
    }
  }
  isEditNew: boolean; // редактирование - новое назначение
  // Перейти в режим редактирования/создания назначение
  openEditor(_it?: SetProxy) {
    this.isChildPanelVisible = true;
    this.childTmplMode = 'main';
    this.isEditNew = !Boolean(_it);
    let it = _it;
    if (!it) {
      it = <any>{};
    }
    // редактирование
    this.principalUser.name = it.userName;
    this.principalUser.loginId = it.userLoginId;
    this.proxyUser.name = it.proxyName;
    this.proxyUser.loginId = it.proxyUserLogin_id;
    this.PADate = it.paDate;
    this.PANumber = it.paNumber;
  }

  childTmplMode: "main" | "person" = "main";
  tmplSelectPerson: TemplateRef<any>;

  //Поля редактирования из child-panel
  isProxySet: boolean;
  PANumber: string;
  PADate: Date;
  proxyUser: UserAttrs = <any>{};
  principalUser: UserAttrs = <any> {};

  chooseWhat: 'principal' | 'proxy';
  isVotingOnly = false; // ограничение на выбор пользователя - доверитель только голосующий
  // На выбор участника
  onChoosePerson(chooseWhat: 'principal'|'proxy' ) {
    this.chooseWhat = chooseWhat;
    this.childTmplMode = "person";
    this.isVotingOnly = chooseWhat != 'proxy';
  }


  isChildPanelVisible = false;
  isSelectPrincipal: boolean;
  onChildIsVisibleChange($event: boolean) {
  }

  //---- Сохранить
  onSaveClick(...flds: NgModel[]) {
    if (flds.every(f => f.valid) && this.proxyUser.loginId && this.principalUser.loginId) {
      this.http.post<any>(AjaxMethods.Proxy.replace("{id}", this.pollingId + '')
        , <SetProxy>{
          pollingId: Number(this.pollingId),
          paDate: moment(this.PADate).toDate(),
          paNumber: this.PANumber,
          proxyUserLogin_id: this.proxyUser.loginId,
          userLoginId: this.principalUser.loginId,
          oldUserLoginId: this.isEditNew ? null : this.proxies[this.ixCurrProxy].userLoginId
        })
        .subscribe(() => {
          this.isChildPanelVisible = false;
          this.displayProxyList = true; // показать список
          this.isEditable = false;
          var newRecordData = <SetProxy>{
            paDate: moment(this.PADate).toDate(),
            paNumber: this.PANumber,
            userLoginId: this.principalUser.loginId,
            userName: this.principalUser.name,
            proxyUserLogin_id: this.proxyUser.loginId,
            proxyName: this.proxyUser.name
          };
          if (this.isEditNew  // добавили новую запись - в list ее
            && !this.proxies.find(p => p.userLoginId == newRecordData.userLoginId)) {//такого уже не должно быть, что дублируется доверитель, но на всякий случай. Если новая запись с тем же доверителем, то это редактирование
            this.proxies.push(newRecordData);
          }
          else {// редактирование. Заместить
            Object.assign(this.proxies[this.ixCurrProxy], newRecordData);
          }
        });
    }
    else {
      flds.forEach(f => f.control.markAsTouched());
    }
  }
  //Выбрали user
  userClick(u: UserAttrs) {
    if (this.chooseWhat == 'proxy') {
      this.proxyUser = u;
    }
    else {
      this.principalUser = u;
    }
    this.childTmplMode = 'main';
  }

  //Выйти из редактирования
  onCancelEdit() {
    this.isChildPanelVisible = false;
  }

  // Отозвать делегирование
  onRevokeClick() {
    this.common.confirmationDialog.confirm({ message: 'Отозвать делегирование?', title: 'Отзыв делегирования', noText: 'Отменить', yesText: 'Отозвать' })
      .then(yes => {
        if (yes) {
          this.http.delete<any>(AjaxMethods.Proxy.replace('{id}', this.pollingId + '').replace('{principalId}', this.principalUser.loginId+''))
            .subscribe(() => {
              // удалить proxy
              var ix2del = this.proxies.findIndex(p => p.proxyUserLogin_id == this.proxyUser.loginId && p.userLoginId == this.principalUser.loginId);
              if (ix2del >= 0) {
                this.proxies.splice(ix2del, 1);
              }
              this.onCancelEdit();
              this.displayProxyList = true;
              this.isEditable = false; 
            });
        }
      });
  }

  formatDate = Utils.formatDate;

  // список login, которые запрещены для делегирования. 
  get userListExcludeIds(): number[] {
    // Исключить тех, кто уже доверители
    var excl = this.proxies?.map(p => p.userLoginId);//.concat(this.proxies?.map(p => p.proxyUserLogin_id));
    // Добавить в исключения выбранного в редактировании доверителя
    excl = excl.concat([this.principalUser?.loginId]);
    // Добавить в исключения для выбора доверителя тех, кто уже поверенные
    if (this.chooseWhat == "principal") {
      excl = excl.concat(this.proxies?.map(p => p.proxyUserLogin_id));
    }
    return excl;
  //if(this.chooseWhat == 'principal') { // выбор поверенного
    //return (this.proxies?.map(p => p.userLoginId) ?? []).concat( this.principalUser?.loginId ?? 0);// если никто еще не выбран, не страшно - 0 в списке исключений
  }
}
