import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../../common-data.service';
import { AjaxMethods } from '../../models/AjaxMethods';

@Component({
  selector: 'app-public-event-question-edit',
  templateUrl: './public-event-question-edit.component.html',
  styleUrls: ['./public-event-question-edit.component.less']
})
export class PublicEventQuestionEditComponent implements OnInit {

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private common: CommonDataService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // параметры public-events/:publicEventId/polling/:pollingId/question/:questionId
      let pollingId = params["pollingId"];
      this.publicEventId = params["publicEventId"];
      let questionId = params["questionId"];
      // прочитать вопрос с хоста
      this.http.get<Questions>(AjaxMethods.getPublicEventQuestions.replace('{pollingId}', pollingId))
        .subscribe(data => {
          this.questions = data;
          this.polling = data.polling;
          if (!this.questions.isEditor) {
            this.router.navigate([this.backToPollingUrl]);
          }
          else {
            this.question = data.items.find(it => it.id == questionId);
            if (!this.question) {
              this.question = this.initEmpty();
            }
          }
        });
    });
  }
  // Создание нового голосования
  initEmpty(): Question {
    return { id: 0,fullText: '', pollingId: this.polling?.id || 0, no: 1 };
  }
  publicEventId: any;
  questions: Questions;
  question: Question = this.initEmpty();
  polling: Polling = <any>{id:0};

  get backUrl() { return ""; }
  // url для возврата к списку вопросов
  get backToPollingUrl(): string {
    return `public-events/${this.publicEventId}/polling/${this.polling.id}`;
  }
  // Сохранить
  onSaveClick() {
    this.http.post<{ newId: number }>(AjaxMethods.PostPublicEventQuestion, this.question)
      .subscribe(data => {
        this.question.id = data.newId;
        // выйти из редактирования. 
        this.router.navigate([this.backToPollingUrl]);
      });
  }
  // Отмена сохранения. Вернуться , как при save или, если не сохраняли новый, то на корень голосования
  onCancelEditClick() {
    if (this.polling.id) {
      this.router.navigate([this.backToPollingUrl]);
    }
    else {
      this.router.navigate([`public-events/${this.publicEventId}/polling`]);
    }
  }
  // Удалить
  onDeleteClick() {
    if (this.question?.allVotes?.length) {// с голосами вопрос удалить нельзя
      this.common.confirmationDialog.msgBox({ message:"По вопросу был принят голос(а). Удаление невозможно" });
    }
    else {
      this.common.confirmationDialog.confirm({ message: "Удалить вопрос?", title: "Удаление" })
        .then(confirmed =>
          confirmed && this.http.delete<any>(AjaxMethods.DeletePublicEventQuestion.replace('{questionId}', this.question.id + '')).subscribe(() => {
            this.router.navigate([this.backToPollingUrl])
          }));
    }
  }
}
