<div appHeight100vh class="screen-wrap" #screenWrap>
  <app-screen-top-header btnBackground="violet" [backUrl]="backUrl"></app-screen-top-header>
  <div class="absbackdrop">
    <div class="upper-part">

    </div>
    <div class="grey-backdrop"></div>
  </div>

  <div class="screen-panel" #screenPanel>
    <ng-container *ngTemplateOutlet="fixedTmpl"></ng-container>

    <div class="header">
      <div class="text">{{header}}</div><!-- {{screenWrap.style.height}} client:{{screenWrap.clientHeight}} screenPanelH:{{screenPanel.clientHeight}}-->
      <div *ngIf="showFilter" class="icon" (click)="isFilterVisible = !isFilterVisible">
        <app-icon-button icon="filter" [applaySm]="true" [showRedDot]="isFilterApplied"></app-icon-button>
      </div>
    </div>

    <div class="screen-body" (scroll)="onScreenBodyScroll($event)">

      <ng-content></ng-content>
    </div>



    <!--Фильтр-->
    <app-child-panel header="Фильтр" [(isVisible)]="isFilterVisible">
      <div class="child-panel-wrapper">
        <div class="filter-body"><ng-container *ngTemplateOutlet="filterTmpl"></ng-container></div>
        <!--кнопки фильтра-->
        <div class="filter-btns">
          <button (click)="onFilterResetClick()" class="dialog-button action-red">Сбросить</button>
          <button (click)="onFilterApplyClick()" class="dialog-button action-violet">Применить</button>
        </div>
      </div>
    </app-child-panel>
  </div>
</div>
