import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-child-panel',
  templateUrl: './child-panel.component.html',
  styleUrls: ['./child-panel.component.less']
})
export class ChildPanelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  header: string;
  @Input()
  height: string = 'auto';
  @Input()
  width: string = '375px'; // 'calc(100% - 40px)';
  @Input()
  isVisible = false;
  @Input()
  isPositionFixed = false;
  @Output()
  isVisibleChange = new EventEmitter<boolean>();
  @Output()
  onCloseClick = new EventEmitter<void>();

  onCloseBtnClick() {
    this.onCloseClick.emit();
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }

}
