import { Component, ElementRef, OnDestroy, OnInit, ViewChild, NgZone  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AjaxMethods } from '../models/AjaxMethods';
import { Observable, Subject, Subscription, pipe, from } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CommonDataService } from '../common-data.service';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-tchlink',
  templateUrl: './tchlink.component.html',
  styleUrls: ['./tchlink.component.less']
})
export class TCHLinkComponent implements OnInit, OnDestroy {

  constructor(private http: HttpClient, private commonSrv: CommonDataService, private ngZone: NgZone) {
    //commonSrv.TCHLinkEditBackUrl = "tch-link";
    this.showAmount = false;
  }

  ngOnDestroy(): void {
    if (this.searchTextChangedSubs) {
      this.searchTextChangedSubs.unsubscribe();
    }
  }

  selectCompany: any = 'Обеспечительный платеж';
  searchText: any = ''; // строка поиска
  searchTextChanged = new Subject<string>();
  searchTextChangedSubs: Subscription;
  onSearchTextChange(v) {
    this.searchTextChanged.next(v);
  }

  @ViewChild("inpSearch")
  inpSearch: ElementRef;

  opts = [];


  get filteredOptions() { return this.opts; }

  displayFn(opt) {
    if (!opt) return "";
    return `${opt.comp_code} ${opt.comp_name}`;
  }
  // Выбрана организация из dropdown
  onCompanySelected(opt) {
    console.log("Company selected. Opt:", opt, "searchText:", this.searchText);

    this.selectCompany = this.searchText.comp_name;

    this.ngOnInit(this.searchText?.comp_id);
    if (this.inpSearch) {
      this.inpSearch.nativeElement?.blur();
    }
  }
  // Кнопка "Очистить" в строке поиска
  onClearSearch() {
    this.showAmount = false;
    this.searchText = null;
    //this.selectCompany = 'Обеспечительный платеж';
    this.selectCompany = '';
    this.amountFmt = '0.00  ₽';
    this.dtFmt = '';
  }

  onClick() {
    if (this.sioUrl) {
      if (this.commonSrv.platform === "web") {
        document.location.href = this.sioUrl;
      }
      else {
        this.ngZone.run(() => Browser.open({ url: this.sioUrl }));
      }
    }

  }

  ngOnInit(compId?:number): void {
    this.http.get<TCHLinkResponse>(AjaxMethods.tchLinkGetAgencyAmount.replace('{id}', compId+'')).subscribe(am => {
      this.amount = am.amount;
      this.dtFmt = am.dtFmt;
      this.amountFmt = am.amountFmt;
      this.isTCHUser = am.isTCHUser;
      this.sioUrl = am.sioUrl;
      if (this.isTCHUser && this.searchText) {
        this.showAmount = true;
      }
      if (!this.searchTextChangedSubs && am.isTCHUser) {// если еще не подписывались
        this.searchTextChangedSubs = this.searchTextChanged
          .pipe(
            debounceTime(400)
            , distinctUntilChanged()
          )
          .subscribe(() => {
            let context = this.searchText;
            if (context && context.length > 2) {
              this.http.get<any>(AjaxMethods.searchCompanyDict, { params: { context: context } })
                .subscribe(data => {
                  this.opts = data;
                });
            }
            else if (this.opts && this.opts.length > 0) {
              this.opts = [];
            }
          });
      }
    });
  }
  amount: number;
  dtFmt: string;
  amountFmt: string;
  isTCHUser: boolean;
  showAmount: boolean;
  sioUrl: string;
}
