import { AfterViewChecked, Directive, ElementRef, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appMoveOutside]'
})
export class MoveOutsideDirective implements AfterViewChecked {

  constructor(private htmlEl: ElementRef) { }

  @Input("appMoveOutside")
  outsideEl: any;

  ngAfterViewChecked(): void {
    let thisEl = <HTMLElement>this.htmlEl.nativeElement;
    if ((<any>thisEl).moveDone) return;
    if (this.outsideEl?.parentElement) {
      (<any>thisEl).moveDone = true;
      let outsideParent = this.outsideEl.parentElement;
      if (thisEl.parentElement?.removeChild(thisEl) && outsideParent) {
        outsideParent.insertAdjacentElement("beforeEnd", thisEl);
      }
    }
  }

}
