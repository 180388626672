import { HttpClient } from '@angular/common/http';
import { Component, OnInit, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';
import { ChatService, ChatMessageType } from '../services/chat.service';
import { CommonDataService } from '../common-data.service';
import { AjaxMethods } from '../models/AjaxMethods';
import { Utils } from '../utils';
import { PublicEventComponent } from './public-event/public-event.component';



@Component({
  selector: 'app-public-events',
  templateUrl: './public-events.component.html',
  styleUrls: ['./public-events.component.less']
})
export class PublicEventsComponent implements OnInit {

  constructor(private http: HttpClient, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private chatSrv: ChatService, private commonSrv: CommonDataService, private _ngZone: NgZone) {
  }

  destroy$: Subject<boolean> = new Subject<boolean>();

  publicEvents: PublicEvents = <any>{};
  isEditor: boolean;
  loginId: number;

  ngOnInit(): void {
    this.route.url.subscribe(urls => {
      if (this.commonSrv.publicEventsCCS === null || this.commonSrv.publicEventsCCS === undefined) {//если значение потеряно, например по нажатию F5
          this.commonSrv.publicEventsCCS = (urls[0].path === "public-events-ccs");
      }


      // !!!CHAT
      let tmpMessages: PublicEventChatItem[] = [];
      if (this.chatSrv) {
        // переннесено в Home
        // this.chatSrv.connect(data.events.filter(pe => pe.id > 0 && pe.isAnyChat).map(pe => pe.id).join(','), this.loginId);

        // подписка на события от Chat Hub
        this.chatSrv.messageReceivedObservable.pipe(
          takeUntil(this.destroy$),
          distinctUntilChanged((prev, curr) => prev.lastMessageId === curr.lastMessageId)
        ).subscribe((it: PublicEventChatItem) => {
          if (it) {

            if (it.messages?.length > 0 && this.chatSrv.clientId !== it.clientId) {
              this._ngZone.run(() => {
                // Обновление badge
                console.debug(`Unread count badge update. EventId=${it.publicEventId}, ClientId=${it.clientId}`);
                if (this.publicEvents) {
                  this.incUnreadCount(this.publicEvents.events, it);
                }
                else {
                  // Если еще не зашли на страницу, складываем сообщения в приемник
                  tmpMessages.push(it);
                }

              });
            }
          }
        });
      }

      this.http.get<PublicEvents>(AjaxMethods.getPublicEvents, { params: { showCCSOnly: this.commonSrv.publicEventsCCS } })
        .subscribe(data => {
          this.publicEvents = data;
          this.isEditor = data.isEditor;
          this.loginId = data.loginId;

          // !!!CHAT
          if (this.chatSrv) {
            // Проверка и восстановление соединения чата
            const now = new Date();
            const events = this.publicEvents.events.filter(ev => ev.isAnyChat && ev.isEventUser && now >= new Date(ev.publishDateBegin) && now <= new Date(ev.publishDateEnd)).map(ev => ev.id);
            if (events && events.length > 0) {
              this._ngZone.runOutsideAngular(() => {
                this.chatSrv.connect(events, this.loginId);
              });
            }

            if (tmpMessages && tmpMessages.length > 0) {
              tmpMessages.forEach((it) => {
                this.incUnreadCount(this.publicEvents.events, it, true);
              });
            }
          }

      });
    });
  }



  ngOnDestroy(): void {

    //!!!CHAT
    if (this.destroy$) {
      this.destroy$.next(true);
      this.destroy$.complete();
      this.destroy$.unsubscribe();

      console.log("PublicEvent Component Chat Unsubscribe");
    }
    // !!! не закрываем
    // this.chatSrv.close();
  }
  
  incUnreadCount(events: PublicEvent[], it: PublicEventChatItem, isUnreadInc = false) {
    if (events) {
      const ev = events.find(x => x.id === it.publicEventId);
      if (ev) {
        if (it.chatId === 0 && (it.isPrivate && it.loginId === this.loginId)) {
          ev.unreadCount++;
        }
        else if (it.chatId > 0 && ev.userChats.some(y => y.chatId === it.chatId)) {
          if (isUnreadInc) {
            const readMessageOrder = ev.userChats.find(x => x.chatId === it.chatId)?.readMessageOrder;
            if (readMessageOrder > 0 && it.lastMessageOrder > readMessageOrder) {
                ev.unreadCount++;
            }
          }
          else {
            ev.unreadCount++;
          }
        }
      }
    }
  }



  //Кнопка "удалить" доступна, если есть хоть один isSelected==true
  get isDeleteEnabled(): boolean {
    return this.publicEvents?.events && this.publicEvents.events.some(ev => ev.isSelected);
  }
  // Новое мероприятие
  onNewClick() {
    this.router.navigate(["/public-events/0/edit"], { state: { data: { publicEvents: this.publicEvents, publicEvent:null } } });
  }
  getInfoBar(it: PublicEvent) {
    return PublicEventComponent.getInfoBar(it);
  }
  strToHex(str: string) {
      var hex, i;

      var result = "";
      for (i = 0; i < str.length; i++) {
        hex = str.charCodeAt(i).toString(16);
        result += '\\u' + ("000" + hex).slice(-4);
      }
    return result;
  }
  filterDates = { filterDateBegin: null, filterDateEnd: null };
  // отфильтрованные события
  get filteredEvents(): PublicEvent[] {
    if (!this.publicEvents || !this.publicEvents.events) return [];
    if (!this.isFilterApplied) return this.publicEvents.events;
    console.debug(`filterDateBegin:${this.filterDates.filterDateBegin} filterDateEnd:${this.filterDates.filterDateEnd}`);
    Utils.moment2Date(this.filterDates, "filterDateBegin", "filterDateEnd");

    let searchWords = this.filterSearchText ? this.filterSearchText.split(" ") : [];
    // строим RegExp
    let expr = searchWords.map(s => "[^А-Яа-я]" + s + ".*").join('');
    console.log("searchExpr=" + expr);
    let regExp = null; 
    if (expr) {
      regExp = new RegExp(expr, 'i');
    }
    let dt = this.filterDates;
    return this.publicEvents.events
      .filter(ev => (!regExp || regExp.test(' ' + ev.title + ' ' + ev.locationText))
        && ((!dt.filterDateBegin || !dt.filterDateEnd) ||
        (dt.filterDateBegin && dt.filterDateEnd && dt.filterDateBegin <= new Date(ev.endDate) && dt.filterDateEnd >= new Date(ev.beginDate)))
        && (!dt.filterDateBegin || dt.filterDateBegin <= new Date(ev.beginDate))
        && (!dt.filterDateEnd || dt.filterDateEnd >= new Date(ev.endDate))
      );
  }
  onFilterDateBeginChange(v) {
    this.filterDates.filterDateBegin = moment(v).toDate();
    console.debug('filterDateBegin:' + this.filterDates.filterDateBegin);
  }
  onFilterDateEndChange(v) {
    this.filterDates.filterDateEnd = moment(v).toDate();
  }

  // предстоящие события 
  get actualEvents(): PublicEvent[] {
    let now = new Date();
    return this.filteredEvents.filter(ev => now <= new Date(ev.endDate));
  }
  // Прошедшие события. Если год (yy) не задан, то все прошедшие события
  getHistoryEvents(yy: number): PublicEvent[] {
    return this.filteredEvents
      .filter(ev => {
        if (!yy) return true;
        if (new Date() <= new Date(ev.endDate)) return false; // событие еще не кончилось - оно не в истории
        var beginDate = new Date(ev.beginDate);
        return beginDate.getFullYear() == yy;
      });
  }
  histYear;// год, по которому показать прошедшие события
  // годы по прошедшим событиям
  get historyYears(): number[] {
    let events = this.getHistoryEvents(null);
    let years = {};
    events.forEach(ev => years[new Date(ev.beginDate).getFullYear()] = '');
    return <any>Object.keys(years).sort().reverse();
  }
  isFilterApplied = false;
  filterSearchText = "";
  onFilterApply() {
    console.log("filter", this.filterSearchText, this.filterDates);
    this.isFilterApplied = true;
  }
  onFilterReset() {
    this.filterSearchText = '';
    this.filterDates = { filterDateBegin: null, filterDateEnd: null };
    this.isFilterApplied = false;
  }
}
